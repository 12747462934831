import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import { pdfjs  } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import "./TermsAndConditions.css";
import mammoth from 'mammoth';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const TermsAndConditions = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        tryCatchFinally,
        useParams,
        layoutManager,
        useReducerSmart,
        pageProfile,
        AppLoading,
        BindedCheckBox,
        getData,
        toastPrint,
        useNavigate,
    } = PageDependencies();
    const nav = useNavigate();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const [checkBoxDisabled, setCheckBoxDisabled] = useState(false);
    const {} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        acceptWebsiteTerms: false,
        acceptPrivacyCollectionNotice: false
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [docContent, setDocContent] = useState('');
    const loadUserInfo = async ()=>{
        const userRoleResponse = await getData.processPost(true, "api/DefaultModel/Accessor/Get", {});
        if(userRoleResponse !== undefined){
          if(userRoleResponse.viewModel !== undefined){
            const {userRole, isAgreeCondition} = userRoleResponse.viewModel;
            if (userRole === 'Administrator') {
                nav('/Admin/Home', { replace: true });
            }else if (isAgreeCondition) {
                nav('/', { replace: true });
            }
          }
        }
      }

    useEffect(()=>{
        layoutManager.Layout_HideHeadter_Footer_HideSideBar(props);
        loadUserInfo();
        const fetchDocx = async () => {
            try {
                const response = await fetch('/AccuRate Website Terms Version 1.0 150724.docx');
                const arrayBuffer = await response.arrayBuffer();
                const result = await mammoth.convertToHtml({ arrayBuffer });
                setDocContent(result.value);
            } catch (error) {
                console.error('Error fetching or processing .docx file:', error);
            }
        };
        fetchDocx();
        if(init===false) setInit(true);
    },[]);

    const submit = (e)=>{
        e.preventDefault();
        const postUrl = "api/TermsAndConditions/Accessor/Edit";
        setLoading({ isLoading: true, text: "submitting agreement" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            if (response.state){
                nav('/', { replace: true });
            }
            else{
                toastPrint.printErrorMessage(response.message);
            }
        }, setLoading);
    };

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className=" pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Terms and Conditions
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        <form noValidate="noValidate">
                            <div className="form-row">
                                <div className="col-lg-12">
                                <h6 style={{marginLeft:"12px", marginBottom: "20px", marginTop: "20px", color: "red"}}>Please read this agreement carefully before accepting it at the end of this page.</h6>
                                    <div className="form-row row">
                                        <style>
                                            {`
                                                ol {
                                                    counter-reset: section;
                                                    list-style-type: none;
                                                }
                                                ol > li {
                                                    counter-increment: section;
                                                    font-weight: bold;
                                                    padding-top: 10px;
                                                }
                                                ol > li::before {
                                                    content: counter(section) ".    ";
                                                }
                                                ol ol {
                                                    counter-reset: subsection;
                                                }
                                                ol ol > li {
                                                    counter-increment: subsection;
                                                    font-weight: normal;
                                                    padding-top: 0px;
                                                }
                                                ol ol > li::before {
                                                    content: counter(section) "." counter(subsection) "    ";
                                                }
                                                ol ol ol {
                                                    counter-reset: subsubsection;
                                                }
                                                ol ol ol > li {
                                                    counter-increment: subsubsection;
                                                }
                                                ol ol ol > li::before {
                                                    content: "(" counter(subsubsection, lower-alpha) ")    ";
                                                }
                                            `}
                                        </style>
                                        <div style={{ padding: '20px' }}>
                                            <div
                                                style={{ marginTop: '20px', whiteSpace: 'pre-wrap' }}
                                                dangerouslySetInnerHTML={{ __html: docContent }}
                                            />
                                        </div>

                                        <div className="custom-checkbox custom-control">                               
                                            <BindedCheckBox
                                                id="HasAcceptedWebsiteTerms"
                                                name="HasAcceptedWebsiteTerms"
                                                className="custom-control-input"
                                                disabled = {checkBoxDisabled}
                                                binding={[
                                                    viewModel,
                                                    "acceptWebsiteTerms",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedCheckBox>
                                            <label
                                                style={{ marginLeft: "5px" }}
                                                htmlFor="HasAcceptedWebsiteTerms"
                                                className="custom-control-label"
                                            >
                                            I accept the <a href="/Software as a Service Agreement (for Direct Users of AccuRate Enterprise) Version 1.0 150724.pdf" target="_blank">Terms of Service</a>.
                                            </label>
                                        </div>
                                        <div className="custom-checkbox custom-control">
                                            <BindedCheckBox
                                                id="HasAcceptedPrivacyCollectionNotice"
                                                name="HasAcceptedPrivacyCollectionNotice"
                                                className="custom-control-input"
                                                disabled = {checkBoxDisabled}
                                                binding={[
                                                    viewModel,
                                                    "acceptPrivacyCollectionNotice",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedCheckBox>
                                            <label
                                                style={{ marginLeft: "5px" }}
                                                htmlFor="HasAcceptedPrivacyCollectionNotice"
                                                className="custom-control-label"
                                            >
                                            I accept the terms of the <a href="/Privacy Collection Notice Version 1.0 150724.pdf" target="_blank">Privacy Collection Notice</a> which sets out how CSIRO collects and manages personal information in accordance with the Privacy Act 1988 (Cth).
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="divider row"></div>
                            <div className="row">
                                <div className="d-flex align-items-center col-md-6">
                                    <div className="ml-auto">
                                        <button id="SubmitButton" className="btn btn-primary btn-lg" disabled={!(viewModel.acceptWebsiteTerms && viewModel.acceptPrivacyCollectionNotice)} onClick={(e)=>submit(e)}>Accept</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default  connectAppStore(TermsAndConditions);
