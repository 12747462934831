
import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import ShadingSchemeDataTable from "../Shading/ShadingSchemeDataTable";

const ShadingSchemes = (props) => {
    const {
        layoutManager,
        useParams,
        useLocation,
        getAppStore,
        pageProfile,
        Fragment, 
        useEffect, 
        useState,
        loaModelFromApi
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const redirectUrl = useLocation().pathname;
    const projectId = useParams().projectId;
    const developmentId = useParams().developmentId;
    const designVariationId = useParams().designVariationId;
    const designVariationVersionName = useParams().designVariationVersionName;
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/DefaultModel/Accessor/Get";
    const [loading, setLoading] = useState({isLoading:false,text:""});
    const [extraPostModelForDevelopment, setExtraPostModelForDevelopment] = useState({
        developmentId: developmentId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName
    });
    const [data, setData] = useState([]);
    const [tableReloadForDevelopment, setTableReloadForDevelopment] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [viewModel, setViewModel] = useState({
        developmentId: developmentId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName
    });

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, setViewModel, setLoading, "Loading data", appStoreDispatch);
    },[]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                            <i className="pe-7s-umbrella icon-gradient bg-tempting-azure">
                            </i>
                            </div>
                            <div>
                            Shading Schemes
                            <div className="page-title-subheading">
                                {pageProfile.getPageSubTitle(appStoreState)}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ShadingSchemesAccordion">
                    <div className="main-card mb-3 card">
                        <div className="card-header">
                            <button type="button" data-toggle="collapse" data-target="#ShadingSchemesCollapsibleDiv" aria-expanded="true" aria-controls="ShadingSchemesCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                                <h5 className="m-0 p-0 card-title">Shading Schemes</h5>
                            </button>
                        </div>
                        <div data-parent="#ShadingSchemesAccordion" id="ShadingSchemesCollapsibleDiv" className="collapse show">
                            <div className="card-body">
                            <ShadingSchemeDataTable
                                tableReload={tableReloadForDevelopment}
                                setTableReload={setTableReloadForDevelopment}
                                extraPostModel = {extraPostModelForDevelopment}
                                redirectUrl = {redirectUrl}
                                debug = {debug}
                            ></ShadingSchemeDataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(ShadingSchemes);