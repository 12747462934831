
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import ConstructionElementDataTable from "../../../Accessor/Construction/ConstructionElementDataTable";
import InfiltrationPenetrationDataTable from "../InfiltrationPenetrationDataTable";

const FloorCeilingDetail = (props) =>{
  const { 
      layoutManager,
      guid,
      useReducerSmart,
      useParams,
      useLocation,
      useFromDefaultValue,
      BaseFormNoHeader,
      BindedInput,
      BindedSelect,
      getAppStore,
      pageProfile,
      loaModelFromApi,
      FormValidate,
      BindedInputNumber,
      htmlToReactParse,
      Fragment, useEffect, useState
  } = PageDependencies();
  const debug = layoutManager.Layout_GetDebugModel(props);
  const emptyGuid = guid.emptyGuid();
  const currentUrl = useLocation().pathname;
  const defaultParams = pageProfile.getDefaultParams(useParams());
  const [reloadForm, setReloadForm] = useState("");
  let {designVariationVersionName, developmentId, projectId, designVariationId, elementId, zoneId, redirectUrlHex} = defaultParams;
  if(zoneId === "undefined" || zoneId === undefined) zoneId = emptyGuid;
  const defaultViewModel = {
      ...defaultParams,
      id:emptyGuid,
      zoneId: zoneId,
      elementType: props.elementType,
      selectedItemIds: [elementId]
  };
  const [extraPostModelForConstruction, setExtraPostModelForConstruction] = useState({
      developmentId: developmentId,
      projectId: projectId,
      designVariationId: designVariationId,
      designVariationVersionName: designVariationVersionName,
      constructionType:"FloorCeiling"
  });
  const [tableReloadForConstruction, setTableReloadForConstruction] = useState("");
  const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
  const [loading, setLoading] = useState({ isLoading: false, text: "" });
  const [appStoreState, appStoreDispatch] = getAppStore(props);
  const postUrl = "api/Element/Accessor/UpdateFloorCeiling";
  const getUrl = "api/Element/Accessor/GetFloorCeiling";
  const validateMethods = {
      name:(value)=>{return FormValidate.validateTextRequired(value, "Floor/Ceiling name");},
      constructionId:(value)=>{ return value === emptyGuid ? "Must select a construction." : "";},
      grossArea: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Area");},
      openingArea: (value) => { return FormValidate.validateNumberGreaterThanOrEqual0(value, "Opening area");},
      floorNumber: (value) => { return FormValidate.validateNumberGreaterThanOrEqual0(value, "Floor number");},
      adjacentZoneAboveId:(value)=>{ return value === emptyGuid ? "Must select an adjacent zone." : "";},
      adjacentZoneBelowId:(value)=>{ return value === emptyGuid ? "Must select an adjacent zone." : "";},
      openingArea:(value)=>{ if(value === '') {
        dispatchViewModel({...viewModel, openingArea:0});
      }
      return "";
    }
  };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

  const [init, setInit] = useState(false);

  useFromDefaultValue("FloorCeilingDefaultValue_f4c4706e-dfa7-4414-97b1-9f444ab40112", viewModel, dispatchViewModel, ["constructionId", "floorNumber"], [emptyGuid, 0],
      (v)=>{
          //update cooike condition
          return v.constructionId != emptyGuid || v.floorNumber > 0;
      },
      (v)=>{
          //update view model condition
          return v.elementId == guid.emptyGuid() && v.constructionId == emptyGuid && v.floorNumber == 0;
  },);

  useEffect(()=>{   
    layoutManager.Layout_Headter_Footer_SideBar(props);
    loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Floor/Ceiling", appStoreDispatch, undefined, setInit);
  },[reloadForm]);

  useEffect(()=>{   
    if(init){
      dispatchViewModel({...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId]}, ()=>{
        setReloadForm(guid.newGuid());
      });
    }
  },[elementId]);

  const generateStayUrl = (submitResponse)=>{
    let redirectUrl = currentUrl;
    if(elementId === emptyGuid){
      //"ElementAccessor/Detail/Floor/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:redirectUrlHex"
      redirectUrl =  "/ElementAccessor/Detail/"+props.elementType+"/" + designVariationVersionName + "/" + developmentId +"/"+projectId+"/"+designVariationId+"/"+zoneId+"/"+submitResponse.elementId+"/"+redirectUrlHex;
    }
    return redirectUrl;
  };

  return (
    <Fragment>
      {debug && <p>{JSON.stringify(viewModel)}</p>}
      <div className="app-main__inner">
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-safe icon-gradient bg-tempting-azure"></i>
              </div>
              <div>
                {props.elementType}
                <div className="page-title-subheading">
                  {pageProfile.getPageSubTitle(appStoreState)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-12">
            <div className="main-card mb-3 card">
              <BaseFormNoHeader
                id="FloorCeilingDetail"
                submitButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl, text: "Save and close"}}
                submitButtonAndStay={{ text:"Save", postUrl: postUrl, redirectMethod: (submitResponse)=>{return generateStayUrl(submitResponse)}}}
                cancelButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl }}
                validate={formValidate}
                setReload = {setReloadForm}
                loading={loading}
                init={init}
                setLoading={setLoading}
                data={viewModel}
                cardTitle={props.elementType}
              >
                <div className="form-row row">
                  <div className="col-6">
                    <div className="position-relative form-group">
                      <label htmlFor="Name">Name *</label>
                      <BindedInput
                        className="form-control"
                        id="Name"
                        name="Name"
                        type="text"
                        validate={formValidate}
                        binding={[viewModel, "name", bindingViewModel]}
                      ></BindedInput>
                    </div>
                  </div>
                  {props.elementType === "Ceiling" &&
                  <div className="col-6">
                    <div className="position-relative form-group">
                      <label htmlFor="AdjacentZoneAboveId">Zone Above *</label>
                      <BindedSelect
                        className="form-control"
                        id="AdjacentZoneAboveId"
                        name="AdjacentZoneAboveId"
                        options="adjacentZonesAbove"
                        validate={formValidate}
                        binding={[viewModel, "adjacentZoneAboveId", bindingViewModel]}
                      ></BindedSelect>
                    </div>
                  </div>}
                </div>
                <div className="form-row row">
                  <div className="col-6">
                    <div className="position-relative form-group">
                      <label htmlFor="FloorCeilingConstructionId">
                        Construction *
                      </label>
                      <BindedSelect
                        className="form-control"
                        id="FloorCeilingConstructionId"
                        name="FloorCeilingConstructionId"
                        options="constructions"
                        validate={formValidate}
                        binding={[viewModel, "constructionId", bindingViewModel]}
                      ></BindedSelect>
                    </div>
                  </div>
                  {props.elementType !== "Ceiling" &&
                  <div className="col-6">
                    <div className="position-relative form-group">
                      <label htmlFor="AdjacentZoneBelowId">Zone Below *</label>
                      <BindedSelect
                        className="form-control"
                        id="AdjacentZoneBelowId"
                        name="AdjacentZoneBelowId"
                        options="adjacentZonesBelow"
                        validate={formValidate}
                        binding={[viewModel, "adjacentZoneBelowId", bindingViewModel]}
                      ></BindedSelect>
                    </div>
                  </div>}
                </div>
                <div className="form-row row">
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <BindedInputNumber inputId="FloorCeilingGrossArea" label={htmlToReactParse("Area (m<sup>2</sup>) *")} min="0" minFractionDigits={0} maxFractionDigits={2} binding={[viewModel, "grossArea", bindingViewModel]} validate = {formValidate}/>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <BindedInputNumber inputId="FloorCeilingOpeningArea" label={htmlToReactParse("Opening area (m<sup>2</sup>) *")} min="0" minFractionDigits={0} maxFractionDigits={2} binding={[viewModel, "openingArea", bindingViewModel]} validate = {formValidate}/>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <label htmlFor="OpeningTypeId">
                        Opening type *
                      </label>
                      <BindedSelect
                        className="form-control"
                        id="OpeningTypeId"
                        name="OpeningTypeId"
                        options="openingTypes"
                        validate={formValidate}
                        binding={[viewModel, "openingTypeId", bindingViewModel]}
                      ></BindedSelect>
                    </div>
                  </div>
                  
                </div>
                <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                      <label htmlFor="FloorNumber">
                        Floor Number *
                      </label>
                      <BindedInput
                        className="form-control"
                        id="FloorNumber"
                        name="FloorNumber"
                        type="number"
                        min="0"
                        validate={formValidate}
                        binding={[viewModel, "floorNumber", bindingViewModel]}
                      ></BindedInput>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="position-relative form-group">
                      <BindedInputNumber inputId="EdgeInsulationRValue" label="Edge Insulation (R) *" min="0" max="4.5" step='0.5' minFractionDigits={0} maxFractionDigits={1} binding={[viewModel, "edgeInsulationRValue", bindingViewModel]} validate = {formValidate}/>
                    </div>
                  </div>
                </div>
              </BaseFormNoHeader>
            </div>
          </div>
          {init === true &&
            <>
                <div className="col-xl-6 col-lg-12">
            <div className="main-card mb-3 card">
              <div className="card-header">
                <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                  <h5 className="m-0 p-0 card-title">{props.elementType} Constructions</h5>
                </button>
              </div>
              <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                <div className="card-body">
                  <div className="separator"></div>
                    <ConstructionElementDataTable
                        tableId = {"FloorCeilingElement-fa8e2163-4353-407e-a209-47d0822cf59c"}
                        tableReload={tableReloadForConstruction}
                        setTableReload={setTableReloadForConstruction}
                        extraPostModel = {extraPostModelForConstruction}
                        constructionType = "FloorCeiling"
                        redirectUrl = {currentUrl}
                        debug = {debug}
                    >
                    </ConstructionElementDataTable>
                </div>
              </div>
            </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">INFILTRATIONS AND CEILING PENETRATIONS</h5>
                        </button>
                    </div>
                    <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <InfiltrationPenetrationDataTable
                                id="InfiltrationPenetration_be4e1fce-8b86-4568-8c82-30e7989057e6"
                                elementType="Ceiling"
                                elementId={elementId}
                                designVariationVersionName={designVariationVersionName}
                                developmentId={developmentId}
                                projectId={projectId}
                                designVariationId={designVariationId}
                                zoneId={zoneId}
                            ></InfiltrationPenetrationDataTable>
                        </div>
                    </div>
                </div>
           </div>
            </>
          }
      
        </div>
      </div>
    </Fragment>
  );
}
export default connectAppStore(FloorCeilingDetail);