import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import PaymentDataTable from "./PaymentDataTable";
const UserRefunds = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        useReducerSmart,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const {} = defaultParams;
    const defaultViewModel = {
        ...defaultParams
    };
    const [extraPostModel, setExtraPostModel] = useState({
        ...defaultParams,
        paymentTypeId: 2
      });
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/DefaultModel/Accessor/Get";
    const [reloadForm, setReloadForm] = useState("");
    const [data, setData] = useState([]);

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        //loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Payments", appStoreDispatch, undefined, setInit);
    },[]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Refund History
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <PaymentDataTable
                                debug = {debug}
                                data = {data}
                                setData = {setData}
                                extraPostModel = {extraPostModel}
                                url="api/Credit/Accessor/RetrieveUserRefunds"
                                tableReload = {reloadForm}
                                setTableReload = {setReloadForm}
                                showInvoice = {false}
                                tableId = "UserRefund_dfd871d6-d26f-49a7-8f9c-220e2b1e8eb8"
                            ></PaymentDataTable>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default  connectAppStore(UserRefunds);
