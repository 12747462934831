const GetSelectedItemText = (selectedValue, options) => {
  if (options === undefined) return "";
  if (options === null) return "";
  for (let i = 0; i < options.length; i++) {
    const currentObject = options[i];
    if (currentObject.value === selectedValue) {
      return currentObject.text;
    }
  }
  return "";
};
export default GetSelectedItemText;
