import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";
const RefundCredits = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        stringCoding,
        tryCatchFinally,
        layoutManager,
        getData,
        useReducerSmart,
        BindedInput,
        getAppStore,
        useNavigate,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const nav = useNavigate();
    const [init, setInit] = useState(false);
    const { id } = defaultParams;
    const defaultViewModel = {
        selectedItemIds: [id]
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/CreditAdmin/RefundCredits";
    const validateMethods = {
        qualitySimulation:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Simulation number");},
        qualityCertificate:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Certificate number");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Add Credits", appStoreDispatch, undefined, setInit);
    },[]);

    const calculateAmount = (unit, quanlity) => {
        if (unit === undefined || unit === "" || unit === null) unit = 0;
        if (quanlity === undefined || quanlity === "" || quanlity === null) quanlity = 0;
        unit = Number(unit);
        quanlity = Number(quanlity);
        return ((unit * quanlity)).toFixed(2);
    };

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/CreditAdmin/InitializeRefund";
        setLoading({ isLoading: true, text: "Submitting" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            const urlParams = stringCoding.encodeObject(response.viewModel);
            nav("/Admin/Credits/Refund/Approve/" + urlParams);
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Refund Credits
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                     <div className="main-card mb-3 col-3 card">
                     <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                         <div className="card-body">
                         <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <label>Credit type: {viewModel.creditType}</label>
                                     </div>
                                 </div>
                             </div>
                             <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <label>Unit price: {viewModel.unitPrice}</label>
                                     </div>
                                 </div>
                             </div>
                             <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <label htmlFor="NumberOfRefund">Number of refund * </label>
                                         <BindedInput
                                             className="form-control"
                                             id="NumberOfRefund"
                                             name="NumberOfRefund"
                                             type="number"
                                             min="0"
                                             max={viewModel.availableOfRefund}
                                             validate = {formValidate}
                                             binding={[viewModel, "numberOfRefund", bindingViewModel]}
                                         ></BindedInput>
                                     </div>
                                 </div>
                             </div>
                             <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <label htmlFor="RefundAmount">Refund amount (AUD): {calculateAmount(viewModel.unitPrice, viewModel.numberOfRefund)}</label>
                                     </div>
                                 </div>
                             </div>
                             <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <label htmlFor="Reason">Refund reason </label>
                                         <BindedInput
                                             className="form-control"
                                             id="Reason"
                                             name="Reason"
                                             type="text"
                                             binding={[viewModel, "reason", bindingViewModel]}
                                         ></BindedInput>
                                     </div>
                                 </div>
                             </div>
                             <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Refund</button>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
                }
           
            </div>
        </Fragment>
    );
}
export default connectAppStore(RefundCredits);
