import React, { Component, Fragment } from "react";
import cx from "classnames";
import Nav from "../AppNav/VerticalNavWrapper";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderLogo from "../AppLogo";
import { getAppStore, connectAppStore } from "../../Store/AppStore";

class AppSidebar extends Component {
  constructor(props){
    super(props);
  }
  state = {};
  toggleMobileSidebar = () => {

  };
    getHeightStyle() {
        let body = document.body;
        let  html = document.documentElement;
        let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) * 0.8;
        height = Math.ceil(height);
        return height;
    }
  render() {
    let {
      enableBackgroundImage,
      backgroundImage,
      backgroundImageOpacity,
    } = this.props;

    const [appStoreState, appStoreDispatch] = getAppStore(this.props);
    const theme = appStoreState.appThemeReducer;
    let {
      showSideBar
    }  = theme;

    return (
      <Fragment>
        <div className={showSideBar?"":"hideAppSiderBar"}>
        <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar}/>
        <TransitionGroup>
          <CSSTransition component="div"
            className="app-sidebar sidebar-shadow"
             appear={true} enter={false} exit={false} timeout={500}>
            <div>
              <HeaderLogo />
                <PerfectScrollbar style={{ height: this.getHeightStyle() }} >
                <div className="app-sidebar__inner">
                <Nav />
                </div>
              </PerfectScrollbar>
              <div className={cx("app-sidebar-bg", backgroundImageOpacity)}
                style={{
                  backgroundImage: enableBackgroundImage
                    ? "url(" + backgroundImage + ")"
                    : null,
                }}>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
        </div>
      </Fragment>
    );
  }
}

export default connectAppStore(AppSidebar);
