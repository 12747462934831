class ObjectPropertyValueGetSet
{
    getPropertyValue(obj, propPath) {
        const props = propPath.split('.');
        let currentObj = obj;

        for (const prop of props) {
            if (currentObj.hasOwnProperty(prop)) {
                currentObj = currentObj[prop];
                if(currentObj === null) currentObj = "";
            } else {
                return ""; // Property not found
            }
        }

        return currentObj;
    }

    SetPropertyValue(obj, propPath, newValue) {
        const props = propPath.split('.');
        let currentObj = obj;

        for (let i = 0; i < props.length; i++) {
            const prop = props[i];
            if (i === props.length - 1) {
                // Last property in the path, set the new value
                currentObj[prop] = newValue;
            } else {
                // Navigate to the next level of the object
                if (!currentObj.hasOwnProperty(prop)) currentObj[prop] = {};
                if (currentObj[prop] === null) currentObj[prop] = {};
                currentObj = currentObj[prop];
            }
        }
    }
}
const objectPropertyValueGetSet = new ObjectPropertyValueGetSet();
export default objectPropertyValueGetSet;