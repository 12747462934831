
import PageDependencies from "../../../../../Feature/PageDependencies";

const LightingDataTable = (props)=>{
    const { 
      Fragment,
        ServerSideDataTable,
    } = PageDependencies();
    const debug = props.debug === undefined ? false : props.debug;
    const tableId = "Lighting_afa07309-239f-4545-a54d-448e0bc545da";
    const tableReload = props.tableReload;
    const extraPostModel = props.extraPostModel;
    const url = props.url;
    const columns =[
      { name: "zoneName", text: "Zone Name", width: "20%" },
      { name: "zoneType", text: "Zone Type", width: "15%" },
      { name: "zoneArea", text: "Area", width: "10%" },
      { name: "energyConsumption", text: "Energy Consumption (kWh/yr)", width: "15%" },
      { name: "halogenConsumption", text: "Halogen Consumption (kWh/yr)", width: "15%" },
      { name: "numberOfHalogen", text: "Number of halogen", width: "15%" },
      { name: "cO2Emission", text: "CO2 Emission", width: "10%" }
    ];

      return(<Fragment>
            <ServerSideDataTable
              disableSorting
              removePaging
              removeSearch
              debug ={debug}
              id={tableId}
              location="Lighting"
              data={props.data}
              setData={props.setData}
              reload={tableReload}
              columns={columns}
              dataSource={{ isAuth: true, url: url }}
              extraPostModel={extraPostModel}
              setSelectedItemIds = {props.setSelectedIds}
              allowSelect
              ></ServerSideDataTable>
      </Fragment>);
}
export default LightingDataTable;