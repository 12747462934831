//https://stackoverflow.com/questions/30203044/using-an-authorization-header-with-fetch-in-react-native
import authService from '../api-authorization/AuthorizeService'

class GetData {

    constructor() {
        this.authService = authService;
    }

    async getResponseData(response) {
        if (!response.ok) {
            const error = { state: false, message: response.status + ', ' + response.statusText };
            return error;
        }
        const data = await response.json();
        return data;
    }

    async processGet(requireAuth, apiEndpoint) {
        try {
            let response = {};
            if (requireAuth === true) {
                const token = await this.authService.getAccessToken();
                response = await fetch(apiEndpoint, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                });
            }
            else {
                response = await fetch(apiEndpoint);
            }
            const dataResponse = await this.getResponseData(response);
            return dataResponse;
        } catch (err) {
            return { state: false, message: err.message }
        }
    }

    async processPost(requireAuth, apiEndpoint, data) {
        try {
            const timeout = 1000 * 60 * 3;
            let response = {};
            let options = {
                timeout: timeout,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            };
            if (requireAuth === true) {
                const token = await this.authService.getAccessToken();
                options['headers']['Authorization'] = `Bearer ${token}`;
                response = await fetch(apiEndpoint, options);
            }
            else {
                response = await fetch(apiEndpoint, options);
            }
            const dataResponse = await this.getResponseData(response);
            return dataResponse;
        } catch (err) {
            return { state: false, message: err.message }
        }
    }

    async processDelete(requireAuth, apiEndpoint, data) {
        let response = {};
        let options = {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        if (requireAuth === true) {
            const token = await this.authService.getAccessToken();
            options['headers']['Authorization'] = `Bearer ${token}`;
            response = await fetch(apiEndpoint, options);
        }
        else {
            response = await fetch(apiEndpoint, options);
        }
        const dataResponse = await this.getResponseData(response);
        return dataResponse;
    }
}
const getData = new GetData();
export default getData;