import { connectAppStore } from "../../../../../Store/AppStore";
import PageDependencies from "../../../../../Feature/PageDependencies";

const PlugLoadCookingModule = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        tryCatchFinally,
        layoutManager,
        getData,
        guid,
        useReducerSmart,
        toastPrint,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const {} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/ExistingHome/PlugLoadCookingModule/Accessor/Get";
    const [reloadForm, setReloadForm] = useState("");
    const validateMethods = {
        cookTypeId:(value)=>{ return value == -999 ? "Must select a cooktop type." : "";},
        ovenTypeId:(value)=>{ return value == -999 ? "Must select a oven type." : "";}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(),false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Plug Load and Cooking", appStoreDispatch, undefined, setInit);
    },[reloadForm]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/ExistingHome/PlugLoadCookingModule/Accessor/UpdatePlugLoadCooking";
        setLoading({ isLoading: true, text: "Updating Plug Load & Cooking" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            setReloadForm(guid.newGuid());
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-plug icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Plug Load & Cooking
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                    <>
      <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Annual Energy Consumption</h5>
                        </button>
                    </div>
                    <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label>Plug Load (kWh/yr): {parseFloat(viewModel.annualPlugLoadEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label>Cooktop ({viewModel.annualCookTopEnergyUnit}): {parseFloat(viewModel.annualCookTopEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label>Oven ({viewModel.annualOvenEnergyUnit}): {parseFloat(viewModel.annualOvenEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Plug Load & Cooking Configuration</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="NumberOfOccupants">Occupants Number </label>
                                        <BindedInput
                                            disabled
                                            className="form-control"
                                            id="NumberOfOccupants"
                                            name="NumberOfOccupants"
                                            type="number"
                                            binding={[viewModel, "numberOfOccupants", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="CookType">Type of Cooktop *</label>
                                        <BindedSelect
                                            className="form-control"
                                            id="CookType"
                                            name="CookType"
                                            options="cookTypes"
                                            validate={formValidate}
                                            binding={[viewModel, "cookTypeId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="OvenType">Type of Oven *</label>
                                        <BindedSelect
                                            className="form-control"
                                            id="OvenType"
                                            name="OvenType"
                                            options="ovenTypes"
                                            validate={formValidate}
                                            binding={[viewModel, "ovenTypeId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row-separator"></div>
                            <div className="form-row row">
                                <div className="col-12">
                                    <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </>            
                }
          
            </div>
        </Fragment>
    );
}
export default connectAppStore(PlugLoadCookingModule);