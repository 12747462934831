
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const ExternalScreenDetail = (props) => {
    const { 
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        useLocation,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        dataExtension,
        Fragment, useEffect, useState
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const currentUrl = useLocation().pathname;
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const { designVariationVersionName, developmentId, projectId, designVariationId, zoneId, parentId, redirectUrlHex, elementId } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        selectedItemIds: [elementId]
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props); 
    const getUrl = "api/Element/Accessor/GetVerticalShadingElement";
    const postUrl = "api/Element/Accessor/UpdateVerticalShadingElement";
    const validateMethods = {
        name:(value)=>{return FormValidate.validateTextRequired(value, "External screen name");},
        verticalShadingId:(value)=>{ return value === emptyGuid ? "Must select a vertical shading." : "";},
        // length: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Length");},
        // height: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Height");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, undefined, setInit);
    },[]);

    useEffect(()=>{  
        if(init){
          dispatchViewModel({...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId]}, ()=>{
            setReloadForm(guid.newGuid());
          });
        } 
    },[elementId]);

    useEffect(()=>{
        const shading = dataExtension.getObjectByPropertyValue(viewModel.verticalShadings, "value", viewModel.verticalShadingId);
        if(shading !== null){
            const newModel = {...viewModel, height: shading.height, width: shading.width};
            dispatchViewModel(newModel);
        }
    },[viewModel.verticalShadingId]);

    const generateStayUrl = (submitResponse)=>{
        let redirectUrl = currentUrl;
        if(elementId === emptyGuid){
            //ElementAccessor/Detail/ExternalScreen/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex
            redirectUrl =  "/ElementAccessor/Detail/ExternalScreen/" + designVariationVersionName + "/" + developmentId +"/"+projectId+"/"+designVariationId+"/"+zoneId+"/"+submitResponse.elementId +"/"+parentId+"/"+redirectUrlHex;
        }
        return redirectUrl;
    }
    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <BaseForm
                id="DesignVariationFrom"
                icon="pe-7s-safe"
                loading={loading}
                init={init}
                setLoading={setLoading}
                submitButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl, text: "Save and close"}}
                submitButtonAndStay={{ text:"Save", postUrl: postUrl, redirectMethod: (submitResponse)=>{return generateStayUrl(submitResponse)}}}
                cancelButton={{ redirectUrl: viewModel.redirectUrl }}
                data={viewModel}
                validate ={formValidate}
                title = {"External Screen"} 
                subTitle={pageProfile.getPageSubTitle(appStoreState)}
            >
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                        <label htmlFor="ShadingName">Name *</label>
                            <BindedInput validate={formValidate} className="form-control valid" id="ShadingName" name="ShadingName" type="text" binding={[viewModel, "name", bindingViewModel]}></BindedInput>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="VerticalElementShadingVerticalShadingId">Available screens *</label>
                            <BindedSelect
                                className="form-control"
                                id="VerticalElementShadingVerticalShadingId"
                                name="VerticalElementShadingVerticalShadingId"
                                options="verticalShadings"
                                binding={[viewModel, "verticalShadingId", bindingViewModel]}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="VerticalElementShadingHeight">Height (mm) *</label>           
                            <BindedInput className="form-control valid" id="VerticalElementShadingHeight" name="VerticalElementShadingHeight" validate={formValidate} type="number" binding={[viewModel, "height", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="VerticalElementShadingWidth">Width (mm) *</label>           
                            <BindedInput className="form-control" id="VerticalElementShadingWidth" name="VerticalElementShadingWidth" validate={formValidate} type="number" binding={[viewModel, "width", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="VerticalElementShadingDistance">Distance (mm) *</label>           
                            <BindedInput className="form-control valid" id="VerticalElementShadingDistance" name="VerticalElementShadingDistance" validate={formValidate} type="number" binding={[viewModel, "distance", bindingViewModel]}></BindedInput>       
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                        <label htmlFor="VerticalElementShadingHorizontalOffset">Horizontal offset (mm) *</label>           
                            <BindedInput className="form-control valid" id="VerticalElementShadingHorizontalOffset" name="VerticalElementShadingHorizontalOffset" validate={formValidate} type="number" binding={[viewModel, "horizontalOffset", bindingViewModel]}></BindedInput>  
                        </div>
                    </div>
                    <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="VerticalElementShadingVerticalOffset">Vertical offset (mm) *</label>           
                            <BindedInput className="form-control valid" id="VerticalElementShadingVerticalOffset" name="VerticalElementShadingVerticalOffset" validate={formValidate} type="number" binding={[viewModel, "verticalOffset", bindingViewModel]}></BindedInput>  
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-12">
                        <label>Blocking factor (opaque = 100%)</label>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage0}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage1}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage2}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage3}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage4">May</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage4}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage5}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage6}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage7}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage8}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage9}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage10}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>           
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {viewModel.blockingFactorPercentage11}</label>
                        </div>
                    </div>
                </div>
            </BaseForm>
        </Fragment>
    );
}
export default connectAppStore(ExternalScreenDetail);