import React, { Component, Fragment, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import Layout from "./components/Layout";
import "./custom.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import AppLoading from "./components/Feature/AppLoading";
import appStore from "./components/Store/AppStore";
import AppDebugMenu from "./AppDebugMenu";

import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Fragment>
        <PrimeReactProvider>
          <Provider store={appStore}>
            <AppDebugMenu></AppDebugMenu>
            <Layout>
              <ToastContainer style={{marginTop:"75px"}} autoClose={4000}></ToastContainer>
              <Suspense
                fallback={
                  <div className="centered">
                    <AppLoading active></AppLoading>
                  </div>
                }
              ></Suspense>
              <Routes>
                {AppRoutes.map((route, index) => {
                  const { element, requireAuth, ...rest } = route;
                  return (
                    <Route
                      key={index}
                      {...rest}
                      element={
                        requireAuth ? (
                          <AuthorizeRoute {...rest} element={element} />
                        ) : (
                          element
                        )
                      }
                    />
                  );
                })}
              </Routes>
            </Layout>
          </Provider>
        </PrimeReactProvider>     
      </Fragment>
    );
  }
}
