
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import dataExtension from "../../../../Feature/DataTable/DataExtension";

const HorizontalShadingDetail = (props) => {
    const {
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        Fragment, useEffect, useState
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const defaultParams = pageProfile.getDefaultParams(useParams());
    let {designVariationVersionName, developmentId, projectId, designVariationId, redirectUrl, shadingId, jsonParam} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        id:emptyGuid,
        typeName: props.typeName,
        selectedItemIds:[shadingId]
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [pageLayout, setPageLayout] = useState({
        vertOffsetRangeDisplay: false,
        vertWidthRangeDisplay: false,
        vertWidthRangeDisabled: false,
        windowWidthDisplay: false,
        windowWidthDisabled: false,
        eaveWidthDisabled: false,
        eaveLengthDisabled: false,
        eaveProjectionDisabled: false,
        eaveVertOffserDisabled: false,
        eaveHorOffserDisabled: false,
    });
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [init, setInit] = useState(false);
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/Shading/Accessor/Get";
    const postUrl = "api/Shading/Accessor/Update";
    const validateMethods = {
        name:(value)=>{return FormValidate.validateTextRequired(value, "Horizontal shading name");},
        // eaveLength: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Eave length");},
        // eaveWidth: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Eave width");},
        // otherFixedShadingLength: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Other fixed shading length");},
        // otherFixedShadingWidth: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Other fixed shading width");},
        blockingFactorPercentage0: (value) => { return FormValidate.validateNumberBetween(value, "Jan", 0 , 100);},
        blockingFactorPercentage1: (value) => { return FormValidate.validateNumberBetween(value, "Feb", 0 , 100);},
        blockingFactorPercentage2: (value) => { return FormValidate.validateNumberBetween(value, "Mar", 0 , 100);},
        blockingFactorPercentage3: (value) => { return FormValidate.validateNumberBetween(value, "Apr", 0 , 100);},
        blockingFactorPercentage4: (value) => { return FormValidate.validateNumberBetween(value, "May", 0 , 100);},
        blockingFactorPercentage5: (value) => { return FormValidate.validateNumberBetween(value, "Jun", 0 , 100);},
        blockingFactorPercentage6: (value) => { return FormValidate.validateNumberBetween(value, "Jul", 0 , 100);},
        blockingFactorPercentage7: (value) => { return FormValidate.validateNumberBetween(value, "Aug", 0 , 100);},
        blockingFactorPercentage8: (value) => { return FormValidate.validateNumberBetween(value, "Sep", 0 , 100);},
        blockingFactorPercentage9: (value) => { return FormValidate.validateNumberBetween(value, "Oct", 0 , 100);},
        blockingFactorPercentage10: (value) => { return FormValidate.validateNumberBetween(value, "Nov", 0 , 100);},
        blockingFactorPercentage11: (value) => { return FormValidate.validateNumberBetween(value, "Dec", 0 , 100);}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, undefined, setInit);
    },[]);

    useEffect(()=>{
        const display = viewModel.simulationType === "Existing Home" && (viewModel.shadingInputMethodTypeId == 2);
        setPageLayout((oldLayout)=>{
            const newLayout = {...oldLayout};
            newLayout.vertOffsetRangeDisplay = display;
            newLayout.vertWidthRangeDisplay = display;
            newLayout.windowWidthDisplay = display;
            newLayout.eaveWidthDisabled = display;
            newLayout.eaveHorOffserDisabled = display;
            newLayout.eaveVertOffserDisabled = display;
            newLayout.eaveProjectionDisabled = display;
            return newLayout;
        });
    },[viewModel.simulationType, viewModel.shadingInputMethodTypeId])

    useEffect(()=>{
        const isCalculate = viewModel.simulationType === "Existing Home" && ( viewModel.shadingInputMethodTypeId == 2);
        if(isCalculate){
            const newModel = {...viewModel};
            if( viewModel.eaveHorizontalShadingVentOffsetRangeTypeId == 1){
                //clear data
                newModel.eaveHorizontalShadingVentWidthRangeTypeId = "1";
                newModel.windowWidth = 0;
                newModel.eaveLength = 0;
                newModel.eaveWidth = 0;
                newModel.eaveHorizontalOffset = 0;
                newModel.eaveVerticalOffset = 0;       
                dispatchViewModel(newModel);
                setPageLayout((oldLayout)=>{
                    const newLayout = {...oldLayout};
                    newLayout.vertWidthRangeDisabled = true;
                    newLayout.windowWidthDisabled = true;
                    newLayout.eaveLengthDisabled = true;
                    newLayout.windowWidthDisabled = true;
                    return newLayout;
                });
            }else{
                //calculate
                const vertOffset = parseInt(dataExtension.getSelectOptionByValue(viewModel.eaveHorizontalShadingVentOffsetRangeTypes, viewModel.eaveHorizontalShadingVentOffsetRangeTypeId).dataValue);
                const vertWidth = parseInt(dataExtension.getSelectOptionByValue(viewModel.eaveHorizontalShadingVentWidthRangeTypes, viewModel.eaveHorizontalShadingVentWidthRangeTypeId).dataValue);
                const length = vertWidth * 2 + parseInt(viewModel.windowWidth);
                newModel.eaveLength = length;
                newModel.eaveWidth = vertWidth;
                newModel.eaveHorizontalOffset = vertWidth;
                newModel.eaveVerticalOffset = vertOffset;     
                dispatchViewModel(newModel);
                setPageLayout((oldLayout)=>{
                    const newLayout = {...oldLayout};
                    newLayout.vertWidthRangeDisabled = false;
                    newLayout.windowWidthDisabled = false;
                    newLayout.eaveLengthDisabled = false;
                    newLayout.windowWidthDisabled = false;
                    return newLayout;
                });
            }
        }
    },[viewModel.shadingInputMethodTypeId, viewModel.eaveHorizontalShadingVentOffsetRangeTypeId, viewModel.eaveHorizontalShadingVentWidthRangeTypeId, viewModel.windowWidth]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <BaseForm
            id="DesignVariationFrom"
            icon="pe-7s-safe"
            loading={loading}
            init={init}
            setLoading={setLoading}
            submitButton={{ postUrl: postUrl + props.typeName, redirectUrl: redirectUrl}}
            cancelButton={{ redirectUrl: redirectUrl }}
            data={viewModel}
            validate ={formValidate}
            title = {props.typeName + " Shading"} 
            subTitle={pageProfile.getPageSubTitle(appStoreState)}
            >
                <div className="form-row row">
                    <div className="col-12" style={{marginBottom:"15px"}}>
                        <div className="position-relative form-group">
                        <label htmlFor="ShadingName">Name *</label>
                            <BindedInput validate={formValidate} className="form-control valid" id="ShadingName" name="ShadingName" type="text" binding={[viewModel, "name", bindingViewModel]}></BindedInput>
                        </div>
                    </div>
                </div>
                <div className="separator"></div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Eave</h5>
                        </button>
                    </div>
                    <div className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            {viewModel.simulationType === "Existing Home" &&
                                <div className="form-row row">
                                     <div className="col-3" style={{marginBottom:"15px"}}>
                                        <div className="position-relative form-group">
                                            <label htmlFor="shadingInputMethodTypeId">Input Method</label>           
                                            <BindedSelect
                                                className="form-control"
                                                id="shadingInputMethodTypeId"
                                                name="shadingInputMethodTypeId"
                                                options="shadingInputMethodTypes"
                                                binding={[viewModel, "shadingInputMethodTypeId", bindingViewModel]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                    {pageLayout.vertOffsetRangeDisplay &&
                                        <div className="col-3" style={{marginBottom:"15px"}}>
                                            <div className="position-relative form-group">
                                                <label htmlFor="eaveHorizontalShadingVentOffsetRangeTypeId">Vertical Offset Range</label>           
                                                <BindedSelect
                                                    className="form-control"
                                                    id="eaveHorizontalShadingVentOffsetRangeTypeId"
                                                    name="eaveHorizontalShadingVentOffsetRangeTypeId"
                                                    options="eaveHorizontalShadingVentOffsetRangeTypes"
                                                    binding={[viewModel, "eaveHorizontalShadingVentOffsetRangeTypeId", bindingViewModel]}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                    }
                                    {pageLayout.vertWidthRangeDisplay &&
                                        <div className="col-3" style={{marginBottom:"15px"}}>
                                            <div className="position-relative form-group">
                                                <label htmlFor="eaveHorizontalShadingVentWidthRangeTypeId">Vertical Width Range</label>           
                                                <BindedSelect
                                                    className="form-control"
                                                    id="eaveHorizontalShadingVentWidthRangeTypeId"
                                                    name="eaveHorizontalShadingVentWidthRangeTypeId"
                                                    options="eaveHorizontalShadingVentWidthRangeTypes"
                                                    binding={[viewModel, "eaveHorizontalShadingVentWidthRangeTypeId", bindingViewModel]}
                                                    disabled = {pageLayout.vertWidthRangeDisabled}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                    }
                                    {pageLayout.windowWidthDisplay &&
                                        <div className="col-3" style={{marginBottom:"15px"}}>
                                            <div className="position-relative form-group">
                                                <label htmlFor="windowWidth">Window Width (mm) *</label>           
                                                <BindedInput disabled={pageLayout.windowWidthDisabled} className="form-control" id="windowWidth" name="windowWidth" validate={formValidate} type="number" binding={[viewModel, "windowWidth", bindingViewModel]}></BindedInput>                
                                            </div>
                                        </div>
                                    }                                  
                                </div>
                            }
                            <div className="form-row row">
                                <div className="col-4" style={{marginBottom:"15px"}}>
                                    <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingEaveLength">Length (mm) *</label>           
                                        <BindedInput disabled={pageLayout.eaveLengthDisabled} className="form-control valid" id="HorizontalShadingEaveLength" name="HorizontalShadingEaveLength" validate={formValidate} type="number" binding={[viewModel, "eaveLength", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-4" style={{marginBottom:"15px"}}>
                                    <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingEaveWidth">Width (mm) *</label>           
                                        <BindedInput disabled={pageLayout.eaveWidthDisabled} className="form-control" id="HorizontalShadingEaveWidth" name="HorizontalShadingEaveWidth" validate={formValidate} type="number" binding={[viewModel, "eaveWidth", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-4" style={{marginBottom:"15px"}}>
                                    <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingEaveProjectionTypeId">Projection *</label>           
                                        <BindedSelect
                                            className="form-control"
                                            id="HorizontalShadingEaveProjectionTypeId"
                                            name="HorizontalShadingEaveProjectionTypeId"
                                            options="eaveProjectionTypes"
                                            binding={[viewModel, "eaveProjectionTypeId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4" style={{marginBottom:"15px"}}>
                                    <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingEaveHorizontalOffset">Horizontal offset (mm) *</label>           
                                        <BindedInput disabled={pageLayout.eaveHorOffserDisabled} className="form-control" id="HorizontalShadingEaveHorizontalOffset" name="HorizontalShadingEaveHorizontalOffset" validate={formValidate} type="number" binding={[viewModel, "eaveHorizontalOffset", bindingViewModel]}></BindedInput>  
                                    </div>
                                </div>
                                <div className="col-4" style={{marginBottom:"15px"}}>
                                    <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingEaveVerticalOffset">Vertical offset (mm) *</label>           
                                        <BindedInput disabled={pageLayout.eaveVertOffserDisabled} className="form-control" id="HorizontalShadingEaveVerticalOffset" name="HorizontalShadingEaveVerticalOffset" validate={formValidate} type="number" binding={[viewModel, "eaveVerticalOffset", bindingViewModel]}></BindedInput>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="separator"></div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Other fixed shading</h5>
                        </button>
                    </div>
                    <div className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-4" style={{marginBottom:"15px"}}>
                                    <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingOtherFixedShadingLength">Length (mm) *</label>           
                                        <BindedInput className="form-control valid" id="HorizontalShadingOtherFixedShadingLength" name="HorizontalShadingOtherFixedShadingLength" validate={formValidate} type="number" binding={[viewModel, "otherFixedShadingLength", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-4" style={{marginBottom:"15px"}}>
                                    <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingOtherFixedShadingWidth">Width (mm) *</label>           
                                        <BindedInput className="form-control" id="HorizontalShadingOtherFixedShadingWidth" name="HorizontalShadingOtherFixedShadingWidth" validate={formValidate} type="number" binding={[viewModel, "otherFixedShadingWidth", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-4" style={{marginBottom:"15px"}}>
                                    <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingOtherFixedShadingProjectionTypeId">Projection *</label>           
                                        <BindedSelect
                                            className="form-control"
                                            id="HorizontalShadingOtherFixedShadingProjectionTypeId"
                                            name="HorizontalShadingOtherFixedShadingProjectionTypeId"
                                            options="otherFixedShadingProjectionTypes"
                                            binding={[viewModel, "otherFixedShadingProjectionTypeId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3" style={{marginBottom:"15px"}}>
                                        <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingOtherFixedShadingHorizontalOffset">Horizontal offset (mm) *</label>           
                                            <BindedInput className="form-control" id="HorizontalShadingOtherFixedShadingHorizontalOffset" name="HorizontalShadingOtherFixedShadingHorizontalOffset" validate={formValidate} type="number" binding={[viewModel, "otherFixedShadingHorizontalOffset", bindingViewModel]}></BindedInput>  
                                        </div>
                                    </div>
                                    <div className="col-3" style={{marginBottom:"15px"}}>
                                        <div className="position-relative form-group">
                                        <label htmlFor="HorizontalShadingOtherFixedShadingVerticalOffset">Vertical offset (mm) *</label>           
                                            <BindedInput className="form-control" id="HorizontalShadingOtherFixedShadingVerticalOffset" name="HorizontalShadingOtherFixedShadingVerticalOffset" validate={formValidate} type="number" binding={[viewModel, "otherFixedShadingVerticalOffset", bindingViewModel]}></BindedInput>  
                                        </div>
                                    </div>
                                </div>                             
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage0" name="ShadingBlockingFactorPercentage0" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage0", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage1" name="ShadingBlockingFactorPercentage1" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage1", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage2" name="ShadingBlockingFactorPercentage2" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage2", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage3" name="ShadingBlockingFactorPercentage3" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage3", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage4">May</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage4" name="ShadingBlockingFactorPercentage4" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage4", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage5" name="ShadingBlockingFactorPercentage5" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage5", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage6" name="ShadingBlockingFactorPercentage6" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage6", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage7" name="ShadingBlockingFactorPercentage7" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage7", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage8" name="ShadingBlockingFactorPercentage8" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage8", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage9" name="ShadingBlockingFactorPercentage9" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage9", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage10" name="ShadingBlockingFactorPercentage10" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage10", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                                <div className="col-1">
                                    <div className="position-relative form-group">
                                        <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>           
                                        <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage11" name="ShadingBlockingFactorPercentage11" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage11", bindingViewModel]}></BindedInput>                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </BaseForm>
        </Fragment>
    );
}
export default connectAppStore(HorizontalShadingDetail);