import React, { Fragment, useEffect, useState, useRef } from 'react';
import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";
import { InputNumber } from 'primereact/inputnumber';
import guid from '../Function/guid';

const BindedInputNumber = (props) => {
    const newProps = {};
    const binding = props.binding;
    const bindingObject = binding[0];
    const bindingProperty = binding[1];
    const bindingMethod = binding[2];
    let bindingDefaultValue = binding[3];
    const bindingValue = objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty);
    const [message, setMessage] = useState("");
    let inputRef = useRef();
    if(props.componentRef !== undefined) inputRef = props.componentRef;
    const validate = props.validate;
    let inputStyle = props.inputStyle;
    if (inputStyle !== undefined){
        inputStyle.padding = 6;
    }else{
        inputStyle = {padding: 6};
    }
    for (const prop in props) {
        if (!(prop==='binding') && !(prop==='componentRef') && !(prop==='validate')) {
            newProps[prop] = props[prop];
        }
    }
    if(props.min !== undefined && bindingDefaultValue === undefined){
        bindingDefaultValue = Number(props.min);
    }
    if(props.min === undefined && bindingDefaultValue === undefined){
        bindingDefaultValue = 0;
    }

    let label = props.label;
    let inputId = props.inputId;
    if(inputId === undefined){
        inputId = guid.newGuid();
    }
    if(label === undefined){
        label = "";
    }

    useEffect(() => {
        if (validate !== undefined) {
            if (validate.setMessages !== undefined) {
                validate.setMessages[1](oldSetMessage => {
                    let newMessages = {};
                    if (oldSetMessage !== undefined) {
                        newMessages = { ...oldSetMessage };
                    }
                    newMessages[bindingProperty] = setMessage;
                    return newMessages;
                });
            }           
        }    
    }, []);

    const getValidateVisibility = ()=>{
        if(validate === undefined) return false;
        if(validate.validateVisibility === undefined) return false;
        return validate.validateVisibility;
    }

    return (
        <Fragment>
            <div className="flex flex-wrap gap-3 p-fluid">
                <div className="flex-auto">
                    <label htmlFor={inputId} className="block mb-2">{label}</label>    
                    <InputNumber inputRef={inputRef} inputId={inputId} inputStyle={inputStyle} value={bindingValue} {...newProps} useGrouping={false} onValueChange={(e) => {
                        let value = e.value 
                        if(value === null){
                            inputRef.current.value = bindingDefaultValue;
                            value = bindingDefaultValue;
                        }
                        const bindingObjectNew = { };
                        bindingObjectNew[bindingProperty] = value;  
                        bindingMethod(bindingObjectNew, e, undefined, undefined, bindingDefaultValue);
                        if (validate !== undefined) {
                            const validateObject = { ...bindingObject };
                            validateObject[bindingProperty] = value;
                            validate.checkValidateResult(validateObject);
                        }
                    }}></InputNumber>
                {<span id={bindingProperty + "-Validate"} className="field-validation-valid" style={{color:"red"}}>{getValidateVisibility() && message}</span>}
              </div>
            </div>         
        </Fragment>
    );
};
export default BindedInputNumber;