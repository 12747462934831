import { Fragment } from "react";
import PageDependencies from "../../../Feature/PageDependencies";

const ModuleSummaryDataTable = (props)=>{
    const { 
        CilentSideDataTable,
    } = PageDependencies();
    const debug = props.debug === undefined ? false : props.debug;
    const data = props.data;
    const setData = props.setData;
    const tableId = "Summary_146fb823-413f-46db-a6c2-724ee0f9df6c";
    const tableReload = props.tableReload;
    const columns =[
      { name: "fuelType", text: "Fuel Type", width: "10%" },
      { name: "lighting", text: "Lighting", width: "10%" },
      { name: "hotWater", text: "Hot Water", width: "10%" },
      { name: "cooling", text: "Cooling", width: "10%" },
      { name: "heating", text: "Heating", width: "10%" },
      { name: "poolSpa", text: "Pool Spa", width: "10%" },
      { name: "plugLoad", text: "Plug Load", width: "10%" },
      { name: "cookTop", text: "CookTop", width: "10%" },
      { name: "oven", text: "Oven", width: "10%" },
      { name: "totalDemand", text: "Total Demand", width: "10%" }
    ];

      return(<Fragment>
            {debug && <p>{JSON.stringify(data)}</p>}
            <CilentSideDataTable
              disableSorting
              removePaging
              removeSearch
              debug ={debug}
              id={tableId}
              location="ModuleSummary"
              data={data}
              setData={setData}
              reload={tableReload}
              columns={columns}
              ></CilentSideDataTable>
      </Fragment>);
}
export default ModuleSummaryDataTable;