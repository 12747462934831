import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const MultipleOpaqueLouvresDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedTriStateCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const zoneId = useParams().zoneId;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedElementIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedElementIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateMultipleOpaqueLouvres";
    const getUrl = "api/Element/Accessor/GetMultipleOpaqueLouvres";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading opaque louvres", appStoreDispatch);
    },[]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="FloorCeilingFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "Opaque louvres detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                    <label htmlFor="OpaqueLouvreName">Name *</label>
                    <BindedInput
                        className="form-control"
                        id="OpaqueLouvreName"
                        name="OpaqueLouvreName"
                        type="text"
                        binding={[viewModel, "name", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                        <label htmlFor="OpaqueLouvreConstructionId">
                            Construction *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="OpaqueLouvreConstructionId"
                            name="OpaqueLouvreConstructionId"
                            options="constructions"
                            binding={[
                            viewModel,
                            "constructionId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="OpaqueLouvreHeight">Height (mm) *</label>
                    <BindedInput
                        className="form-control"
                        id="OpaqueLouvreHeight"
                        name="OpaqueLouvreHeight"
                        type="number"
                        min="0"
                        binding={[viewModel, "height", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="OpaqueLouvreWidth">Width (mm) *</label>
                    <BindedInput
                        className="form-control"
                        id="OpaqueLouvreWidth"
                        name="OpaqueLouvreWidth"
                        type="number"
                        min="0"
                        binding={[viewModel, "width", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                {/* <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="OpaqueLouvreOpeningArea">
                        Area (m<sup>2</sup>) *
                    </label>
                    <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateArea(viewModel.width, viewModel.height)}</label>
                    </div>
                </div> */}
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="OpaqueLouvreHorizontalOffset">
                        Horizontal offset (mm) *
                    </label>
                    <BindedInput
                        className="form-control"
                        min="0"
                        id="OpaqueLouvreHorizontalOffset"
                        name="OpaqueLouvreHorizontalOffset"
                        type="number"
                        binding={[viewModel, "horizontalOffset", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="OpaqueLouvreOpenablePercent">
                        Openable (%) *
                    </label>
                    <BindedInput                                 
                        className="form-control"
                        min="0"
                        max="100"
                        id="OpaqueLouvreOpenablePercent"
                        name="OpaqueLouvreOpenablePercent"
                        type="number"
                        binding={[viewModel, "openablePercent", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>     
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        disabled
                        id="OpaqueLouvreHasInsectScreens"
                        name="OpaqueLouvreHasInsectScreens"
                        className="custom-control-input"
                        binding={[viewModel, "hasInsectScreens", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                    style={{ marginLeft: "5px" }}
                    htmlFor="OpaqueLouvreHasInsectScreens"
                    className="custom-control-label"
                    >
                    Insect screens
                    </label>
                </div>
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        disabled
                        id="OpaqueLouvreIsWeatherstripped"
                        name="OpaqueLouvreIsWeatherstripped"
                        className="custom-control-input"
                        binding={[viewModel, "isWeatherstripped", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                    style={{ marginLeft: "5px" }}
                    htmlFor="OpaqueLouvreIsWeatherstripped"
                    className="custom-control-label"
                    >
                    Weatherstripped
                    </label>
                </div>
            </div>
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleOpaqueLouvresDetail);