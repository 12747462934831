import { connectAppStore } from "../Store/AppStore";
import PageDependencies from "../Feature/PageDependencies";

const AdminHome = (props)=>{
    const { 
        useEffect,
        layoutManager,
        getData,
        useNavigate,
    } = PageDependencies();
    const nav = useNavigate();
    const loadUserInfo = async ()=>{
        const userRoleResponse = await getData.processPost(true, "api/DefaultModel/Accessor/Get", {});
        if(userRoleResponse !== undefined){
          if(userRoleResponse.viewModel !== undefined){
            const {userRole} = userRoleResponse.viewModel;
            if (userRole !== 'Administrator') {
                nav('/', { replace: true });
            }
          }
        }
      }

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loadUserInfo();
    },[])

    return(    <div className="app-main__inner">
    <div className="app-page-title">
        <div className="page-title-wrapper">
            <div className="page-title-heading">
                <div className="page-title-icon">
                    <i className="pe-7s-home icon-gradient bg-tempting-azure">
                    </i>
                </div>
                <div>
                    Home
                </div>
            </div>
        </div>
    </div>
    <div className="main-card mb-3 card">
        <div className="card-body">
            <p>
                Welcome administrator.
            </p>
        </div>
    </div>
</div>);
}

export default connectAppStore(AdminHome);