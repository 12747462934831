
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import WindowLibTree from "../WindowLibTree";

const WindowConstructionDetail = (props)=>{
    const { 
        stringCoding,
        layoutManager,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        dataExtension,
        Fragment, 
        useEffect, 
        useState
    } = PageDependencies();
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const constructionId = useParams().constructionId;
    const designVariationVersionName = useParams().designVariationVersionName;
    const defaultViewModel = {
        id:constructionId,
        projectId: projectId,
        typeName: "Window",
        name:"",
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName,
        developmentId: developmentId,
        constructionId: constructionId,
        selectedItemIds:[constructionId],
        category:"",
        constructionTypeName: "Window",
        isLibrary: props.isLibrary === true
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Construction/Accessor/Update";
    const getUrl = "api/Construction/Accessor/Get";
    const [init, setInit] = useState(false);

    const validateMethods = {
        name:(value)=>{ return FormValidate.validateTextRequired(value, "Construction name"); },
        frameAbsorptance:(value)=>{ return FormValidate.validateNumberBetween(value, "Frame solar absorptance", 0 , 100); },
        systemCode:(value)=>{ return value === undefined || value === "" ? "Select a window from the tree" : ""; },
        frameColourTypeId:(value)=>{ 
          const newValue = value == "" ? -999 : Number(value);
          return newValue == -999 ? "Selecte a frame color" : "";
         }
      };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    const applyWindowName = ()=>{
       const defaultWindowName = viewModel.systemCode + " " + viewModel.windowName;
       dispatchViewModel({...viewModel, name: defaultWindowName})
    }

    useEffect(()=>{
        try {
          if(!init) return;
          const selectedColor = dataExtension.getSelectOptionByValue(viewModel.frameColourTypes, viewModel.frameColourTypeId);
          let absorptance = 0;
          if(selectedColor.text === "Dark"){
            absorptance = 85;
          }
          if(selectedColor.text === "Medium"){
            absorptance = 50;
          }
          if(selectedColor.text === "Light"){
            absorptance = 30;
          }
          if(absorptance > 0){
            dispatchViewModel({...viewModel, frameAbsorptance: absorptance});
          }
        }catch{}
      }, [viewModel.frameColourTypeId]);

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading construction", appStoreDispatch, (newViewModel)=>{
          dispatchViewModel({...newViewModel, category: "Glazed"});
        }, setInit);
    },[]);

    return(
    <Fragment>
      {layoutManager.Layout_GetDebugModel(props) &&  <p>{JSON.stringify(viewModel)}</p>}
      <BaseForm
            id="DesignVariationFrom"
            icon="pe-7s-safe"
            loading={loading}
            setLoading={setLoading}
            submitButton={{ postUrl: postUrl + viewModel.category, redirectUrl: redirectUrl}}
            cancelButton={{ redirectUrl: redirectUrl }}
            data={viewModel}
            init={init}
            validate ={formValidate}
            title = {viewModel.constructionTypeName + " construction"} 
            subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <BindedInput id="DesignVariationVersionName" name="DesignVariationVersionName" type="hidden"  binding={[viewModel, "designVariationVersionName", bindingViewModel]}></BindedInput>
            <div className="form-row row">
                  <div className="col-12">
                      <div className="position-relative form-group">
                      <label htmlFor="Name">Name *</label>
                          <BindedInput validate={formValidate} className="form-control valid" id="Name" name="Name" type="text" binding={[viewModel, "name", bindingViewModel]}></BindedInput>
                      </div>
                  </div>
              </div>
              <div className="form-row row">
                  <div className="col-3">
                      <div className="position-relative form-group">
                          <label htmlFor="SystemCode">System Code *</label>           
                          <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} validate={formValidate} disabled id="SystemCode" name="SystemCode" binding={[viewModel, "systemCode", bindingViewModel]}></BindedInput>                
                      </div>
                  </div>
                  <div className="col-3">
                      <div className="position-relative form-group">
                          <label htmlFor="windowName">Window Name</label>           
                          <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} disabled id="windowName" name="windowName" binding={[viewModel, "windowName", bindingViewModel]}></BindedInput>                
                      </div>
                  </div>
                  <div className="col-3">
                      <div className="position-relative form-group">
                            <label>System code + window name</label>     
                            <a onClick={applyWindowName} className="btn btn-primary btn-round form-control">Apply default name</a>             
                      </div>
                  </div>
              </div>
              <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="GlazedConstructionFrameColourTypeId">Frame colour *</label>           
                          <BindedSelect className="form-control" id="GlazedConstructionFrameColourTypeId" name="GlazedConstructionFrameColourTypeId" validate={formValidate} binding={[viewModel, "frameColourTypeId", bindingViewModel]} options="frameColourTypes"></BindedSelect>                
                      </div>
                  </div>
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="GlazedConstructionFrameAbsorptance">Frame absorptance (%) *</label>           
                          <BindedInput className="form-control valid" id="GlazedConstructionFrameAbsorptance" name="GlazedConstructionFrameAbsorptance" validate={formValidate} type="number" binding={[viewModel, "frameAbsorptance", bindingViewModel]}></BindedInput>       
                      </div>
                  </div>
              </div>   
              <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                      <label htmlFor="UValue">U-value *</label>           
                          <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} validate={formValidate} disabled id="UValue" name="UValue" binding={[viewModel, "uValue", bindingViewModel]}></BindedInput>  
                      </div>
                  </div>
                  <div className="col-6">
                  <div className="position-relative form-group">
                      <label htmlFor="SHGC">SHGC *</label>           
                          <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} validate={formValidate} disabled id="SHGC" name="SHGC" binding={[viewModel, "shgc", bindingViewModel]}></BindedInput>  
                      </div>
                  </div>
              </div>      
        </BaseForm>
        {init === true &&
             <div className="app-main__inner">
             <div className="row">
                 <div className="col-12">
                     <div id="ConstructionLayerAccordion">
                         <div className="main-card mb-3 card">
                             <div className="card-header">
                                 <button type="button" className="text-left m-0 p-0 btn btn-block">
                                     <h5 className="m-0 p-0 card-title">Windows</h5>
                                 </button>
                             </div>
                             <div className="card-body">
                               <WindowLibTree
                                   id="windowsTree-b0cfa4fc-83b8-41b4-9748-453709a14d88"
                                   setViewModel = {dispatchViewModel}
                                   viewModel = {viewModel}
                               >
                               </WindowLibTree>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>       
         </div> 
        }
      
    </Fragment>);
}
export default connectAppStore(WindowConstructionDetail);