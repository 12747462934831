import WindowDetailV001 from "../../Portal/Accessor/Element/V001/WindowDetail";
import DoorDetailV001 from "../../Portal/Accessor/Element/V001/DoorDetail";
import OpaqueLouvreDetailV001 from "../../Portal/Accessor/Element/V001/OpaqueLouvreDetail";
import ExternalWallDetailV001 from "../../Portal/Accessor/Element/V001/ExternalWallDetail";
import InternalWallDetailV001 from "../../Portal/Accessor/Element/V001/InternalWallDetail";
import FloorCeilingDetailV001 from "../../Portal/Accessor/Element/V001/FloorCeilingDetail";
import RoofDetailV001 from "../../Portal/Accessor/Element/V001/RoofDetail";
import ExternalScreenDetailV001 from "../../Portal/Accessor/Element/V001/ExternalScreenDetail";
import InfiltrationPenetrationDetailV001 from "../../Portal/Accessor/Element/V001/InfiltrationPenetrationDetail";
import Elements from "../../Portal/Accessor/Element/Elements";
import ElementsNew from "../../Portal/Accessor/Element/ElementsNew";
import RoofWindowDetailV001 from "../../Portal/Accessor/Element/V001/RoofWindowDetail";
import SkylightDetailV001 from "../../Portal/Accessor/Element/V001/SkylightDetail";
import MultipleExternalWallsDetailV001 from "../../Portal/Accessor/Element/V001/MultipleExternalWallsDetail";
import MultipleInternalWallsDetailV001 from "../../Portal/Accessor/Element/V001/MultipleInternalWallsDetail";
import MultipleFloorCeilingsDetailV001 from "../../Portal/Accessor/Element/V001/MultipleFloorCeilingsDetail";
import MultipleRoofsDetailV001 from "../../Portal/Accessor/Element/V001/MultipleRoofsDetail";
import MultipleDoorsDetailV001 from "../../Portal/Accessor/Element/V001/MultipleDoorsDetail";
import MultipleOpaqueLouvresDetailV001 from "../../Portal/Accessor/Element/V001/MultipleOpaqueLouvresDetail";
import MultipleWindowsDetailV001 from "../../Portal/Accessor/Element/V001/MultipleWindowsDetail";
import MultipleSkylightsDetailV001 from "../../Portal/Accessor/Element/V001/MultipleSkylightsDetail";
import MultipleRoofWindowsDetailV001 from "../../Portal/Accessor/Element/V001/MultipleRoofWindowsDetail";
import MultipleInfiltrationPenetrationsDetailV001 from "../../Portal/Accessor/Element/V001/MultipleInfiltrationPenetrationsDetail";
import MultipleExternalScreensDetailV001 from "../../Portal/Accessor/Element/V001/MultipleExternalScreensDetail";

const AccessorElementRouters = [
    {
      path: "ElementAccessor/Detail/Window/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <WindowDetailV001/>,
    },
    {
      path: "ElementAccessor/Detail/Door/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <DoorDetailV001/>,
    },
    {
      path: "ElementAccessor/Detail/OpaqueLouvre/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <OpaqueLouvreDetailV001/>,
    },
    {
      path: "ElementAccessor/Detail/ExternalScreen/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <ExternalScreenDetailV001/>,
    },
    {
      path: "ElementAccessor/Detail/InfiltrationPenetrationInExternalWall/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <InfiltrationPenetrationDetailV001 elementType="InfiltrationPenetrationInExternalWall"/>,
    },
    {
      path: "ElementAccessor/Detail/InfiltrationPenetrationInRoof/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
        element: <InfiltrationPenetrationDetailV001 elementType="InfiltrationPenetrationInRoof" />,
    },
    {
      path: "ElementAccessor/Detail/ExternalWall/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <ExternalWallDetailV001/>,
    },
    {
      path: "ElementAccessor/Detail/InternalWall/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <InternalWallDetailV001/>,
    },
    {
      path: "ElementAccessor/Detail/Floor/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <FloorCeilingDetailV001 elementType="Floor"/>,
    },
    {
      path: "ElementAccessor/Detail/Ceiling/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <FloorCeilingDetailV001 elementType="Ceiling"/>,
    },
    {
      path: "ElementAccessor/Detail/Roof/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <RoofDetailV001/>,
    },
    {
      path: "ElementAccessor/Elements/:designVariationVersionName/:developmentId/:projectId/:designVariationId",
      exact: true,
      requireAuth: true,
      element: <Elements/>,
    },
    {
      path: "ElementAccessor/ElementsNew/:designVariationVersionName/:developmentId/:projectId/:designVariationId",
      exact: true,
      requireAuth: true,
      element: <ElementsNew/>,
    },
    {
        path: "ElementAccessor/Detail/InfiltrationPenetrationInCeiling/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex",
        exact: true,
        requireAuth: true,
        element: <InfiltrationPenetrationDetailV001 elementType="InfiltrationPenetrationInCeiling" />,
    },
    {
        path: "ElementAccessor/Detail/RoofWindow/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex",
        exact: true,
        requireAuth: true,
        element: <RoofWindowDetailV001 elementType="RoofWindowInRoof" />,
    },
    {
        path: "ElementAccessor/Detail/Skylight/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex",
        exact: true,
        requireAuth: true,
        element: <SkylightDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/ExternalWall/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleExternalWallsDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/InternalWall/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleInternalWallsDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/Floor/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleFloorCeilingsDetailV001 elementType="Floor" />,
    },
    {
      path: "ElementAccessor/MultipleDetail/Ceiling/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleFloorCeilingsDetailV001 elementType="Ceiling" />,
    },
    {
      path: "ElementAccessor/MultipleDetail/Roof/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleRoofsDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/Door/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleDoorsDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/OpaqueLouvre/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleOpaqueLouvresDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/Window/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleWindowsDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/Skylight/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleSkylightsDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/RoofWindow/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleRoofWindowsDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/InfiltrationPenetration/:elementType/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleInfiltrationPenetrationsDetailV001 />,
    },
    {
      path: "ElementAccessor/MultipleDetail/ExternalScreen/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:jsonParamHex/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <MultipleExternalScreensDetailV001 />,
    }
  ];
export default AccessorElementRouters;