import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalDatatableItem = (props)=>{
    function closeModal() {
        props.setModal({...props.modal, isOpen:false});
    }
    function handleConfirm() {
        props.modal.handleComfirm();
        props.setModal({...props.modal, isOpen:false});
    }
    const comfirmButtonStyle = props.modal.comfirmButtonStyle === undefined ? "primary" : props.modal.comfirmButtonStyle;
    const comfirmButtonText = props.modal.comfirmButtonText === undefined ? "Comfirm" : props.modal.comfirmButtonText;
    return (
        <span className="d-inline-block mb-2 me-2">
          <Modal isOpen={props.modal.isOpen} className={props.className} centered={true}>
            <ModalHeader>{props.modal.title}</ModalHeader>
            <ModalBody>
                <p>{props.modal.message}</p>
            </ModalBody>
            <ModalFooter>
                <button onClick={closeModal} type='button' className='btn btn-secondary' data-dismiss='modal'>Close</button>
                <button onClick={handleConfirm} type='button' className={'btn btn-' + comfirmButtonStyle}>{comfirmButtonText}</button>
            </ModalFooter>
          </Modal>
        </span>
      );
}
export default ModalDatatableItem;