import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import ModuleSummaryDataTable from "./ModuleSummaryDataTable";
import HeatingCoolingSimulationDataTable from "./HeatingCoolingSimulationDataTable";

const ModuleSummary = (props) => {
    const { 
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const defaultViewModel = {
        ...defaultParams,
    };
    const [extraPostModel, setExtraPostModel] = useState({
        ...defaultParams,
      });
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [data, setData] = useState([]);
    const [simulationData, setSimulationData] = useState([]);
    const [appStoreState, appStoreDispatch] = getAppStore(props);

    const getSimulationType = ()=>{
        let type = "NewHome";
        if( pageProfile.getModuleTypes(appStoreState).length > 0){
            type = pageProfile.getModuleTypes(appStoreState)[0].simulationType.replace(" ","");          
        }
        return type;
    }

    useEffect(()=>{
        const getUrl = "api/" + getSimulationType() + "/ModuleSummary/Accessor/Get";
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Summary", appStoreDispatch, (responseModel)=>{
            const rows = responseModel.moduleEnergyRows;
            setData(rows);
            const simulationRows = responseModel.heatingCoolingEnergyRows;
            setSimulationData(simulationRows);
            setReloadForm(guid.newGuid());
        }, setInit);
    },[]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className=" pe-7s-note2 icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Summary
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                    <>
                           <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Solar PV</h5>
                        </button>
                    </div>
                    <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label>Electricity Generation (kWh/yr): {parseFloat(viewModel.electricityGeneration).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label>Export to Grid (kWh/yr): {parseFloat(viewModel.exportGrid).toFixed(2)}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Energy Costs ($)</h5>
                        </button>
                    </div>
                    <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label>PV Export: {parseFloat(viewModel.pvExport).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label>Grid Import: {parseFloat(viewModel.gridImport).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label>Gas: {parseFloat(viewModel.gas).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label>Wood: {parseFloat(viewModel.wood).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label>Net Energy Value: {parseFloat(viewModel.netEnergyValue).toFixed(2)}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Energy Consumptions in Modules</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <ModuleSummaryDataTable
                                debug = {debug}
                                data = {data}
                                setData = {setData}
                                extraPostModel = {extraPostModel}
                                tableReload = {reloadForm}
                                setTableReload = {setReloadForm}
                            ></ModuleSummaryDataTable>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Heating & Cooling Simulation Energy Consumption</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <HeatingCoolingSimulationDataTable
                                debug = {debug}
                                data = {simulationData}
                                setData = {setSimulationData}
                                extraPostModel = {extraPostModel}
                                tableReload = {reloadForm}
                                setTableReload = {setReloadForm}
                            ></HeatingCoolingSimulationDataTable>
                        </div>
                    </div>
                </div>
                    </>
                }
         
            </div>
        </Fragment>
    );
}
export default connectAppStore(ModuleSummary);