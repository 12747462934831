import { toast } from 'react-toastify';

export class ToastPrint {

    constructor() {
        this.toast = toast;
    }

    async printResponse(response) {
        if (response.state.toString() === 'true') {
            this.toast.success(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            this.toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } 

    async printSuccessMessage(message) {
        this.toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            index:999
        });
    } 

    async printErrorMessage(message) {
        this.toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            index:999
        });
    } 
}
const toastPrint = new ToastPrint();
export default toastPrint;