
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import ConstructionElementDataTable from "../../../Accessor/Construction/ConstructionElementDataTable";

const DoorDetail = (props) => {
    const {
        guid,
        layoutManager,
        useReducerSmart,
        useParams,
        useLocation,
        BaseFormNoHeader,
        BindedInput,
        BindedCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        Fragment, 
        useEffect,
        useEffectWithPrevious,
        useState
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const currentUrl = useLocation().pathname;
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const { elementId } = defaultParams;
    let {designVariationVersionName, developmentId, projectId, designVariationId, zoneId, parentId, redirectUrlHex} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        selectedItemIds: [elementId]
    };
    const [extraPostModelForConstruction, setExtraPostModelForConstruction] = useState({
      developmentId: developmentId,
      projectId: projectId,
      designVariationId: designVariationId,
      designVariationVersionName: designVariationVersionName,
      constructionType:"Door"
    });
    const [tableReloadForConstruction, setTableReloadForConstruction] = useState("");
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const getUrl = "api/Element/Accessor/GetDoor";
    const postUrl = "api/Element/Accessor/UpdateDoor";
    const validateMethods = {
        name:(value)=>{return FormValidate.validateTextRequired(value, "Door name");},
        constructionId:(value)=>{ return value === emptyGuid ? "Must select a construction." : "";},
        width: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Width");},
        height: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Height");},
        gapSizeTypeId: (value) => { return (!viewModel.isWeatherstripped && value<1)? "Must select a valid gap size" : ""}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, undefined, setInit);
    },[]);

    useEffect(()=>{  
      if(init){
        dispatchViewModel({...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId]}, ()=>{
            setReloadForm(guid.newGuid());
        });
      } 
    },[elementId]);

    useEffectWithPrevious((oldState) => {
        if(oldState === undefined) return;
        const prevModeTypeId = oldState[0];
        if(prevModeTypeId !== undefined && viewModel.isWeatherstripped !== undefined){
            if (viewModel.dwellingType === "New" && viewModel.isWeatherstripped) {
                dispatchViewModel({...viewModel, gapSizeTypeId: 1});
            }
            else if (viewModel.dwellingType === "New" && !viewModel.isWeatherstripped) {
                dispatchViewModel({...viewModel, gapSizeTypeId: 2});
            }
        }
    },[viewModel.isWeatherstripped]);

    const generateStayUrl = (submitResponse)=>{
        let redirectUrl = currentUrl;
        if(elementId === emptyGuid){
            //"ElementAccessor/Detail/Door/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex"
            redirectUrl =  "/ElementAccessor/Detail/Door/" + designVariationVersionName + "/" + developmentId +"/"+projectId+"/"+designVariationId+"/"+zoneId+"/"+submitResponse.elementId+"/"  +parentId+ "/" +redirectUrlHex;
        }
        return redirectUrl;
    }

    const calculateArea = (width, height) => {
        if (width === undefined || width === "" || width === null) width = 0;
        if (height === undefined || height === "" || height === null) height = 0;
        width = Number(width);
        height = Number(height);
        return ((width * height) / 1e6).toFixed(2);
      };

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-safe icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Door
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-12">
                        <div className="main-card mb-3 card">
                        <BaseFormNoHeader
                                    id="DoorDetail"
                                    submitButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl, text: "Save and close"}}
                                    submitButtonAndStay={{ text:"Save", postUrl: postUrl, redirectMethod: (submitResponse)=>{return generateStayUrl(submitResponse)}}}
                                    cancelButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl }}
                                    validate={formValidate}
                                    setReload = {setReloadForm}
                                    loading={loading}
                                    init={init}
                                    setLoading={setLoading}
                                    data={viewModel}
                                    cardTitle={"Door in external wall " + viewModel.externalWallName + " (zone " + viewModel.zoneName + ")" }
                                >
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                            <label htmlFor="DoorName">Name *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="DoorName"
                                                name="DoorName"
                                                type="text"
                                                validate={formValidate}
                                                binding={[viewModel, "name", bindingViewModel]}
                                            ></BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <label htmlFor="DoorConstructionId">
                                                    Construction *
                                                </label>
                                                <BindedSelect
                                                    className="form-control"
                                                    id="DoorConstructionId"
                                                    name="DoorConstructionId"
                                                    options="constructions"
                                                    validate={formValidate}
                                                    binding={[
                                                    viewModel,
                                                    "constructionId",
                                                    bindingViewModel,
                                                    ]}
                                                ></BindedSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                            <label htmlFor="DoorHeight">Height (mm) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="DoorHeight"
                                                name="DoorHeight"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "height", bindingViewModel]}
                                            ></BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                            <label htmlFor="DoorWidth">Width (mm) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="DoorWidth"
                                                name="DoorWidth"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "width", bindingViewModel]}
                                            ></BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                            <label htmlFor="DoorOpeningArea">
                                                Area (m<sup>2</sup>) *
                                            </label>
                                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateArea(viewModel.width, viewModel.height)}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                            <label htmlFor="DoorHorizontalOffset">
                                                Horizontal offset (mm) *
                                            </label>
                                            <BindedInput
                                                className="form-control"
                                                min="0"
                                                id="DoorHorizontalOffset"
                                                name="DoorHorizontalOffset"
                                                type="number"
                                                validate={formValidate}
                                                binding={[viewModel, "horizontalOffset", bindingViewModel]}
                                            ></BindedInput>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="position-relative form-group">
                                            <label htmlFor="DoorOpenablePercent">
                                                Openable (%) *
                                            </label>
                                            <BindedInput                                 
                                                className="form-control"
                                                min="0"
                                                max="100"
                                                id="DoorOpenablePercent"
                                                name="DoorOpenablePercent"
                                                type="number"
                                                validate={formValidate}
                                                binding={[viewModel, "openablePercent", bindingViewModel]}
                                            ></BindedInput>
                                            </div>
                                        </div>     
                                    </div>
                                    <div className="form-row row">
                                        <div className="custom-checkbox custom-control">
                                            <BindedCheckBox
                                                disabled
                                                id="DoorHasInsectScreens"
                                                name="DoorHasInsectScreens"
                                                className="custom-control-input"
                                                binding={[
                                                    viewModel,
                                                    "hasInsectScreens",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedCheckBox>
                                            <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="DoorHasInsectScreens"
                                            className="custom-control-label"
                                            >
                                            Insect screens
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="custom-checkbox custom-control">
                                            <BindedCheckBox
                                                disabled={viewModel.dwellingType === "New" && String(viewModel.zoneTypeId) !== "7"}
                                                id="DoorIsWeatherstripped"
                                                name="DoorIsWeatherstripped"
                                                className="custom-control-input"
                                                binding={[
                                                    viewModel,
                                                    "isWeatherstripped",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedCheckBox>
                                            <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="DoorIsWeatherstripped"
                                            className="custom-control-label"
                                            >
                                            Weatherstripped
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-row row">                       
                                        <div className="position-relative form-group" style={{ marginLeft: "5px" }}>
                                            <label htmlFor="DoorGapSizeTypeId">
                                                Gap size
                                            </label>
                                            <BindedSelect
                                                disabled={viewModel.isWeatherstripped===true}
                                                className="form-control"
                                                id="DoorGapSizeTypeId"
                                                name="DoorGapSizeTypeId"
                                                options="gapSizeTypes"
                                                validate={formValidate}
                                                binding={[
                                                viewModel,
                                                "gapSizeTypeId",
                                                bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </BaseFormNoHeader>
                        </div>
                    </div>
                    {init === true &&
                           <div className="col-xl-6 col-lg-12">
                           <div className="main-card mb-3 card">
                               <div className="card-header">
                                   <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">Door Construction</h5>
                                   </button>
                               </div>
                               <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                                   <div className="card-body">
                                   <div className="separator"></div>
                                       <ConstructionElementDataTable
                                           tableId = {"DoorConstructionElement-da7c6e0d-a7c2-4a8c-87bd-fdda5f19c2ba"}
                                           tableReload={tableReloadForConstruction}
                                           setTableReload={setTableReloadForConstruction}
                                           extraPostModel = {extraPostModelForConstruction}
                                           constructionType = "Door"
                                           redirectUrl = {currentUrl}
                                           debug = {debug}
                                       >
                                       </ConstructionElementDataTable>
                                   </div>
                               </div>
                           </div>
                       </div>
                    }
             
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(DoorDetail);