import React from "react";
import RowsPerPage from "../DataTable/SharedComponent/RowsPerPage";
import SearchBar from "../DataTable/SharedComponent/SearchBar";
import TablePaginate from "../DataTable/SharedComponent/TablePaginate";
import ContextMenu from "../DataTable/SharedComponent/ContextMenu";
import "bootstrap/dist/css/bootstrap.min.css";
import "./DataTableAe.css";
import guid from "../../Function/guid";
import PageDependencies from "../PageDependencies";
const HtmlToReactParser = require("html-to-react").Parser;

const CilentSideDataTable = (props) => {
  const { 
    Fragment, 
    useEffect, 
    useState,
    useRef,
    dataExtension,
    cookiesManager,
    getData,
    toastPrint,
  } = PageDependencies();
  const [init, setInit] = useState(false);
  const tableRef = useRef();
  const dataTableId = props.id === undefined ? guid.newGuid() : props.id;
  const htmlToReactParser = new HtmlToReactParser();
  const contextMenu = props.contextMenu;
  const tableCell = { border: "1px solid #e9ecef" };
  const [shiftClickIndxes, setShiftClickIndxes] = useState([]);
  let columnMinumSize = props.columnMinumSize === undefined ? 5 : props.columnMinumSize;
  const timeOut = props.timeOut === undefined ? 800 : props.timeOut;
  const removePaging = props.removePaging === undefined ? false : true;
  const removeSearch = props.removeSearch === undefined ? false : true;
  const disableSorting = props.disableSorting === undefined ? false : true;
  const allowSelect = props.allowSelect === undefined ? false : true;
  const allowSelectMultiple =
    props.allowSelectMultiple === undefined ? false : true;
  const dataSource = props.dataSource;
  const noRecordsMessage =
    props.noRecordsMessage === undefined 
      ? "No records found."
      : props.noRecordsMessage;
  let [data, setData] = useState([]);
  if(props.data!==undefined && props.setData !== undefined){
    data = props.data;
    setData = props.setData;
  } 
  const setSelectedItemIds = props.setSelectedItemIds;
  const [dataDisplay, setDataDisplay] = useState([]);
  const perPageOpts =
    props.perPageOpts === undefined
      ? [
          { value: 10, label: "10" },
          { value: 20, label: "20" },
          { value: 50, label: "50" },
          { value: 999999999, label: "All" },
        ]
      : props.perPageOpts;

  //load table setting from cookies
  const tableCookiesId = "data-table-" + dataTableId;
  const defautTableColumnSize = cookiesManager.getCookies(tableCookiesId + "_TableColumnSize", setColumnDefaultSize(props.columns));
  const defautTableSetting = cookiesManager.getCookies(tableCookiesId, {
    search: "",
    rowPerPage: removePaging ? -1 : 10,
    currentPage: 0,
    sortingColumns: setColumnDefaultSorting(props.columns),
  });

  let columSizes = [];
  for(let i = 0; i < props.columns.length; i++){
    const columnData = props.columns[i];
    if(columnData.generate !== undefined){
      defautTableSetting.sortingColumns[i].generate = columnData.generate;
    }
    if(defautTableColumnSize.length > 0){
      try {
        columSizes.push(defautTableColumnSize[i]);
      } catch (error) {
        columSizes.push(10);
      }
    }else{
      columSizes = setColumnDefaultSize(props.columns);
    }
  }
  const [columnWidths, setColumnWidths] = useState(columSizes);
  const [search, setSearch] = useState(defautTableSetting.search);
  const [rowPerPage, setRowPerPage] = useState(defautTableSetting.rowPerPage);
  const [sortingColumns, setSortingColumns] = useState(
    defautTableSetting.sortingColumns
  );
  const [currentPage, setCurrentPage] = useState(
    defautTableSetting.currentPage
  );
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(data.length);

  function setColumnDefaultSize(columnObjects) {
    const sizes = [];
    for (let i = 0; i < columnObjects.length; i++) {
      const width = Math.round(columnObjects[i].width.replace("%",""));
      sizes.push(width);
    }
    return sizes;
  }

  function setColumnDefaultSorting(columnObjects) {
    const columnsClone = [];
    if(columnObjects !== undefined){
      for (let i = 0; i < columnObjects.length; i++) {
        columnsClone[i] = { ...columnObjects[i] };
        if (i === 0) {
          columnsClone[i].sorting = "sorting_asc";
        } else {
          columnsClone[i].sorting = "sorting";
        }
          columnsClone[i].id = guid.newGuid();
      }
    }
    return columnsClone;
  }

  function setDataDefaultIsSelected(oData) {
    if (oData.length === undefined) {
      return [];
    }
    if (oData.length === 0) {
      return [];
    }
    if (oData[0].isSelected === undefined) {
      let dataClone = [];
      for (let i = 0; i < oData.length; i++) {
        dataClone[i] = { ...oData[i], isSelected: false };
        if (dataClone[i].id === undefined) {
            dataClone[i].id = guid.newGuid();
        }
      }
      return dataClone;
    }
    return oData;
  }

  const handleMouseDown = (index) => (e) => {
    var timer = setTimeout(()=>{
      const totalWidth = tableRef.current.clientWidth;
      const startX = e.pageX;
      const mouseMoveHandler = (ex) => {
        if (tableRef.current) {
          const newColumnWidths = [...columnWidths];
          let deltaX =  Math.round((ex.pageX - startX) / totalWidth * 100);
          const widthCurrent = newColumnWidths[index];
          const widthNext = newColumnWidths[index + 1];
          if(deltaX > widthNext - columnMinumSize){
            deltaX = widthNext - columnMinumSize;
          }
          if(0 - deltaX > widthCurrent - columnMinumSize){
            deltaX = -(widthNext - columnMinumSize);
          }
          newColumnWidths[index] = newColumnWidths[index] + deltaX;
          newColumnWidths[index + 1] = newColumnWidths[index + 1] - deltaX;
          setColumnWidths(newColumnWidths);
          cookiesManager.setCookies(tableCookiesId + "_TableColumnSize", newColumnWidths);
        }
    };
  
      const mouseUpHandler = () => {
          document.removeEventListener('mousemove', mouseMoveHandler);
          document.removeEventListener('mouseup', mouseUpHandler);
      };
  
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }, 300);
    return timer;
  };

  const handleSortingClick = async (e) => {
    const sortingId = e.currentTarget.getAttribute("id");
    const sortingValue = e.currentTarget.getAttribute("class");
    const sortingColumnsClone = [];
    for (let i = 0; i < sortingColumns.length; i++) {
      if (sortingColumns[i].id === sortingId) {
        const sortingNew = sortingValue.includes("asc")
          ? "sorting_desc"
          : "sorting_asc";
        sortingColumnsClone[i] = { ...sortingColumns[i], sorting: sortingNew };
      } else {
        sortingColumnsClone[i] = { ...sortingColumns[i], sorting: "sorting" };
      }
    }
    setSortingColumns(sortingColumnsClone);
  };

  const handleOptionChange = async (e) => {
    const optionValue = e.currentTarget.value;
    setRowPerPage(optionValue);
  };

  const handleSearchChange = async (e) => {
    const optionValue = e.currentTarget.value;
    setSearch(optionValue);
    setCurrentPage(0);
  };

  const handlePageClick = async (data) => {
    let selected = data.selected;
    setCurrentPage(selected);
  };

  const onTdValueChange = (e, columnName, valueProcess) => {
    let value = e.target.value;
    if(valueProcess !== undefined){
      value = valueProcess(value);
    }
    const inputId = e.currentTarget.getAttribute("id");
    const id = inputId.replace(dataTableId,"").replace(columnName,"");
    const dataClone = [...data];
    for (let i = 0; i < dataClone.length; i++) {
      if (dataClone[i].id === id) {
        dataClone[i][columnName] = value;
        break;
      }
    }
    updateSelectedItemIds(dataClone);
    setData(dataClone, ()=>{
      const inputElement = document.getElementById(inputId);
      inputElement.focus();
    });
  };

  const showCell = (model) => {
    var html = sortingColumns.map((c, i) => {
      const style = { ...c.style, tableCell };
      const elementHtml = c.generate ? c.generate(model, c, i, style, onTdValueChange) : model[c.name];
        return (<td style={style} key={i + "_" + guid.newGuid()}>
              {elementHtml}
            </td>);
    });
    return html;
  };

  const showRows = () => {
    const rows = dataDisplay.map((m, i) => {
      return (
        <tr
          id={m.id}
          onClick={async (e) => handleClickRow(e)}
              key={i + "_" + guid.newGuid()}
          className={
            m.isSelected
              ? "exclude table-active"
              : i % 2 === 1
              ? "odd "
              : "even "
          }
        >
          {showCell(m)}
        </tr>
      );
    });
    return rows;
  };

  const handleClickRow = async (e) => {
    if (e === null) return;
    if (e === undefined) return;
    const elementId = e.currentTarget.getAttribute("id");
    if (allowSelect && !e.shiftKey) {
      setShiftClickIndxes([]);
      setDataDisplay((prevState) => {
        const newData = [];
        const model = prevState.filter((m) => {
          return m.id === elementId;
        })[0];
        const isSelected = !model.isSelected;
        for (let i = 0; i < prevState.length; i++) {
          if (prevState[i].id === elementId) {
            newData[i] = { ...prevState[i], isSelected: isSelected };
          } else {
            if (!allowSelectMultiple) {
              newData[i] = { ...prevState[i], isSelected: false };
            } else {
              newData[i] = { ...prevState[i] };
            }
          }
        }
        if (props.actionOnSelectedRow !== undefined) {
          if (isSelected) {
            props.actionOnSelectedRow(model);
          }
        }
        updateSelectedItemIds(newData);
        return newData;
      });
    }
    if(allowSelect && e.shiftKey){
      let newShiftClickIndxes = [...shiftClickIndxes];
      if(newShiftClickIndxes.length === 0){
        for (let i = 0; i < data.length; i++) {
          if (data[i].isSelected === true){
            newShiftClickIndxes.push(i);
            break;
          }
        }
      }
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === elementId){
          newShiftClickIndxes.push(i);
          break;
        }
      }
      newShiftClickIndxes = newShiftClickIndxes.sort();
      setShiftClickIndxes(newShiftClickIndxes);
    }
  };

  const generateEmptyTd = () => {
    const count = sortingColumns.length - 1;
    const emptys = [];
    for (let i = 0; i < count; i++) {
      emptys[i] = i;
    }
    const trs = emptys.map((i, key) => {
      return <td key={key} style={tableCell}></td>;
    });
    return trs;
  };

  async function loadDataOnline() {
    if(dataSource !== undefined){
        const postModel = generatePostModel();
        const expensesResponse = await getData.processPost(
          dataSource.isAuth,
          dataSource.url,
          postModel
        );
        if(!expensesResponse.state){
          toastPrint.printErrorMessage();
          setData([]);
        }else{
          const tableData = expensesResponse.data;
          const rows = setDataDefaultIsSelected(tableData.data);
          setData(rows);
        }   
    }else{
      loadDataLocally();
    }
  }

  function compare(propertyName) {
    return function (a, b) {
      if (a[propertyName] < b[propertyName]) {
        return -1;
      }
      if (a[propertyName] > b[propertyName]) {
        return 1;
      }
      return 0;
    };
  }

  function sorting() {
    let rows = [...data];
    sortingColumns.forEach((c) => {
      const opt = c.sorting.replace("sorting", "").replace("_", "");
      if (opt.length > 0) {
        rows.sort(compare(c.name));
        if (opt === "desc") {
          rows.reverse();
        }
      }
    });
    return rows;
  }

  function paginate(array, pageSize, pageNumber) {
    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }

  function searchRows(array, searchPhase) {
    let result = array.filter((row) => {
      let keys = Object.keys(row).filter(
        (k) => k !== "id" && k !== "Id" && k !== "isSelected"
      );
      for (let i = 0; i < keys.length; i++) {
        if (
          row[keys[i]]
            .toString()
            .toLowerCase()
            .includes(searchPhase.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    return result;
  }

  async function loadDataLocally() {
    let rows = sorting();
    rows = searchRows(rows, search);
    const newRowPerPage = rowPerPage === -1 ? rows.length : rowPerPage;
    const totalPage = Math.ceil(rows.length / newRowPerPage);
    setTotalCount(rows.length);
    rows = paginate(rows, newRowPerPage, currentPage);
    setPageCount(totalPage);
    setDataDisplay(rows);
  }

  const generatePostModel = () => {
    const postModel = {
      start: 0,
      length: -1,
      search: { value: "", regex: "" },
      order: [],
      columns: [],
    };

    if (props.extraPostModel !== undefined) {
      const properties = Object.keys(props.extraPostModel);
      for (let i = 0; i < properties.length; i++) {
        postModel[properties[i]] = props.extraPostModel[properties[i]];
      }
    }

    let intdex = 0;
    for (let i = 0; i < sortingColumns.length; i++) {
      const column = sortingColumns[i];
      if (column.sorting.includes("asc") || column.sorting.includes("desc")) {
        postModel.order[intdex] = {
          column: i,
          dir: column.sorting.replace("sorting_", ""),
        };
        intdex++;
      }
      postModel.columns[i] = {
        data: sortingColumns[i].name,
      };
    }
    return postModel;
  };

  const generateTBody = () => {
    const trs = (
      <Fragment>
        {isLoading && (
          <tr className="odd">
            <td style={tableCell}>Loading data...</td>
            {generateEmptyTd()}
          </tr>
        )}
        {!isLoading && dataDisplay.length === 0 && (
                <tr id={guid.newGuid()} className="odd">
            <td style={tableCell}>{noRecordsMessage}</td>
            {generateEmptyTd()}
          </tr>
        )}
        {!isLoading && dataDisplay.length > 0 && showRows()}
      </Fragment>
    );
    return <tbody>{trs}</tbody>;
  };

  const contextMenuBuild = ($trigger, e) => {
    if (allowSelect) {
      const newData = [];
      const elementId = e.currentTarget.id;
      const model = data.filter((m) => {
        return m.id === elementId;
      })[0];
      if (model !== undefined) {
        if (!model.isSelected) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].id === elementId) {
              newData[i] = { ...data[i], isSelected: true };
            } else {
              if (!allowSelectMultiple) {
                newData[i] = { ...data[i], isSelected: false };
              } else {
                newData[i] = { ...data[i] };
              }
            }
          }
          updateSelectedItemIds(newData);
          setData(newData);
        }
      }
    }
  };

  useEffect(() => {
    const newTime = init ? timeOut / 3 : timeOut;
    const timer = setTimeout(() => {
      //set cookies
      cookiesManager.setCookies(tableCookiesId, {
        rowPerPage: rowPerPage,
        search: search,
        sortingColumns: sortingColumns,
        currentPage: currentPage,
      });
      if (init) {
        setIsLoading(true);
        loadDataLocally();
        setIsLoading(false);
      } else {
        setIsLoading(true);
        loadDataOnline();
        setIsLoading(false);
        setInit(true);
      }
    }, newTime);
    return () => {
      clearTimeout(timer);
    };
  }, [
    rowPerPage,
    search,
    sortingColumns,
    currentPage,
    data,
    props.reload,
    props.extraPostModel,
  ]);

  useEffect(()=>{
    if(setSelectedItemIds !== undefined){
      if(data.length > 0){
        const selectedIds = dataExtension.getSelectedRowDataIds(data);
        setSelectedItemIds({
          selectedItemIds:selectedIds
        });
      }else{
        setSelectedItemIds({
          selectedItemIds:[]
        });
      }
    }
  }, [data]);

  useEffect(()=>{
    if(shiftClickIndxes.length > 0){
      const startIndex = shiftClickIndxes[0];
      const lastIndex = shiftClickIndxes[shiftClickIndxes.length - 1];
      setData((oldData)=>{
        const newData = [...oldData];
        for(let i = 0; i < newData.length; i++){
          if(i >= startIndex && i <= lastIndex){
            newData[i].isSelected = true;
          }
        }
        updateSelectedItemIds(newData);
        return newData;
      });
    }
  }, [shiftClickIndxes]);

  const handleSelectAllClick = ()=>{
    setData((oldData)=>{
      const newData = [...oldData];
      for(let i = 0; i < newData.length; i++){
        newData[i].isSelected = true;
      }
      updateSelectedItemIds(newData);
      return newData;
    });
  }

  const handleUnselectAllClick = ()=>{
    setData((oldData)=>{
      const newData = [...oldData];
      for(let i = 0; i < newData.length; i++){
        newData[i].isSelected = false;
      }
      updateSelectedItemIds(newData);
      return newData;
    });
  }

  function updateSelectedItemIds(newData){
    if(setSelectedItemIds !== undefined){
      if(newData.length > 0){
        const selectedIds = dataExtension.getSelectedRowDataIds(newData);
        setSelectedItemIds({ selectedItemIds:selectedIds });
      }else{
        setSelectedItemIds({
          selectedItemIds:[]
        });
      }
    }
  }

  return (
    <Fragment>
      <ContextMenu
        contextMenu={contextMenu}
        data={data}
        build={contextMenuBuild}
        loadData={loadDataOnline}
        dataTableId={dataTableId}
      ></ContextMenu>
      <div className="dataTables_wrapper dt-bootstrap4">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            {!removePaging && (
              <RowsPerPage
                rowPerPage={rowPerPage}
                perPageOpts={perPageOpts}
                handleOptionChange={handleOptionChange}
                selectAllButtonId = {dataTableId + "_SelectAll"}
                unselectAllButtonId = {dataTableId + "_UnselectAll"}
                handleSelectAllClick = {handleSelectAllClick}
                handleUnselectAllClick = {handleUnselectAllClick}
              />
            )}
            {removePaging &&
              <>
                <a style={{display:"inline-block", marginRight:"15px", marginBottom:"10px"}} id = {dataTableId + "_SelectAll"} onClick={handleSelectAllClick} className="btn btn-primary btn-round">Select All</a>
                <a style={{display:"inline-block", marginRight:"15px", marginBottom:"10px"}}  id = {dataTableId + "_UnselectAll"} onClick={handleUnselectAllClick} className="btn btn-primary btn-round">Unselect All</a>
              </>
            }
          </div>
          <div className="col-sm-12 col-md-6">
            {!removeSearch && (
              <SearchBar
                search={search}
                handleSearchChange={handleSearchChange}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <table
              id={dataTableId}
              ref = {tableRef}
              style={{
                width: "100%",
                "--bs-table-hover-bg": "#E0F3FF",
                "--bs-table-active-bg": "#0d6efd",
              }}
              className="table table-hover table-striped table-bordered dataTable"
              role="grid"
            >
              <thead>
                <tr>
                  {sortingColumns.map((column, index) => {
                    if (disableSorting) {
                      return (
                        <th
                          key={index}
                          style={{ ...tableCell, width: columnWidths[index] + "%" }}
                          //style={{ ...tableCell, width: column.width }}
                        >
                                <div  style={{marginRight:"10px"}}>
                            {htmlToReactParser.parse(column.text)}
                          </div>                   
                          {index !== sortingColumns.length -1 &&
                            <div
                                className="resizer"
                                onMouseDown={handleMouseDown(index)}                            
                            />
                          }                                       
                        </th>
                      );
                    }
                    if (column.disableSorting !== undefined){
                      return (
                        <th
                          key={index}
                          id={column.id}
                          style={{ ...tableCell, width: columnWidths[index] + "%"}}
                          //style={{ ...tableCell, width: column.width }}
                        >
                               <div  style={{marginRight:"10px"}}>
                            {htmlToReactParser.parse(column.text)}
                          </div>                        
                          {index !== sortingColumns.length -1 &&
                            <div
                                className="resizer"
                                onMouseDown={handleMouseDown(index)}                            
                            />
                          }     
                        </th>
                      );
                    }
                    return (
                      <th
                        key={index}
                        id={column.id}
                        onClick={async (e) => handleSortingClick(e)}
                        className={column.sorting}
                        style={{ ...tableCell, width: columnWidths[index] + "%"}}
                        //style={{ ...tableCell, width: column.width }}
                      >
                         <div  style={{marginRight:"10px"}}>
                            {htmlToReactParser.parse(column.text)}
                          </div>                       
                          {index !== sortingColumns.length -1 &&
                            <div
                                className="resizer"
                                onMouseDown={handleMouseDown(index)}                            
                            />
                          }      
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tfoot>
                <tr>
                  {sortingColumns.map((column, index) => {
                    return (
                      <th
                        key={index}
                        style={{ ...tableCell, width: columnWidths[index] + "%"}}
                        //style={{ ...tableCell, width: column.width }}
                      >
                       <div  style={{marginRight:"10px"}}>
                            {htmlToReactParser.parse(column.text)}
                          </div>                     
                          {index !== sortingColumns.length -1 &&
                            <div
                                className="resizer"
                                onMouseDown={handleMouseDown(index)}                            
                            />
                          }    
                      </th>
                    );
                  })}
                </tr>
              </tfoot>
              {generateTBody()}
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-5">
            {!removePaging && (
              <div className="dataTables_info" role="status" aria-live="polite">
                Showing{" "}
                {dataDisplay.length > 0 ? currentPage * rowPerPage + 1 : 0} to{" "}
                {currentPage * rowPerPage + dataDisplay.length} of {totalCount}{" "}
                entries,{" "}
                <span className="select-info">
                  <span className="select-item">
                    {
                      dataDisplay.filter((m) => {
                        return m.isSelected;
                      }).length
                    }{" "}
                    row selected
                  </span>
                  <span className="select-item"></span>
                  <span className="select-item"></span>
                </span>
              </div>
            )}
          </div>
          {!removePaging && (
            <TablePaginate
              pageCount={pageCount}
              currentPage={currentPage}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default CilentSideDataTable;
