const SearchBar = (props)=>{
    return(
        <div className="dataTables_filter">
        <label>
          Search:
          <input
            type="search"
            className="form-control form-control-sm"
            value={props.search}
            onChange={async (e) => props.handleSearchChange(e)}
          />
        </label>
      </div>
       );
}
export default SearchBar;