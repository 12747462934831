function OnDragEndOperation(result, draggableTablesDic) {
    try {
        const sourceTableId = result.source.droppableId;
        const sourceRowIndex = result.source.index;
        const sourceRowId = result.draggableId;
        if (result.destination !== null) {
            const destinationTableId = result.destination.droppableId;
            const destinationRowIndex = result.destination.index;
            //get dragged row
            const sourceData = draggableTablesDic[sourceTableId].data;
            const sourceSetData = draggableTablesDic[sourceTableId].setData;
            const row = { ...sourceData.filter((r) => { return r.id === sourceRowId })[0] };
            //remove from source table
            const sourceDataClone = [...sourceData];
            sourceDataClone.splice(sourceRowIndex, 1);
            if (destinationTableId === sourceTableId) {
                sourceDataClone.splice(destinationRowIndex, 0, row);
                sourceSetData(sourceDataClone);
            }
            else {
                //update source table
                sourceSetData(sourceDataClone);
                //add row to destination Table
                const destinationData = draggableTablesDic[destinationTableId].data;
                const destinationSetData = draggableTablesDic[destinationTableId].setData;
                const destinationDataClone = [...destinationData];
                destinationDataClone.splice(destinationRowIndex, 0, row);
                destinationSetData(destinationDataClone);
            }
        }
    } catch (ex) {
        console.log(ex);
    }
}
export default OnDragEndOperation;