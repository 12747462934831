const RowsPerPage = (props) => {
  return (
    <div className="dataTables_length">
      <label>
        Show
        <select
          value={props.rowPerPage}
          onChange={async (e) => props.handleOptionChange(e)}
          className="form-control form-control-sm"
        >
          {props.perPageOpts.map((o, index) => {
            return (
              <option value={o.value} key={index}>
                {o.label}
              </option>
            );
          })}
        </select>
        entries
      </label>
      <a style={{display:"inline-block",marginLeft:"15px", marginRight:"15px"}} onClick={props.handleSelectAllClick} id= {props.SelectAllButtonId} className="btn btn-primary btn-round">Select All</a>
      <a style={{display:"inline-block", marginRight:"15px"}} onClick={props.handleUnselectAllClick} id= {props.unselectAllButtonId} className="btn btn-primary btn-round">Unselect All</a>
    </div>
  );
};
export default RowsPerPage;
