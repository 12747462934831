import UserPayments from "../../Portal/Accessor/User/UserPayments";
import UserCredits from "../../Portal/Accessor/User/UserCredits";
import PurchaseCredits from "../../Portal/Accessor/User/PurchaseCredits";
import PaymentDetail from "../../Portal/Accessor/User/PaymentDetail";
import Profile from "../../Portal/Accessor/User/Profile";
import ChangePassword from "../../Portal/Accessor/User/ChangePassword";
import PaymentResult from "../../Portal/Accessor/User/PaymentResult";
import UserRefunds from "../../Portal/Accessor/User/UserRefunds";
import RefundDetail from "../../Portal/Accessor/User/RefundDetail";
import RefundResult from "../../Portal/Accessor/User/RefundResult";
import TermsAndConditions from "../../Portal/Accessor/Default/TermsAndConditions";

const AccessorUserRouters = [
    {
        path: "/UserAccessor/Credits",
        exact: true,
        requireAuth: true,
        element: <UserCredits />,
    },
    {
        path: "/UserAccessor/Payments",
        exact: true,
        requireAuth: true,
        element: <UserPayments />,
    },
    {
        path: "/UserAccessor/Credits/Purchase",
        exact: true,
        requireAuth: true,
        element: <PurchaseCredits />,
    },
    {
        path: "/UserAccessor/Credits/Payment/:jsonParam",
        exact: true,
        requireAuth: true,
        element: <PaymentDetail />,
    },
    {
        path: "UserAccessor/Credits/PaymentResult/:jsonParam",
        exact: true,
        requireAuth: true,
        element: <PaymentResult />,
    },
    {
        path: "UserAccessor/Profile",
        exact: true,
        requireAuth: true,
        element: <Profile />,
    },
    {
        path: "/UserAccessor/ChangePassword",
        exact: true,
        requireAuth: true,
        element: <ChangePassword />,
    },
    {
        path: "/UserAccessor/Refunds",
        exact: true,
        requireAuth: true,
        element: <UserRefunds />,
    },
    {
        path: "/UserAccessor/Credits/Refund/:creditId",
        exact: true,
        requireAuth: true,
        element: <RefundDetail />,
    },
    {
        path: "/UserAccessor/Credits/RefundResult/:jsonParam",
        exact: true,
        requireAuth: true,
        element: <RefundResult />,
    },
    {
        path: "/UserAccessor/TermsConditions",
        exact: true,
        requireAuth: true,
        element: <TermsAndConditions />,
    }
  ];
  export default AccessorUserRouters;