class StringCoding {
  encode(str) {
    let hexString = "";
    for (let i = 0; i < str.length; i++) {
      const hex = str.charCodeAt(i).toString(16);
      hexString += ("00" + hex).slice(-2);
    }
    return hexString;
  }

  encodeObject(obj) {
    const str = JSON.stringify(obj);
    return this.encode(str);
  }

  decode(hexString) {
    if(hexString === undefined) return null;
    if(hexString === null) return null;
    let str = "";
    for (let i = 0; i < hexString.length; i += 2) {
      const hexPair = hexString.substr(i, 2);
      const charCode = parseInt(hexPair, 16);
      str += String.fromCharCode(charCode);
    }
    return str;
  }

  decodeObject(hexString){
    const str = this.decode(hexString);
    return JSON.parse(str);
  }
}
const stringCoding = new StringCoding();
export default stringCoding;
