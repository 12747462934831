import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";

const RegisterUsersCSV = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        layoutManager,
        useReducerSmart,
        BaseForm,
        BindedInput,
        getAppStore,
        AppLoading,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
  
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart({csvDataBase64: "", filePath: ""});
    const [loading, setLoading] = useState({ isLoading: false, text: "" });

    //add model post url here
    const postUrl = "api/User/Admin/CreateUsersByCsv";
 
    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_HideSideBar(props);
    },[]);

    useEffect(()=>{
        if(viewModel.filePath !== undefined && viewModel.filePath !== ""){
            const input = document.getElementById("formFile");
            const fReader = new FileReader();
            fReader.readAsDataURL(input.files[0]);
            fReader.onloadend = function(event){
                const file = event.target.result;
                dispatchViewModel({...viewModel, csvDataBase64: file});
            }
        }    
    },[viewModel.filePath]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <BaseForm
                id="AdminRegisterUsersCSV"
                icon="pe-7s-info"
                loading={loading}
                setReload={setReloadForm}
                setLoading={setLoading}
                submitButton= {{text:"Upload", postUrl: postUrl, redirectUrl: "/Admin/Users" }}
                cancelButton={{ redirectUrl: "/Admin/Users" }}
                data={viewModel}
                title = "Create Users By CSV file"
                subTitle={"Create users"}
            >
                <div className="form-row row">
                    <div className="col-4">          
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Upload CSV file</label>
                            <BindedInput  type="file" id="formFile" accept="csv/*" className="form-control" binding={[viewModel, "filePath", bindingViewModel]}></BindedInput>    
                        </div>
                    </div>
                </div>
            </BaseForm>
        </Fragment>
    );
}
export default connectAppStore(RegisterUsersCSV);