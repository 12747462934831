import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";

const ElementTypeDataTable = (props) => {
    const { 
        Fragment,
        useState,
        useParams,
        layoutManager,
        cookiesManager,
        useEffect,
        tryCatchFinally,
        usePrevious,
        pageProfile,
        ServerSideDataTable,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const {designVariationVersionName, projectId, designVariationId} = defaultParams;
    const [extraPostModel, setExtraPostModel] = useState({
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName
    });
    const [data, setData] = useState([]);
    const tableId = "ElementType_4da152fc-a068-4e05-ba7d-1839afb58572";
    const selectCookieId = "ElementTypeCookie_4da152fc-a068-4e05-ba7d-1839afb58572_" + designVariationId;
  
    let [selectedIds, setSelectedIds] = useState([]);
    if (props.selectedIds !== undefined) selectedIds = props.selectedIds;
    if (props.setSelectedIds !== undefined) setSelectedIds = props.setSelectedIds;

    const columns = [
        { name: "name", text: "Name", width: "100%" }
    ];

    const previousDataLength = usePrevious(data.length);
    useEffect(()=>{
        tryCatchFinally.syncProcess(()=>{
            if(previousDataLength !== undefined && previousDataLength == 0 && data.length > 0){
                const cookie = cookiesManager.getCookies(selectCookieId, {selectedIndexes: []});
                if(cookie.selectedIndexes.length > 0){
                    const newData = [];
                    const newSelectedIds = {selectedItemIds:[]};
                    for(let i = 0; i < data.length; i++ ){
                        newData.push({...data[i]});
                    }
                    for(let i = 0; i < data.length; i++ ){
                        if(cookie.selectedIndexes.filter(index=>index==i).length > 0){
                            newData[i].isSelected = true;
                            newSelectedIds.selectedItemIds.push(newData[i].id)
                        }
                    }
                    setData(newData);
                    setSelectedIds(newSelectedIds);
                }
            }   
        });     
    }, [data.length])

    useEffect(()=>{
        tryCatchFinally.syncProcess(()=>{
            if (data !== undefined && data.length > 0){
                const cookie = {selectedIndexes: []};
                for(let i = 0; i < data.length; i++ ){
                   if(data[i].isSelected == true){
                    cookie.selectedIndexes.push(i);
                   }
                }
                cookiesManager.setCookies(selectCookieId, cookie);
            }
        }); 
    }, [data]);

    return (
        <Fragment>
             {debug && <p>{JSON.stringify(extraPostModel)}</p>}
             <h5>Element Types</h5>
             <ServerSideDataTable
                id={tableId}
                location="ElementTypes"
                data={data}
                setData={setData}
                columns={columns}
                allowSelect
                allowSelectMultiple
                removePaging
                removeSearch
                disableSorting
                dataSource={{ isAuth: true, url: "api/Element/Accessor/GetElementTypes" }}
                extraPostModel={extraPostModel}
                setSelectedItemIds = {setSelectedIds}
            ></ServerSideDataTable>
        </Fragment>
    );
}
export default connectAppStore(ElementTypeDataTable);