import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";
const CreditUnitDetail = (props) => {
    const { 
        Fragment,
        guid,
        useEffect,
        useState,
        useParams,
        tryCatchFinally,
        toastPrint,
        layoutManager,
        getData,
        useReducerSmart,
        BindedInput,
        BindedSelect,
        getAppStore,
        useNavigate,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const nav = useNavigate();
    const [init, setInit] = useState(false);
    const { } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        id:emptyGuid,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/CreditUnitAdmin/Get";
    const validateMethods = {
        unitPrice:(value)=>{return FormValidate.validateTextRequired(value, "Unit price");},
        createTime:(value)=>{return FormValidate.validateTextRequired(value, "Create time");},
        activated:(value)=>{return FormValidate.validateTextRequired(value, "Activated");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Credit Unit", appStoreDispatch, undefined, setInit);
    },[]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/CreditUnitAdmin/Update";
        setLoading({ isLoading: true, text: "Submitting" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            nav("/Admin/CreditUnits");
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Create New Unit
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                      <div className="main-card mb-3 col-3 card">
                      <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                          <div className="card-body">
                              <div className="form-row row">
                                  <div className="col-12">
                                      <div className="position-relative form-group">
                                          <label htmlFor="UnitPrice">Unit Price * </label>
                                          <BindedInput
                                              className="form-control"
                                              id="UnitPrice *"
                                              name="UnitPrice *"
                                              type="text"
                                              binding={[viewModel, "unitPrice", bindingViewModel]}
                                          ></BindedInput>
                                      </div>
                                  </div>
                                  <div className="col-12">
                                      <div className="position-relative form-group">
                                          <label htmlFor="CreditTypeId">Credit Type * </label>
                                          <BindedSelect
                                              className="form-control"
                                              id="CreditTypeId"
                                              name="CreditTypeId"
                                              binding={[viewModel, "creditTypeId", bindingViewModel]}
                                              options="creditTypes"
                                              validate ={formValidate}
                                          ></BindedSelect>
                                      </div>
                                  </div>
                                  <div className="col-12">
                                      <div className="position-relative form-group">
                                          <label htmlFor="CreateTime">Create Time * </label>
                                          <BindedInput
                                              className="form-control"
                                              id="CreateTime *"
                                              name="CreateTime *"
                                              type="text"
                                              binding={[viewModel, "createTime", bindingViewModel]}
                                          ></BindedInput>
                                      </div>
                                  </div>
                                  <div className="col-12">
                                      <div className="position-relative form-group">
                                          <label htmlFor="Activated">Activated * </label>
                                          <BindedInput
                                              className="form-control"
                                              id="Activated *"
                                              name="Activated *"
                                              type="text"
                                              binding={[viewModel, "activated", bindingViewModel]}
                                          ></BindedInput>
                                      </div>
                                  </div>
                              </div>
                              <div className="form-row row">
                                  <div className="col-12">
                                      <div className="position-relative form-group">
                                          <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Submit</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                }
          
            </div>
        </Fragment>
    );
}
export default connectAppStore(CreditUnitDetail);
