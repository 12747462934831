import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";

const ZonesInElementsPage = (props) => {
    const { 
        Fragment,
        useState,
        useParams,
        stringCoding,
        layoutManager,
        guid,
        useNavigate,
        useEffect,
        usePrevious,
        pageProfile,
        dataExtension,
        cookiesManager,
        ServerSideDataTable,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const tableReload = props.tableReload;
    const {designVariationVersionName, developmentId, projectId, designVariationId, redirectUrl, jsonParam} = defaultParams;
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const tableId = "ZoneInElementsPage_c8e34a55-c73c-4804-bb83-5f1806ee5603";
    const selectCookieId = "ZoneInElementsPageCookie_c8e34a55-c73c-4804-bb83-5f1806ee5603_" + designVariationId;
    let [selectedIds, setSelectedIds] = useState([]);
    if (props.selectedIds !== undefined) selectedIds = props.selectedIds;
    if (props.setSelectedIds !== undefined) setSelectedIds = props.setSelectedIds;
    const [extraPostModel, setExtraPostModel] = useState({
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName
    });
    const columns = [
        { name: "zoneType", text: "Type", width: "30%" },
        { name: "name", text: "Name", width: "70%" }
    ];
    const newZoneUrl = "/ZoneAccessor/Detail/" + designVariationVersionName + "/" + projectId + "/" + designVariationId + "/" + guid.emptyGuid();
    const contextMenu = {
        items: {   
            new: {
                name: "New",
                icon: "add",
                url: newZoneUrl,
                defaultAction: true,
                },
                separator: "---------",
          edit: {
            name: "Edit",
            icon: "edit",
            defaultAction: false,
              disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          }
        },
        callBack: (
          contextMenuCallBackKey,
          contextMenuCallBackElement,
          contextMenuCallBackTableRoadData,
          localtion
        ) => {
          if (contextMenuCallBackKey === "edit") {
             const selectedZoneIds = dataExtension.getSelectedRowDataIds(data);
             if(selectedZoneIds.length == 1){
                nav("/ZoneAccessor/Detail/" + extraPostModel.designVariationVersionName + "/" + extraPostModel.projectId + "/" + extraPostModel.designVariationId + "/" + selectedZoneIds[0]);
             }else{
                nav("/ZoneAccessor/MultipleDetail/" + extraPostModel.designVariationVersionName + "/" + extraPostModel.projectId + "/" + extraPostModel.designVariationId + "/" + stringCoding.encodeObject(selectedZoneIds));
             }
          }        
        },
      };

    const actionOnDoubleClickRow = (model, newData) =>{
      nav("/ZoneAccessor/Detail/" + extraPostModel.designVariationVersionName + "/" + extraPostModel.projectId + "/" + extraPostModel.designVariationId + "/" + model.id);
    }

    const previousDataLength = usePrevious(data.length);
    useEffect(()=>{
        if(previousDataLength !== undefined && previousDataLength == 0 && data.length > 0){
            const cookie = cookiesManager.getCookies(selectCookieId, {selectedIndexes: []});
            if(cookie.selectedIndexes.length > 0){
                const newData = [];
                const newSelectedIds = {selectedItemIds:[]};
                for(let i = 0; i < data.length; i++ ){
                    newData.push({...data[i]});
                }
                for(let i = 0; i < data.length; i++ ){
                    if(cookie.selectedIndexes.filter(index=>index==i).length > 0){
                        newData[i].isSelected = true;
                        newSelectedIds.selectedItemIds.push(newData[i].id)
                    }
                }
                setData(newData);
                setSelectedIds(newSelectedIds);
            }
        }   
    }, [data.length])

    useEffect(()=>{
        if (data !== undefined && data.length > 0){
            const cookie = {selectedIndexes: []};
            for(let i = 0; i < data.length; i++ ){
               if(data[i].isSelected === true){
                cookie.selectedIndexes.push(i);
               }
            }
            cookiesManager.setCookies(selectCookieId, cookie);
        }
    }, [data]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(extraPostModel)}</p>}
            <h5>Zones</h5>
            <ServerSideDataTable
                id={tableId}
                location="ZonesInElements"
                data={data}
                setData={setData}
                reload={tableReload}
                columns={columns}
                actionOnDoubleClickRow={actionOnDoubleClickRow}
                allowSelect
                allowSelectMultiple
                dataSource={{ isAuth: true, url: "api/Zone/Accessor" }}
                contextMenu={contextMenu}
                extraPostModel={extraPostModel}
                setSelectedItemIds = {setSelectedIds}
            ></ServerSideDataTable>  
        </Fragment>
    );
}
export default connectAppStore(ZonesInElementsPage);