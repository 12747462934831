import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const MultipleSkylightsDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedTriStateCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const zoneId = useParams().zoneId;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedElementIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedElementIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateMultipleSkylights";
    const getUrl = "api/Element/Accessor/GetMultipleSkylights";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading skylights", appStoreDispatch);
    },[]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="FloorCeilingFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "Skylights detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="SkylightName">Name *</label>
                        <BindedInput
                            className="form-control"
                            id="SkylightName"
                            name="SkylightName"
                            type="text"
                            binding={[viewModel, "name", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="ZoneLitZoneId">
                            Zone *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="ZoneLitZoneId"
                            name="ZoneLitZoneId"
                            options="zoneLitZones"
                            binding={[
                                viewModel,
                                "zoneLitZoneId",
                                bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="SkylightConstructionId">
                            Construction *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="SkylightConstructionId"
                            name="SkylightConstructionId"
                            options="constructions"
                            binding={[viewModel, "constructionId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="OutdoorShadingPercentTypeId">
                            Outdoor Shading*
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="OutdoorShadingPercentTypeId"
                            name="OutdoorShadingPercentTypeId"
                            options="outdoorShadingPercentTypes"
                            binding={[
                                viewModel,
                                "outdoorShadingPercentTypeId",
                                bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="OpenablePercent">Openable (%) *</label>
                        <BindedInput
                            className="form-control"
                            id="OpenablePercent"
                            name="OpenablePercent"
                            type="number"
                            min="0"
                            binding={[viewModel, "openablePercent", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="GrossArea">Gross Area (m<sup>2</sup>) *</label>
                        <BindedInput
                            className="form-control"
                            id="GrossArea"
                            name="GrossArea"
                            type="number"
                            min="0"
                            binding={[viewModel, "grossArea", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="Pitch">Pitch (degree) *</label>
                        <BindedInput
                            className="form-control"
                            id="Pitch"
                            name="Pitch"
                            type="number"
                            min="0"
                            binding={[viewModel, "pitch", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ShaftTypeId">Shaft type *</label>
                        <BindedSelect
                            className="form-control"
                            id="ShaftTypeId"
                            name="ShaftTypeId"
                            options="shaftTypes"
                            binding={[viewModel, "shaftTypeId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ShaftLength">Shaft Length *</label>
                        <BindedInput
                            className="form-control"
                            id="ShaftLength"
                            name="ShaftLength"
                            type="number"
                            min="0"
                            binding={[viewModel, "shaftLength", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ShaftReflectance">Shaft Reflectance *</label>
                        <BindedInput
                            className="form-control"
                            id="ShaftReflectance"
                            name="ShaftReflectance"
                            type="number"
                            min="0"
                            binding={[viewModel, "shaftReflectance", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ShaftInsulationRValue">Shaft insulation (R-value) *</label>
                        <BindedInput
                            className="form-control"
                            id="ShaftInsulationRValue"
                            name="ShaftInsulationRValue"
                            type="number"
                            min="0"
                            binding={[viewModel, "shaftInsulationRValue", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        id="HasDiffuser"
                        name="HasDiffuser"
                        className="custom-control-input"
                        binding={[viewModel, "hasDiffuser", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="HasDiffuser"
                        className="custom-control-label"
                    >
                        Diffuser
                    </label>
                </div>
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        id="IsVented"
                        name="IsVented"
                        className="custom-control-input"
                        binding={[viewModel, "isVented", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="IsVented"
                        className="custom-control-label"
                    >
                        Vented
                    </label>
                </div>
            </div>
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleSkylightsDetail);