import { configureStore } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import appSlices from "./reducers/index";


const appStore = configureStore(appSlices);
const mapStateToProps = (state) => {
  return { storeState: state };
};
const mapDispatchToProps = (dispatch) => {
  return { storeDispatch: dispatch };
};

export const connectAppStore = (classComponent) => {
  return connect(mapStateToProps, mapDispatchToProps)(classComponent);
};

export const getAppStore = (props) => {
  const storeSate = props.storeState;
  const storeDispatch = props.storeDispatch;
  return [storeSate, storeDispatch];
};
export default appStore;
