
import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
const Zones = (props)=>{
    const { 
        layoutManager,
        stringCoding,
        getData,
        guid,
        toastPrint,
        useParams,
        getAppStore,
        useNavigate,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        tryCatchFinally,
        dataExtension,
        Fragment, 
        useEffect, 
        useState,
        ServerSideDataTable,
        useStateOperation,
        useReducerSmart,
        ModalDatatableItem,
    } = PageDependencies();
    let [dataResponse, setDataResponse] = useState({});
    const designVariationId = useParams().designVariationId;
    const projectId = useParams().projectId;
    const designVariationVersionName = useParams().designVariationVersionName;
    const nav = useNavigate();
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
    });
    const [loading, setLoading] = useState({isLoading:false,text:""});
    const [extraPostModel, setExtraPostModel] = useState({
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName
    });
    const [data, setData] = useState([]);
    const tableId = "c8e34a55-c73c-4804-bb83-5f1806ee5603";
    const [tableReload, setTableReload] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const [viewModel, setViewModel] = useReducerSmart({
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName
    });
    const columns = [
        { name: "zoneType", text: "Type", width: "20%" },
        { name: "name", text: "Name", width: "25%" },
        { name: "floor", text: "Floor number", width: "10%" },
        { name: "floorArea", text: "Floor area (m<sup>2</sup>)", width: "15%" },
        { name: "volume", text: "Volume (m<sup>3</sup>)", width: "15%" },
        { name: "ceilingHeight", text: "Max. Ceiling Height (mm)", width: "15%" }
    ];
    const newZoneUrl = "/ZoneAccessor/Detail/" + designVariationVersionName + "/" + projectId + "/" + designVariationId + "/" + guid.emptyGuid();
    const contextMenu = {
        items: {
          new: {
            name: "New",
            icon: "add",
            url: newZoneUrl,
            defaultAction: true,
          },
          separator: "---------",
          edit: {
            name: "Edit",
            icon: "edit",
            defaultAction: false,
              disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          },
          duplicate: {
            name: "Duplicate",
            icon: "copy",
            url: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          },
          delete: {
            name: "Delete",
            icon: "delete",
            url: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          }
        },
        callBack: (
          contextMenuCallBackKey,
          contextMenuCallBackElement,
          contextMenuCallBackTableRoadData,
          localtion
        ) => {
          if (contextMenuCallBackKey === "edit") {
             const selectedZoneIds = dataExtension.getSelectedRowDataIds(data);
             if(selectedZoneIds.length == 1){
                nav("/ZoneAccessor/Detail/" + viewModel.designVariationVersionName + "/" + viewModel.projectId + "/" + viewModel.designVariationId + "/" + selectedZoneIds[0]);
             }else{
                nav("/ZoneAccessor/MultipleDetail/" + viewModel.designVariationVersionName + "/" + viewModel.projectId + "/" + viewModel.designVariationId + "/" + stringCoding.encodeObject(selectedZoneIds));
             }
          }
          if (contextMenuCallBackKey === "delete") {
            setModal({
              ...modal,
              isOpen: true,
              title: "Delete zones",
              message:
                "Are sure to delete " +
                dataExtension.getSelectedRowDataIds(data).length +
                " selected zones?",
              comfirmButtonStyle: "danger",
              comfirmButtonText: "Confirm to delete",
              handleComfirm: handleDeleteComfirm,
            });
          }
          if (contextMenuCallBackKey === "duplicate") {
            setModal({
              ...modal,
              isOpen: true,
              title: "Duplicate zones",
              message:
                "Are sure to duplicate " +
                dataExtension.getSelectedRowDataIds(data).length +
                " selected zones?",
              comfirmButtonStyle: "primary",
              comfirmButtonText: "Confirm to duplicate",
              handleComfirm: handleDuplicatedComfirm,
            });
          }
        },
      };

    const handleDeleteComfirm = async () => {
      await useStateOperation.asyncInvoke(setSelectedIds, async (newSelectedIds)=>{
        setLoading({isLoading:true,text:"Deleting zones"});
        const selectResponse = await getData.processPost(
        true,
        "api/Zone/Accessor/Delete",
            {...newSelectedIds, designVariationVersionName: viewModel.designVariationVersionName, projectId: projectId, designVariationId: viewModel.designVariationId}
        ); 
        toastPrint.printResponse(selectResponse);
        setTableReload(guid.newGuid());
      }, setLoading);
    }

    const handleDuplicatedComfirm = async () => {
      await useStateOperation.asyncInvoke(setSelectedIds, async (newSelectedIds)=>{
        setLoading({isLoading:true,text:"Duplicating zones"});
        const selectResponse = await getData.processPost(
        true,
        "api/Zone/Accessor/Copy",
            {...newSelectedIds, designVariationVersionName: viewModel.designVariationVersionName, projectId: projectId, designVariationId: viewModel.designVariationId}
        ); 
      toastPrint.printResponse(selectResponse);
      setTableReload(guid.newGuid());
      }, setLoading);
    }

    const actionOnDoubleClickRow = (model, newData) =>{
      nav("/ZoneAccessor/Detail/" + viewModel.designVariationVersionName + "/" + viewModel.projectId + "/" + viewModel.designVariationId + "/" + model.id);
    }
    
    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, "api/DefaultModel/Accessor/Get", viewModel, setViewModel, setLoading, "Loading data", appStoreDispatch);
    },[]);
    
    return(<Fragment>
        {layoutManager.Layout_GetDebugModel(props) && <p>Selected items: {JSON.stringify(selectedIds.selectedItemIds)}</p>}
        <ModalDatatableItem
        modal={modal}
        setModal={setModal}
        ></ModalDatatableItem>
        {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>}
        <div className="app-main__inner">
            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">
                        <div className="page-title-icon">
                        <i className="pe-7s-safe icon-gradient bg-tempting-azure">
                        </i>
                        </div>
                        <div>
                        Zones
                        <div className="page-title-subheading">
                            {pageProfile.getPageSubTitle(appStoreState)}
                        </div>
                        </div>
                    </div>
                    {/* <div className="page-title-actions">
                        <a onClick={()=>{nav(newZoneUrl)}} id="NewZoneButton" data-toggle="tooltip" title="" data-placement="bottom" className="mr-3 btn btn-primary btn-wide btn-lg text-white" data-original-title="New zone (Shift+N)">
                            <i className="fa fa-plus">New</i>
                        </a>
                    </div> */}
                </div>
            </div>
            {/*TODO related to work item 148, will do it in future */}
            {/* <div className="main-card mb-3 card">
              <div className="card-header">
                  <button type="button" data-toggle="collapse" data-target="#ZonesSummaryDiv" aria-expanded="true" aria-controls="ZonesSummaryDiv" className="text-left m-0 p-0 btn btn-block">
                      <h5 className="m-0 p-0 card-title">Zones Summary</h5>
                  </button>
              </div>
              <div className="card-body">
                {dataResponse.state === true &&
                  <div className="form-row row">
                    <div className="col-2">
                        <div className="position-relative form-group">
                            <label htmlFor="TotalFloorArea">Total floor area (m<sup>2</sup>)</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {dataResponse.totalFloorArea}</label>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="position-relative form-group">
                            <label htmlFor="TotalConditonedFloorArea">Total conditioned floor area (m<sup>2</sup>)</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {dataResponse.totalConditionedFloorArea}</label>
                        </div>
                    </div>
                  </div>
                }                     
              </div>
            </div> */}
            <div className="main-card mb-3 card">
                <div className="card-body">
                    <ServerSideDataTable
                        id={tableId}
                        location="Zone"
                        data={data}
                        setData={setData}
                        reload={tableReload}
                        columns={columns}
                        actionOnDoubleClickRow={actionOnDoubleClickRow}
                        allowSelect
                        allowSelectMultiple
                        dataSource={{ isAuth: true, url: "api/Zone/Accessor" }}
                        contextMenu={contextMenu}
                        extraPostModel={extraPostModel}
                        setSelectedItemIds = {setSelectedIds}
                        dataResponse = {dataResponse}
                        setDataResponse = {setDataResponse}
                    ></ServerSideDataTable>      
                </div>
            </div>
        </div>
    </Fragment>);
}
export default connectAppStore(Zones);