import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";
const FinanceReport = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        stringCoding,
        tryCatchFinally,
        layoutManager,
        getData,
        useReducerSmart,
        BindedInput,
        getAppStore,
        useNavigate,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const nav = useNavigate();
    const [init, setInit] = useState(false);
    const { } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/PaymentAdmin/FinanceReport/Get";
    const validateMethods = {
        // qualitySimulation:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Simulation number");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Data", appStoreDispatch, undefined, setInit);
    },[]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/PaymentAdmin/FinanceReport/GetResult";
        setLoading({ isLoading: true, text: "Submitting" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            const urlParams = stringCoding.encodeObject(response);
            nav("/Admin/FinanceReport/Result/" + urlParams);
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Request for Finance Report
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                       <div className="main-card mb-3 col-3 card">
                       <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                           <div className="card-body">
                               <div className="form-row row">
                                   <div className="col-12">
                                       <div className="position-relative form-group">
                                           <label htmlFor="StartTime">Start Time * </label>
                                           <BindedInput
                                               className="form-control"
                                               id="StartTime"
                                               name="StartTime"
                                               type="text"
                                               binding={[viewModel, "startTime", bindingViewModel]}
                                           ></BindedInput>
                                       </div>
                                   </div>
                               </div>
                               <div className="form-row row">
                                   <div className="col-12">
                                       <div className="position-relative form-group">
                                           <label htmlFor="EndTime">End Time * </label>
                                           <BindedInput
                                               className="form-control"
                                               id="EndTime"
                                               name="EndTime"
                                               type="text"
                                               binding={[viewModel, "endTime", bindingViewModel]}
                                           ></BindedInput>
                                       </div>
                                   </div>
                               </div>
                               <div className="form-row row">
                                   <div className="col-12">
                                       <div className="position-relative form-group">
                                           <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Send Email</button>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
                }
         
            </div>
        </Fragment>
    );
}
export default connectAppStore(FinanceReport);
