import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";

const SimulationByUserName = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        getData,
        useReducerSmart,
        toastPrint,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart({});
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    //change model get url here if not using default model
    const getUrl = "api/Simulation/Admin/GetSimulationByUserName";
    //add model post url here
    const postUrl = "api/Simulation/Admin/SimulationByUserName";
    const validateMethods = {
    //add form property validate here
        userNameNeed:(value)=>{return FormValidate.validateTextRequired(value, "User name")},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        if(init===false) setInit(true);
        layoutManager.Layout_Headter_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch);
    },[]);

    const handleSubmitButtonClick = async (e) => {
        e.preventDefault();
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult){
          return ;
        }
        setLoading({isLoading: true, text: "Submitting form"});
        const postResponse = await getData.processPost(true, postUrl, viewModel);
        setLoading({isLoading: false, text: ""});
        toastPrint.printResponse(postResponse);
    };

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Simulation By Username
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                      <div className="main-card mb-3 col-3 card">
                      <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                          <div className="card-body">
                              <div className="form-row row">
                                  <div className="col-12">
                                      <div className="position-relative form-group">
                                          <label htmlFor="usernameNeed">Username * </label>
                                          <BindedInput
                                              className="form-control"
                                              id="usernameNeed"
                                              name="usernameNeed"
                                              type="text"
                                              binding={[viewModel, "userNameNeed", bindingViewModel]}
                                              validate ={formValidate}
                                          ></BindedInput>
                                      </div>
                                  </div>
                                  <div className="col-12">
                                      <div className="position-relative form-group">
                                          <label htmlFor="SimulationTypeId">Simulation Type * </label>
                                          <BindedSelect
                                              className="form-control"
                                              id="SimulationTypeId"
                                              name="SimulationTypeId"
                                              binding={[viewModel, "simulationTypeId", bindingViewModel]}
                                              options="simulationTypes"
                                              validate ={formValidate}
                                          ></BindedSelect>
                                      </div>
                                  </div>
                              </div>
                              <div className="form-row row">
                                  <div className="col-12">
                                      <div className="position-relative form-group">
                                          <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={(e)=>handleSubmitButtonClick(e)}>Submit</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                }
            </div>
        </Fragment>
    );
}
export default connectAppStore(SimulationByUserName);