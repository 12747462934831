import React, { Fragment } from "react";
import cx from "classnames";
import HeaderLogo from "../AppLogo";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MegaMenu from "./Components/MegaMenu";
import UserBox from "./Components/UserBox";
import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";
import avatarDefault from "../../../assets/default.png";

const Header = (props) => {
  const { 
    Fragment,
    useEffect,
    useState,
    getData,
    getAppStore,
  } = PageDependencies();

  const [appStoreState, appStoreDispatch] = getAppStore(props);
    const theme = appStoreState.appThemeReducer;
    let {
      enableMobileMenuSmall,
      showHeader
    } = theme;

  const [userInfo, setUserInfo] = useState({userName: "", userRole:""});
  const [avatar, setAvatar] = useState(avatarDefault);

  const loadUserInfo = async ()=>{
    const userRoleResponse = await getData.processPost(true, "api/DefaultModel/Accessor/Get", {});
    if(userRoleResponse !== undefined){
      if(userRoleResponse.viewModel !== undefined){
        const {userName, userRole} = userRoleResponse.viewModel;
        setUserInfo({userName: userName, userRole: userRole});
      }
    }
    const photoResponse = await getData.processPost(true, "api/Profile/Accessor/GetPhoto", {});
    if(photoResponse.state){
      setAvatar(photoResponse.data);
    }
  }

  useEffect(()=>{
    loadUserInfo();
  },[]);

    return (
      <Fragment >
        <div className={showHeader?"":"hideAppHeader"}>
        <TransitionGroup >
          <CSSTransition component="div"
            className="app-header header-shadow"
            appear={true} timeout={1500} enter={false} exit={false}>
            <div>
            <HeaderLogo/>
              <div className={cx("app-header__content", {
                  "header-mobile-open": enableMobileMenuSmall,
                })}>
                <div className="app-header-left">           
                </div>
                <div className="app-header-right">
                  <MegaMenu userInfo = {userInfo}/>
                  <UserBox avatar={avatar} userInfo = {userInfo}/>
                </div>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
        </div>
        
      </Fragment>
    );
}
export default connectAppStore(Header);
