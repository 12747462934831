import ApiAuthorzationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import Home from "./components/Pages/Home";
import Page404 from "./components/Pages/Page404";
import AccessorDevelopmentRouters from "./components/PortalRouters/Accessor/AccessorDevelopmentRouters";
import AccessorProjectRouters from "./components/PortalRouters/Accessor/AccessorProjectRouters";
import AccessorDesignVariationRouters from "./components/PortalRouters/Accessor/AccessorDesignVariationRouters";
import AccessorZoneRouters from "./components/PortalRouters/Accessor/AccessorZoneRouters";
import AccessorConstructionRouters from "./components/PortalRouters/Accessor/AccessorConstructionRouters";
import AccessorElementRouters from "./components/PortalRouters/Accessor/AccessorElementRouters";
import AccessorShadingRouters from "./components/PortalRouters/Accessor/AccessorShadingRouters";
import AccessorModuleNewHomeRouters from "./components/PortalRouters/Accessor/AccessorModuleNewHomeRouters";
import AccessorModuleExistingHomeRouters from "./components/PortalRouters/Accessor/AccessorModuleExistingHomeRouters";
import AdminUserRouters from "./components/PortalRouters/Admin/AdminUserRouters";
import AccessorUserRouters from "./components/PortalRouters/Accessor/AccessorUserRouters";

const AppRoutes = [
  {
    index: true,
    requireAuth: true,
    element: <Home />,
  },
  {
    path: "Home",
    exact: true,
    requireAuth: true,
    element: <Home/>,
  },
  //Accessor
  ...AccessorElementRouters,
  ...AccessorConstructionRouters,
  ...AccessorProjectRouters,
  ...AccessorDevelopmentRouters,
  ...ApiAuthorzationRoutes,
  ...AccessorDesignVariationRouters,
  ...AccessorZoneRouters,
  ...AccessorShadingRouters,
  ...AccessorModuleNewHomeRouters,
  ...AccessorModuleExistingHomeRouters,
  ...AccessorUserRouters,
  //admin
  ...AdminUserRouters,
  //undefined
  {
    path: "undefined",
    exact: true,
    requireAuth: true,
    element: <Home/>,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default AppRoutes;
