import { connectAppStore } from "../../../../../Store/AppStore";
import PageDependencies from "../../../../../Feature/PageDependencies";
import ConditionerDataTable from "./ConditionerDataTable";

const HeatingModule = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        htmlToReactParse,
        tryCatchFinally,
        layoutManager,
        getData,
        guid,
        useReducerSmart,
        usePrevious,
        toastPrint,
        useParams,
        useRef,
        BindedInput,
        BindedSelect,
        BindedCheckBox,
        BindedRadio,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
        dataExtension,
        DropDragDataTable,
        OnDragEndOperation,
        useEffectWithPrevious,
        DragDropContext
    } = PageDependencies();
    const defaultStarRatingDic = {
        "8": 1,
        "9": 1,
        "10": 3,
        "11": 5,
        "12": 1,
        "13": 2,
        "14": 1
    };
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const defaultViewModel = {
        ...defaultParams,
    };
    const [extraPostModel, setExtraPostModel] = useState({
        ...defaultParams,
      });
    const buttonWidthRef = useRef();
    const [pSelectedItem, setPSelectedItem] = useState({selectedItemIds:[]});
    const [selectedIds, setSelectedIds] = useState({selectedItemIds:[]});
    const [heatingModeType, setHeatingModeType] = useState("");
    const [buttonText, setButtonText] = useState("Add");
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    //change model get url here if not using default model
    const getUrl = "api/ExistingHome/HeatingModule/Accessor/Get";
    const validateMethodsAdd = {
    //add form property validate here
        appliedZones: (value) => { return FormValidate.validateTextRequired(value, "Applied zones", undefined, true);},
    };
    const formValidateAdd = new FormValidate(useState(validateMethodsAdd), useState(), useState(), useState(), false);
    const validateMethodsCalculate = {
        //add form property validate here
        starRating: (value, newViewModel) => { 
                if(parseInt(newViewModel.conditionerTypeId) != 1){              
                    return FormValidate.validateNumberGreaterThan0(value, "Star rating");
                } 
                return "";
            },
        capacity: (value, newViewModel) => { 
                if(newViewModel.calculationModel !== "StarRatingOnly" && parseInt(newViewModel.conditionerTypeId) != 1){              
                    return FormValidate.validateNumberGreaterThan0(value, "Capacity");
                } 
                return "";
            },
        };
    const formValidateCalculate = new FormValidate(useState(validateMethodsCalculate), useState(), useState(), useState(), false);
    //conditioner table
    const [conditionerData, setConditionerData] = useState([]);
    //drag table
    const [allZonesData, setAllZonesData ] = useState([]);
    const [availableZonesData, setAvailableZonesData ] = useState([]);
    const [appliedZonesData, setAppliedZonesData ] = useState([]);
    const draggableTablesDic = {};
    const table1Id = "Heating_AvailableZones_21d36a29-d6f0-4a6d-9b0e-2f1380241bb1";
    const table2Id = "Heating_AppliedZones_21d36a29-d6f0-4a6d-9b0e-2f1380241bb1";
    draggableTablesDic[table1Id] = { data: availableZonesData, setData: setAvailableZonesData };
    draggableTablesDic[table2Id] = { data: appliedZonesData, setData: setAppliedZonesData };
    const columnsZone = [
        { name: "name", text: "Name", width: "100%" }
      ];
    //form
    const [canSubmit, setCanSubmit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const [pageLayout, setPageLayout] = useState({showCop: true, showHspf: true, disabledHspf: true, disabledCop: true, showStarRating: false, showCapacity: false});

    const getAvailableZones = (viewModel) => {
        if(viewModel.existingConditionersExistingHome === undefined){
            return [];
        }
        const allZones = viewModel.allZones;
        let existingConditionedZones = dataExtension.selectMany(viewModel.existingConditionersExistingHome.map(c=>c.appliedZoneIds));
        existingConditionedZones = dataExtension.distinct(existingConditionedZones);
        let newAvailableZones = [];
        for(let i =0; i < allZones.length; i++){
            const zone = allZones[i];
            if(!existingConditionedZones.includes(zone.id)){
                newAvailableZones.push(zone)
            }
        }
        return newAvailableZones;
    }

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading heating module", appStoreDispatch, (newViewModel)=>{
            const allZones = newViewModel.allZones;        
            setAllZonesData(allZones);
            setAvailableZonesData(getAvailableZones(newViewModel));
        }, setInit);
    },[reloadForm]);

    useEffect(()=>{
        const newViewModel = {...viewModel, appliedZones: appliedZonesData};
        dispatchViewModel(newViewModel);
    }, [appliedZonesData]);

    useEffect(()=>{
        tryCatchFinally.syncProcess(()=>{
            setCanSubmit(false);
            const selectedConditioner =  dataExtension.getSelectOptionByValue(viewModel.conditionerTypes, viewModel.conditionerTypeId);
            if(selectedConditioner !== undefined){
                //default and wood
                if(parseInt(selectedConditioner.value) == 1 || (parseInt(selectedConditioner.value) >= 13 && parseInt(selectedConditioner.value) < 17)){
                    setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: true, disabledHspf: true});
                //gas
                }else if(parseInt(selectedConditioner.value) > 9 && parseInt(selectedConditioner.value) < 13){
                    setPageLayout({...pageLayout, showStarRating: true, showCapacity: false, showHspf: false});
                }else if(parseInt(selectedConditioner.value) == 17){
                //existing default
                    setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: true, disabledHspf: true, showCop: true, disabledCop: true});
                }else if(parseInt(selectedConditioner.value) == 18){
                //existing heat pump
                    if (viewModel.year >= 2019 && (viewModel.modeTypeId == 5 || viewModel.modeTypeId == 6)) {
                        setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: true, disabledHspf: true, showCop: false, disabledCop: true});
                    }else{
                        setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: true, disabledHspf: true, showCop: true, disabledCop: true});
                    }
                }else if(parseInt(selectedConditioner.value) == 19 || parseInt(selectedConditioner.value) == 33){
                //gas & LPG
                    setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: false, showCop: true, disabledCop: true});
                }else if(parseInt(selectedConditioner.value) == 20 || parseInt(selectedConditioner.value) == 21 || parseInt(selectedConditioner.value) == 22){
                //wood
                    setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: false, showCop: true, disabledCop: false});
                }else if(parseInt(selectedConditioner.value) >= 23 || parseInt(selectedConditioner.value) < 33 || parseInt(selectedConditioner.value) > 33){
                //others
                    setPageLayout({...pageLayout, showStarRating: false, showCapacity: false, showHspf: false, showCop: true, disabledCop: false});
                }else{
                //rest
                    setPageLayout({...pageLayout, showStarRating: true, showCapacity: true, showHspf: true, disabledHspf: false});
                }
                //set cop
                dispatchViewModel({...viewModel, eerOrCop: selectedConditioner.eerOrCop});
                if (selectedConditioner.value < 17 ) {
                    setPageLayout((oldState)=>{
                        const newLayout = {...oldState, disabledCop: selectedConditioner.text.toLowerCase().includes(" gas") ||
                        selectedConditioner.text.toLowerCase().includes("Electric Resistant Heater".toLowerCase()) || 
                        selectedConditioner.text.toLowerCase().includes("Reverse Cycle Air Conditioner - Default".toLowerCase())};
                        return newLayout;
                    });
                }
            }
        });   
    }, [viewModel.conditionerTypeId, viewModel.starRating, viewModel.capacity, viewModel.calculationModel, viewModel.year]);

    const prevSelectedIds = usePrevious(selectedIds.selectedItemIds);
    useEffect(()=>{
        tryCatchFinally.syncProcess(()=>{
            if(selectedIds !== undefined){
                setPSelectedItem({selectedItemIds:prevSelectedIds});
                const ids = selectedIds.selectedItemIds;
                if(ids !== undefined && ids.length === 1){
                    setButtonText("Update");
                    //load conditioner
                    const conditionerId = ids[0];
                    let conditioner = {};
                    for(let i = 0; i < viewModel.existingConditionersExistingHome.length; i++){
                        if(viewModel.existingConditionersExistingHome[i].id == conditionerId){
                            conditioner = viewModel.existingConditionersExistingHome[i];
                        }
                    }
                    const appliedZoneIds = conditioner.appliedZoneIds;
                    const newAvailableZonesData = [];
                    const newAppliedZonesData = [];
                    for(let i = 0; i< allZonesData.length; i++){
                        const zone = allZonesData[i];
                        let push = false;
                        for(let j = 0; j< appliedZoneIds.length; j++){
                            if(appliedZoneIds[j] == zone.id){
                                newAppliedZonesData.push(zone);
                                push = true;
                            }
                        }
                        if(!push){
                            newAvailableZonesData.push(zone);
                        }
                    }
                    const loadModel = {...viewModel, 
                        conditionerTypeId: conditioner.index,
                        capacity: conditioner.capacity,
                        starRating: conditioner.starRating,
                        eerOrCop: conditioner.cop,
                        hspf: conditioner.hspf,
                        calculationModel: conditioner.starRatingCheck === true ? "StarRatingOnly" : "CapacityOnly",
                        id: conditioner.id,
                        appliedZones:newAppliedZonesData,
                        year: conditioner.year,
                        modeTypeId: conditioner.modeTypeId,
                        productTypeId: conditioner.productTypeId,
                        gasFanAssisted: conditioner.gasFanAssisted
                    };
                    dispatchViewModel(loadModel);
                    setAvailableZonesData(getAvailableZones(viewModel));
                    setAppliedZonesData(newAppliedZonesData);
                }else{
                    setButtonText("Add");
                    const loadModel = {...viewModel, 
                        conditionerTypeId: 1,
                        capacity: 0,
                        starRating: 0,
                        eerOrCop:0,
                        hspf: 0,
                        calculationModel:"StarRatingOnly",
                        id: guid.emptyGuid(),
                        modeTypeId: 1,
                        productTypeId: 1,
                        ratingPlateInput: 0,
                        ratingPlateOutput: 0,
                        gasFanAssisted: false
                    };
                    dispatchViewModel(loadModel);
                    setAppliedZonesData([]);
                    setAvailableZonesData(getAvailableZones(viewModel));
                }
            }
        }); 
    }, [selectedIds]);

    const applyAllClick = () => {
        const newZones = dataExtension.distinctBy([...appliedZonesData, ...getAvailableZones(viewModel)],"id");
        setAvailableZonesData([]);
        setAppliedZonesData(newZones);
    }

    const resetClick = () =>{
        const newZones = dataExtension.distinctBy([...appliedZonesData, ...getAvailableZones(viewModel)],"id");
        setAvailableZonesData(newZones);
        setAppliedZonesData([]);
    }

    const calculationCOPHSPF = async ()=>{
        formValidateCalculate.setValidateVisibility(true);
        const validateResult = formValidateCalculate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const calculateUrl = "api/ExistingHome/HeatingModule/Accessor/CalculateHeaterCopAndHspf";
        setLoading({ isLoading: true, text: "Calculating" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, calculateUrl, viewModel);
            if(!response.state){
                toastPrint.printErrorMessage(response.message);
            }else{
                const newModel = response.viewModel;
                dispatchViewModel({...viewModel, eerOrCop: newModel.eerOrCop, hspf: newModel.hspf});
            }
        }, setLoading);
        setCanSubmit(true);
    }

    useEffect(() => {
        tryCatchFinally.syncProcess(()=>{
            if (viewModel.conditionerTypes === undefined || viewModel.conditionerTypes.length === 0) {
                return;
            }
            let r = "";
            const selectedConditioner =  dataExtension.getSelectOptionByValue(viewModel.conditionerTypes, viewModel.conditionerTypeId);
            if (selectedConditioner === undefined) {
                return;
            }
            if (selectedConditioner.text.toLowerCase().includes("heat pump")) {
                r = "heatPump";
            }
            if (selectedConditioner.text.toLowerCase().includes("gas") || selectedConditioner.text.toLowerCase().includes("lpg") || selectedConditioner.text.toLowerCase().includes("png")) {
                r = "gas";
            }
            if (selectedConditioner.text.toLowerCase().includes("wood")) {
                r = "wood";
            }
            setHeatingModeType(r);
        });
    }, [viewModel.conditionerTypeId])

    useEffect(() => {
        tryCatchFinally.syncProcess(()=>{
            const newViewModel = {...viewModel};
            const heatPump = [5,6,7,15,16,17,18,19,20];
            const gas = [1,2,3,4,8,9,10,11,12,13,14];
            const wood = [21,22,23,24,25];
            if (heatingModeType === "heatPump") {
                if (heatPump.filter(i => i == newViewModel.modeTypeId).length > 0) {
                    return;
                }
                newViewModel.modeTypeId = 5;
                dispatchViewModel(newViewModel);
                return;
            }
            if (heatingModeType === "gas") {
                if (gas.filter(i => i == newViewModel.modeTypeId).length > 0) {
                    return;
                }
                newViewModel.modeTypeId = 1;
                dispatchViewModel(newViewModel);
                return;
            }
            if (heatingModeType === "wood") {
                if (wood.filter(i => i == newViewModel.modeTypeId).length > 0) {
                    return;
                }
                newViewModel.modeTypeId = 21;
                dispatchViewModel(newViewModel);
                return;
            }
        });
    }, [heatingModeType])

    useEffectWithPrevious((oldState) => {
        if(oldState === undefined) return;
        const prevModeTypeId = oldState[0];
        if(prevModeTypeId !== undefined && viewModel.modeTypeId !== undefined){    
            tryCatchFinally.syncProcess(()=>{
                if(pSelectedItem !== undefined && selectedIds !== undefined && pSelectedItem.selectedItemIds !== undefined && selectedIds.selectedItemIds !== undefined){
                    if(pSelectedItem.selectedItemIds.length === 0 && selectedIds.selectedItemIds.length === 1){
                        //user selected one existing device
                        setPSelectedItem({selectedItemIds: selectedIds.selectedItemIds});
                    }
                    if(pSelectedItem.selectedItemIds.length === 1 && selectedIds.selectedItemIds.length === 1 && pSelectedItem.selectedItemIds[0] != selectedIds.selectedItemIds[0]){
                        //user change selected one existing device
                        setPSelectedItem({selectedItemIds: selectedIds.selectedItemIds});
                    }
                    if((pSelectedItem.selectedItemIds.length === 1 && selectedIds.selectedItemIds.length === 1 && pSelectedItem.selectedItemIds[0] == selectedIds.selectedItemIds[0]) || selectedIds.selectedItemIds.length === 0){
                     
                        //user change selected one existing device     
                        const star = defaultStarRatingDic[viewModel.modeTypeId.toString()];
                        if (star !== undefined && star !== null) {
                            const newViewModel = {...viewModel, starRating: star};
                            dispatchViewModel(newViewModel);
                        }
                    }
                }    
            });   
        }    
    }, [viewModel.modeTypeId]);

    const submit = ()=>{
        formValidateAdd.setValidateVisibility(true);
        const validateResult = formValidateAdd.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/ExistingHome/HeatingModule/Accessor/" + buttonText + "HeatingConditioner";
        setLoading({ isLoading: true, text: "Processing heating device" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            resetClick();
            setReloadForm(guid.newGuid());
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className=" pe-7s-angle-up-circle icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Heating
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                    <>
                                 <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Annual Energy Consumption</h5>
                        </button>
                    </div>
                    <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label>ZERL Zone: <span style={{color:"red"}}>{viewModel.zerlZone}</span></label>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="position-relative form-group">
                                        <label>Electricity (kWh/yr): {parseFloat(viewModel.annualElectricityEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="position-relative form-group">
                                        <label>Gas (MJ/yr): {parseFloat(viewModel.annualGasEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label>Other (MJ/yr): {parseFloat(viewModel.annualOtherEnergy).toFixed(2)}</label> 
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative form-group">
                                        <label>LPG (MJ/yr): {parseFloat(viewModel.annualLpgEnergy).toFixed(2)}</label> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Applied Appliances</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            {debug && <p>{JSON.stringify(conditionerData)}</p>}
                            <ConditionerDataTable
                                debug = {debug}
                                data = {conditionerData}
                                setData = {setConditionerData}
                                extraPostModel = {extraPostModel}
                                url="api/ExistingHome/HeatingModule/Accessor"
                                conditionerType="Heating"
                                tableReload = {reloadForm}
                                setTableReload = {setReloadForm}
                                selectedIds = {selectedIds}
                                setSelectedIds = {setSelectedIds}
                            ></ConditionerDataTable>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
        <div className="card-header">
            <button type="button" data-toggle="collapse" data-target="" aria-expanded="true" aria-controls="" className="text-left m-0 p-0 btn btn-block">
                <h5 className="m-0 p-0 card-title">Heating Appliance</h5>
            </button>
        </div>
        <div data-parent="#HeatingAccordion" id="HeatingCollapsibleDiv" className="collapse show">
            <div className="card-body">
                <div className="form-row row">
                    <div className="col-lg-12 col-12">
                        <div className="position-relative form-group pr-3">
                            <BindedSelect
                                className="form-control"
                                id="ConditionerTypeId"
                                name="ConditionerTypeId"
                                binding={[viewModel, "conditionerTypeId", bindingViewModel]}
                                options={"conditionerTypes"}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>
                <div className="form-row-separator"></div>
                <div className="form-row row">
                    {pageLayout.showStarRating && 
                        <div className="col-12">
                            <div className="position-relative form-group pr-3">
                                <div className="form-row row">
                                    <div className="col-lg-2 col-2 form-check" style={{marginTop:"10px", marginLeft:"15px"}}>
                                        <BindedRadio
                                            id="StarRatingOnly"
                                            buttonValue = "StarRatingOnly"
                                            buttonLabel = "Star Rating Only"
                                            binding={[viewModel, "calculationModel", bindingViewModel]}
                                        ></BindedRadio>
                                    </div>
                                    <div className="col-lg-2 col-2">
                                        <BindedInput
                                            className="form-control"
                                            binding={[viewModel, "starRating", bindingViewModel]}
                                            id="StarRating"
                                            name="StarRating"
                                            type="number" 
                                            step="0.5" 
                                            validate = {formValidateCalculate}
                                        ></BindedInput>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {pageLayout.showCapacity &&
                        <div className="col-12">
                            <div className="position-relative form-group pr-3">
                                <div className="form-row row">
                                    <div style={{marginLeft:"15px"}} className="col-lg-2 col-2 form-check">
                                        <BindedRadio
                                            id="CapacityOnly"
                                            buttonValue = "CapacityOnly"
                                            buttonLabel = "Capacity (KW)"
                                            binding={[viewModel, "calculationModel", bindingViewModel]}
                                        ></BindedRadio>              
                                    </div>
                                    {viewModel.calculationModel === "CapacityOnly" &&
                                    <div className="col-lg-2 col-2">
                                        <BindedInput
                                            className="form-control"
                                            binding={[viewModel, "capacity", bindingViewModel]}
                                            id="Capacity"
                                            name="Capacity"
                                            type="number" 
                                            step="0.5" 
                                            validate = {formValidateCalculate}
                                        ></BindedInput>
                                    </div>
                                    }                              
                                </div>
                            </div>
                        </div>
                    }

                </div>
                <div className="form-row row">
                    {(viewModel.conditionerTypeId == 18 ||
                      viewModel.conditionerTypeId == 19 ||
                      viewModel.conditionerTypeId == 20 ||
                      viewModel.conditionerTypeId == 21 ||
                      viewModel.conditionerTypeId == 22 ||
                      viewModel.conditionerTypeId == 33 ) &&
                    <Fragment>
                        <div className="col-2">
                            <div className="position-relative form-group">
                                <label htmlFor="Year">Year</label>
                                <BindedInput
                                    disabled={viewModel.modeTypeId == 7}
                                    className="form-control"
                                    binding={[viewModel, "year", bindingViewModel]}
                                    id="Year"
                                    name="Year"
                                    type="number" 
                                    step="1" 
                                ></BindedInput>
                            </div>
                        </div>
                        {(viewModel.conditionerTypeId == 18 ||
                          viewModel.conditionerTypeId == 19 ||
                          viewModel.conditionerTypeId == 33 ) &&
                            <div className="col-2">
                                <div className="position-relative form-group">
                                    <label htmlFor="EStarRating">Star Rating</label>
                                    <BindedInput
                                        disabled={viewModel.modeTypeId == 7}
                                        className="form-control"
                                        binding={[viewModel, "starRating", bindingViewModel]}
                                        id="EStarRating"
                                        name="EStarRating"
                                        type="number" 
                                        step="0.1" 
                                    ></BindedInput>
                                </div>
                            </div>
                        }
                        <div className="col-4">
                            <div className="position-relative form-group">
                                <label htmlFor="ModeTypeId">Efficiency based on</label>
                                <BindedSelect
                                    className="form-control"
                                    id="ModeTypeId"
                                    name="ModeTypeId"
                                    binding={[viewModel, "modeTypeId", bindingViewModel]}
                                    options={heatingModeType+"ModeTypes"}
                                ></BindedSelect>
                            </div>
                        </div>
                        {(viewModel.conditionerTypeId == 18) &&
                            <div className="col-4">
                                <div className="position-relative form-group">
                                    <label htmlFor="ProductTypeId">Type</label>
                                    <BindedSelect
                                        className="form-control"
                                        id="ProductTypeId"
                                        name="ProductTypeId"
                                        binding={[viewModel, "productTypeId", bindingViewModel]}
                                        options={"productTypes"}
                                    ></BindedSelect>
                                </div>
                            </div>
                        }
                    </Fragment>
                    }
                </div>
                <div className="form-row row">
                    {(viewModel.conditionerTypeId == 19 ||
                      viewModel.conditionerTypeId == 33 ) &&
                        <div className="col-2">
                            <div className="custom-checkbox custom-control">
                                <BindedCheckBox
                                    id="GasFanAssisted"
                                    name="GasFanAssisted"
                                    className="custom-control-input"
                                    binding={[
                                        viewModel,
                                        "gasFanAssisted",
                                        bindingViewModel,
                                    ]}
                                ></BindedCheckBox>
                                <label
                                    style={{ marginLeft: "5px" }}
                                    htmlFor="GasFanAssisted"
                                    className="custom-control-label"
                                >
                                    Gas Fan-assisted
                                </label>
                            </div>
                        </div>
                    }
                    {(viewModel.modeTypeId == 7) &&
                        <Fragment>
                            <div className="col-2">
                                <div className="position-relative form-group">
                                    <label htmlFor="RatingPlateInput">Rating Plaet Input</label>
                                    <BindedInput
                                        className="form-control"
                                        binding={[viewModel, "ratingPlateInput", bindingViewModel]}
                                        id="RatingPlateInput"
                                        name="RatingPlateInput"
                                        type="number" 
                                        step="0.1" 
                                    ></BindedInput>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="position-relative form-group">
                                    <label htmlFor="RatingPlateOutput">Rating Plate Output</label>
                                    <BindedInput
                                        className="form-control"
                                        binding={[viewModel, "ratingPlateOutput", bindingViewModel]}
                                        id="RatingPlateOutput"
                                        name="RatingPlateOutput"
                                        type="number" 
                                        step="0.1" 
                                    ></BindedInput>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>
                <div className="form-row row">
                    {(pageLayout.showCop || (viewModel.year < 2019 && viewModel.conditionerTypeId == 18)) &&
                        <div className="col-2">
                            <div className="position-relative form-group">
                                <label htmlFor="EerOrCop">COP</label>
                                <BindedInput
                                    className="form-control"
                                    disabled ={pageLayout.disabledCop}
                                    binding={[viewModel, "eerOrCop", bindingViewModel]}
                                    id="EerOrCop"
                                    name="EerOrCop"
                                    type="number" 
                                    step="0.1" 
                                ></BindedInput>
                            </div>
                        </div>
                    }
                    {pageLayout.showHspf && 
                        <div className="col-2">
                            <div className="position-relative form-group">
                                <label htmlFor="HSPF" id="HSPFLabel">HSPF</label>
                                <BindedInput
                                    className="form-control"
                                    disabled ={pageLayout.disabledHspf}
                                    binding={[viewModel, "hspf", bindingViewModel]}
                                    id="HSPF"
                                    name="HSPF"
                                    type="number" 
                                    step="0.1" 
                                ></BindedInput>
                            </div>     
                        </div>
                    }
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label style={{color:"red"}}> {!canSubmit && "Calculation COP or HSPF before submiting" } {canSubmit && htmlToReactParse("&nbsp;") }</label>
                            <a type="button" className="btn btn-primary form-control" onClick={calculationCOPHSPF}>Calculation COP or HSPF</a>
                        </div>                   
                    </div>
                </div>
                <div className="divider"></div>         
                <DragDropContext onDragEnd={(result) => OnDragEndOperation(result, draggableTablesDic)} >
                    <div className="form-row row">
                        <div className="col-4">
                            <p>Available Zones</p> 
                            <DropDragDataTable   
                                style={{maxHeight:"300px", overflowY:"scroll"}}                   
                                columns={columnsZone}
                                id={table1Id}
                                data={availableZonesData}
                                setData={setAvailableZonesData}
                                noRecordsMessage="No zones"
                            ></DropDragDataTable>                          
                        </div>
                        <div className="col-3" align="center">
                            <div className="row" display="inline-block">
                                <p>Zones operation</p> 
                                <p><button type="button" className="btn btn-primary" ref={buttonWidthRef} onClick={applyAllClick}>Apply All</button></p> 
                                <p><button type="button" className="btn btn-primary" style={{width: buttonWidthRef.current === undefined ? -1 : buttonWidthRef.current.clientWidth}} onClick={resetClick}>Reset</button></p> 
                                <BindedInput
                                    hidden
                                    className="form-control"
                                    binding={[viewModel, "appliedZones", bindingViewModel]}
                                    id="AppliedZones"
                                    name="AppliedZones"
                                    validate = {formValidateAdd}
                                ></BindedInput>
                            </div>                       
                        </div>
                        <div className="col-4">
                            <p>Applied Zones</p>
                            <DropDragDataTable
                                style={{maxHeight:"300px", overflowY:"scroll"}}        
                                columns={columnsZone}
                                id={table2Id}
                                data={appliedZonesData}
                                setData={setAppliedZonesData}
                                noRecordsMessage="No zones"
                            ></DropDragDataTable>   
                            <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" disabled={!canSubmit} onClick={submit}>{buttonText} appliance to applied zones</button>
                        </div>
                    </div>
                </DragDropContext>  
            </div>
        </div>
        </div>
                    </>
                }
   
        </div>
    </Fragment>
    );
}
export default connectAppStore(HeatingModule);