import UserDetail from "../../Portal/Admin/UserDetail";
import AdminHome from "../../Pages/AdminHome";
import Users from "../../Portal/Admin/Users";
import RegisterUsersCSV from "../../Portal/Admin/RegisterUsersCSV";
import Credits from "../../Portal/Admin/Credits";
import AddCredits from "../../Portal/Admin/AddCredits";
import RefundCredits from "../../Portal/Admin/RefundCredits";
import ApproveRefund from "../../Portal/Admin/ApproveRefund";
import PaymentCreditsRefunds from "../../Portal/Admin/PaymentCreditsRefunds";
import CreditUnits from "../../Portal/Admin/CreditUnits";
import CreditUnitDetail from "../../Portal/Admin/CreditUnitDetail";
import FinanceReport from "../../Portal/Admin/FinanceReport";
import FinanceReportResult from "../../Portal/Admin/FinanceReportResult";
import Developments from "../../Portal/Admin/Developments";
import Projects from "../../Portal/Admin/Projects";
import SimulationByUserName from "../../Portal/Admin/SimulationByUserName";

const AdminUserRouters = [
    {
      path: "Admin/Home",
      exact: true,
      requireAuth: true,
      element: <AdminHome />,
    },
    {
      path: "Admin/Users/Detail/:id",
      exact: true,
      requireAuth: true,
      element: <UserDetail />,
    },
    {
      path: "Admin/Users",
      exact: true,
      requireAuth: true,
      element: <Users />,
    },
    {
      path: "Admin/Users/RegisterUsersCSV",
      exact: true,
      requireAuth: true,
      element: <RegisterUsersCSV />,
    },
    {
      path: "Admin/Credits/:id",
      exact: true,
      requireAuth: true,
      element: <Credits />,
    },
    {
      path: "Admin/Credits/AddCredits/:id",
      exact: true,
      requireAuth: true,
      element: <AddCredits />,
    },
    {
      path: "Admin/Credits/Refund/:id",
      exact: true,
      requireAuth: true,
      element: <RefundCredits />,
    },
    {
      path: "Admin/Credits/Refund/Approve/:jsonParam",
      exact: true,
      requireAuth: true,
      element: <ApproveRefund />,
    },
    {
      path: "Admin/PaymentCredits",
      exact: true,
      requireAuth: true,
      element: <PaymentCreditsRefunds paymentType="Credit"/>,
    },
    {
      path: "Admin/PaymentRefunds",
      exact: true,
      requireAuth: true,
      element: <PaymentCreditsRefunds paymentType="Refund"/>,
    },
    {
      path: "Admin/CreditUnits",
      exact: true,
      requireAuth: true,
      element: <CreditUnits />,
    },
    {
      path: "Admin/CreditUnits/New",
      exact: true,
      requireAuth: true,
      element: <CreditUnitDetail />,
    },
    {
      path: "Admin/FinanceReport",
      exact: true,
      requireAuth: true,
      element: <FinanceReport />,
    },
    {
      path: "Admin/FinanceReport/Result/:jsonParam",
      exact: true,
      requireAuth: true,
      element: <FinanceReportResult />,
    },
    {
      path: "Admin/Developments",
      exact: true,
      requireAuth: true,
      element: <Developments />,
    },
    {
      path: "Admin/Projects",
      exact: true,
      requireAuth: true,
      element: <Projects />,
    },
    {
      path: "Admin/SimulationByUserName",
      exact: true,
      requireAuth: true,
      element: <SimulationByUserName />,
    }
  ];
  export default AdminUserRouters;