import toastPrint  from "../Feature/ToastPrint";
import getData  from "./GetData";
import pageProfile  from "../Portal/Accessor/PageProfile";
class LoaModelFromApi{
        async loadDefault(auth, url, model, dispatchModel, setLoading, loadingMessage, appStoreDispatch, callBack, setInit){
            setLoading({ isLoading: true, text: loadingMessage });
            try {
                const getResponse = await getData.processPost(auth, url, model);
                if (!getResponse.state) {
                    toastPrint.printResponse(getResponse);
                    setLoading({ isLoading: false, text: "" });
                    return;
                }
                pageProfile.setDefaultValues(appStoreDispatch, getResponse.viewModel);
                dispatchModel(getResponse.viewModel, ()=>{
                    if (callBack !== undefined) callBack(getResponse.viewModel);
                    setLoading({ isLoading: false, text: "" });
                    if (setInit !== undefined) {
                        setInit((preInit) => {
                            return true;
                        })
                    }
                });
            } catch (ex) {
                    toastPrint.printErrorMessage(ex.message);                  
                    if (setInit !== undefined) {
                        setInit((preInit) => {
                            return true;
                        })
                    }
            } 
            finally{
                setLoading({ isLoading: false, text: "" });
            }
    }
}
const loaModelFromApi = new LoaModelFromApi();
export default loaModelFromApi;