
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const VerticalShadingDetail = (props) => {
    const {
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        dataExtension,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        Fragment, useEffect, useState
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const defaultParams = pageProfile.getDefaultParams(useParams());
    let {redirectUrl, shadingId} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        id:emptyGuid,
        typeName: props.typeName,
        selectedItemIds:[shadingId]
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [init, setInit] = useState(false);
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/Shading/Accessor/Get";
    const postUrl = "api/Shading/Accessor/Update";
    const validateMethods = {
        name:(value)=>{return FormValidate.validateTextRequired(value, "Vertical shading name");},
        height: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Height");},
        width: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Width");},
        distance: (value) => { return FormValidate.validateNumberGreaterThanOrEqual0(value, "Distance");},
        blockingFactorPercentage0: (value) => { return FormValidate.validateNumberBetween(value, "Jan", 0 , 100);},
        blockingFactorPercentage1: (value) => { return FormValidate.validateNumberBetween(value, "Feb", 0 , 100);},
        blockingFactorPercentage2: (value) => { return FormValidate.validateNumberBetween(value, "Mar", 0 , 100);},
        blockingFactorPercentage3: (value) => { return FormValidate.validateNumberBetween(value, "Apr", 0 , 100);},
        blockingFactorPercentage4: (value) => { return FormValidate.validateNumberBetween(value, "May", 0 , 100);},
        blockingFactorPercentage5: (value) => { return FormValidate.validateNumberBetween(value, "Jun", 0 , 100);},
        blockingFactorPercentage6: (value) => { return FormValidate.validateNumberBetween(value, "Jul", 0 , 100);},
        blockingFactorPercentage7: (value) => { return FormValidate.validateNumberBetween(value, "Aug", 0 , 100);},
        blockingFactorPercentage8: (value) => { return FormValidate.validateNumberBetween(value, "Sep", 0 , 100);},
        blockingFactorPercentage9: (value) => { return FormValidate.validateNumberBetween(value, "Oct", 0 , 100);},
        blockingFactorPercentage10: (value) => { return FormValidate.validateNumberBetween(value, "Nov", 0 , 100);},
        blockingFactorPercentage11: (value) => { return FormValidate.validateNumberBetween(value, "Dec", 0 , 100);}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, undefined, setInit);
    },[]);

    useEffect(()=>{
        const isCalculate = viewModel.simulationType === "Existing Home" && ( viewModel.shadingInputMethodTypeId == 2);
        if(isCalculate){
            //calculate
            const newModel = {...viewModel};
            if(viewModel.verticalShadingVerticalCategoryTypeId == 2 ){
                newModel.verticalOffset = 0;
            }
            const positionWallObstruction = parseInt(dataExtension.getSelectOptionByValue(viewModel.verticalShadingPositionOfWallTypes, viewModel.verticalShadingPositionOfWallTypeId).obstruction) * 1000;
            const positionWallOffset = parseInt(dataExtension.getSelectOptionByValue(viewModel.verticalShadingPositionOfWallTypes, viewModel.verticalShadingPositionOfWallTypeId).offset) * 1000;
            const distance = parseInt(dataExtension.getSelectOptionByValue(viewModel.verticalShadingDistanceCategoryTypes, viewModel.verticalShadingDistanceCategoryTypeId).dataValue) * 1000;
            let wallWidth = parseInt(viewModel.relatedWallWidth);
            const height = ((3+(parseFloat(viewModel.storyNumberOfNeighbourBuilding)-1)*2.7)*1000);
            newModel.height = Math.round(height);
            newModel.distance = distance;
             if(viewModel.verticalShadingPositionOfWallTypeId == 1){
                const width = positionWallObstruction + wallWidth;
                const horizontalOffset = positionWallOffset + wallWidth;
                newModel.width = width;
                newModel.horizontalOffset = horizontalOffset;
             }
             if( viewModel.verticalShadingPositionOfWallTypeId == 2){
                const width = positionWallObstruction + wallWidth;
                const horizontalOffset = positionWallOffset;
                newModel.width = width;
                newModel.horizontalOffset = horizontalOffset;
             }
             if( viewModel.verticalShadingPositionOfWallTypeId == 3){
                const width = positionWallObstruction + wallWidth;
                const horizontalOffset = positionWallOffset;
                newModel.width = width;
                newModel.horizontalOffset = horizontalOffset;
             }  
             dispatchViewModel(newModel);
        }
    },[viewModel.shadingInputMethodTypeId, viewModel.storyNumberOfNeighbourBuilding, viewModel.relatedWallWidth, viewModel.verticalShadingPositionOfWallTypeId, viewModel.verticalShadingDistanceCategoryTypeId, viewModel.verticalShadingVerticalCategoryTypeId]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <BaseForm
            id="DesignVariationFrom"
            icon="pe-7s-safe"
            loading={loading}
            init={init}
            setLoading={setLoading}
            submitButton={{ postUrl: postUrl + props.typeName, redirectUrl: redirectUrl}}
            cancelButton={{ redirectUrl: redirectUrl }}
            data={viewModel}
            validate ={formValidate}
            title = {props.typeName + " Shading"} 
            subTitle={pageProfile.getPageSubTitle(appStoreState)}
            >
                <div className="form-row row">
                    <div className="col-12">
                        <div className="position-relative form-group">
                        <label htmlFor="ShadingName">Name *</label>
                            <BindedInput validate={formValidate} className="form-control valid" id="ShadingName" name="ShadingName" type="text" binding={[viewModel, "name", bindingViewModel]}></BindedInput>
                        </div>
                    </div>
                </div>
                {viewModel.simulationType === "Existing Home" &&
                    <div className="form-row row">
                        <div className="col-4" style={{marginBottom:"15px"}}>
                            <div className="position-relative form-group">
                                <label htmlFor="shadingInputMethodTypeId">Input Method</label>           
                                <BindedSelect
                                    className="form-control"
                                    id="shadingInputMethodTypeId"
                                    name="shadingInputMethodTypeId"
                                    options="shadingInputMethodTypes"
                                    binding={[viewModel, "shadingInputMethodTypeId", bindingViewModel]}
                                ></BindedSelect>
                            </div>
                        </div>
                        {(viewModel.shadingInputMethodTypeId == 2) &&
                            <>
                            <div className="col-4">
                                <div className="position-relative form-group">
                                    <label htmlFor="StoryNumberOfNeighbourBuilding">Story Number Of Neighbour Building</label>           
                                    <BindedInput className="form-control valid" id="StoryNumberOfNeighbourBuilding" name="StoryNumberOfNeighbourBuilding" validate={formValidate} type="number" binding={[viewModel, "storyNumberOfNeighbourBuilding", bindingViewModel]}></BindedInput>                
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="position-relative form-group">
                                    <label htmlFor="RelatedWallWidth">Related Wall Width (mm) *</label>           
                                    <BindedInput className="form-control valid" id="RelatedWallWidth" name="RelatedWallWidth" validate={formValidate} type="number" binding={[viewModel, "relatedWallWidth", bindingViewModel]}></BindedInput>                
                                </div>
                            </div>
                            <div className="col-12" style={{marginBottom:"15px"}}>
                                <div className="position-relative form-group">
                                    <label htmlFor="VerticalShadingPositionOfWallTypeId">Position Of Wall</label>           
                                    <BindedSelect
                                        className="form-control"
                                        id="VerticalShadingPositionOfWallTypeId"
                                        name="VerticalShadingPositionOfWallTypeId"
                                        options="verticalShadingPositionOfWallTypes"
                                        binding={[viewModel, "verticalShadingPositionOfWallTypeId", bindingViewModel]}
                                    ></BindedSelect>
                                </div>
                            </div>
                            <div className="col-3" style={{marginBottom:"15px"}}>
                                <div className="position-relative form-group">
                                    <label htmlFor="VerticalShadingDistanceCategoryTypeId">Distance Category</label>           
                                    <BindedSelect
                                        className="form-control"
                                        id="VerticalShadingDistanceCategoryTypeId"
                                        name="VerticalShadingDistanceCategoryTypeId"
                                        options="verticalShadingDistanceCategoryTypes"
                                        binding={[viewModel, "verticalShadingDistanceCategoryTypeId", bindingViewModel]}
                                    ></BindedSelect>
                                </div>
                            </div>
                            <div className="col-3" style={{marginBottom:"15px"}}>
                                <div className="position-relative form-group">
                                    <label htmlFor="VerticalShadingVerticalCategoryTypeId">Vertical Category</label>           
                                    <BindedSelect
                                        className="form-control"
                                        id="VerticalShadingVerticalCategoryTypeId"
                                        name="VerticalShadingVerticalCategoryTypeId"
                                        options="verticalShadingVerticalCategoryTypes"
                                        binding={[viewModel, "verticalShadingVerticalCategoryTypeId", bindingViewModel]}
                                    ></BindedSelect>
                                </div>
                            </div>
                            </>                                      
                        }                     
                    </div>    
                }
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="VerticalShadingHeight">Height (mm) *</label>           
                            <BindedInput disabled={ viewModel.shadingInputMethodTypeId == 2} className="form-control valid" id="VerticalShadingHeight" name="VerticalShadingHeight" validate={formValidate} type="number" binding={[viewModel, "height", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="VerticalShadingWidth">Width (mm) *</label>           
                            <BindedInput disabled={ viewModel.shadingInputMethodTypeId == 2} className="form-control" id="VerticalShadingWidth" name="VerticalShadingWidth" validate={formValidate} type="number" binding={[viewModel, "width", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="VerticalShadingDistance">Distance (mm) *</label>           
                            <BindedInput disabled={ viewModel.shadingInputMethodTypeId == 2} className="form-control valid" id="VerticalShadingDistance" name="VerticalShadingDistance" validate={formValidate} type="number" binding={[viewModel, "distance", bindingViewModel]}></BindedInput>       
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="VerticalShadingVerticalOffset">Vertical offset (mm) *</label>           
                            <BindedInput disabled={ viewModel.verticalShadingVerticalCategoryTypeId == 2} className="form-control valid" id="VerticalShadingVerticalOffset" name="VerticalShadingVerticalOffset" validate={formValidate} type="number" binding={[viewModel, "verticalOffset", bindingViewModel]}></BindedInput>  
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="position-relative form-group">
                        <label htmlFor="VerticalShadingHorizontalOffset">Horizontal offset (mm) *</label>           
                            <BindedInput disabled={ viewModel.shadingInputMethodTypeId == 2} className="form-control valid" id="VerticalShadingHorizontalOffset" name="VerticalShadingHorizontalOffset" validate={formValidate} type="number" binding={[viewModel, "horizontalOffset", bindingViewModel]}></BindedInput>  
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage0" name="ShadingBlockingFactorPercentage0" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage0", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage1" name="ShadingBlockingFactorPercentage1" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage1", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage2" name="ShadingBlockingFactorPercentage2" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage2", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage3" name="ShadingBlockingFactorPercentage3" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage3", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage4">May</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage4" name="ShadingBlockingFactorPercentage4" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage4", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage5" name="ShadingBlockingFactorPercentage5" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage5", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage6" name="ShadingBlockingFactorPercentage6" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage6", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage7" name="ShadingBlockingFactorPercentage7" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage7", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage8" name="ShadingBlockingFactorPercentage8" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage8", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage9" name="ShadingBlockingFactorPercentage9" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage9", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage10" name="ShadingBlockingFactorPercentage10" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage10", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>           
                            <BindedInput className="form-control valid" id="ShadingBlockingFactorPercentage11" name="ShadingBlockingFactorPercentage11" validate={formValidate} type="number" binding={[viewModel, "blockingFactorPercentage11", bindingViewModel]}></BindedInput>                
                        </div>
                    </div>
                </div>
            </BaseForm>
        </Fragment>
    );
}
export default connectAppStore(VerticalShadingDetail);