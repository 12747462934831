import { getAppStore } from "../../components/Store/AppStore";
import { appThemeSliceActions } from "../../components/Store/reducers/index";

class LayoutManager {

  Layout_SetDebugModel(props, value){
    const appStoreDispatch = getAppStore(props)[1];
    appStoreDispatch(
      appThemeSliceActions.setAppTheme({
        type: "debugModel",
        debugModel: value
      })
    );
  }

  Layout_GetDebugModel(props){
    const appStoreState = getAppStore(props)[0];
    return appStoreState.appThemeReducer.debugModel;
  }

  Layout_Headter_Footer_SideBar(props) {
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    if (!appStoreState.appThemeReducer.showSideBarOptButton) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showSideBarOptButton",
          showSideBarOptButton: true,
        })
      );
    }
    if (!appStoreState.appThemeReducer.showSideBar) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showSideBar",
          showSideBar: true,
        })
      );
    }
    if (!appStoreState.appThemeReducer.showHeader) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showHeader",
          showHeader: true,
        })
      );
    }
    if (!appStoreState.appThemeReducer.showFooter) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showFooter",
          showFooter: true,
        })
      );
    }
  }

  Layout_Headter_Footer_HideSideBar(props) {
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    if (appStoreState.appThemeReducer.showSideBar) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showSideBar",
          showSideBar: false,
        })
      );
    }
    if (appStoreState.appThemeReducer.showHeader) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showHeader",
          showHeader: true,
        })
      );
    }
    if (!appStoreState.appThemeReducer.showFooter) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showFooter",
          showFooter: true,
        })
      );
    }
  }

  Layout_HideHeadter_Footer_HideSideBar(props) {
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    if (appStoreState.appThemeReducer.showSideBar) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showSideBar",
          showSideBar: false,
        })
      );
    }
    if (appStoreState.appThemeReducer.showHeader) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showHeader",
          showHeader: false,
        })
      );
    }
    if (!appStoreState.appThemeReducer.showFooter) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showFooter",
          showFooter: true,
        })
      );
    }
  }

  Layout_HideHeadterButton_Footer_HideSideBar(props) {
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    if (appStoreState.appThemeReducer.showSideBarOptButton) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showSideBarOptButton",
          showSideBarOptButton: false,
        })
      );
    }
    if (appStoreState.appThemeReducer.showSideBar) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showSideBar",
          showSideBar: false,
        })
      );
    }
    if (!appStoreState.appThemeReducer.showHeader) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showHeader",
          showHeader: true,
        })
      );
    }
    if (!appStoreState.appThemeReducer.showFooter) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showFooter",
          showFooter: true,
        })
      );
    }
  }

  Layout_HideHeadter_HideFooter_HideSideBar(props) {
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    if (appStoreState.appThemeReducer.showSideBar) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showSideBar",
          showSideBar: false,
        })
      );
    }
    if (appStoreState.appThemeReducer.showHeader) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showHeader",
          showHeader: false,
        })
      );
    }
    if (appStoreState.appThemeReducer.showFooter) {
      appStoreDispatch(
        appThemeSliceActions.setAppTheme({
          type: "showFooter",
          showFooter: false,
        })
      );
    }
  }
}

const layoutManager = new LayoutManager();
export default layoutManager;
