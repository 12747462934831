
import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";

const DevelopmentDetail = (props) => {
    const { 
      layoutManager,
      getData,
      guid,
      useReducerSmart,
      toastPrint,
      useParams,
      BaseForm,
      BindedInput,
      FormValidate,
      Fragment, 
      useEffect, 
      tryCatchFinally,
      useState
    } = PageDependencies();
    const developmentId = useParams().developmentId;
    const postUrl = "api/Development/Accessor/Update";
    const getUrl = "api/Development/Accessor/Detail/" + developmentId;
    const defaultDevelopment = {id:guid.emptyGuid(), name:""};
    const [loading, setLoading] = useState({isLoding: false, text:""});
    const [init, setInit] = useState(false);
    const [development, dispatchDevelopment, bindingDevelopment] = useReducerSmart(defaultDevelopment);

    const validateMethods = {
      name:(value)=>{return FormValidate.validateTextRequired(value, "Development name");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    const loadDevelopment= async ()=>{
        setLoading({isLoading:true, text:"Loading development"});
        await tryCatchFinally.asyncProcess(async ()=>{
          if(developmentId != guid.emptyGuid()){
            const getDevelopmentResponse = await getData.processGet(true, getUrl);
            if (getDevelopmentResponse.state == true) {
                dispatchDevelopment({
                    ...development, id: developmentId, name: getDevelopmentResponse.developmentViewModel.name
                },()=>{
                  setInit(true);
                  setLoading({...loading, isLoading:false});
                });
            } else {
                toastPrint.printResponse(getDevelopmentResponse);
                setInit(true);
                setLoading({...loading, isLoading:false});
            }       
            }else{
              setInit(true);
              setLoading({...loading, isLoading:false});
          }

        }, setLoading);     
    }

    useEffect(() => {
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        //get development from controller
        loadDevelopment();
    }, []);

  return (
    <Fragment>
        {layoutManager.Layout_GetDebugModel(props) && <p>{JSON.stringify(development)}</p>}
       <BaseForm 
        id = "DevelopmentFrom"
        icon = "lnr-apartment"
        loading = {loading}
        init = {init}
        validate={formValidate}
        setLoading = {setLoading}
        submitButton={{ postUrl: postUrl, redirectUrl: "/DevelopmentAccessor" }}
        cancelButton={{ redirectUrl: "/DevelopmentAccessor" }}
        data = {development}
        title="Development" 
        subTitle="Create new development">
        <div className="col-12">
          <div className="form-group bmd-form-group is-filled">
            <BindedInput binding={[development.id, "id", bindingDevelopment]} type="hidden"></BindedInput>
            <label htmlFor="DevelopmentName">Name *</label>
            <BindedInput 
                className="form-control"
                binding={[development, "name", bindingDevelopment]}
                id="DevelopmentName"
                name="DevelopmentName"
                type="text"
                validate = {formValidate}
            ></BindedInput>
          </div>
        </div>
      </BaseForm>
    </Fragment>
  );
};
export default connectAppStore(DevelopmentDetail);
