

import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";

const DesignVariationDetail = (props) => {
    const { 
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedCheckBox,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        Fragment, 
        useEffect, 
        useState
    } = PageDependencies();
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const defaultViewModel = {
        id:emptyGuid,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName:""
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [init, setInit] = useState(false);
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/DesignVariation/Accessor/Update";
    const getUrl = "api/DesignVariation/Accessor/Get";
    const validateMethods = {
        name:(value)=>{return FormValidate.validateTextRequired(value, "Design variation name");},
      };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading design variation", appStoreDispatch, undefined, setInit);
    },[]);

    return(<Fragment>
        {layoutManager.Layout_GetDebugModel(props) && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
            id="DesignVariationFrom"
            icon="pe-7s-science"
            loading={loading}
            init={init}
            setLoading={setLoading}
            submitButton={{ postUrl: postUrl, redirectUrl: "/ZoneAccessor/Zones/" + viewModel.designVariationVersionName + "/" +  viewModel.developmentId + "/" + viewModel.projectId + "/" + viewModel.designVariationId}}
            cancelButton={{ redirectUrl: "/DesignVariationAccessor/DesignVariations/" + viewModel.projectId }}
            data={viewModel}
            validate ={formValidate}
            title = "Design variation"
            subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                    <label htmlFor="DesignVariationName">Name *</label>
                    <BindedInput className="form-control valid" id="DesignVariationName" name="DesignVariationName"   validate ={formValidate}  type="text" binding={[viewModel, "name", bindingViewModel]}></BindedInput>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                    <label htmlFor="DesignVariationDescription">Description</label>           
                    <BindedInput className="form-control" id="DesignVariationDescription" name="DesignVariationDescription" type="text"  binding={[viewModel, "description", bindingViewModel]}></BindedInput>                
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <div className="form-check">
                        <BindedCheckBox
                            id="IsBaseDesignVariation" 
                            name="IsBaseDesignVariation"
                            binding={[viewModel, "isBaseDesignVariation", bindingViewModel]}
                        ></BindedCheckBox>
                        <label htmlFor="IsBaseDesignVariation" className="form-check-label"> Mark as this project's base design variation </label>
                    </div>
                </div>
            </div>        
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(DesignVariationDetail);
