
import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";

const Plan = (props) => {
    const { 
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        getAppStore,
        useEffectWithPrevious,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        Fragment, useEffect, useState
      } = PageDependencies();
    const postUrl = "api/Project/Accessor/UpdatePlan";
    const getUrl = "api/Project/Accessor/GetPlan";
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const defaultViewModel = {
        projectId: projectId,
        designVariationId: emptyGuid,
        designVariationVersionName:""
      };
    const [init, setInit] = useState(false);
    const [plan, dispatchPlan, bindingPlan] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const validateMethods = {
        preparedBy:(value)=>{return FormValidate.validateTextRequired(value, "Prepared by");},
      };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, plan, dispatchPlan, setLoading, "Loading plan", appStoreDispatch, ()=>{}, setInit);
    },[]);

    const [disableButton, setDisableButton] = useState(true);
    useEffectWithPrevious((pPlanDependencies)=>{
      if(disableButton === false) return;
      if(pPlanDependencies === undefined) return;
      if(pPlanDependencies.length === 0) return;
      const pPlan = pPlanDependencies[0];
      if(init === true){
          if(Object.keys(pPlan).length === Object.keys(plan).length){
            const isDiff = JSON.stringify(pPlan) != JSON.stringify(plan);
            if(isDiff){
              setDisableButton(false);
            }
          }
      }
    }, [plan]);

    return(<Fragment>
        { layoutManager.Layout_GetDebugModel(props) && <p>{JSON.stringify(plan)}</p>}
        <BaseForm
            id="PlanFrom"
            icon="pe-7s-portfolio"
            init = {init}
            loading={loading}
            setLoading={setLoading}
            submitButtonDisabled = {disableButton}
            submitButton={{ postUrl: postUrl, redirectUrl: "/DesignVariationAccessor/DesignVariations/" + plan.projectId}}
            cancelButton={{ redirectUrl: "/ProjectAccessor/Client/" + plan.projectId }}
            data={plan}
            validate ={formValidate}
            title = "Project profile - Plan"
            subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-6">
                <div className="position-relative form-group">
                    <label htmlFor="VersionNumber">Version number</label>
                    <BindedInput
                        className="form-control valid" id="VersionNumber" name="VersionNumber" type="text"
                        binding={[plan, "versionNumber", bindingPlan]}
                    ></BindedInput>
                </div>
                </div>
                <div className="col-6">
                <div className="position-relative form-group">
                    <label htmlFor="ReferenceNumber">Reference number</label>
                    <BindedInput
                        className="form-control valid" id="ReferenceNumber" name="ReferenceNumber" type="text"
                        binding={[plan, "referenceNumber", bindingPlan]}
                    ></BindedInput>
                </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-6">
                <div className="position-relative form-group">
                    <label htmlFor="PlanDateTime">Plan date *</label>
                    <BindedInput
                        className="form-control valid" id="PlanDateTime" name="PlanDateTime" type="date"
                        binding={[plan, "planDateTime", bindingPlan]}                 
                    ></BindedInput>
                </div>
                </div>
                <div className="col-6">
                <div className="position-relative form-group">
                    <label htmlFor="PreparedBy">Prepared by *</label>
                    <BindedInput
                        className="form-control valid" id="PreparedBy" name="PreparedBy" type="text"
                        binding={[plan, "preparedBy", bindingPlan]}
                        validate = {formValidate}
                    ></BindedInput>
                </div>
                </div>
            </div>
        </BaseForm>
    </Fragment>);
} 

export default connectAppStore(Plan);