import ShadingSchemes from "../../Portal/Accessor/Shading/ShadingSchemes";
import VerticalShadingDetailV001 from "../../Portal/Accessor/Shading/V001/VerticalShadingDetail";
import HorizontalShadingDetailV001 from "../../Portal/Accessor/Shading/V001/HorizontalShadingDetail"
const AccessorShadingRouters = [
    {
        path: "ShadingAccessor/ShadingSchemes/:designVariationVersionName/:developmentId/:projectId/:designVariationId",
        exact: true,
        requireAuth: true,
        element: <ShadingSchemes/>,
    },
    {
        path: "ShadingAccessor/Detail/Vertical/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:shadingId/:redirectUrlHex",
        exact: true,
        requireAuth: true,
        element: <VerticalShadingDetailV001 typeName="Vertical"/>,
    },
    {
        path: "ShadingAccessor/Detail/Horizontal/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:shadingId/:redirectUrlHex",
        exact: true,
        requireAuth: true,
        element: <HorizontalShadingDetailV001 typeName="Horizontal"/>,
    },
];
export default AccessorShadingRouters;