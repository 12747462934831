import { Fragment } from "react";
import PageDependencies from "../../Feature/PageDependencies";

const CreditUnitDataTable = (props)=>{
    const { 
        useState,
        dataExtension,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const debug = props.debug === undefined ? false : props.debug;
    const data = props.data;
    const setData = props.setData;
    const tableId = "AdminCreditUnit_5d86dfe1-b186-46fb-a277-956707a4ae83";
    const tableReload = props.tableReload;
    const extraPostModel = props.extraPostModel;
    const url = props.url;
    const [selectedIds, setSelectedIds] = useState([]);
    const columns =[
      { name: "unitPrice", text: "Unit Price", width: "25%" },
      { name: "creditType", text: "Credit Type", width: "25%" },
      { name: "createTime", text: "Create Time", width: "25%" },
      { name: "activated", text: "Activated", width: "25%" }
    ];

    const [modal, setModal] = useState({
      isOpen: false,
      title: "",
      message: "",
      comfirmButtonStyle: "",
      comfirmButtonText: "Comfirm",
      handleComfirm: () => {},
    });

    const contextMenu = {
      items: {
        new: {
          name: "New Unit",
          icon: "add",
          defaultAction: true,
          url: "/Admin/CreditUnits/New",
          isNavOption: true
        },
      },callBack: (
        contextMenuCallBackKey,
        contextMenuCallBackElement,
        contextMenuCallBackTableRoadData,
        localtion
      ) => {
      },
    };

    return(<Fragment>
      {debug && <p>{JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
      <ModalDatatableItem
        modal={modal}
        setModal={setModal}
      ></ModalDatatableItem>
      <ServerSideDataTable
        debug ={debug}
        id={tableId}
        location="CreditUnitsAdmin"
        data={data}
        setData={setData}
        reload={tableReload}
        columns={columns}
        dataSource={{ isAuth: true, url: url }}
        contextMenu={contextMenu}
        extraPostModel={extraPostModel}
        setSelectedItemIds = {setSelectedIds}
        ></ServerSideDataTable>
    </Fragment>);
}
export default CreditUnitDataTable;