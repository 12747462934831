import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
import { fabric } from 'fabric';

const Ventilation = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        useLocation,
        layoutManager,
        useReducerSmart,
        usePrevious,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        BindedCheckBox
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const currentUrl = useLocation().pathname;
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const [initCanvas, setInitCanvas] = useState(false);
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    //change model get url here if not using default model
    const getUrl = "api/Ventilation/Accessor/Get";
    //add model post url here
    const postUrl = "api/Ventilation/Accessor/Update";
    const validateMethods = {
    //add form property validate here
        footprintLength:(value)=>{return FormValidate.validateNumberGreaterThanOrEqual0(value, "Length");},
        footprintWidth:(value)=>{return FormValidate.validateNumberGreaterThanOrEqual0(value, "Width");},
        footprintAzimuth:(value)=>{ return parseInt(value) > 359 || parseInt(value) < -359 ? "Azimuth value must between -359 to 359" : "" },
        pressureCalculatedResult:(value)=>{return FormValidate.validateNumberGreaterThanOrEqual0(value, "Test result input");},
        pressureExponent:(value)=>{return FormValidate.validateNumberGreaterThanOrEqual0(value, "Pressure exponent");}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);
    //map
    const [apiKey, setAipKey] = useState("");
    const defaultZoom = 19;
    const canvasWidth = 600;
    const canvasHeight = 400;
    const { editor, onReady } = useFabricJSEditor();
    const defaultFootprintWidthInPixels = 100;
    const defaultFootprintHeightInPixels = 100;

    const convertPixelToMillimeter = (value, newMillimetersPerPixel, scale = 1) => {
        return value * scale * newMillimetersPerPixel;
    }

    const handleMoving = (e, newMillimetersPerPixel) => {
        let angle = parseInt(e.target.angle);
        while (angle > 360) {
            angle = angle - 360;
        }
        angle = angle == -360 ? 0 : angle;
        angle = angle == 360 ? 0 : angle;
        const x = e.target.scaleX;
        const y = e.target.scaleY;
        const width = e.target.width;
        const height = e.target.height;
        const left = parseInt(e.target.left);
        const top = parseInt(e.target.top);
        const widthVentilation = parseInt(convertPixelToMillimeter(x, newMillimetersPerPixel, width));
        const lengthVentilation = parseInt(convertPixelToMillimeter(y, newMillimetersPerPixel, height));
        if(viewModel.footprintTop != top || viewModel.footprintLeft != left || viewModel.footprintWidth != widthVentilation || viewModel.footprintLength != lengthVentilation || viewModel.footprintAzimuth != angle){
            dispatchViewModel({ ...viewModel, footprintTop: top, footprintLeft: left, footprintWidth: widthVentilation, footprintLength: lengthVentilation, footprintAzimuth: angle});
        }
    }

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, (responseModel)=>{
            setAipKey(responseModel.googleApiKey);
        }, setInit);
    }, []);

    useEffect(() => {
        if (viewModel.googleApiKey === undefined || viewModel.googleApiKey === "") return;
        if(!initCanvas){
            const canvas = editor?.canvas;
            const newMillimetersPerPixel = 156543000.03392 * Math.cos(viewModel.footprintLatitude * Math.PI / 180) / Math.pow(2, defaultZoom);
            let angle = parseInt(viewModel.footprintAzimuth) + 360;
            angle = angle == 360 ? 0 : angle;
            canvas.setWidth(canvasWidth);
            canvas.setHeight(canvasHeight);
            const footprintO = new fabric.Rect({
                left: viewModel.footprintLeft,
                top: viewModel.footprintTop,
                fill: '#1db31d',
                width: defaultFootprintWidthInPixels,
                height: defaultFootprintHeightInPixels,
                strokeWidth: 6,
                stroke: "#a0f0a0",
                rx: 1,
                ry: 1,
                angle: angle,
                scaleX: parseFloat(viewModel.footprintWidth) / convertPixelToMillimeter(defaultFootprintWidthInPixels, newMillimetersPerPixel),
                scaleY: parseFloat(viewModel.footprintLength) / convertPixelToMillimeter(defaultFootprintHeightInPixels, newMillimetersPerPixel),
                hasControls: true,
                opacity: 0.7,
                strokeUniform: true
            });
            canvas.add(footprintO);
            const googleMapsUrl = "https://maps.googleapis.com/maps/api/staticmap?"
                + "center=" + parseFloat(viewModel.footprintLatitude) + "," + parseFloat(viewModel.footprintLongitude)
                + "&maptype=satellite"
                + "&zoom=" + defaultZoom
                + "&size=" + canvasWidth + "x" + canvasHeight
                + "&key=" + viewModel.googleApiKey;
            fabric.Image.fromURL(googleMapsUrl, function (img) {
            img.set({ width: canvasWidth, height: canvasHeight, originX: "left", originY: "top" });
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            });
            canvas.on('object:moving', function (e) {
                handleMoving(e, newMillimetersPerPixel);
              });
            canvas.on('object:rotating', function (e) {
                handleMoving(e, newMillimetersPerPixel);
            });
            canvas.on('object:scaling', function (e) {
                handleMoving(e, newMillimetersPerPixel);
            });
            setInitCanvas(true);
        }
        else
        {
            const timer = setTimeout(() => {           
                const canvas = editor?.canvas;
                const newMillimetersPerPixel = 156543000.03392 * Math.cos(viewModel.footprintLatitude * Math.PI / 180) / Math.pow(2, defaultZoom);
                let angle = parseInt(viewModel.footprintAzimuth) + 360;
                angle = angle == 360 ? 0 : angle;
                const scaleX = parseFloat(viewModel.footprintWidth) / convertPixelToMillimeter(defaultFootprintWidthInPixels, newMillimetersPerPixel);
                const scaleY = parseFloat(viewModel.footprintLength) / convertPixelToMillimeter(defaultFootprintHeightInPixels, newMillimetersPerPixel);
                const activeObjects = canvas.getObjects();
                const activeObject = activeObjects[0];
                if(parseInt(activeObject.angle) != angle || parseFloat(activeObject.scaleX).toFixed(3)!=scaleX.toFixed(3) || parseFloat(activeObject.scaleY).toFixed(3)!=scaleY.toFixed(3) ){
                    if (activeObjects) {
                        canvas.discardActiveObject();
                        canvas.remove(...activeObjects);
                    }
                    const footprintO = new fabric.Rect({
                        left: viewModel.footprintLeft,
                        top: viewModel.footprintTop,
                        fill: '#1db31d',
                        width: defaultFootprintWidthInPixels,
                        height: defaultFootprintHeightInPixels,
                        strokeWidth: 6,
                        stroke: "#a0f0a0",
                        rx: 1,
                        ry: 1,
                        angle: angle,
                        scaleX: scaleX,
                        scaleY: scaleY,
                        hasControls: true,
                        opacity: 0.7,
                        strokeUniform: true
                    });
                    canvas.add(footprintO);    
                    canvas.renderAll();
                }    
              }, 1000);
              return () => {
                clearTimeout(timer);
              };       
        } 
    }, [apiKey,viewModel.footprintWidth, viewModel.footprintLength, viewModel.footprintAzimuth]);

    const prevEnterDoorTestResult = usePrevious(viewModel.enterDoorTestResult);  
    useEffect(()=>{
        if (prevEnterDoorTestResult === false && viewModel.enterDoorTestResult === true){
            const newViewModel = {...viewModel, pressureCalculatedResult:21, pressureExponent:0.65};
            dispatchViewModel(newViewModel);
        }     
    },[viewModel.enterDoorTestResult])

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <BaseForm
                id="VentilationFrom"
                icon="pe-7s-refresh-cloud"
                loading={loading}
                setLoading={setLoading}
                validate = {formValidate}
                submitButtonAndStay = {{text:"Save", postUrl: postUrl, redirectUrl: currentUrl}}
                data={viewModel}
                title="Ventilation"
                subTitle={pageProfile.getPageSubTitle(appStoreState)}
            >
                {init === true &&
                    <div className="form-row row">
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="Azimuth">Update method</label>
                            <BindedSelect
                                className="form-control"
                                id="Operation"
                                name="Operation"
                                binding={[viewModel, "operation", bindingViewModel]}
                                options={"operations"}
                                style={{marginBottom:"15px"}}
                            ></BindedSelect>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                        <label htmlFor="Azimuth">Azimuth (degrees) *</label>
                        <BindedInput
                            className="form-control"
                            binding={[viewModel, "footprintAzimuth", bindingViewModel]}
                            id="Azimuth"
                            name="Azimuth"
                            type="number"
                            min="-359"
                            max="359"
                            step="1"
                            validate = {formValidate}
                        ></BindedInput>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                        <label htmlFor="Azimuth">Length (mm) *</label>
                        <BindedInput
                            className="form-control"
                            binding={[viewModel, "footprintLength", bindingViewModel]}
                            id="Length"
                            name="Length"
                            type="number"
                            min="0"
                            step="1"
                            validate = {formValidate}
                        ></BindedInput>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                        <label htmlFor="Azimuth">Width (mm) *</label>
                        <BindedInput
                            className="form-control"
                            binding={[viewModel, "footprintWidth", bindingViewModel]}
                            id="Width"
                            name="Width"
                            type="number"
                            min="0"
                            step="1"
                            validate = {formValidate}
                        ></BindedInput>
                        </div>
                    </div>
                </div>
                }
            
                <div hidden={init === false} className="form-row row" >
                    <FabricJSCanvas onReady={onReady} />
                </div>
                {init && 
                           <div style={{marginTop:"15px"}} className="main-card mb-3 card">
                           <div className="card-header">
                               <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                   <h5 className="m-0 p-0 card-title">Special Inflitration Test Result</h5>
                               </button>
                           </div>
                           <div className="collapse show">
                               <div className="card-body">
                                   <div className="separator"></div>
                                   <div className="form-row row">
                                       <div className="col-12">
                                           <div className="custom-checkbox custom-control">
                                               <BindedCheckBox
                                                   id="EnterDoorTestResult" 
                                                   name="EnterDoorTestResult"
                                                   className="custom-control-input"
                                                   binding={[viewModel, "enterDoorTestResult", bindingViewModel]}
                                                   disabled = {viewModel.simulationType !== "Existing Home"}
                                               ></BindedCheckBox>
                                               <label style={{marginLeft:"5px"}} htmlFor="EnterDoorTestResult" className="custom-control-label">Enter Blower Door Test Result</label>                          
                                           </div>
                                       </div>
                                       <p>(BS EN 13829:2000 Method B. Test of the building envelope)</p>
                                       <div className="col-6">
                                           <div className="position-relative form-group">
                                           <label htmlFor="PressureCalculatedResult ">{viewModel.enterDoorTestResult === true ? "Test" : "Calculated"} Result (ach@50 Pa)</label>
                                           <BindedInput
                                               className="form-control"
                                               binding={[viewModel, "pressureCalculatedResult", bindingViewModel]}
                                               id="PressureCalculatedResult"
                                               name="PressureCalculatedResult"
                                               type="number"
                                               validate = {formValidate}
                                               disabled = {!viewModel.enterDoorTestResult}
                                           ></BindedInput>
                                           </div>
                                       </div>
                                       {viewModel.dwellingType !== "New" &&
                                       <div className="col-6">
                                           <div className="position-relative form-group">
                                           <label htmlFor="PressureExponent">Pressure Exponent</label>
                                           <BindedInput
                                               className="form-control"
                                               binding={[viewModel, "pressureExponent", bindingViewModel]}
                                               id="PressureExponent"
                                               name="PressureExponent"
                                               type="number"
                                               validate = {formValidate}
                                               disabled = {!viewModel.enterDoorTestResult}
                                           ></BindedInput>
                                           </div>
                                       </div>
                                       }
                                       <div className="col-6">
                                           <div className="position-relative form-group">
                                           <label htmlFor="PressureOutputResult">Output Result (ach@ambient)</label>
                                           <BindedInput
                                               className="form-control"
                                               binding={[viewModel, "pressureOutputResult", bindingViewModel]}
                                               id="PressureOutputResult"
                                               name="PressureOutputResult"
                                               type="number"
                                               validate = {formValidate}
                                               disabled
                                           ></BindedInput>
                                           </div>
                                       </div>
                                       {viewModel.dwellingType !== "New" &&
                                       <div className="col-6">
                                           <div className="position-relative form-group">
                                           <label htmlFor="TotalVolume">Total Volume</label>
                                           <BindedInput
                                               className="form-control"
                                               binding={[viewModel, "totalVolume", bindingViewModel]}
                                               id="TotalVolume"
                                               name="TotalVolume"
                                               type="number"
                                               validate = {formValidate}
                                               disabled
                                           ></BindedInput>
                                           </div>
                                       </div>
                                       }
                                   </div>
                               </div>
                           </div>
                       </div>
                }
         
            </BaseForm>
        </Fragment>
    );
}
export default connectAppStore(Ventilation);