import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
const RefundDetail = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        tryCatchFinally,
        stringCoding,
        layoutManager,
        getData,
        useReducerSmart,
        BindedInput,
        getAppStore,
        useNavigate,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const nav = useNavigate();
    const [init, setInit] = useState(false);
    const { creditId } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        selectedItemIds: [creditId]
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/Credit/Accessor/GetRefund";
    const validateMethods = {
        numberOfRefund: (value, viewModel)=>{ return FormValidate.validateNumberBetween(value, "Number of refund", 1, viewModel.availableOfRefund);},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Refund Detail", appStoreDispatch, undefined, setInit);
    },[]);

    const calculateAmount = (unit, quanlity) => {
        if (unit === undefined || unit === "" || unit === null) unit = 0;
        if (quanlity === undefined || quanlity === "" || quanlity === null) quanlity = 0;
        unit = Number(unit);
        quanlity = Number(quanlity);
        return ((unit * quanlity)).toFixed(2);
    };

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/Credit/Accessor/GetRefundResult";
        setLoading({ isLoading: true, text: "Submitting" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            const urlParams = stringCoding.encodeObject(response.viewModel);
            nav("/UserAccessor/Credits/RefundResult/" + urlParams);
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Apply Refund
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                  <div className="main-card mb-3 col-3 card">
                  <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                      <div className="card-body">
                          <div className="form-row row">
                              <div className="col-12">
                                  <div className="position-relative form-group">
                                      <label>Credit type: {viewModel.creditType}</label>
                                  </div>
                              </div>
                          </div>
                          <div className="form-row row">
                              <div className="col-12">
                                  <div className="position-relative form-group">
                                      <label>Unit price: {viewModel.unitPrice}</label>
                                  </div>
                              </div>
                          </div>
                          <div className="form-row row">
                              <div className="col-12">
                                  <div className="position-relative form-group">
                                      <label htmlFor="NumberOfRefund">Number of refund * </label>
                                      <BindedInput
                                          className="form-control"
                                          id="NumberOfRefund"
                                          name="NumberOfRefund"
                                          type="number"
                                          min="0"
                                          max={viewModel.availableOfRefund}
                                          validate = {formValidate}
                                          binding={[viewModel, "numberOfRefund", bindingViewModel]}
                                      ></BindedInput>
                                  </div>
                              </div>
                          </div>
                          <div className="form-row row">
                              <div className="col-12">
                                  <div className="position-relative form-group">
                                      <label htmlFor="RefundAmount">Refund amount (AUD): {calculateAmount(viewModel.unitPrice, viewModel.numberOfRefund)}</label>
                                  </div>
                              </div>
                          </div>
                          <div className="form-row row">
                              <div className="col-12">
                                  <div className="position-relative form-group">
                                      <label htmlFor="Reason">Refund reason </label>
                                      <BindedInput
                                          className="form-control"
                                          id="Reason"
                                          name="Reason"
                                          type="text"
                                          binding={[viewModel, "reason", bindingViewModel]}
                                      ></BindedInput>
                                  </div>
                              </div>
                          </div>
                          <div className="form-row row">
                              <div className="col-12">
                                  <div className="position-relative form-group">
                                      <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Refund</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                }
              
            </div>
        </Fragment>
    );
}
export default  connectAppStore(RefundDetail);
