import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import ConstructionElementDataTable from "../../../Accessor/Construction/ConstructionElementDataTable";

const InternalWallDetail = (props) => {

  const {
    layoutManager,
    GetSelectedItemText,
    guid,
    useReducerSmart,
    useParams,
    useLocation,
    useFromDefaultValue,
    BaseFormNoHeader,
    BindedInput,
    BindedCheckBox,
    BindedSelect,
    getAppStore,
    pageProfile,
    loaModelFromApi,
    FormValidate,
    BindedInputNumber,
    htmlToReactParse,
  } = PageDependencies();
  const debug = layoutManager.Layout_GetDebugModel(props);
  const emptyGuid = guid.emptyGuid();
  const currentUrl = useLocation().pathname;
  const defaultParams = pageProfile.getDefaultParams(useParams());
  const [reloadForm, setReloadForm] = useState("");
  let {
    designVariationVersionName,
    developmentId,
    projectId,
    designVariationId,
    elementId,
    zoneId,
    redirectUrlHex
  } = defaultParams;
  if (zoneId === undefined) zoneId = emptyGuid;
  const defaultViewModel = {
    ...defaultParams,
    id: emptyGuid,
    zoneId: zoneId,
    elementId: elementId,
    selectedItemIds: [elementId]
  };
  const [extraPostModelForConstruction, setExtraPostModelForConstruction] =
    useState({
      developmentId: developmentId,
      projectId: projectId,
      designVariationId: designVariationId,
      designVariationVersionName: designVariationVersionName,
      constructionType: "InternalWall",
    });
    const [init, setInit] = useState(false);
  const [tableReloadForConstruction, setTableReloadForConstruction] = useState("");
  const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
  const [loading, setLoading] = useState({ isLoading: false, text: "" });
  const [appStoreState, appStoreDispatch] = getAppStore(props);
  const postUrl = "api/Element/Accessor/UpdateInternalWall";
  const getUrl = "api/Element/Accessor/GetInternalWall";
  const validateMethods = {
    name: (value) => {return FormValidate.validateTextRequired(value, "Internal wall name");},
    constructionId: (value) => {return value === emptyGuid ? "Must select a construction." : "";},
      length: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Length");},
      height: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Height");}
  };
  const formValidate = new FormValidate(
    useState(validateMethods),
    useState(),
    useState(),
    useState(),
    false 
  );

  useFromDefaultValue("InternalWallDefaultValue_f4c4706e-dfa7-4414-97b1-9f444ab40112", viewModel, dispatchViewModel, ["constructionId", "height"], [emptyGuid, 0],
      (v)=>{
          //update cooike condition
          return v.constructionId != emptyGuid || v.height > 0;
      },
      (v)=>{
          //update view model condition
          return v.elementId == guid.emptyGuid() && v.constructionId == emptyGuid && v.height == 0;
  },);

  useEffect(() => {
    layoutManager.Layout_Headter_Footer_SideBar(props);
    loaModelFromApi.loadDefault(
      true,
      getUrl,
      viewModel,
      dispatchViewModel,
      setLoading,
      "Loading internal wall",
        appStoreDispatch,
        undefined,
        setInit
    );
  }, [reloadForm]);

  useEffect(()=>{   
    if(init){
      dispatchViewModel({...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId]}, ()=>{
        setReloadForm(guid.newGuid());
      });
    }
  },[elementId]);

  useEffect(() => {
    calculateArea(viewModel.length, viewModel.height);
  }, [viewModel.length, viewModel.height]);

  const calculateArea = (length, height) => {
    if (length === undefined || length === "" || length === null) length = 0;
    if (height === undefined || height === "" || height === null) height = 0;
    length = Number(length);
    height = Number(height);
    return ((length * height) / 1e6).toFixed(2);
  };

  const generateStayUrl = (submitResponse) => {
    let redirectUrl = currentUrl;
    if (elementId === emptyGuid) {
      //"ElementAccessor/Detail/InternalWall/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:redirectUrlHex"
      redirectUrl =  "/ElementAccessor/Detail/InternalWall/" + designVariationVersionName + "/" + developmentId +"/"+projectId+"/"+designVariationId+"/"+zoneId+"/"+submitResponse.elementId+"/"+redirectUrlHex;
    }
    return redirectUrl;
  };

  return (
    <Fragment>
      {debug && <p>{JSON.stringify(viewModel)}</p>}
      <div className="app-main__inner">
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-safe icon-gradient bg-tempting-azure"></i>
              </div>
              <div>
                Internal wall
                <div className="page-title-subheading">
                  {pageProfile.getPageSubTitle(appStoreState)}
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div className="row">
          <div className="col-xl-6 col-lg-12">
            <div className="main-card mb-3 card">
              <BaseFormNoHeader
                id="InternalWallDetail"
                submitButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl, text: "Save and close"}}
                submitButtonAndStay={{ text:"Save", postUrl: postUrl, redirectMethod: (submitResponse)=>{return generateStayUrl(submitResponse)}}}
                cancelButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl }}
                validate={formValidate}
                setReload = {setReloadForm}
                loading={loading}
                init={init}
                setLoading={setLoading}
                data={viewModel}
                cardTitle={"Internal Wall between " + GetSelectedItemText(viewModel.adjacentZoneAId, viewModel.adjacentZoneAs) + " and " + GetSelectedItemText(viewModel.adjacentZoneBId, viewModel.adjacentZoneBs)}
              >
                <div className="form-row row">
                  <div className="col-6">
                    <div className="position-relative form-group">
                      <label htmlFor="InternalWallName">Name *</label>
                      <BindedInput
                        className="form-control"
                        id="InternalWallName"
                        name="InternalWallName"
                        type="text"
                        validate={formValidate}
                        binding={[viewModel, "name", bindingViewModel]}
                      ></BindedInput>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="position-relative form-group">
                      <label htmlFor="ZoneId">Zone *</label>
                      <BindedSelect
                        className="form-control"
                        id="ZoneId"
                        name="AdjacentZoneAId"
                        options="adjacentZoneAs"
                        binding={[viewModel, "adjacentZoneAId", bindingViewModel]}
                      ></BindedSelect>
                    </div>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="col-8">
                    <div className="position-relative form-group">
                      <label htmlFor="InternalWallConstructionId">
                        Construction *
                      </label>
                      <BindedSelect
                        className="form-control"
                        id="InternalWallConstructionId"
                        name="InternalWallConstructionId"
                        options="constructions"
                        validate={formValidate}
                        binding={[
                          viewModel,
                          "constructionId",
                          bindingViewModel,
                        ]}
                      ></BindedSelect>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <label htmlFor="InternalWallLayerDirectionTypeId">
                        Order *
                      </label>
                      <BindedSelect
                        className="form-control"
                        id="InternalWallLayerDirectionTypeId"
                        name="InternalWallLayerDirectionTypeId"
                        options="layerDirectionTypes"
                        validate={formValidate}
                        binding={[
                          viewModel,
                          "layerDirectionTypeId",
                          bindingViewModel,
                        ]}
                      ></BindedSelect>
                    </div>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <label htmlFor="InternalWallLength">Length (mm) *</label>
                      <BindedInput
                        className="form-control"
                        id="InternalWallLength"
                        name="InternalWallLength"
                        type="number"
                        validate={formValidate}
                        binding={[viewModel, "length", bindingViewModel]}
                      ></BindedInput>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <label htmlFor="internalWallHeight">Height (mm) *</label>
                      <BindedInput
                        className="form-control"
                        id="InternalWallHeight"
                        name="InternalWallHeight"
                        type="number"
                        validate={formValidate}
                        binding={[viewModel, "height", bindingViewModel]}
                      ></BindedInput>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <label htmlFor="InternalWallGrossArea">
                        Area (m<sup>2</sup>) *
                      </label>
                      <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateArea(viewModel.length, viewModel.height)}</label>
                    </div>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <BindedInputNumber inputId="InternalWallOpeningArea" label={htmlToReactParse("Opening area (m<sup>2</sup>) *")} min="0" minFractionDigits={0} maxFractionDigits={2} binding={[viewModel, "openingArea", bindingViewModel]} validate = {formValidate}/>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <label htmlFor="InternalWallOpeningTypeId">
                        Opening type *
                      </label>
                      <BindedSelect
                        className="form-control"
                        id="InternalWallOpeningTypeId"
                        name="InternalWallOpeningTypeId"
                        options="openingTypes"
                        validate={formValidate}
                        binding={[viewModel, "openingTypeId", bindingViewModel]}
                      ></BindedSelect>
                    </div>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="col-6">
                    <div className="position-relative form-group">
                      <label htmlFor="InternalWallAdjacentZoneAboveId">Adjacent zone *</label>
                      <BindedSelect
                        className="form-control"
                        id="InternalWallAdjacentZoneAboveId"
                        name="AdjacentZoneBId"
                        options="adjacentZoneBs"
                        binding={[viewModel, "adjacentZoneBId", bindingViewModel]}
                      ></BindedSelect>
                    </div>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="custom-checkbox custom-control">
                    <BindedCheckBox
                      id="InternalWallHasCompositeWall"
                      name="InternalWallHasCompositeWall"
                      className="custom-control-input"
                      binding={[
                        viewModel,
                        "hasCompositeWall",
                        bindingViewModel,
                      ]}
                    ></BindedCheckBox>
                    <label
                      style={{ marginLeft: "5px" }}
                      htmlFor="InternalWallHasCompositeWall"
                      className="custom-control-label"
                    >
                      Composite wall
                    </label>
                  </div>
                  {viewModel.hasCompositeWall && (
                    <Fragment>
                      <div className="col-4">
                        <div className="position-relative form-group">
                          <label htmlFor="InternalWallAboveWallId">
                            Wall above
                          </label>
                          <BindedSelect
                            className="form-control"
                            id="InternalWallAboveWallId"
                            name="InternalWallAboveWallId"
                            options="aboveWalls"
                            validate={formValidate}
                            binding={[
                              viewModel,
                              "aboveWallId",
                              bindingViewModel,
                            ]}
                          ></BindedSelect>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="position-relative form-group">
                          <label htmlFor="InternalWallBelowWallId">
                            Wall below
                          </label>
                          <BindedSelect
                            className="form-control"
                            id="InternalWallBelowWallId"
                            name="InternalWallBelowWallId"
                            options="belowWalls"
                            validate={formValidate}
                            binding={[
                              viewModel,
                              "belowWallId",
                              bindingViewModel,
                            ]}
                          ></BindedSelect>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="position-relative form-group">
                          <label htmlFor="InternalWallBaseHeight">Base height (mm)</label>
                          <BindedInput
                            className="form-control"
                            id="InternalWallBaseHeight"
                            name="InternalWallBaseHeight"
                            type="number"
                            validate={formValidate}
                            binding={[
                              viewModel,
                              "baseHeight",
                              bindingViewModel,
                            ]}
                          ></BindedInput>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </BaseFormNoHeader>
            </div>
          </div>
          {init === true &&
            <div className="col-xl-6 col-lg-12">
            <div className="main-card mb-3 card">
              <div className="card-header">
                <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                  <h5 className="m-0 p-0 card-title">Internal Wall Constructions</h5>
                </button>
              </div>
              <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                <div className="card-body">
                  <div className="separator"></div>
                    <ConstructionElementDataTable
                        tableId = {"InternalWallElement-b14d42ba-6e6c-4525-bf83-9b21bad5e0b5"}
                        tableReload={tableReloadForConstruction}
                        setTableReload={setTableReloadForConstruction}
                        extraPostModel = {extraPostModelForConstruction}
                        constructionType = "InternalWall"
                        redirectUrl = {currentUrl}
                        debug = {debug}
                    >
                    </ConstructionElementDataTable>
                  </div>
                </div>
              </div>
            </div>
          }
          
          </div>
      </div>
    </Fragment>
  );
};
export default connectAppStore(InternalWallDetail);
