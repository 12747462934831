import { defaultProjectSliceActions } from "../../Store/reducers/index";
import stringCoding from "../../Function/StringCoding";
class PageProfile {
  getDefaultParams(useParams){
    const keys = Object.keys(useParams);
    const model = {};
    for (const key of keys) {
      model[key] = useParams[key];
      if(key === "jsonParamHex" || key === "redirectUrlHex"){
        model[key.replace("Hex","")] = stringCoding.decode(useParams[key]);
      }
    }
    return model;
  }

  getPageSubTitle(appStoreState) {
    let title = appStoreState.defaultProjectReducer.projectName +
    " (" +
    appStoreState.defaultProjectReducer.designVariationName +
    ")";
    if(title.length === 3) title = "";
    return (title);
  }
  
  getUserRole(appStoreState) {
    return appStoreState.defaultProjectReducer.userRole;
  }

  getModuleTypes(appStoreState) {
    return appStoreState.defaultProjectReducer.modules;
  }

  getSimulationType(appStoreState){
    if (appStoreState.defaultProjectReducer.modules.length > 0){
      return appStoreState.defaultProjectReducer.modules[0].simulationType;
    }
    else{
      return "Thermal Only";
    }
  }

  getDwellingType(appStoreState) {
    return appStoreState.defaultProjectReducer.dwellingType;
  }

  getClimateZoneIndex(appStoreState) {
    return appStoreState.defaultProjectReducer.climateZoneIndex;
  }

  getSoftwareVersion(appStoreState) {
    return appStoreState.defaultProjectReducer.softwareVersion;
  }

  getExtraData(appStoreState) {
    return appStoreState.defaultProjectReducer.extraData;
  }
  
  hasModuleType(appStoreState, moduleName, simulationType) {
    return appStoreState.defaultProjectReducer.modules.filter(e => e.moduleName == moduleName && e.simulationType == simulationType).length > 0;
  }

  setDefaultDevelopmentId(appStoreDispatch, developmentId) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "developmentId",
        developmentId: developmentId,
      })
    );
  }

  setDefaultModuleTypes(appStoreDispatch, modules) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "modules",
        modules: modules,
      })
    );
  }

  setDefaultProjectId(appStoreDispatch, projectId) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "projectId",
        projectId: projectId,
      })
    );
  }

  setDefaultProjectName(appStoreDispatch, projectName) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "projectName",
        projectName: projectName,
      })
    );
  }

  setDefaultDesignVariationName(appStoreDispatch, designVariationName) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "designVariationName",
        designVariationName: designVariationName,
      })
    );
  }

  setDefaultDesignVariationName(appStoreDispatch, designVariationName) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "designVariationName",
        designVariationName: designVariationName,
      })
    );
  }

  setDefaultDesignVariationVersionName(appStoreDispatch, designVariationVersionName) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "designVariationVersionName",
        designVariationVersionName: designVariationVersionName,
      })
    );
  }

  setDefaultDesignVariationVersionId(appStoreDispatch, designVariationVersionId) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "designVariationVersionId",
        designVariationVersionId: designVariationVersionId,
      })
    );
  }

  setDefaultdesignVariationName(appStoreDispatch, designVariationName) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "designVariationName",
        designVariationName: designVariationName,
      })
    );
  }

  setDefaultDesignVariationId(appStoreDispatch, designVariationId) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "designVariationId",
        designVariationId: designVariationId,
      })
    );
  }

  setUserId(appStoreDispatch, userId) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "userId",
        userId: userId,
      })
    );
  }

  setUserName(appStoreDispatch, userName) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "userName",
        userName: userName,
      })
    );
  }

  setUserRole(appStoreDispatch, userRole) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "userRole",
        userRole: userRole,
      })
    );
  }

  setDwellingType(appStoreDispatch, dwellingType) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "dwellingType",
        dwellingType: dwellingType,
      })
    );
  }

  setClimateZoneIndex(appStoreDispatch, climateZoneIndex) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "climateZoneIndex",
        climateZoneIndex: climateZoneIndex,
      })
    );
  }

  setSoftwareVersion(appStoreDispatch, softwareVersion) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "softwareVersion",
        softwareVersion: softwareVersion,
      })
    );
  }

  setExtraData(appStoreDispatch, extraData) {
    appStoreDispatch(
      defaultProjectSliceActions.setDefaultProject({
        type: "extraData",
        extraData: extraData,
      })
    );
  }

  setDefaultValues(appStoreDispatch, model){
    this.setDefaultDevelopmentId(appStoreDispatch, model.developmentId);
    this.setDefaultProjectId(appStoreDispatch, model.projectId);
    this.setDefaultDesignVariationName(appStoreDispatch, model.designVariationName);
    this.setDefaultProjectName(appStoreDispatch, model.projectName);
    this.setDefaultDesignVariationVersionName(appStoreDispatch, model.designVariationVersionName);
    this.setDefaultDesignVariationVersionId(appStoreDispatch, model.designVariationVersionId);
    this.setDefaultDesignVariationName(appStoreDispatch, model.designVariationName);
    this.setDefaultDesignVariationId(appStoreDispatch, model.designVariationId);
    this.setUserId(appStoreDispatch, model.userId);
    this.setUserName(appStoreDispatch, model.userName);
    this.setUserRole(appStoreDispatch, model.userRole);
    this.setDefaultModuleTypes(appStoreDispatch, model.modules);
    this.setDwellingType(appStoreDispatch, model.dwellingType);
    this.setClimateZoneIndex(appStoreDispatch, model.climateZoneIndex);
    this.setSoftwareVersion(appStoreDispatch, model.softwareVersion);
    this.setExtraData(appStoreDispatch, model.extraData);
  }
}
const pageProfile = new PageProfile();
export default pageProfile;
