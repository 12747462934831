import { Fragment } from "react";
import PageDependencies from "../../Feature/PageDependencies";

const DevelopmentDataTable = (props)=>{
    const { 
        getData,
        tryCatchFinally,
        guid,
        useState,
        toastPrint,
        dataExtension,
        ServerSideDataTable,
        AppLoading,
        ModalDatatableItem,
    } = PageDependencies();

    const debug = props.debug === undefined ? false : props.debug;
    const data = props.data;
    const setData = props.setData;
    const [loadingTable, setLoadingTable] = useState({ isLoading: false, text: "" });
    const tableId = "AdminDevelopment_f8a61f13-5d4d-4b37-946f-5070d88b2da0";
    const tableReload = props.tableReload;
    const setTableReload = props.setTableReload;
    const extraPostModel = props.extraPostModel;
    const url = props.url;
    const [selectedIds, setSelectedIds] = useState([]);
    const columns =[
      { name: "name", text: "Development Name", width: "20%" },
      { name: "firstName", text: "First Name", width: "10%" },
      { name: "lastName", text: "Last Name", width: "10%" },
      { name: "organization", text: "Organization", width: "15%" },
      { name: "phone", text: "Phone", width: "10%" },
      { name: "projectCount", text: "Project Count", width: "10%" },
      { name: "clientName", text: "Client Name", width: "15%" },
      { name: "isDeleted", text: "Is Soft Deleted", width: "10%" }
    ];

    const [modal, setModal] = useState({
      isOpen: false,
      title: "",
      message: "",
      comfirmButtonStyle: "",
      comfirmButtonText: "Comfirm",
      handleComfirm: () => {},
    });

    const contextMenu = {
        items: {
            copy: {
                name: "Duplicate",
                icon: "copy",
                defaultAction: false,
                disabled: function () {
                    return dataExtension.getSelectedRowDataIds(data).length == 0;}
            },
            permanent: {
                name: "Permanently Delete",
                icon: "delete",
                defaultAction: false,
                disabled: function () {
                    return dataExtension.getSelectedRowDataIds(data).length == 0;}
            },
            soft: {
                name: "Soft Delete",
                icon: "delete",
                defaultAction: false,
                disabled: function () {
                    return dataExtension.getSelectedRowDataIds(data).length == 0;}
            },
            disable: {
                name: "Revert Soft Delete",
                icon: "",
                defaultAction: false,
                disabled: function () {
                    return dataExtension.getSelectedRowDataIds(data).length == 0;}
            },
        },callBack: (
            contextMenuCallBackKey,
            contextMenuCallBackElement,
            contextMenuCallBackTableRoadData,
            localtion
        ) => {
            if (contextMenuCallBackKey === "copy"){
                copyHandler();
            }
            if (contextMenuCallBackKey === "permanent"){
                permanentDeleteHandler();
            }
            if (contextMenuCallBackKey === "soft"){
                softDeleteHandler();
            }
            if (contextMenuCallBackKey === "disable"){
                disableSoftDeleteHandler();
            }
        },
    };

    const copyHandler = async () => {
        setLoadingTable({isLoading:true,text:"Duplicating Developments"});
        await tryCatchFinally.asyncProcess(async ()=>{
            const selectedItemIds = dataExtension.getSelectedRowDataIds(data);
            const response = await getData.processPost(
                true,
                "api/Development/Admin/Copy",
                {selectedItemIds: selectedItemIds}
                ); 
            toastPrint.printResponse(response);
            setTableReload(guid.newGuid());
        }, setLoadingTable);
    }

    const permanentDeleteHandler = async () => {
        setLoadingTable({isLoading: true, text:"Permanently Deleting Developments"});
        await tryCatchFinally.asyncProcess(async ()=>{
            const selectedItemIds = dataExtension.getSelectedRowDataIds(data);
            const response = await getData.processPost(
                true,
                "api/Development/Admin/Delete",
                {selectedItemIds: selectedItemIds}
                ); 
            toastPrint.printResponse(response);
            setTableReload(guid.newGuid());
        }, setLoadingTable);
    }

    const softDeleteHandler = async () => {
        setLoadingTable({isLoading:true,text:"Soft Deleting Developments"});
        await tryCatchFinally.asyncProcess(async ()=>{
            const selectedItemIds = dataExtension.getSelectedRowDataIds(data);
            const response = await getData.processPost(
                true,
                "api/Development/Admin/SoftDelete",
                {selectedItemIds: selectedItemIds}
                ); 
            toastPrint.printResponse(response);
            setTableReload(guid.newGuid());
        }, setLoadingTable);
    }

    const disableSoftDeleteHandler = async () => {
        setLoadingTable({isLoading:true,text:"Reverting Soft Delete"});
   
        await tryCatchFinally.asyncProcess(async ()=>{
            const selectedItemIds = dataExtension.getSelectedRowDataIds(data);
            const response = await getData.processPost(
                true,
                "api/Development/Admin/DisableSoftDelete",
                {selectedItemIds: selectedItemIds}
                ); 
            toastPrint.printResponse(response);
            setTableReload(guid.newGuid());
        }, setLoadingTable);
    }

    return(<Fragment>
        {debug && <p>{JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
        {debug && <p>{JSON.stringify(loadingTable)}</p>}

        <ModalDatatableItem
            modal={modal}
            setModal={setModal}
        ></ModalDatatableItem>
        {loadingTable.isLoading && <AppLoading active><div>{loadingTable.text}</div></AppLoading>}
        <ServerSideDataTable
            allowSelect
            allowSelectMultiple
            debug ={debug}
            id={tableId}
            location="DevelopmentsAdmin"
            data={data}
            setData={setData}
            reload={tableReload}
            columns={columns}
            dataSource={{ isAuth: true, url: url }}
            contextMenu={contextMenu}
            extraPostModel={extraPostModel}
            setSelectedItemIds = {setSelectedIds}
        ></ServerSideDataTable>
    </Fragment>);
}
export default DevelopmentDataTable;