
import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import ElementDataTable from "./ElementDataTable";
const Elements = (props) => {
    const { 
    layoutManager,
    guid,
    useParams,
    getAppStore,
    pageProfile,
    AppLoading,
    Fragment, 
    useEffect, 
    useState ,
    loaModelFromApi
    } = PageDependencies();
    const emptyGuid = guid.emptyGuid();
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const designVariationVersionName = useParams().designVariationVersionName;
    const developmentId = useParams().developmentId;
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName:designVariationVersionName,
      };
    const [viewModel, setViewModel] = useState(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, "api/DefaultModel/Accessor/Get", viewModel, setViewModel, setLoading, "Loading data", appStoreDispatch);
    },[]);

    return(<Fragment>
             {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>}
             <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-plugin icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Elements
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ElementsAccordion">
                    <div className="main-card mb-3 card">
                        <div className="card-header">
                            <button type="button" data-toggle="collapse" data-target="#ElementsCollapsibleDiv" aria-expanded="true" aria-controls="ElementsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                                <h5 className="m-0 p-0 card-title">Elements</h5>
                            </button>
                        </div>
                        <div data-parent="#ElementsAccordion" id="ElementsCollapsibleDiv" className="collapse show">
                            <div className="card-body">
                            <ElementDataTable
                                id = "Elements_f12db115-fd4e-4b2f-83cf-775ae79d0622"
                                projectId = {viewModel.projectId}
                                designVariationId = {viewModel.designVariationId}
                                developmentId = {viewModel.developmentId}
                                zoneId = {viewModel.zoneId === undefined ? emptyGuid : viewModel.zoneId}
                                designVariationVersionName = {viewModel.designVariationVersionName}
                                isRetrievalInZoneOnly = {false}
                                showZone ={true}
                                debug = {layoutManager.Layout_GetDebugModel(props)}
                            ></ElementDataTable>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </Fragment>);
}
export default  connectAppStore(Elements);
