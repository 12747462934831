import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const MultipleFloorCeilingsDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    let projectId = useParams().projectId;
    let designVariationId = useParams().designVariationId;
    let developmentId = useParams().developmentId;
    let zoneId = useParams().zoneId;
    if (projectId === 'undefined') projectId = emptyGuid;
    if (designVariationId === 'undefined') designVariationId = emptyGuid;
    if (developmentId === 'undefined') developmentId = emptyGuid;
    if (zoneId === 'undefined') zoneId = emptyGuid;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedElementIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        elementType: props.elementType,
        selectedItemIds: selectedElementIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateFloorCeilings";
    const getUrl = "api/Element/Accessor/GetFloorCeilings";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading floor/ceilings", appStoreDispatch);
    },[]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="FloorCeilingFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = {props.elementType + "s detail"}
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="Name">Name *</label>
                        <BindedInput
                        className="form-control"
                        id="Name"
                        name="Name"
                        type="text"
                        binding={[viewModel, "name", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="AdjacentZoneAboveId">Zone Above *</label>
                        <BindedSelect
                        className="form-control"
                        id="AdjacentZoneAboveId"
                        name="AdjacentZoneAboveId"
                        options="adjacentZonesAbove"
                        binding={[viewModel, "adjacentZoneAboveId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="FloorCeilingConstructionId">
                        Construction *
                        </label>
                        <BindedSelect
                        className="form-control"
                        id="FloorCeilingConstructionId"
                        name="FloorCeilingConstructionId"
                        options="constructions"
                        binding={[viewModel, "constructionId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="AdjacentZoneBelowId">Zone Below *</label>
                        <BindedSelect
                        className="form-control"
                        id="AdjacentZoneBelowId"
                        name="AdjacentZoneBelowId"
                        options="adjacentZonesBelow"
                        binding={[viewModel, "adjacentZoneBelowId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="FloorCeilingGrossArea">
                        Area (m<sup>2</sup>) *
                        </label>
                        <BindedInput
                        className="form-control"
                        min="0"
                        id="FloorCeilingGrossArea"
                        name="FloorCeilingGrossArea"
                        type="number"
                        binding={[viewModel, "grossArea", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="FloorCeilingOpeningArea">
                        Opening area (m<sup>2</sup>) *
                        </label>
                        <BindedInput
                        className="form-control"
                        min="0"
                        id="FloorCeilingOpeningArea"
                        name="FloorCeilingOpeningArea"
                        type="number"
                        binding={[viewModel, "openingArea", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="OpeningTypeId">
                        Opening type *
                        </label>
                        <BindedSelect
                        className="form-control"
                        id="OpeningTypeId"
                        name="OpeningTypeId"
                        options="openingTypes"
                        binding={[viewModel, "openingTypeId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="FloorNumber">
                        Floor Number *
                        </label>
                        <BindedInput
                        className="form-control"
                        id="FloorNumber"
                        name="FloorNumber"
                        type="number"
                        min="0"
                        binding={[viewModel, "floorNumber", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="EdgeInsulationRValue">
                        Edge Insulation (R) *
                        </label>
                        <BindedInput
                        className="form-control"
                        id="EdgeInsulationRValue"
                        name="EdgeInsulationRValue"
                        type="number"
                        step='0.5'
                        min="0"
                        max="4.5"
                        binding={[viewModel, "edgeInsulationRValue", bindingViewModel]}
                      ></BindedInput>
                    </div>
                </div>
            </div>
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleFloorCeilingsDetail);