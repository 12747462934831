
import { connectAppStore } from "../../../Store/AppStore";
import GoogleAddress from "../Default/GoogleAddress";
import PageDependencies from "../../../Feature/PageDependencies";
import TooltipTemplate from "../../../Layout/AppNav/TooltipTemplate";

const Dwelling = (props) => {
  const { 
    layoutManager,
    getData,
    guid,
    useReducerSmart,
    toastPrint,
    useParams,
    BindedSelect,
    BindedInput,
    getAppStore,
    useNavigate,
    useEffectWithPrevious,
    pageProfile,
    AppLoading,
    loaModelFromApi,
    tryCatchFinally,
    FormValidate,
    Fragment, useEffect, useState 
  } = PageDependencies();
  const emptyGuid = guid.emptyGuid();
  const projectId = useParams().projectId;
  const nav = useNavigate();
  const postUrl = "api/Project/Accessor/UpdateDwelling";
  const getUrl = "api/Project/Accessor/GetDwelling";
  const [reloadAddress, setReloadAddress] = useState("");
  const [appStoreState, appStoreDispatch] = getAppStore(props);
  const defaultViewModel = {
    googleMapsApiKey:"",
    projectId: projectId,
    designVariationId: emptyGuid,
    developmentId: emptyGuid,
    address: {postcode:"", id:""},
    dwelllingId: emptyGuid,
    climateZones: []
  };
  const getTime = ()=>{
    const twentyMinutesLater = new Date();
    twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 1);
    return twentyMinutesLater;
  }
  const [disableButton, setDisableButton] = useState(true);
  const [initTime, setInitTime] = useState(getTime());
  const [dwelling, dispatchDwelling, bindingDwelling] = useReducerSmart(defaultViewModel);
  const setAddress = (address)=>{dispatchDwelling({...dwelling, address: address}); }
  const [loading, setLoading] = useState({ isLoading: false, text: "" });
  const [loadingForm, setLoadingForm] = useState({ isLoading: false, text: "" });
  const [init, setInit] = useState(false);
  const validateMethods = {
    climateZone:(value)=>{return Number(value) > 0 ? "" : "Must load a climate zone. Please try to find address in search bar or point the address on the map.";},
    exposureTypeId: (value)=>{return Number(value) > 0 ? "" : "Must load a exposure type"},

  };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), true);

  useEffect(() => {
      setLoadingForm({ isLoading: true, text: "Loading dwelling" });
      layoutManager.Layout_Headter_Footer_SideBar(props);
      loaModelFromApi.loadDefault(true, getUrl, dwelling, dispatchDwelling, setLoading, "Loading dwelling", appStoreDispatch, ()=>{
      setReloadAddress(guid.newGuid());
      setLoadingForm({ isLoading: false, text: "" });
      setInitTime(Date.now());
    }, setInit);
    getClimateZones();
  }, [projectId]);

  useEffect(()=>{
    const timer = setTimeout(() => {
      getClimateZones();
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  },[dwelling.address.postcode]);

  useEffect(()=>{
    const timer = setTimeout(() => {
      let nccClimateZone = "";
      if(dwelling.climateZone >= 1 && dwelling.climateZone.length > 0){
        try{
          nccClimateZone = dwelling.climateZones.filter(c=>{return c.value==dwelling.climateZone})[0].nccClimateZone;
          dispatchDwelling({...dwelling, nccClimateZone: nccClimateZone});
        }catch{
          nccClimateZone = dwelling.climateZones[0].nccClimateZone;
          dispatchDwelling({...dwelling, nccClimateZone: nccClimateZone, climateZone:dwelling.climateZones[0].value});
        }    
      }
      if(dwelling.climateZone == 0 && dwelling.climateZone.length > 0){ 
        try{
          nccClimateZone = dwelling.climateZones[0].nccClimateZone;
          dispatchDwelling({...dwelling, nccClimateZone: nccClimateZone, climateZone:dwelling.climateZones[0].value});
        }catch{}  
      }
      const selected = dwelling.climateZones.filter(c=>{return c.value==dwelling.climateZone}).length > 0;
      if(!selected && dwelling.climateZones.length>0){   
        nccClimateZone = dwelling.climateZones[0].nccClimateZone;
        dispatchDwelling({...dwelling, nccClimateZone: nccClimateZone, climateZone:dwelling.climateZones[0].value});
      }
      if(selected){
        const bcaClimateZone = dwelling.climateZones.filter(c=>{return c.value==dwelling.climateZone})[0].bcaClimateZone;
        dispatchDwelling({...dwelling, bcaZone:bcaClimateZone});
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  },[dwelling.climateZone, dwelling.climateZones]);

  const getClimateZones = async ()=>{
    setLoading({ isLoading: true, text: "Loading climate zones" });
    try{
      if(dwelling.address.postcode !== undefined){
        if(dwelling.address.postcode.length > 0){
          const climateZonesResponse = await getData.processGet(true,"api/Project/Accessor/GetClimateZones/" + dwelling.address.postcode);   
          if(!climateZonesResponse.state){
            toastPrint.printResponse(climateZonesResponse);
            setLoading({ isLoading: false, text: "" });
            return;
          }else{
            const data = climateZonesResponse.data;   
            const state = climateZonesResponse.locationState;
            dispatchDwelling((oldDwelling)=>{
              const newDwelling =  {...oldDwelling, climateZones: data};
              if(newDwelling.address.state != state){
                newDwelling.address.state = state
              }          
              if(data.length > 0){
                let isIn = false;
                for(let i = 0; i < data.length; i++){
                  if(data[i].value == newDwelling.climateZone){
                    isIn = true;
                  }
                }
                if(!isIn){
                  newDwelling.climateZone = data[0].value;
                }
              }
              return newDwelling;
            }, ()=>{
              setLoading({ isLoading: false, text: "" })
            });     
          }
        }
        setLoading({ isLoading: false, text: "" });
      }
    }catch(ex){
      toastPrint.printErrorMessage(ex.message);
      setLoading({ isLoading: false, text: "" });
    }
  }

  const handleSubmitButtonClick = async (e) => {
      e.preventDefault();
      await tryCatchFinally.asyncProcess(async ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(dwelling);
        if(!validateResult) return ;
        setLoadingForm({ isLoading: true, text: "Submmting form" });
        const postResponse = await getData.processPost(true, postUrl, dwelling);
        setLoadingForm({ isLoading: false, text: "" });
        toastPrint.printResponse(postResponse);
        if(!postResponse.state) return;
        nav("/ProjectAccessor/Client/" + projectId);
      }, setLoading);
  };

  const handleCancelButtonClick = async (e) => {
    nav("/ProjectAccessor/Detail/" + emptyGuid + "/" + projectId);
  };

  useEffectWithPrevious((pDwellingDependencies)=>{
    if(disableButton === false) return;
    if(pDwellingDependencies === undefined) return;
    if(pDwellingDependencies.length === 0) return;
    const pDwelling = pDwellingDependencies[0];
    if(init === true){
      if(Date.now() - initTime < 1200) return;
        if(Object.keys(pDwelling).length === Object.keys(dwelling).length){
          const isDiff = JSON.stringify(pDwelling) != JSON.stringify(dwelling);
          if(isDiff){
            setDisableButton(false);
          }
        }
    }
  }, [dwelling]);

  return (
    <Fragment>
      {layoutManager.Layout_GetDebugModel(props) && <p>{JSON.stringify(dwelling)}</p>}
      {loadingForm.isLoading && (<AppLoading active><div>{loadingForm.text}</div></AppLoading>)}
      <form autoComplete="off">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper">
              <div className="page-title-heading">
                <div className="page-title-icon">
                  <i className="pe-7s-map-marker icon-gradient bg-tempting-azure"></i>
                </div>
                <div>
                  Project profile - Dwelling
                  <div className="page-title-subheading">
                    {pageProfile.getPageSubTitle(appStoreState)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {init && 
      <>
        {/* Address */}
        <GoogleAddress 
          id="AddressDewelling"
          updateAddressCallBack = {(newAddress)=>{
              setAddress(newAddress);
              formValidate.checkValidateResult(newAddress);
          }}
          reload = {reloadAddress}
          setReload = {setReloadAddress}
          address = {dwelling.address}
          setAddress = {setAddress}
          googleMapsApiKey = {dwelling.googleMapsApiKey}
          validate={formValidate}
          isClass2={dwelling.isUnitNumberNeeded}
          debug = {layoutManager.Layout_GetDebugModel(props)}
          ></GoogleAddress>
          {/* Environment */}
          <div className="main-card mb-3 card">
            <div className="card-header">
              <button type="button" className="text-left m-0 p-0 btn btn-block">
                <h5 className="m-0 p-0 card-title">Environment</h5>
              </button>
            </div>
            <div className="collapse show">
              <div className="card-body">
                <div className="form-row row">
                  <div className="col-4">
                    <div className="position-relative form-group mb-1">
                      <label htmlFor="ClimateZone">Climate zone<TooltipTemplate text="When more than one climate zone exists for the postcode, the alternatives are listed in the preferred order" result={0} color="grey" /></label>
                      <BindedSelect
                        className="form-control"
                        id="ClimateZone"
                        name="ClimateZone"
                        binding={[
                          dwelling,
                          "climateZone",
                          bindingDwelling,
                        ]}
                        options={"climateZones"}
                        validate = {formValidate}
                      ></BindedSelect>
                    </div>
                    <div className="position-relative form-group">
                      <label
                        className="form-control pl-3"
                        style={{ border: "none" }}
                      >
                        <span id="ClimateZone_BCA_Label">
                          {dwelling.nccClimateZone}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                  <label htmlFor="ExposureTypeId">Exposure</label>
                      <BindedSelect
                        className="form-control"
                        id="ExposureTypeId"
                        name="ExposureTypeId"
                        validate={formValidate}
                        binding={[
                          dwelling,
                          "exposureTypeId",
                          bindingDwelling,                 
                        ]}
                        options={"exposureTypes"}
                      ></BindedSelect>
                  </div>
                  {/* {dwelling.simulationType === "Research" && //todo - need to be shown in research mode
                  <div className="col-4">
                    <div className="position-relative form-group">
                      <label htmlFor="GroundReflectance">
                        Ground reflectance
                      </label>
                      <BindedSelect
                        className="form-control"
                        id="GroundReflectance"
                        name="GroundReflectance"
                        binding={[
                          dwelling,
                          "groundReflectance",
                          bindingDwelling,
                        ]}
                        options={"groundReflectances"}
                      ></BindedSelect>
                    </div>
                  </div>} */}
                </div>
                <div className="form-row row">
                  <div className="col-4">
                      <div className="position-relative form-group">
                      <label htmlFor="floorEntrance">Floor Entrance</label>
                      <BindedInput
                          id="BCAZone " name="floorEntrance"
                          binding={[dwelling, "floorEntrance", bindingDwelling]}
                          className="form-control"
                      ></BindedInput>                                  
                      </div>
                  </div>
                </div>
                <div className="col-4">
                      <div className="position-relative form-group">
                      <label htmlFor="floorCount">Floor Count</label>
                      <BindedInput
                          id="BCAZone " name="floorCount"
                          binding={[dwelling, "floorCount", bindingDwelling]}
                          className="form-control"
                          type="number"
                          step="1"
                          min="0"
                          max="100"
                      ></BindedInput>                                  
                      </div>
                  </div>
                <div className="form-row row">
                  <div className="col-4">
                      <div className="position-relative form-group">
                      <label htmlFor="BCAZone">BCA Zone *</label>
                      <BindedInput
                          id="BCAZone " name="BCAZone"
                          binding={[dwelling, "bcaZone", bindingDwelling]}
                          className="form-control"
                          validate = {formValidate}
                          disabled
                      ></BindedInput>                                  
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main-card mb-3 card">
            <div className="card-header">
              <button type="button" className="text-left m-0 p-0 btn btn-block">
                <h5 className="m-0 p-0 card-title">Submit Form</h5>
              </button>
            </div>
            <div className="card-footer">
              <div className="ml-auto">
                <button
                  className="btn btn-primary btn-round"
                  style={{marginRight:"15px"}}
                  type="submit"
                  id="SubmitButton"
                  disabled = {disableButton}
                  onClick={(e) => {
                    handleSubmitButtonClick(e);
                  }}
                >
                  Submit
                </button>
                <button
                  className="btn btn-primary btn-round"
                  id="CancelButton"
                  onClick={(e) => {
                    handleCancelButtonClick(e);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
      </>
    }
        
        </div>
      </form>
    </Fragment>
  );
};
export default connectAppStore(Dwelling);
