import Constructions from "../../Portal/Accessor/Construction/Constructions";
import InternalExternalConstructionDetailV001 from "../../Portal/Accessor/Construction/V001/InternalExternalConstructionDetail";
import WindowConstructionDetailV001 from "../../Portal/Accessor/Construction/V001/WindowConstructionDetail";
import SkylightRoofWindowConstructionDetailV001 from "../../Portal/Accessor/Construction/V001/SkylightRoofWindowConstructionDetail";
const AccessorConstructionRouters = [
    {
      path: "ConstructionAccessor/Constructions/:designVariationVersionName/:developmentId/:projectId/:designVariationId",
      exact: true,
      requireAuth: true,
      element: <Constructions/>,
    },
    {
      path: "ConstructionAccessor/Detail/Skylight/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:constructionId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <SkylightRoofWindowConstructionDetailV001 typeName="Skylight"/>,
    },
    {
      path: "ConstructionAccessor/Detail/RoofWindow/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:constructionId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <SkylightRoofWindowConstructionDetailV001 typeName="RoofWindow"/>,
    },
    {
      path: "ConstructionAccessor/Detail/Window/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:constructionId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <WindowConstructionDetailV001 typeName="Window"/>,
    },
    {
      path: "ConstructionAccessor/LibraryDetail/Skylight/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:constructionId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <SkylightRoofWindowConstructionDetailV001 typeName="Skylight" isLibrary={true}/>,
    },
    {
      path: "ConstructionAccessor/LibraryDetail/RoofWindow/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:constructionId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <SkylightRoofWindowConstructionDetailV001 typeName="RoofWindow" isLibrary={true}/>,
    },
    {
      path: "ConstructionAccessor/LibraryDetail/Window/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:constructionId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <WindowConstructionDetailV001 typeName="Window" isLibrary={true}/>,
    },
    {
        path: "ConstructionAccessor/Detail/:typeName/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:constructionId/:redirectUrlHex",
        exact: true,
        requireAuth: true,
        element: <InternalExternalConstructionDetailV001/>,
    },
    {
      path: "ConstructionAccessor/LibraryDetail/:typeName/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:constructionId/:redirectUrlHex",
      exact: true,
      requireAuth: true,
      element: <InternalExternalConstructionDetailV001 isLibrary={true}/>,
    },
  ];
export default AccessorConstructionRouters;