
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import ElementDataTable from "../../Element/ElementDataTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator';
import { Tooltip } from 'react-tooltip';
import dataExtension from "../../../../Feature/DataTable/DataExtension";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

const ZoneDetailV001 = (props) =>{
    const { 
        layoutManager,
        process,
        guid,
        useReducerSmart,
        useFromDefaultValue,
        useParams,
        getData,
        BaseForm,
        BindedInput,
        BindedCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        toastPrint,
        tryCatchFinally,
        Fragment, 
        useEffect, 
        useState 
    } = PageDependencies();
    const emptyGuid = guid.emptyGuid();
    const [isRoofDataOpen, setIsRoofDataOpen] = useState(false);
    const [init, setInit] = useState(false);
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const zoneId = useParams().zoneId;
    const defaultViewModel = {
        zoneId:zoneId,
        id:zoneId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        selectedItemIds:[emptyGuid],
      };
    const [reloadForm, setReloadForm] = useState("");
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [viewModelRoofData, dispatchViewModelRoofData, bindingViewModelRoofData] = useReducerSmart({});
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Zone/Accessor/Update";
    const postRoofDataUrl = "api/Zone/Accessor/UpdateRoofData";
    const getUrl = "api/Zone/Accessor/Get";
    const debug = layoutManager.Layout_GetDebugModel(props);
    const zoneTypeNames = {
        "Bedroom": ["bedroom", "bed"],
        // "Corridor": ["corridor", "cor"],
        "Day time": ["hall", "passage", "entry", "daytime", "day time", "day", "dt"],
        "Garage conditioned": ["garage conditioned", "gc"],
        "Garage": ["garage", "gar"],
        "Living/Kitchen": ["living/kitchen", "kitchen", "kit"],
        "Living": ["family", "lounge", "living", "liv"],
        "Night time": ["nighttime", "night time", "night"],
        "Roof space": ["roofspace", "roof space", "rs"],
        "Sub-floor": ["sub-floor", "subfloor", "sf"],
        // "Shared Basement Carpark": ["shared", "carpark", "basement"],
        "Unconditioned": ["bathroom", "bath", "toilet", "wc", "laundry", "unconditioned", "uncond", "uncon", "uc"]
    };

    const validateMethods = {
        zoneName:(value)=>{return FormValidate.validateTextRequired(value, "Zone name");},
        zoneTypeId: (value)=>{return Number(value) != -999 ? "" : "Must select a zone type."},
        zoneCeilingHeight: (value)=>{return Number(value) > 0 ? "" : "Ceiling height must greater than 0."},
        roofSpaceSarkingTypeId: (value, newViewModel)=>{return newViewModel.zoneTypeId != 10? "" : Number(value) != -999 ? "" : "Must select a sarking type."},
        roofSpaceSurfaceTypeId: (value, newViewModel)=>{return newViewModel.zoneTypeId != 10? "" : Number(value) != -999 ? "" : "Must select a surface type."},
        roofSpaceOpennessTypeId: (value, newViewModel)=>{return newViewModel.zoneTypeId != 10? "" : Number(value) != -999 ? "" : "Must select a openness type."}
      };
    const validateMethodsRoofData = {
        length: (value)=>{return Number(value) > 0 ? "" : "Length must greater than 0."},
        width: (value)=>{return Number(value) > 0 ? "" : "Width must greater than 0."},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);
    const formValidateRoofData = new FormValidate(useState(validateMethodsRoofData), useState(), useState(), useState(), false);

    const guessZoneType = () => {
        if(viewModel.zoneName !== undefined){
            const zoneName = viewModel.zoneName.toLowerCase();
            for (let zoneTypeName in zoneTypeNames) {
                var zoneTypeNameVariations = zoneTypeNames[zoneTypeName];
                for (let i = 0; i < zoneTypeNameVariations.length; i++) {
                    if (zoneName.startsWith(zoneTypeNameVariations[i])) {
                        const opt = dataExtension.getSelectOptionByText(viewModel.zoneTypes, zoneTypeName);
                        const newViewModel = {...viewModel, zoneTypeId: opt.value};
                        dispatchViewModel(newViewModel); 
                        formValidate.checkValidateResult(newViewModel);                   
                        return;
                    }
                }
            }
        }
    }

    const calculateVolume = () => {
        if(viewModel.zoneFloorArea !== undefined && viewModel.zoneFloorArea!== "" && viewModel.zoneCeilingHeight !== undefined && viewModel.zoneCeilingHeight !== ""){
            const volume = (viewModel.zoneFloorArea * viewModel.zoneCeilingHeight / 1000).toFixed(2);
            dispatchViewModel({...viewModel, zoneVolume: volume});
        }
    };

    const calculateRoofVolume = () => {
        if(viewModel.zoneId == "00000000-0000-0000-0000-000000000000") {
            toastPrint.printErrorMessage("Create a roof, roof and external wall constructions before calculating volume.");
            return;
        };
        setIsRoofDataOpen(true);
    };

    const checkIsHabitableZone = () => {
        const habitableZones = [1,2,3,4,6,7,8];
        if (habitableZones.filter(i => i == viewModel.zoneTypeId).length > 0) {
            return true;
        }
        return false;
    }

    useFromDefaultValue("ZoneDefaultValue_f4c4706e-dfa7-4414-97b1-9f444ab40112", viewModel, dispatchViewModel, ["zoneFloorHeight", "zoneCeilingHeight", "level"], [0, 0, 0],
    (v)=>{
        //update cooike condition
        return v.zoneFloorHeight > 0 || v.zoneCeilingHeight > 0 || v.level > 0;
    },
    (v)=>{
        //update view model condition
        return v.zoneId == guid.emptyGuid() && v.zoneFloorHeight == 0 && v.zoneCeilingHeight == 0 && v.level == 0;
    });
    
    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading zone", appStoreDispatch, ()=>{}, setInit);
    },[reloadForm]);

    useEffect(()=>{   
        if(init){
            dispatchViewModel({...viewModel, zoneId: zoneId, id: zoneId}, ()=>{
                setReloadForm(guid.newGuid());
            });
        }
    },[zoneId]);

    useEffect(()=>{   
        if(init) {
            return process.setProcessTimeout(()=>{
                guessZoneType();
            }, 1000);
        } 
    },[viewModel.zoneName]);

    useEffect(()=>{   
        if(init) {
            try {
                const newModel = {...viewModel};
                const type = parseInt(viewModel.zoneTypeId);
                if(type < 0 || type == 5 || type == 7|| type == 10 ||  type == 11||  type == 12){
                    newModel.zoneIsHeated = false;
                    newModel.zoneIsCooled = false;
                }else{
                    newModel.zoneIsHeated = true;
                    newModel.zoneIsCooled = true;
                }
                dispatchViewModel(newModel);
            }catch{

            }
        } 
    },[viewModel.zoneTypeId]);

    useEffect(()=>{
        if(viewModel.roofData !== undefined){
            dispatchViewModelRoofData(viewModel.roofData);
        }
    },[viewModel.roofData])

    const handleSave = async (e) =>{
        e.preventDefault();
        setIsRoofDataOpen(false,async ()=>{
            await tryCatchFinally.asyncProcess(async ()=>{
                const validateResult = formValidateRoofData.checkValidateResult(viewModelRoofData);
                formValidateRoofData.setValidateVisibility(true);
                if(validateResult === true){
                    setLoading({isLoading: true, text: "Calculating volume..."});
                    const response = await getData.processPost(true, postRoofDataUrl, viewModelRoofData);
                    if(response.state === false){
                        toastPrint.printResponse(response);
                        setLoading({isLoading:false, text:""});
                    }else{
                        setReloadForm(guid.newGuid());
                    }
                }
            }, setLoading);
        });
    }

    const insert = () => {
        dispatchViewModelRoofData({...viewModelRoofData, 
            azimuth: viewModelRoofData.ventilationAzimuth,
            length: viewModelRoofData.ventilationLength,
            width: viewModelRoofData.ventilationWidth,
        })
    }

    return(<Fragment>
        {debug && <p>Zone: {JSON.stringify(viewModel)}</p>}
        {debug && <p>Roof Data: {JSON.stringify(viewModel.roofData)}</p>} 
        {viewModel.zoneTypeId == 10 &&
            <span className="d-inline-block mb-4 me-4">
                <Modal isOpen={isRoofDataOpen} className={props.className} centered={true}>          
                    <ModalHeader>Roof data</ModalHeader>                    
                    <ModalBody>                   
                    <div className="row">
                            <div className="col-12">
                                <div className="position-relative form-group">
                                    <h5 style={{color:"red"}}>Warning</h5>
                                    <h6 style={{color:"red"}}>Apply new roof type will delete all extsing roofs and external walls in the roof.</h6>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="position-relative form-group">
                                    <label htmlFor="RoofDataLength">Length *</label>           
                                    <BindedInput className="form-control valid" id="=RoofDataLength" name="RoofDataLength" type="number" validate={formValidateRoofData} binding={[viewModelRoofData, "length", bindingViewModelRoofData]}></BindedInput>       
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="position-relative form-group">
                                    <label htmlFor="RoofDataWidth">Width *</label>           
                                    <BindedInput className="form-control valid" id="=RoofDataWidth" name="RoofDataWidth" type="number" validate={formValidateRoofData} binding={[viewModelRoofData, "width", bindingViewModelRoofData]}></BindedInput>       
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="position-relative form-group">
                                    <label htmlFor="RoofDataPitch">Pitch *</label>           
                                    <BindedInput className="form-control valid" id="=RoofDataPitch" name="RoofDataPitch" type="number" validate={formValidateRoofData} binding={[viewModelRoofData, "pitch", bindingViewModelRoofData]}></BindedInput>       
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="position-relative form-group">
                                    <label htmlFor="RoofDataAzimuth">Azimuth *</label>           
                                    <BindedInput className="form-control valid" id="=RoofDataAzimuth" name="RoofDataAzimuth" type="number" validate={formValidateRoofData} binding={[viewModelRoofData, "azimuth", bindingViewModelRoofData]}></BindedInput>       
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="position-relative form-group">
                                    <label htmlFor="RoofDataSimpleRoofTypeId">Simple Roof Type *</label>           
                                    <BindedSelect className="form-control" id="RoofDataSimpleRoofTypeId" name="RoofDataSimpleRoofTypeId" type="text"  binding={[viewModelRoofData, "simpleRoofTypeId", bindingViewModelRoofData]} options="simpleRoofTypes"></BindedSelect>                
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="position-relative form-group">
                                    <Image style={{width: "100%", height: "100%"}} hidden={viewModelRoofData.simpleRoofTypeId != 1} src="/imgRoofHip.bmp"></Image>
                                    <Image style={{width: "100%", height: "100%"}} hidden={viewModelRoofData.simpleRoofTypeId != 2} src="/imgRoofGable.bmp"></Image>
                                    <Image style={{width: "100%", height: "100%"}} hidden={viewModelRoofData.simpleRoofTypeId != 3} src="/imgRoofSingleSlope.bmp"></Image>
                                </div>                          
                            </div>
                            <div className="form-row row">
                                <div className="position-relative form-group">
                                    <Button label="Insert Default Values" icon="pi pi-plus-circle" onClick={insert} outlined tooltip="Insert default value from ventilation"/>
                                </div>
                            </div>
                        </div>                        
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={()=>{setIsRoofDataOpen(false);}} type='button' className='btn btn-secondary' data-dismiss='modal'>Close</button>
                        <button onClick={(e)=>handleSave(e)} type='button' className='btn btn-primary'>Apply</button>
                    </ModalFooter>
                </Modal>
            </span>
        }
        <Tooltip style={{zIndex:999} } id="ZoneCalculateorVolume" />
        <BaseForm
           id="ZoneFrom"
           icon="pe-7s-safe"
           loading={loading}
           init = {init}
           setReload={setReloadForm}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: "/ZoneAccessor/Zones/" + viewModel.designVariationVersionName + "/" + viewModel.developmentId + "/" + viewModel.projectId + "/" + viewModel.designVariationId }}
           submitButtonAndStay = {{text:"Save", postUrl: postUrl, redirectUrl: "/ZoneAccessor/Detail/" + viewModel.designVariationVersionName + "/" + viewModel.projectId + "/" + viewModel.designVariationId, redirectParams: (submitResponse)=>{ return submitResponse.zoneId;}}}
           cancelButton={{ redirectUrl: "/ZoneAccessor/Zones/" + viewModel.designVariationVersionName + "/" + viewModel.developmentId + "/" + viewModel.projectId + "/" + viewModel.designVariationId }}
           data={viewModel}
           validate ={formValidate}
           title = "Zone detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row">
                <div className="col-8">
                    <div className="form-row row">
                        <div className="col-12">
                            <div className="form-row row">
                                <label htmlFor="ZoneName">Name *</label>
                                <BindedInput className="form-control" id="ZoneName" name="ZoneName" validate ={formValidate} binding={[viewModel, "zoneName", bindingViewModel, dispatchViewModel]}></BindedInput>
                            </div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="ZoneTypeId">Type *</label>
                            <BindedSelect
                            className="form-control"
                            id="ZoneTypeId"
                            name="ZoneTypeId"
                            binding={[viewModel, "zoneTypeId", bindingViewModel]}
                            options={"zoneTypes"}
                            validate ={formValidate}
                        ></BindedSelect>
                        </div>
                        {viewModel.zoneTypeId != 10 &&
                            <div className="col-6">
                                <label htmlFor="ZoneVolume" >Volume (m<sup>3</sup>) * <a onClick={calculateVolume} data-tooltip-id="ZoneCalculateorVolume" data-placement="bottom" data-tooltip-html="This automatic volume calculation is for reference only.<br /> It does not consider conditions that may be specific to your project including multiple wall heights, shared floors/ceilings.<br /> By clicking on this button, you agree to accept these conditions."><FontAwesomeIcon style={{color:"blue"}} icon={faCalculator} /></a></label>
                                <BindedInput id="Volume" name="Volume" type="number" className="form-control" binding={[viewModel, "zoneVolume", bindingViewModel]}></BindedInput> 
                            </div>
                        } 
                        {viewModel.zoneTypeId == 10 &&
                            <div className="col-6">
                                <label htmlFor="ZoneVolume" >Volume (m<sup>3</sup>) * <a onClick={calculateRoofVolume}  data-tooltip-id="ZoneCalculateorVolume" data-placement="bottom" data-tooltip-html="Create a roof space, roof and external wall constructions before clicking the icon for calculating volume. <br />This automatic volume calculation is for reference only.<br /> It does not consider conditions that may be specific to your project including multiple wall heights, shared floors/ceilings.<br /> By clicking on this button, you agree to accept these conditions."><i className="pi pi-home" style={{color:"blue"}} icon={faCalculator} /></a></label>
                                <BindedInput id="Volume" name="Volume" type="number" className="form-control" binding={[viewModel, "zoneVolume", bindingViewModel]}></BindedInput> 
                            </div>
                        }                        
                    </div>
                    <div className="form-row row">
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="ZoneFloorHeight">Floor height (mm) *</label>
                                <BindedInput id="ZoneFloorHeight" name="ZoneFloorHeight" type="number" className="form-control" binding={[viewModel, "zoneFloorHeight", bindingViewModel]}></BindedInput>                      
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="ZoneCeilingHeight">Maximum ceiling height above floor (mm) *</label>
                                <BindedInput id="ZoneCeilingHeight" name="ZoneCeilingHeight" type="number" validate ={formValidate} className="form-control" binding={[viewModel, "zoneCeilingHeight", bindingViewModel]}></BindedInput>   
                            </div>
                        </div>
                        <div className="col-6" style={{marginTop:"15px"}}>
                            <div className="position-relative form-group">
                                <label htmlFor="Level">Floor number *</label>
                                <BindedInput id="Level" name="Level" type="number" validate ={formValidate} className="form-control" binding={[viewModel, "level", bindingViewModel, 0]}></BindedInput>   
                            </div>
                        </div>
                        {viewModel.simulationType === "Existing Home" &&
                         <div className="col-6" style={{marginTop:"15px"}}>
                            <div className="position-relative form-group">
                                <label htmlFor="NumberOfHalogen">Number Of Halogen (Existing Home)</label>
                                <BindedInput id="NumberOfHalogen" name="NumberOfHalogen" min="0" step="1" type="number" validate ={formValidate} className="form-control" binding={[viewModel, "numberOfHalogen", bindingViewModel]}></BindedInput>   
                            </div>
                        </div>
                        }                   
                    </div>
                </div>
                <div className="col-1">
                    <div className="separator"></div>
                </div>
                <div className="col-3">
                    <div className="position-relative row form-group">
                    <label data-tooltip-id="ZoneCalculateorVolume" data-tooltip-content="Floor area is calculated automatically" htmlFor="ZoneFloorArea" className="col-6 col-form-label">Floor area (m<sup>2</sup>)</label>
                        <div className="col-form-label">
                            <BindedInput id="ZoneFloorArea" name="ZoneFloorArea" type="hidden"  binding={[viewModel, "zoneFloorArea", bindingViewModel]}></BindedInput>
                            {viewModel.zoneFloorArea}
                        </div>
                    </div>
                    <div className="position-relative row form-group">
                    <div className="col-12">
                        <div className="custom-checkbox custom-control">
                            <BindedCheckBox
                                id="ZoneIsHeated" 
                                name="ZoneIsHeated"
                                className="custom-control-input"
                                disabled
                                binding={[viewModel, "zoneIsHeated", bindingViewModel]}
                            ></BindedCheckBox>
                            <label style={{marginLeft:"5px"}} htmlFor="ZoneIsHeated" className="custom-control-label">Heated</label>                          
                        </div>
                    </div>
                    </div>
                    <div className="position-relative row form-group">
                    <div className="col-12">
                        <div className="custom-checkbox custom-control">
                            <BindedCheckBox
                                id="ZoneIsCooled" 
                                name="ZoneIsCooled"
                                className="custom-control-input"
                                disabled
                                binding={[viewModel, "zoneIsCooled", bindingViewModel]}
                            ></BindedCheckBox>
                            <label style={{marginLeft:"5px"}} htmlFor="ZoneIsCooled" className="custom-control-label">Cooled</label>                   
                        </div>
                    </div>
                    </div>
                    <div className="position-relative row form-group">
                        <div className="col-12">
                            <div className="custom-checkbox custom-control">
                                <BindedCheckBox
                                    disabled={checkIsHabitableZone()===true}
                                    id="ZoneIsReflective" 
                                    name="ZoneIsReflective"
                                    className="custom-control-input"
                                    binding={[viewModel, "zoneIsReflective", bindingViewModel]}
                                ></BindedCheckBox>
                                <label style={{marginLeft:"5px"}} htmlFor="ZoneIsReflective" className="custom-control-label">Reflective</label>                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
           {( viewModel.zoneTypeId == 11) &&  <div className="form-row">
                <div className="col-6">
                    <div className="form-row row">
                        <div className="position-relative form-group">
                            <label htmlFor="SubfloorZoneInfiltration_OpennessType">Openness</label>
                            <BindedSelect
                                className="form-control"
                                id="SubfloorZoneInfiltration_OpennessType"
                                name="SubfloorZoneInfiltration_OpennessType"
                                binding={[viewModel, "subFloorOpennessTypeId", bindingViewModel]}
                                options={"suFloorOpennesses"}
                                style={{marginBottom:"15px"}}
                            ></BindedSelect>
                        </div>
                        <div className="custom-checkbox custom-control">
                            <BindedCheckBox
                                id="SubfloorZoneInfiltration_HasWallCavity" 
                                name="SubfloorZoneInfiltration.HasWallCavity"
                                className="custom-control-input"
                                binding={[viewModel, "hasWallCavity", bindingViewModel]}
                            ></BindedCheckBox>
                            <label style={{marginLeft:"5px"}} htmlFor="SubfloorZoneInfiltration_HasWallCavity" className="custom-control-label">Is there a wall cavity allowing unobstructed air flow between the sub-floor and roof-space or outdoors?</label>
                        </div>
                        <div className="position-relative form-group">
                            <label htmlFor="SubfloorZoneInfiltration_VentilationOpeningsTotalArea">Area of sub-floor ventilation openings</label>
                            <BindedSelect
                                className="form-control"
                                id="SubfloorZoneInfiltration_VentilationOpeningsTotalArea"
                                name="SubfloorZoneInfiltration.VentilationOpeningsTotalArea"
                                binding={[viewModel, "ventilationOpeningsTotalArea", bindingViewModel]}
                                options={"ventilationOpeningsTotalAreas"}
                                style={{marginBottom:"15px"}}
                            ></BindedSelect>
                     </div>
                    </div>
                </div>
            </div>}
             {( viewModel.zoneTypeId == 10) &&  <div className="form-row">
                <div className="col-6">
                    <div className="form-row row">
                        <div className="position-relative form-group">
                            <label htmlFor="RoofspaceZoneInfiltration_SarkingType">Sarking</label>
                            <BindedSelect
                                className="form-control"
                                id="RoofspaceZoneInfiltration_SarkingType"
                                name="RoofspaceZoneInfiltration.SarkingType"
                                binding={[viewModel, "roofSpaceSarkingTypeId", bindingViewModel]}
                                options={"roofSpaceSarkings"}
                                style={{marginBottom:"15px"}}
                                validate = {formValidate}
                            ></BindedSelect>
                        </div>
                        <div className="position-relative form-group">
                            <label htmlFor="RoofspaceZoneInfiltration_SurfaceType">Roof Surface</label>
                            <BindedSelect
                                className="form-control"
                                id="RoofspaceZoneInfiltration_SurfaceType"
                                name="RoofspaceZoneInfiltration.SurfaceType"
                                binding={[viewModel, "roofSpaceSurfaceTypeId", bindingViewModel]}
                                options={"roofSpaceSurfaceTypes"}
                                style={{marginBottom:"15px"}}
                                validate = {formValidate}
                            ></BindedSelect>
                        </div>
                        <div className="position-relative form-group">
                            <label htmlFor="RoofspaceZoneInfiltration_OpennessType">Openness</label>
                            <BindedSelect
                                className="form-control"
                                id="RoofspaceZoneInfiltration_OpennessType"
                                name="RoofspaceZoneInfiltration.OpennessType"
                                binding={[viewModel, "roofSpaceOpennessTypeId", bindingViewModel]}
                                options={"roofSpaceOpennesses"}
                                style={{marginBottom:"15px"}}
                                validate = {formValidate}
                            ></BindedSelect>
                     </div>
                    </div>
                </div>
            </div>}
            {viewModel.simulationType === "Existing Home" &&
                <>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Existing Infiltration</h5>
                        </button>
                    </div>
                    <div className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            
                    <div className="row">
                        <div className="col-6">
                            <div className="main-card mb-3 card">
                                <div className="card-header">
                                    <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">Infiltration Fan & Wall Vent</h5>
                                    </button>
                                </div>
                                <div className="collapse show">
                                    <div className="card-body">
                                        <div className="separator"></div>
                                        <div className="form-row row">
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="FanUnsealed">Exhaust Fan Unsealed (to outside)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "fanUnsealed", bindingViewModel]}
                                                    id="FanUnsealed"
                                                    name="FanUnsealed"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="FanSealed">Exhaust Fan Sealed (to outside)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "fanSealed", bindingViewModel]}
                                                    id="FanSealed"
                                                    name="FanSealed"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="Vent">Vent (to attic)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "vent", bindingViewModel]}
                                                    id="Vent"
                                                    name="Vent"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="col-6">
                            <div className="main-card mb-3 card">
                                <div className="card-header">
                                    <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">Ceiling</h5>
                                    </button>
                                </div>
                                <div className="collapse show">
                                    <div className="card-body">
                                        <div className="separator"></div>
                                        <div className="form-row row">
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="Rose">Rose</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "rose", bindingViewModel]}
                                                    id="Rose"
                                                    name="Rose"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="HatchUnsealed">Hatch Unsealed</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "hatchUnsealed", bindingViewModel]}
                                                    id="HatchUnsealed"
                                                    name="HatchUnsealed"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="HatchWeatherStripped">Hatch Weather-stripped</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "hatchWeatherStripped", bindingViewModel]}
                                                    id="HatchWeatherStripped"
                                                    name="HatchWeatherStripped"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="main-card mb-3 card">
                                <div className="card-header">
                                    <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">Internal Cavity Sliding Door</h5>
                                    </button>
                                </div>
                                <div className="collapse show">
                                    <div className="card-body">
                                        <div className="separator"></div>
                                        <div className="form-row row">
                                            <div style={{marginTop:"15px"}} className="col-6">
                                                <div className="position-relative form-group">
                                                <label htmlFor="NonWeatherStrippedGaps">Enter number of unsealed</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "nonWeatherStrippedGaps", bindingViewModel]}
                                                    id="NonWeatherStrippedGaps"
                                                    name="NonWeatherStrippedGaps"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-6">
                                                <div className="position-relative form-group">
                                                <label htmlFor="WeatherStrippedGaps">Enter number of sealed</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "weatherStrippedGaps", bindingViewModel]}
                                                    id="WeatherStrippedGaps"
                                                    name="WeatherStrippedGaps"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>                            
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="col-6">
                            <div className="main-card mb-3 card">
                                <div className="card-header">
                                    <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">Downlight</h5>
                                    </button>
                                </div>
                                <div className="collapse show">
                                    <div className="card-body">
                                        <div className="separator"></div>
                                        <div className="form-row row">
                                            <div style={{marginTop:"15px"}} className="col-6">
                                                <div className="position-relative form-group">
                                                <label htmlFor="DownlightGimballed">Gimballed</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "downlightGimballed", bindingViewModel]}
                                                    id="DownlightGimballed"
                                                    name="DownlightGimballed"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-6">
                                                <div className="position-relative form-group">
                                                <label htmlFor="DownlightThinRingOpening">Thin Ring Opening</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "downlightThinRingOpening", bindingViewModel]}
                                                    id="DownlightThinRingOpening"
                                                    name="DownlightThinRingOpening"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                                </div>
                                            </div>                            
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="main-card mb-3 card">
                        <div className="card-header">
                            <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                <h5 className="m-0 p-0 card-title">Gaps in elements</h5>
                            </button>
                        </div>
                        <div className="collapse show">
                            <div className="card-body">
                                <div className="separator"></div>
                                <div className="form-row row">
                                    <div style={{marginTop:"15px"}} className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExistingInfiltrationFloorBoardsTypeId">Floorboard Gaps</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ExistingInfiltrationFloorBoardsTypeId"
                                                name="ExistingInfiltrationFloorBoardsTypeId"
                                                binding={[viewModel, "existingInfiltrationFloorBoardsTypeId", bindingViewModel]}
                                                options={"existingInfiltrationFloorBoardsTypes"}
                                                style={{marginBottom:"15px"}}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                    <div style={{marginTop:"15px"}} className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExistingInfiltrationSkirtingTypeId">Skirting Board Gaps</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ExistingInfiltrationSkirtingTypeId"
                                                name="ExistingInfiltrationSkirtingTypeId"
                                                binding={[viewModel, "existingInfiltrationSkirtingTypeId", bindingViewModel]}
                                                options={"existingInfiltrationSkirtingTypes"}
                                                style={{marginBottom:"15px"}}
                                            ></BindedSelect>
                                        </div>
                                    </div>   
                                    <div style={{marginTop:"15px"}} className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExistingInfiltrationOtherSignificantGapsTypeId">Other Significant Gaps</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ExistingInfiltrationOtherSignificantGapsTypeId"
                                                name="ExistingInfiltrationOtherSignificantGapsTypeId"
                                                binding={[viewModel, "existingInfiltrationOtherSignificantGapsTypeId", bindingViewModel]}
                                                options={"existingInfiltrationOtherSignificantGapsTypes"}
                                                style={{marginBottom:"15px"}}
                                            ></BindedSelect>
                                        </div>
                                    </div> 
                                    <div style={{marginTop:"15px"}} className="col-3">
                                        <div className="position-relative form-group">
                                        <label htmlFor="OutletNumberInZone">Outlet Number in zone</label>
                                        <BindedInput
                                            className="form-control"
                                            binding={[viewModel, "outletNumberInZone", bindingViewModel]}
                                            id="OutletNumberInZone"
                                            name="OutletNumberInZone"
                                            type="number"
                                            min = "0"
                                            step = "1"
                                            validate = {formValidate}
                                        ></BindedInput>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                        </div>
                    </div> 
                        </div>
                    </div>
                </div>
                </>
            }
           
        </BaseForm>
        {zoneId != '00000000-0000-0000-0000-000000000000' && init &&
            <div className="app-main__inner">
            <div id="ElementsAccordion">
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#ElementsCollapsibleDiv" aria-expanded="true" aria-controls="ElementsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Zone elements</h5>
                        </button>
                    </div>
                    <div data-parent="#ElementsAccordion" id="ElementsCollapsibleDiv" className="collapse show">
                        <div className="card-body">
                        <ElementDataTable
                            id = "Zone_f12db115-fd4e-4b2f-83cf-775ae79d0622"
                            projectId = {viewModel.projectId}
                            designVariationId = {viewModel.designVariationId}
                            developmentId = {viewModel.developmentId}
                            zoneId = {viewModel.zoneId}
                            designVariationVersionName = {viewModel.designVariationVersionName}
                            isRetrievalInZoneOnly = {true}
                            debug = {layoutManager.Layout_GetDebugModel(props)}
                        ></ElementDataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        
    </Fragment>);
}
export default connectAppStore(ZoneDetailV001);