import { Fragment } from "react";
import PageDependencies from "../../Feature/PageDependencies";

const UserDataTable = (props)=>{
    const { 
        guid,
        useState,
        useNavigate,
        dataExtension,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const debug = props.debug === undefined ? false : props.debug;
    const emptyGuid = guid.emptyGuid();
    const nav = useNavigate();
    const data = props.data;
    const setData = props.setData;
    const tableId = "Users_3af1cd4b-3350-47c9-bc22-6f313e7722dc";
    const tableReload = props.tableReload;
    const extraPostModel = props.extraPostModel;
    const url = props.url;
    const [selectedIds, setSelectedIds] = useState([]);
    const columns =[
      { name: "userName", text: "User Name", width: "17%" },
      { name: "firstName", text: "First Name", width: "17%" },
      { name: "lastName", text: "Last Name", width: "17%" },
      { name: "organization", text: "Organization", width: "17%" },
      { name: "phone", text: "Phone", width: "17%" },
      { name: "userType", text: "User Type", width: "15%" }
    ];

    const [modal, setModal] = useState({
      isOpen: false,
      title: "",
      message: "",
      comfirmButtonStyle: "",
      comfirmButtonText: "Comfirm",
      handleComfirm: () => {},
    });

    const contextMenu = {
      items: {
        userCredit: {
            name: "User Credit",
            icon: "",
            defaultAction: true,
            url: "/Admin/Credits/" + dataExtension.getSelectedRowDataIds(data)[0],
            isNavOption: true,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length !== 1;}
          },
        new: {
          name: "New User",
          icon: "add",
          defaultAction: true,
          url: "/Admin/Users/Detail/" + emptyGuid,
          isNavOption: true
        },
        edit: {
          name: "Edit",
          icon: "edit",
          defaultAction: true,
          url: "/Admin/Users/Detail/",
          isNavOption: true,
          disabled: function () {
            return dataExtension.getSelectedRowDataIds(data).length !== 1;}
        },
      },callBack: (
        contextMenuCallBackKey,
        contextMenuCallBackElement,
        contextMenuCallBackTableRoadData,
        localtion
      ) => {
      },
    };

    const actionOnDoubleClickRow = (model) =>{
      nav("/Admin/Users/Detail/" + model.id);
    }

    return(<Fragment>
      {debug && <p>{JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
      <ModalDatatableItem
        modal={modal}
        setModal={setModal}
      ></ModalDatatableItem>
      <ServerSideDataTable
        allowSelect
        debug ={debug}
        id={tableId}
        location="AdminUsers"
        data={data}
        setData={setData}
        reload={tableReload}
        columns={columns}
        actionOnDoubleClickRow = {actionOnDoubleClickRow}
        dataSource={{ isAuth: true, url: url }}
        contextMenu={contextMenu}
        extraPostModel={extraPostModel}
        setSelectedItemIds = {setSelectedIds}
        ></ServerSideDataTable>
    </Fragment>);
}
export default UserDataTable;