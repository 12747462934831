import { connectAppStore } from "../../../../../Store/AppStore";
import PageDependencies from "../../../../../Feature/PageDependencies";

const HotWaterModule = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        tryCatchFinally,
        layoutManager,
        getData,
        guid,
        useReducerSmart,
        toastPrint,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
        dataExtension,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const emptyGuid = guid.emptyGuid();
    const [init, setInit] = useState(false);
    const defaultViewModel = {
        ...defaultParams,
    };
    const [solarThermalResult, setSolarThermalResult] = useState({systemSize:"", stc: ""});
    const [heatPumResult, setheatPumpResult] = useState({systemSize:"", stc: ""});
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/ExistingHome/HotWaterModule/Accessor/Get";
    const [reloadForm, setReloadForm] = useState("");
    const validateMethods = {
        systemTypeId:(value)=>{ return value === emptyGuid ? "Must select a hot water type." : "";},
        pvDiverterTankVolume:(value, newViewModel)=>{ 
            const types = newViewModel.systemTypes === undefined ? [] : newViewModel.systemTypes;
            const type = dataExtension.getSelectOptionByValue(types, newViewModel.systemTypeId);
            if(type !== undefined){
                if(type.text === "PVD (PV Diverter)"){
                    return FormValidate.validateNumberGreaterThan0(value, "Tank Volume");
                }
            }
            return "";
        }
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Hot Water", appStoreDispatch, undefined, setInit);
    },[reloadForm]);

    useEffect(()=>{       
        if(viewModel.solarThermalOrGasSTCMeasureTypeId == 1){     
           const newViewModel = {...viewModel};   
           newViewModel.tubeSystemInputTypeId = 1;
           newViewModel.tubeNumber = 0;
           dispatchViewModel(newViewModel, ()=>{
                calculateSolarThermal();
           });
        } 
    },[viewModel.solarThermalOrGasSTCMeasureTypeId]);
   
    useEffect(()=>{
        const newViewModel = {...viewModel};
        if(viewModel.tubeSystemInputTypeId == 1){
            newViewModel.tubeNumber = 0;
        }else{
            newViewModel.collectorArea = 0;
        }
        dispatchViewModel(newViewModel, ()=>{
            calculateSolarThermal();
        });
    },[viewModel.tubeSystemInputTypeId])

    useEffect(()=>{
        calculateSolarThermal();
    }, [viewModel.collectorArea, viewModel.tubeNumber]);

    useEffect(()=>{
        if(viewModel.solarThermalOrGasSTCLevelTypeId == 1){
            const newViewModel = {...viewModel};
            newViewModel.collectorArea = 0;
            newViewModel.tubeNumber = 0;
            dispatchViewModel(newViewModel);
        }
    }, [viewModel.solarThermalOrGasSTCLevelTypeId]);

    useEffect(()=>{
        calculatetHeatPump();
    },[viewModel.heatPumpTankSize]);

    useEffect(()=>{
        if(viewModel.heatPumpSTCLevelTypeId == 1){
            const newViewModel = {...viewModel};
            newViewModel.heatPumpTankSize = 0;
            dispatchViewModel(newViewModel);
        }
    },[viewModel.heatPumpSTCLevelTypeId]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/ExistingHome/HotWaterModule/Accessor/UpdateHotWater";
        setLoading({ isLoading: true, text: "Updating Hot Water" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            setReloadForm(guid.newGuid());
        }, setLoading);
    }

    const calculateSolarThermal = () => {
        const result = {systemSize:"", stc: ""};
        if(viewModel.solarThermalOrGasSTCLevelTypeId == 2){
            if(viewModel.tubeSystemInputTypeId == 1){
                //calculate by collector Area
                const collectorArea = parseFloat(viewModel.collectorArea);
                if(collectorArea > 0){
                    const rangesArea = viewModel.stcSolarSystemReference.rangesArea;
                    if(collectorArea < rangesArea[0]){
                        result.systemSize = "Small";
                    }
                    if(collectorArea >= rangesArea[0] && collectorArea <= rangesArea[1]){
                        result.systemSize = "Medium";
                    }
                    if(collectorArea > rangesArea[1]){
                        result.systemSize = "Large";
                    }
                }
            }else{
                //calculate by tube number
                const tubeNumber = parseInt(viewModel.tubeNumber);
                if(tubeNumber > 0){
                    const rangesTubeNumber = viewModel.stcSolarSystemReference.rangesTubeNumber;
                    if(tubeNumber < rangesTubeNumber[0]){
                        result.systemSize = "Small";
                    }
                    if(tubeNumber >= rangesTubeNumber[0] && tubeNumber <= rangesTubeNumber[1]){
                        result.systemSize = "Medium";
                    }
                    if(tubeNumber > rangesTubeNumber[1]){
                        result.systemSize = "Large";
                    }
                }
            }
            if(result.systemSize.length > 0){
                const stcSystemReferenceCells = viewModel.stcSolarSystemReference.stcSystemReferenceCells;
                const zoneNumber = viewModel.zoneIndexOther;
                const cells = stcSystemReferenceCells.filter(c=>c.zoneNumber == zoneNumber && c.size == result.systemSize);
                if(cells.length > 0){
                    const cell = cells[0];
                    result.stc = cell.stc;
                }
            }
        }
        setSolarThermalResult(result);
    }

    const calculatetHeatPump = () => {
        const result = {systemSize:"", stc: ""};
        if(viewModel.heatPumpSTCLevelTypeId == 2){
             //calculate by collector Area
             const tankSize = parseInt(viewModel.heatPumpTankSize);
       
             if(tankSize > 0){
                 const rangesTank = viewModel.stcHeatPumpSystemReference.rangesTank;
                 if(tankSize < rangesTank[0]){
                     result.systemSize = "Small";
                 }else{
                    result.systemSize = "Medium";
                 }
             }
            if(result.systemSize.length > 0){
                const stcSystemReferenceCells = viewModel.stcHeatPumpSystemReference.stcSystemReferenceCells;
                const zoneNumber = viewModel.zoneIndexHeatPump;
                const cells = stcSystemReferenceCells.filter(c=>c.zoneNumber == zoneNumber && c.size == result.systemSize);
                if(cells.length > 0){
                    const cell = cells[0];
                    result.stc = cell.stc;
                }
            }
        }
        setheatPumpResult(result);
    }
    
    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-coffee icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Hot Water
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                    <>
                       <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Annual Energy Consumption</h5>
                        </button>
                    </div>
                    <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-3">
                                    <div className="position-relative form-group">
                                        <label>Electricity (kWh/yr): {parseFloat(viewModel.annualElectricityEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="position-relative form-group">
                                        <label>Gas (MJ/yr): {parseFloat(viewModel.annualGasEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="position-relative form-group">
                                        <label>LPG (MJ/yr): {parseFloat(viewModel.annualLpgEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="position-relative form-group">
                                        <label>Other (MJ/yr): {parseFloat(viewModel.annualOtherEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Hot Water Configuration</h5>
                        </button>
                    </div>              
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            {viewModel.simulationType === "Existing Home" &&
                            
                                <>
                                 <div className="form-row row">
                                        <div className="col-12">
                                            <div className="main-card mb-3 card">
                                                <div className="card-header">
                                                    <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                                                        <h5 className="m-0 p-0 card-title">Solar thermal water heater with ELECTRIC or gas boost select tool</h5>
                                                    </button>
                                                </div>
                                                <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                                                    <div className="card-body">
                                                        <div className="separator"></div>
                                                        <div className="form-row row">
                                                            <div className="col-3">
                                                                <div className="position-relative form-group">
                                                                    <label htmlFor="SolarThermalOrGasSTCLevelTypeId">STC level defined by</label>
                                                                    <BindedSelect
                                                                        className="form-control"
                                                                        id="SolarThermalOrGasSTCLevelTypeId"
                                                                        name="SolarThermalOrGasSTCLevelTypeId"
                                                                        options="solarThermalOrGasSTCLevelTypes"
                                                                        validate={formValidate}
                                                                        binding={[viewModel, "solarThermalOrGasSTCLevelTypeId", bindingViewModel]}
                                                                    ></BindedSelect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(viewModel.solarThermalOrGasSTCLevelTypeId == 2) &&
                                                            <>
                                                                <div className="form-row row">
                                                                    <div className="col-3">
                                                                        <div className="position-relative form-group">
                                                                            <label htmlFor="SolarThermalOrGasSTCMeasureTypeId">STC messurement type</label>
                                                                            <BindedSelect
                                                                                className="form-control"
                                                                                id="SolarThermalOrGasSTCMeasureTypeId"
                                                                                name="SolarThermalOrGasSTCMeasureTypeId"
                                                                                options="solarThermalOrGasSTCMeasureTypes"
                                                                                validate={formValidate}
                                                                                binding={[viewModel, "solarThermalOrGasSTCMeasureTypeId", bindingViewModel]}
                                                                            ></BindedSelect>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="position-relative form-group">
                                                                            <label htmlFor="TubeSystemInputTypeId">Input type</label>
                                                                            <BindedSelect
                                                                                className="form-control"
                                                                                id="TubeSystemInputTypeId"
                                                                                name="TubeSystemInputTypeId"
                                                                                options="tubeSystemInputTypes"
                                                                                validate={formValidate}
                                                                                binding={[viewModel, "tubeSystemInputTypeId", bindingViewModel]}
                                                                                disabled = {viewModel.solarThermalOrGasSTCMeasureTypeId == 1}
                                                                            ></BindedSelect>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="position-relative form-group">
                                                                            <label htmlFor="CollectorArea">Collector Area</label>
                                                                            <BindedInput
                                                                                className="form-control"
                                                                                id="CollectorArea"
                                                                                name="CollectorArea"
                                                                                type="number"
                                                                                min="0"
                                                                                binding={[viewModel, "collectorArea", bindingViewModel]}
                                                                                disabled = {viewModel.tubeSystemInputTypeId == 2}
                                                                            ></BindedInput>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="position-relative form-group">
                                                                            <label htmlFor="TubeNumber">Tube Number</label>
                                                                            <BindedInput
                                                                                className="form-control"
                                                                                id="TubeNumber"
                                                                                name="TubeNumber"
                                                                                type="number"
                                                                                min="0"
                                                                                binding={[viewModel, "tubeNumber", bindingViewModel]}
                                                                                disabled = {viewModel.solarThermalOrGasSTCMeasureTypeId == 1 || viewModel.tubeSystemInputTypeId == 1 }
                                                                            ></BindedInput>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row row">
                                                                    <div className="col-3">
                                                                        <div className="position-relative form-group">
                                                                            <label style={{color:"green"}}><b>System Size: {solarThermalResult.systemSize}</b></label>                                                                  
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="position-relative form-group">
                                                                            <label style={{color:"green"}}><b>Estimated STC: {solarThermalResult.stc}</b></label>                                                                  
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }                                                   
                                                    </div>
                                                </div>
                                            </div>
                                        </div>       
                                        <div className="col-12">
                                            <div className="main-card mb-3 card">
                                                <div className="card-header">
                                                    <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                                                        <h5 className="m-0 p-0 card-title">Heat pump water heater</h5>
                                                    </button>
                                                </div>
                                                <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                                                    <div className="card-body">
                                                        <div className="separator"></div>
                                                        <div className="form-row row">
                                                            <div className="col-3">
                                                                <div className="position-relative form-group">
                                                                    <label htmlFor="HeatPumpSTCLevelTypeId">STC level defined by</label>
                                                                    <BindedSelect
                                                                        className="form-control"
                                                                        id="HeatPumpSTCLevelTypeId"
                                                                        name="HeatPumpSTCLevelTypeId"
                                                                        options="heatPumpSTCLevelTypes"
                                                                        validate={formValidate}
                                                                        binding={[viewModel, "heatPumpSTCLevelTypeId", bindingViewModel]}
                                                                    ></BindedSelect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(viewModel.heatPumpSTCLevelTypeId == 2) &&
                                                            <>
                                                                <div className="form-row row">                                                                 
                                                                    <div className="col-3">
                                                                        <div className="position-relative form-group">
                                                                            <label htmlFor="HeatPumpTankSize">Tank Size (L)</label>
                                                                            <BindedInput
                                                                                className="form-control"
                                                                                id="HeatPumpTankSize"
                                                                                name="HeatPumpTankSize"
                                                                                type="number"
                                                                                min="0"
                                                                                binding={[viewModel, "heatPumpTankSize", bindingViewModel]}                                                 
                                                                            ></BindedInput>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row row">
                                                                    <div className="col-3">
                                                                        <div className="position-relative form-group">
                                                                            <label style={{color:"green"}}><b>System Size: {heatPumResult.systemSize}</b></label>                                                                  
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="position-relative form-group">
                                                                            <label style={{color:"green"}}><b>Estimated STC: {heatPumResult.stc}</b></label>                                                                  
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }                                                   
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                              
                                    </div>                                
                                </>
                            }
                            <div className="form-row row">
                                <div className="col-6">
                                    <div className="position-relative form-group">
                                        <label htmlFor="HotWaterSystemTypeId">Hot water system *</label>
                                        <BindedSelect
                                            className="form-control"
                                            id="HotWaterSystemTypeId"
                                            name="HotWaterSystemTypeId"
                                            options="systemTypes"
                                            validate={formValidate}
                                            binding={[viewModel, "systemTypeId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="NumberOfOccupants">Occupants Number </label>
                                        <BindedInput
                                            disabled
                                            className="form-control"
                                            id="NumberOfOccupants"
                                            name="NumberOfOccupants"
                                            type="number"
                                            binding={[viewModel, "numberOfOccupants", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="HotWaterClimateZoneName">Hot water zone </label>
                                        <BindedInput
                                            disabled
                                            className="form-control"
                                            id="HotWaterClimateZoneName"
                                            name="HotWaterClimateZoneName"
                                            type="text"
                                            binding={[viewModel, "hotWaterClimateZone", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="FloorArea">Floor Area (m<sup>2</sup>) </label>
                                        <BindedInput
                                            disabled
                                            className="form-control"
                                            id="FloorArea"
                                            name="FloorArea"
                                            type="number"
                                            binding={[viewModel, "floorArea", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                            </div>
                            
                            {viewModel.systemTypes !== undefined && dataExtension.getSelectOptionByValue(viewModel.systemTypes, viewModel.systemTypeId).index == 183 &&
                                <div className="form-row row">
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label htmlFor="PvDiverterTypeId">PV Diverter Type *</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="PvDiverterTypeId"
                                                name="PvDiverterTypeId"
                                                options="pvDiverterTypes"
                                                validate={formValidate}
                                                binding={[viewModel, "pvDiverterTypeId", bindingViewModel]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label htmlFor="PvDiverterTankVolume">PV Diverter Tank Volume (m<sup>3</sup>) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="PvDiverterTankVolume"
                                                name="PvDiverterTankVolume"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "pvDiverterTankVolume", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                </div>
                            }                       
                            <div className="form-row-separator"></div>
                            <div className="form-row row"></div>
                            <div className="form-row row">
                                <div className="col-12">
                                    <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </>
                }
             
            </div>
        </Fragment>
    );
}
export default connectAppStore(HotWaterModule);