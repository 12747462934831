class Guid
{
    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
        function(c) {
           var uuid = Math.random() * 16 | 0, v = c == 'x' ? uuid : (uuid & 0x3 | 0x8);
           return uuid.toString(16);
        });
    }

    emptyGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }
}
const guid = new Guid();
export default guid;
