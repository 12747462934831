import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import { Button } from 'primereact/button';

const MultipleInfiltrationPenetrationsDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedTriStateCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const zoneId = useParams().zoneId;
    const parentType = useParams().elementType;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedElementIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedElementIds,
        elementType: "InfiltrationPenetrationIn" + parentType
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateMultipleInfiltrationPenetrations";
    const getUrl = "api/Element/Accessor/GetMultipleInfiltrationPenetrations";

    const insert = () => {
        if (viewModel.typeId == 3) {
            dispatchViewModel({...viewModel, isSealed: false, diameter: 250, length: 0, width: 0, clearance: 50, isVentHeatLight: false});
        }else if (viewModel.typeId == 4) {
            dispatchViewModel({...viewModel, isSealed: false, diameter: 250, length: 0, width: 0, clearance: 0, isVentHeatLight: false});
        }else if (viewModel.typeId == 6 && !viewModel.isVentHeatLight) {
            dispatchViewModel({...viewModel, isSealed: false, diameter: 250, length: 0, width: 0, clearance: 0});
        }else if(viewModel.typeId == 6 && viewModel.isVentHeatLight){
            dispatchViewModel({...viewModel, isSealed: false, diameter: 250, length: 0, width: 0, clearance: 50});
        }
    };

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading infiltration penetrations", appStoreDispatch);
    },[]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="FloorCeilingFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "Infiltration penetrations detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="InfiltrationPenetrationName">Name *</label>
                        <BindedInput
                            className="form-control"
                            id="InfiltrationPenetrationName"
                            name="InfiltrationPenetrationName"
                            type="text"
                            binding={[viewModel, "name", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="InfiltrationPenetrationZoneAboveId">Zone above *</label>
                        <BindedSelect
                            className="form-control"
                            id="InfiltrationPenetrationZoneAboveId"
                            name="InfiltrationPenetrationZoneAboveId"
                            options="aboveZones"
                            binding={[viewModel, "aboveZoneId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="InfiltrationPenetrationTypeId">Type *</label>
                        <BindedSelect
                            className="form-control"
                            id="InfiltrationPenetrationTypeId"
                            name="InfiltrationPenetrationTypeId"
                            options="types"
                            binding={[viewModel, "typeId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="InfiltrationPenetrationQuantity">Quantity *</label>
                        <BindedInput
                            className="form-control"
                            id="InfiltrationPenetrationQuantity"
                            name="InfiltrationPenetrationQuantity"
                            type="number"
                            min="0"
                            binding={[viewModel, "quantity", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
            </div>
            {viewModel.parentType !== "ExternalWall" &&
                <div className="form-row row">
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="DownlightTypeId">Downlight Type *</label>
                        <BindedSelect
                            className="form-control"
                            id="DownlightTypeId"
                            name="DownlightTypeId"
                            options="downlightTypes"
                            binding={[viewModel, "downlightTypeId", bindingViewModel]}
                            disabled={String(viewModel.typeId) === "2" ? false : true}
                        ></BindedSelect>
                    </div>
                </div>
            </div>}
            {viewModel.dwellingType === "New" &&
            <>
                <div className="form-row row">
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationDiameter">Diameter (mm) *</label>
                            <BindedSelect
                                className="form-control"
                                id="InfiltrationPenetrationDiameter"
                                name="InfiltrationPenetrationDiameter"
                                options={viewModel.typeId == 1? 
                                    [0, 900, 1200, 1400, 1800, 2100, 2400, 2700, 3000].map((m)=>{return  {text: m, value: m};}) :
                                    [0, 160, 180, 200, 225, 250, 300].map((m)=>{return  {text: m, value: m};})
                                }
                                binding={[viewModel, "diameter", bindingViewModel]}
                                disabled={["2", "5"].includes(String(viewModel.typeId)) || (viewModel.typeId == 6 && (viewModel.length != 0 || viewModel.width != 0))}
                            ></BindedSelect>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationLength">Length (mm) *</label>
                            <BindedInput
                                className="form-control"
                                id="InfiltrationPenetrationLength"
                                name="InfiltrationPenetrationLength"
                                type="number"
                                min="0"
                                binding={[viewModel, "length", bindingViewModel, 0]}
                                disabled={(viewModel.typeId == 6 && viewModel.diameter != 0)}
                            ></BindedInput>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationWidth">Width (mm) *</label>
                            <BindedInput
                                className="form-control"
                                id="InfiltrationPenetrationWidth"
                                name="InfiltrationPenetrationWidth"
                                type="number"
                                min="0"
                                binding={[viewModel, "width", bindingViewModel, 0]}
                                disabled={(viewModel.typeId == 6 && viewModel.diameter != 0)}
                            ></BindedInput>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationClearance">Clearance (mm) *</label>
                            <BindedInput
                                className="form-control"
                                id="InfiltrationPenetrationClearance"
                                name="InfiltrationPenetrationClearance"
                                type="number"
                                min="0"
                                binding={[viewModel, "clearance", bindingViewModel]}
                                disabled={["1", "5", "7", "8"].includes(String(viewModel.typeId))}
                            ></BindedInput>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="custom-checkbox custom-control">
                        <BindedTriStateCheckBox
                            id="InfiltrationPenetrationIsVentHeatLight"
                            name="InfiltrationPenetrationIsVentHeatLight"
                            className="custom-control-input"
                            binding={[
                                viewModel,
                                "isVentHeatLight",
                                bindingViewModel,
                            ]}
                            disabled={String(viewModel.typeId) === "6" ? false : true}
                        ></BindedTriStateCheckBox>
                        <label
                            style={{ marginLeft: "5px" }}
                            htmlFor="InfiltrationPenetrationIsVentHeatLight"
                            className="custom-control-label"
                            >
                            Exhaust fan with heat & light
                        </label>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="custom-checkbox custom-control">
                        <BindedTriStateCheckBox
                            id="InfiltrationPenetrationIsSealed"
                            name="InfiltrationPenetrationIsSealed"
                            className="custom-control-input"
                            binding={[
                                viewModel,
                                "isSealed",
                                bindingViewModel,
                            ]}
                        ></BindedTriStateCheckBox>
                        <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="InfiltrationPenetrationIsSealed"
                        className="custom-control-label"
                        >
                        Sealed
                        </label>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="custom-checkbox custom-control">
                        <BindedTriStateCheckBox
                            id="InfiltrationPenetrationIsInsulated"
                            name="InfiltrationPenetrationIsInsulated"
                            className="custom-control-input"
                            binding={[
                                viewModel,
                                "isInsulated",
                                bindingViewModel,
                            ]}
                            disabled={String(viewModel.typeId) !== "2"}
                        ></BindedTriStateCheckBox>
                        <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="InfiltrationPenetrationIsInsulated"
                        className="custom-control-label"
                        >
                        Insulated
                        </label>
                    </div>
                </div>
            </>}
            {viewModel.dwellingType !== "New" &&
            <>
                <div className="form-row row">
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationDiameter">Diameter (mm) *</label>
                            <BindedSelect
                                className="form-control"
                                id="InfiltrationPenetrationDiameter"
                                name="InfiltrationPenetrationDiameter"
                                options={viewModel.typeId == 1? 
                                    [0, 250, 900, 1200, 1400, 1800, 2100, 2400, 2700, 3000].map((m)=>{return  {text: m, value: m};}) :
                                    [0, 160, 180, 200, 225, 250, 300].map((m)=>{return  {text: m, value: m};})
                                }
                                binding={[viewModel, "diameter", bindingViewModel]}
                                disabled={!["1", "3", "4", "6", "7", "8"].includes(String(viewModel.typeId)) || (viewModel.length != 0 || viewModel.width != 0)}
                            ></BindedSelect>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationLength">Length (mm) *</label>
                            <BindedInput
                                className="form-control"
                                id="InfiltrationPenetrationLength"
                                name="InfiltrationPenetrationLength"
                                type="number"
                                min="0"
                                binding={[viewModel, "length", bindingViewModel, 0]}
                                disabled={(viewModel.typeId == 2 && viewModel.downlightTypeId == 0) || viewModel.diameter != 0}
                            ></BindedInput>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationWidth">Width (mm) *</label>
                            <BindedInput
                                className="form-control"
                                id="InfiltrationPenetrationWidth"
                                name="InfiltrationPenetrationWidth"
                                type="number"
                                min="0"
                                binding={[viewModel, "width", bindingViewModel, 0]}
                                disabled={(viewModel.typeId == 2 && viewModel.downlightTypeId == 0) || viewModel.diameter != 0}
                            ></BindedInput>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationClearance">Clearance (mm) *</label>
                            <BindedInput
                                className="form-control"
                                id="InfiltrationPenetrationClearance"
                                name="InfiltrationPenetrationClearance"
                                type="number"
                                min="0"
                                binding={[viewModel, "clearance", bindingViewModel]}
                                disabled={["3", "4", "6"].includes(String(viewModel.typeId)) || (viewModel.typeId == 2 && viewModel.downlightTypeId !== 0) ? false : true}
                            ></BindedInput>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="custom-checkbox custom-control">
                        <BindedTriStateCheckBox
                            id="InfiltrationPenetrationIsVentHeatLight"
                            name="InfiltrationPenetrationIsVentHeatLight"
                            className="custom-control-input"
                            binding={[
                                viewModel,
                                "isVentHeatLight",
                                bindingViewModel,
                            ]}
                            disabled={String(viewModel.typeId) === "6" ? false : true}
                        ></BindedTriStateCheckBox>
                        <label
                            style={{ marginLeft: "5px" }}
                            htmlFor="InfiltrationPenetrationIsVentHeatLight"
                            className="custom-control-label"
                            >
                            Exhaust fan with heat & light
                        </label>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="custom-checkbox custom-control">
                        <BindedTriStateCheckBox
                            id="InfiltrationPenetrationIsSealed"
                            name="InfiltrationPenetrationIsSealed"
                            className="custom-control-input"
                            binding={[
                                viewModel,
                                "isSealed",
                                bindingViewModel,
                            ]}
                            disabled={(viewModel.typeId == 2 && viewModel.downlightTypeId == 0) || viewModel.typeId == 1 || viewModel.typeId == 5}
                        ></BindedTriStateCheckBox>
                        <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="InfiltrationPenetrationIsSealed"
                        className="custom-control-label"
                        >
                        Sealed
                        </label>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="custom-checkbox custom-control">
                        <BindedTriStateCheckBox
                            id="InfiltrationPenetrationIsInsulated"
                            name="InfiltrationPenetrationIsInsulated"
                            className="custom-control-input"
                            binding={[
                                viewModel,
                                "isInsulated",
                                bindingViewModel,
                            ]}
                            disabled={String(viewModel.typeId) !== "2" || (String(viewModel.typeId) === "2" && String(viewModel.downlightTypeId) === "0")}
                        ></BindedTriStateCheckBox>
                        <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="InfiltrationPenetrationIsInsulated"
                        className="custom-control-label"
                        >
                        Insulated
                        </label>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="position-relative form-group">
                        <Button label="Insert Default Values" icon="pi pi-plus-circle" onClick={insert} outlined tooltip="Insert default value for Chimney/Ceiling vent/Ceiling exhaust fan" tooltipOptions={{ showOnDisabled: true }} disabled={viewModel.typeId == null || ["-999", "1", "2", "5", "7", "8"].includes(String(viewModel.typeId))} />
                    </div>
                </div>
            </>}
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleInfiltrationPenetrationsDetail);