import { connectAppStore } from "../Store/AppStore";
import PageDependencies from "../Feature/PageDependencies";

const Home = (props)=>{
    const { 
        useEffect,
        layoutManager,
        getData,
        useNavigate,
        pageProfile,
        getAppStore
    } = PageDependencies();
    const nav = useNavigate();
    const [appStoreState, appStoreDispatch] = getAppStore(props);

    const loadUserInfo = async ()=>{
        const userRoleResponse = await getData.processPost(true, "api/DefaultModel/Accessor/Get", {});
        if(userRoleResponse.state === true){
            pageProfile.setSoftwareVersion(appStoreDispatch, userRoleResponse.viewModel.softwareVersion);
        }
        if(userRoleResponse !== undefined){
          if(userRoleResponse.viewModel !== undefined){
            const {userRole, isAgreeCondition} = userRoleResponse.viewModel;
            if (userRole === 'Administrator') {
                nav('Admin/Home', { replace: true });
            }else if (!isAgreeCondition) {
                nav('UserAccessor/TermsConditions', { replace: true });
            }
          }
        }
      }

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loadUserInfo();
    },[])

    return(    
    <div className="app-main__inner">
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        <i className="pe-7s-home icon-gradient bg-tempting-azure">
                        </i>
                    </div>
                    <div>
                        Home
                        <div className="page-title-subheading">
                            Latest news
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="main-card mb-3 card">
            <div className="card-body">
                <p>
                    Thank you for using AccuRate Enterprise. Please be advised that this website is still in beta release phase and 
                    undergoing active feature improvements and bug fixes.
                </p>
                <ul>
                    <li>The account that you are using may be shared with other testers. As such, you may wish to create a project for yourself. To maintain your privacy and security, please do not enter any personal/confidential data.</li>
                    <li>Data on the website is currently only for testing purposes. They may be reset without notification.</li>
                    <li>Supported browsers include Firefox, Chrome, and Safari. Edge may work but is not tested. Internet Explorer is not and will not be supported.</li>
                    <li>Changes to the website may require you to clear the cache of your browser before they are applied.</li>
                </ul>
                <p>
                    CSIRO logs all activity on this service and may use aggregate or individual use data to understand user behaviour in order to improve the service, comply with Nationwide House Energy Rating Scheme (NatHERS) requirements or comply with our legal obligations. CSIRO conducts all such activity in compliance <a href="https://www.nhmrc.gov.au/about-us/publications/national-statement-ethical-conduct-human-research-2023" target="_blank">National Statement on Ethical Conduct in Human Research 2023</a>. 
                    CSIRO will always use aggregated or de-identified data where possible, however there may be some instances in which it is not possible to obscure individual user information. If you have questions about CSIRO’s ethical obligations, procedures or standards, please go to <a href="https://www.csiro.au/en/about/policies/ethical-human-research" target="_blank">https://www.csiro.au/en/about/policies/ethical-human-research</a>.
                </p>
            </div>
        </div>
    </div>);
}

export default connectAppStore(Home);