
import { connectAppStore } from "../../../Store/AppStore";
import GoogleAddress from "../Default/GoogleAddress";
import PageDependencies from "../../../Feature/PageDependencies";

const Client = (props) => {
    const { 
    layoutManager,
    getData,
    guid,
    useReducerSmart,
    toastPrint,
    useParams,
    BindedInput,
    tryCatchFinally,
    getAppStore,
    useNavigate,
    pageProfile,
    AppLoading,
    loaModelFromApi,
    useEffectWithPrevious,
    Fragment, useEffect, useState,
    FormValidate} = PageDependencies();
    const nav = useNavigate();
    const postUrl = "api/Project/Accessor/UpdateClient";
    const getUrl = "api/Project/Accessor/GetClient";
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const defaultViewModel = {
        googleMapsApiKey:"",
        projectId: projectId,
        designVariationId: emptyGuid,
        address: {postcode:"", id:""},
        clientId: emptyGuid
      };
    const getTime = ()=>{
        const twentyMinutesLater = new Date();
        twentyMinutesLater.setMinutes(twentyMinutesLater.getMinutes() + 1);
        return twentyMinutesLater;
    }
    const phoneRegex = /^\d{8}$|^\d{10}$/;
    const [disableButton, setDisableButton] = useState(true);
    const [initTime, setInitTime] = useState(getTime());
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const [reloadAddress, setReloadAddress] = useState("");
    const [client, dispatchClient, bindingClient] = useReducerSmart(defaultViewModel);
    const setAddress = (address)=>{dispatchClient({...client, address: address}); }
    const validateMethods = {
        firstName:(value)=>{return FormValidate.validateTextRequired(value, "First name")},
        lastName:(value)=>{return FormValidate.validateTextRequired(value, "Last name")},
        emailAddress:(value)=>{return FormValidate.validateEmail(value)},
        phoneNumber:(value)=>{return phoneRegex.test(value)? "" : "Phone number must be 8 or 10 digits"},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), true);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, client, dispatchClient, setLoading, "Loading client", appStoreDispatch, ()=>{
            setReloadAddress(guid.newGuid());
            setInitTime(Date.now());
        }, setInit);
    },[]);

    const handleSubmitButtonClick = async (e) => {
        e.preventDefault();
        await tryCatchFinally.asyncProcess(async ()=>{
            formValidate.setValidateVisibility(true);
            const validateResult = formValidate.checkValidateResult(client);
            if(!validateResult){
              return ;
            }
            setLoading({isLoading: true, text: "Submitting form"});
            const postResponse = await getData.processPost(true, postUrl, client);
            setLoading({isLoading: false, text: ""});
            toastPrint.printResponse(postResponse);
            if(!postResponse.state) return;
            nav("/ProjectAccessor/Plan/" + projectId);
        }, setLoading);
    };
  
    const handleCancelButtonClick = async (e) => {
        e.preventDefault();
        nav("/ProjectAccessor/Dwelling/" + projectId);
    };

    useEffectWithPrevious((pClientDependencies)=>{
        if(disableButton === false) return;
        if(pClientDependencies === undefined) return;
        if(pClientDependencies.length === 0) return;
        const pClient = pClientDependencies[0];
        if(init === true){
            if(Date.now() - initTime < 200) return;
            if(Object.keys(pClient).length === Object.keys(client).length){
              const isDiff = JSON.stringify(pClient) != JSON.stringify(client);
              if(isDiff){
                setDisableButton(false);
              }
            }
        }
      }, [client]);

    return(<Fragment>
        {loading.isLoading && (
            <AppLoading active>
            <div>{loading.text}</div>
            </AppLoading>
        )}
        {layoutManager.Layout_GetDebugModel(props) && JSON.stringify(client)}
        <div className="app-main__inner">
            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">
                        <div className="page-title-icon">
                        <i className="pe-7s-user icon-gradient bg-tempting-azure">
                        </i>
                        </div>
                        <div>
                        Project profile - Client
                        <div className="page-title-subheading">
                        {pageProfile.getPageSubTitle(appStoreState)}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {init &&
                  <form noValidate="novalidate" autoComplete="off">
                  <div id="ClientProfileAccordion">
                      <div className="main-card mb-3 card">
                          <div className="card-header">
                          <button type="button" data-toggle="collapse" data-target="#ClientProfileCollapsibleDiv" aria-expanded="true" aria-controls="ClientProfileCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                              <h5 className="m-0 p-0 card-title">Client</h5>
                          </button>
                          </div>
                          <div data-parent="#ClientProfileAccordion" id="ClientProfileCollapsibleDiv" className="collapse show">
                          <div className="card-body">
                              <div className="form-row row">
                                  <div className="col-6">
                                      <div className="position-relative form-group">
                                      <label htmlFor="FirstName">First name *</label>
                                      <BindedInput
                                          id="FirstName" name="FirstName"
                                          binding={[client, "firstName", bindingClient]}
                                          type="text"
                                          className="form-control"
                                          validate = {formValidate}
                                      ></BindedInput>                                  
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="position-relative form-group">
                                      <label htmlFor="LastName">Last name *</label>
                                      <BindedInput
                                          id="LastName" name="LastName"
                                          binding={[client, "lastName", bindingClient]}
                                          type="text"
                                          className="form-control"
                                          validate = {formValidate}
                                      ></BindedInput>    
                                      </div>
                                  </div>
                              </div>
                              <div className="form-row row">
                                  <div className="col-6">
                                      <div className="position-relative form-group">
                                      <label htmlFor="EmailAddress">Email address *</label>
                                      <BindedInput
                                          id="EmailAddress" name="EmailAddress"
                                          binding={[client, "emailAddress", bindingClient]}
                                          type="text"
                                          className="form-control"
                                          validate = {formValidate}
                                      ></BindedInput>  
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="position-relative form-group">
                                      <label htmlFor="PhoneNumber">Phone number *</label>
                                      <BindedInput
                                          id="PhoneNumber" name="PhoneNumber"
                                          binding={[client, "phoneNumber", bindingClient]}
                                          type="text"
                                          className="form-control"
                                          validate = {formValidate}
                                      ></BindedInput>  
                                      </div>
                                  </div>
                              </div>
                              <div className="form-row row">
                                  <div className="col-6">
                                      <div className="position-relative form-group">
                                      <label htmlFor="ABN">ABN</label>
                                      <BindedInput
                                          id="ABN" name="ABN"
                                          binding={[client, "abn", bindingClient]}
                                          type="text"
                                          className="form-control"
                                      ></BindedInput>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
                  {/* Address */}
                  <GoogleAddress 
                  id="AddressClient"
                  updateAddressCallBack = {(newAddress)=>{
                    formValidate.checkValidateResult(newAddress);
                }}
                  reload = {reloadAddress}
                  setReload = {setReloadAddress}
                  address = {client.address}
                  setAddress = {setAddress}
                  googleMapsApiKey = {client.googleMapsApiKey}
                  removeLotDpNumber
                  validate = {formValidate}
                  debug = {layoutManager.Layout_GetDebugModel(props)}
                  ></GoogleAddress>
                  <div className="main-card mb-3 card">
                      <div className="card-header">
                      <button type="button" className="text-left m-0 p-0 btn btn-block">
                          <h5 className="m-0 p-0 card-title">Submit Form</h5>
                      </button>
                      </div>
                      <div className="card-footer">
                      <div className="ml-auto">
                          <button
                          className="btn btn-primary btn-round"
                          style={{marginRight:"15px"}}
                          type="submit"
                          id="SubmitButton"
                          disabled={disableButton}
                          onClick={(e) => {
                              handleSubmitButtonClick(e);
                          }}
                          >
                          Submit
                          </button>
                          <button
                          className="btn btn-primary btn-round"
                          id="CancelButton"
                          onClick={(e) => {
                              handleCancelButtonClick(e);
                          }}
                          >
                          Cancel
                          </button>
                      </div>
                      <div className="clearfix"></div>
                      </div>
                  </div>
              </form>
            }
      
        </div>
    </Fragment>);
}
export default connectAppStore(Client);