import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const MultipleInternalWallsDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedTriStateCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    let zoneId = useParams().zoneId;
    if(zoneId === 'undefined') zoneId = emptyGuid;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedWallIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedWallIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateInternalWalls";
    const getUrl = "api/Element/Accessor/GetInternalWalls";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading internal walls", appStoreDispatch);
    },[]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="InternalWallFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "Internal walls detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="InternalWallName">Name *</label>
                        <BindedInput
                        className="form-control"
                        id="InternalWallName"
                        name="InternalWallName"
                        type="text"
                        binding={[viewModel, "name", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="ZoneId">Zone *</label>
                        <BindedSelect
                        className="form-control"
                        id="ZoneId"
                        name="AdjacentZoneAId"
                        options="adjacentZoneAs"
                        binding={[viewModel, "adjacentZoneAId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-8">
                    <div className="position-relative form-group">
                        <label htmlFor="InternalWallConstructionId">
                        Construction *
                        </label>
                        <BindedSelect
                        className="form-control"
                        id="InternalWallConstructionId"
                        name="InternalWallConstructionId"
                        options="constructions"
                        binding={[
                            viewModel,
                            "constructionId",
                            bindingViewModel,
                        ]}
                        ></BindedSelect>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="InternalWallLayerDirectionTypeId">
                        Order *
                        </label>
                        <BindedSelect
                        className="form-control"
                        id="InternalWallLayerDirectionTypeId"
                        name="InternalWallLayerDirectionTypeId"
                        options="layerDirectionTypes"
                        binding={[
                            viewModel,
                            "layerDirectionTypeId",
                            bindingViewModel,
                        ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="InternalWallLength">Length (mm) *</label>
                        <BindedInput
                        className="form-control"
                        id="InternalWallLength"
                        name="InternalWallLength"
                        type="number"
                        binding={[viewModel, "length", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="internalWallHeight">Height (mm) *</label>
                        <BindedInput
                        className="form-control"
                        id="InternalWallHeight"
                        name="InternalWallHeight"
                        type="number"
                        binding={[viewModel, "height", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="InternalWallOpeningArea">
                        Opening area (m<sup>2</sup>) *
                        </label>
                        <BindedInput
                        className="form-control"
                        min="0"
                        max="360"
                        id="InternalWallOpeningArea"
                        name="InternalWallOpeningArea"
                        type="number"
                        binding={[viewModel, "openingArea", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="InternalWallOpeningTypeId">
                        Opening type *
                        </label>
                        <BindedSelect
                        className="form-control"
                        id="InternalWallOpeningTypeId"
                        name="InternalWallOpeningTypeId"
                        options="openingTypes"
                        binding={[viewModel, "openingTypeId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="InternalWallAdjacentZoneAboveId">Adjacent zone *</label>
                        <BindedSelect
                        className="form-control"
                        id="InternalWallAdjacentZoneAboveId"
                        name="AdjacentZoneBId"
                        options="adjacentZoneBs"
                        binding={[viewModel, "adjacentZoneBId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        id="InternalWallHasCompositeWall"
                        name="InternalWallHasCompositeWall"
                        className="custom-control-input"
                        binding={[viewModel, "hasCompositeWall", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="InternalWallHasCompositeWall"
                        className="custom-control-label"
                    >
                        Composite wall
                    </label>
                </div>
                {viewModel.hasCompositeWall && (
                <Fragment>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="InternalWallAboveWallId">
                            Wall above
                            </label>
                            <BindedSelect
                            className="form-control"
                            id="InternalWallAboveWallId"
                            name="InternalWallAboveWallId"
                            options="aboveWalls"
                            binding={[
                                viewModel,
                                "aboveWallId",
                                bindingViewModel,
                            ]}
                            ></BindedSelect>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="InternalWallBelowWallId">
                            Wall below
                            </label>
                            <BindedSelect
                            className="form-control"
                            id="InternalWallBelowWallId"
                            name="InternalWallBelowWallId"
                            options="belowWalls"
                            binding={[
                                viewModel,
                                "belowWallId",
                                bindingViewModel,
                            ]}
                            ></BindedSelect>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="InternalWallBaseHeight">Base height (mm)</label>
                            <BindedInput
                            className="form-control"
                            id="InternalWallBaseHeight"
                            name="InternalWallBaseHeight"
                            type="number"
                            binding={[
                                viewModel,
                                "baseHeight",
                                bindingViewModel,
                            ]}
                            ></BindedInput>
                        </div>
                    </div>
                </Fragment>)}
            </div>
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleInternalWallsDetail);