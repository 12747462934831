import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
const PaymentResult = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        stringCoding,
        layoutManager,
        useReducerSmart,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const {jsonParam} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        jsonParam: stringCoding.decodeObject(jsonParam)
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/DefaultModel/Accessor/Get";

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        //loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Refund Result", appStoreDispatch, undefined, setInit);
    },[]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Refund Result: {viewModel.jsonParam.state}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 col-3 card">
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <div className="form-row row">
                                <div className="col-12">
                                    <div className="position-relative form-group">
                                        {viewModel.jsonParam.state === "initialized"
                                            ?<p>Refund was initialized, we will handle it ASAP.<br/>You will get a confirm email when refund was approved.</p>
                                            :<p>{viewModel.jsonParam.message}</p>
                                        }
                                        <a href="/UserAccessor/Credits" type="button" class="btn btn--medium btn--transparent">Back to Credits Page</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default  connectAppStore(PaymentResult);
