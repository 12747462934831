import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import TooltipTemplate from "../../../Layout/AppNav/TooltipTemplate";

const AnalysisResult = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        getData,
        guid,
        useReducerSmart,
        toastPrint,
        getAppStore,
        pageProfile,
        BindedCheckBox,
        tryCatchFinally,
        AppLoading,
        useNavigate,
        loaModelFromApi,
        dataExtension,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const {projectId, designVariationVersionName} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
    };
    const nav = useNavigate();
    const [extraPostModel, setExtraPostModel] = useState({projectId: projectId, designVariationVersionName: designVariationVersionName});
    const [selectedItemIds, setSelectedItemIds] = useState({selectedItemIds:[]});
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [errorLoading, setErrorLoading] = useState(false);
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    //change model get url here if not using default model
    const getUrl = "api/DefaultModel/Accessor/Get";
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
      });
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState([]);
    const [useDefaultDesignVariationOnlyViewModel, dispatchUseDefaultDesignVariationOnlyViewModel, bindingUseDefaultDesignVariationOnlyViewModel] = useReducerSmart({useDefaultDesignVariationOnly: false});
    const postUrlDefaultDesignVariationOnly = "api/Project/Accessor/UpdateUseDefaultDesignVariationOnly";
    const getUrlDefaultDesignVariationOnly = "api/Project/Accessor/GetUseDefaultDesignVariationOnly";
    const tableId = "AnalysisResult_99998d90-e946-4514-9300-45dcdb39157a";
    const [tableReload, setTableReload] = useState("");
    const columns = [
        { name: "name", text: "Design Variation (DV)", width: "5%" },
        { name: "isDefaultDesignVariation", text: "Default", width: "5%" },
        { name: "status", text: "Status", width: "10%" },
        { name: "starRating", text: "Star Rating", width: "10%" },
        { name: "heating", text: "Heating", width: "10%" },
        { name: "cooling", text: "Cooling", width: "10%" },
        { name: "totalEnergy", text: "Total Energy", width: "10%" },
        { name: "conditionedArea", text: "Conditioned Area", width: "10%" },
        { name: "starRatingNewHome", text: "Whole of Home Rating", width: "10%" },
        { name: "submittedDateTime", text: "Submitted Date", width: "10%" },
        { name: "download", text: "Download CSV", width: "10%", generate: downloadGenerate },
      ];

      function downloadGenerate(model, cloumn, index, style, onColumnValueChangeEvent){
        if(model.canBeDownload){
            return(<a href={"api/AnalysisResult/Accessor/Download/" + model.referenceId} target="_blank" className="btn btn-primary">Download</a>);
        }
        return(<p></p>);
      }

      let menuItems = {
        edit: {
          name: "Open",
          icon: "",
          url: "/AnalysisResultAccessor/Detail/" + designVariationVersionName + "/" + projectId + "/",
          defaultAction: true,
            disabled: function () {
            return dataExtension.getSelectedRowDataIds(data).length !== 1 || 
            dataExtension.getSelectedRows(data)[0].starRating === "" || 
            dataExtension.getSelectedRows(data)[0].starRating === "Running" || 
            dataExtension.getSelectedRows(data)[0].starRating === "Preparing";
          },
        },
      };

      menuItems.analysisThermalOnly = {
        name: "Analyse thermal only",
        icon: "",
        defaultAction: false,
        disabled: function () {
          return dataExtension.getSelectedRowDataIds(data).length === 0;
        },
      };

      if (viewModel.simulationType !== undefined && viewModel.simulationType != "Thermal Only"){
        menuItems.analysisWithModule = {
          name: "Analyse thermal and WoH",
          icon: "",
          defaultAction: false,
          disabled: function () {
            return dataExtension.getSelectedRowDataIds(data).length === 0;
          },
        };
      }

      menuItems.cancelAnalysis = {
        name: "Cancel Analyse",
        icon: "",
        defaultAction: false,
        disabled: function () {
          return dataExtension.getSelectedRowDataIds(data).length === 0;
        },
      };

      const contextMenu = {
        items: menuItems,
        callBack: (
          contextMenuCallBackKey,
          contextMenuCallBackElement,
          contextMenuCallBackTableRoadData,
          localtion
        ) => {
          if (contextMenuCallBackKey === "analysisThermalOnly") {
            setModal({
              ...modal,
              isOpen: true,
              title: "Analyse design variations",
              message: "Do you wish to analyse the selected design variations ?",
              comfirmButtonStyle: "primary",
              comfirmButtonText: "Confirm",
              handleComfirm: ()=>{handleAnalysisComfirm(dataExtension.getSelectedRowDataIds(data), "Thermal Only")},
            });
          }
          if (contextMenuCallBackKey === "analysisWithModule") {
            setModal({
              ...modal,
              isOpen: true,
              title: "Analyse design variations",
              message: "Do you wish to analyse the selected design variations ?",
              comfirmButtonStyle: "primary",
              comfirmButtonText: "Confirm",
              handleComfirm: ()=>{handleAnalysisComfirm(dataExtension.getSelectedRowDataIds(data), viewModel.simulationType)},
            });
          }
          if (contextMenuCallBackKey === "cancelAnalysis") {
            setModal({
              ...modal,
              isOpen: true,
              title: "Cancel analyse design variations",
              message: "Do you wish to cancel analyse the selected design variations ?",
              comfirmButtonStyle: "primary",
              comfirmButtonText: "Confirm",
              handleComfirm: ()=>{handleCancelAnalysisComfirm(dataExtension.getSelectedRowDataIds(data))},
            });
          }
        },
      }

      async function handleCancelAnalysisComfirm (selectedItemIds){   
        setLoading({isLoading: true, text:"Canceling analysis"});
        await tryCatchFinally.asyncProcess(async ()=>{
          const response = await getData.processPost(true, "api/AnalysisResult/Accessor/CancelAnalysis", {
            projectId: extraPostModel.projectId,
            selectedItemIds:selectedItemIds,
            designVariationVersionName:designVariationVersionName
          });
          toastPrint.printResponse(response);
          setLoading({isLoading: false, text:""});
          setTimeout(()=>{
              setTableReload(guid.newGuid());
          }, 500);
        }, setLoading);
      }

      async function handleAnalysisComfirm (selectedItemIds, simulationType){
        setLoading({isLoading: true, text:"Submitting project for Analysis"});
        await tryCatchFinally.asyncProcess(async ()=>{
          const response = await getData.processPost(true, "api/AnalysisResult/Accessor/Analysis", {
            projectId: extraPostModel.projectId,
            selectedItemIds:selectedItemIds,
            designVariationVersionName:designVariationVersionName,
            simulationType: simulationType
          });
          toastPrint.printResponse(response);
          setLoading({isLoading: false, text:""});
          setTimeout(()=>{
              setTableReload(guid.newGuid());
          }, 500);
        }, setLoading);
      }

      const loadError = async(requestModel) => {
        await tryCatchFinally.asyncProcess(async ()=>{
          const response = await getData.processPost(true, "api/AnalysisResult/Accessor/GetErrors", requestModel);
          if(!response.state){
              toastPrint.printResponse(response);
          }
          if(response.state){
              setErrors(response.errorsAndWarnings);
          }
          setErrorLoading(false);
        });
      }

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, async (newViewModel)=>  {
            const getPostModel = {
              projectId: newViewModel.projectId,
              userId: newViewModel.userId
            };
            const response = await getData.processPost(true, getUrlDefaultDesignVariationOnly, getPostModel);
            if(response.state === true){
              dispatchUseDefaultDesignVariationOnlyViewModel({useDefaultDesignVariationOnly: response.useDefaultDesignVariationOnly});
            }else{
              toastPrint.printResponse(response);
            }
        }, setInit);
        while(true){
            const interval = setInterval(() => {
                if(!loading.isLoading){
                    setTableReload(guid.newGuid());
                }      
            }, 2 * 60 * 1000);
            return () => clearInterval(interval);
        }
    },[]);

    useEffect(()=>{
        setErrorLoading(true);
        if(selectedItemIds !== undefined && selectedItemIds.selectedItemIds !== undefined && selectedItemIds.selectedItemIds.length === 1){
            var designVariationId = selectedItemIds.selectedItemIds[0];
            var postModel = {...extraPostModel, designVariationId: designVariationId};
            loadError(postModel);
        }else{
            setErrors([]);
            setErrorLoading(false);
        }
   
    },[selectedItemIds]);

    const actionOnDoubleClickRow = (model, newData) => {
      if(model !== undefined && model. starRating !== "" && model. starRating !== "Running" && model. starRating !== "Preparing"){
        nav("/AnalysisResultAccessor/Detail/" + designVariationVersionName + "/" + projectId + "/" + model.id);
      }else{
        toastPrint.printErrorMessage("Simulation resut is not available.");
      }
    }

    const updateUseDefaultDesignVariationOnly = async (newValue)=>{
      try{
        const getPostModel = {
          projectId: viewModel.projectId,
          userId: viewModel.userId,
          useDefaultDesignVariationOnly: newValue
        };
        const response = await getData.processPost(true, postUrlDefaultDesignVariationOnly, getPostModel);
        toastPrint.printResponse(response);
      }catch (ex){
        toastPrint.printErrorMessage(ex.message);
      }
    }
    
    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <ModalDatatableItem
                modal={modal}
                setModal={setModal}
            ></ModalDatatableItem>
                <div className="app-main__inner">
                  <div className="app-page-title">
                    <div className="page-title-wrapper">
                      <div className="page-title-heading">
                        <div className="page-title-icon">
                          <i
                            className = "pe-7s-graph3 icon-gradient bg-tempting-azure"
                          ></i>
                        </div>
                        <div>
                          Analysis of design variations
                          <div className="page-title-subheading">{pageProfile.getPageSubTitle(appStoreState)}</div>
                        </div>
                      </div>
                    </div>
                  </div>  
                </div>
               <div id="AnalysisResultsAccordion">
                <div className="main-card mb-3 card">
                <div className="row">      
                    <div className="col-12">
                      <button className="btn btn-primary" onClick={()=>{handleAnalysisComfirm([], "Thermal Only")}} style={{marginLeft:"15px"}}>Analyse thermal only ({useDefaultDesignVariationOnlyViewModel.useDefaultDesignVariationOnly === true? "default DV": "all DVs"})</button>
                      <button className="btn btn-primary" onClick={()=>{handleAnalysisComfirm([], viewModel.simulationType)}} style={{marginLeft:"15px"}}>Analyse thermal and Whole of Home ({useDefaultDesignVariationOnlyViewModel.useDefaultDesignVariationOnly === true? "default DV": "all DVs"})</button>
                      <TooltipTemplate text={"Analysis of individual design variation(s) can be performed by selecting and right-clicking on the relevant rows in the following table. \nThe simulation is only cancelable during the preparing stage."} result={0} color="grey" />
                    </div>   
                     <div className="col-12">
                      <div style={{marginLeft:"15px"}} className="custom-checkbox custom-control">
                        <BindedCheckBox
                            id="UseDefaultDesignVariationOnly" 
                            name="UseDefaultDesignVariationOnly"
                            className="custom-control-input"
                            binding={[useDefaultDesignVariationOnlyViewModel, "useDefaultDesignVariationOnly", bindingUseDefaultDesignVariationOnlyViewModel, updateUseDefaultDesignVariationOnly]}
                        ></BindedCheckBox>
                          <label style={{marginLeft:"5px"}} htmlFor="UseDefaultDesignVariationOnly" className="custom-control-label">Use base design variation only</label>                          
                      </div>
                    </div>                 
                </div>
                <div id="AnalysisResultsCollapsibleDivError" className="collapse show">
                    <div className="card-body">           
                    {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>}
                    {debug && <p>{JSON.stringify(data)}</p>}
                    <ServerSideDataTable
                        id={tableId}
                        location="AnalysisResults"
                        data={data}
                        setData={setData}
                        reload={tableReload}
                        columns={columns}
                        actionOnDoubleClickRow = {actionOnDoubleClickRow}
                        allowSelect
                        allowSelectMultiple
                        dataSource={{ isAuth: true, url: "api/AnalysisResult/Accessor" }}
                        contextMenu={contextMenu}
                        setSelectedItemIds = {setSelectedItemIds}
                        extraPostModel = {extraPostModel}
                    ></ServerSideDataTable>
                    </div>
                </div>
                </div>
            </div>
            <div id="AnalysisResultsAccordionError">
                <div className="main-card mb-3 card">
                <div className="card-header">
                    <button
                    type="button"
                    data-toggle="collapse"
                    data-target="#ProjectsCollapsibleDiv"
                    aria-expanded="true"
                    aria-controls="ProjectsCollapsibleDiv"
                    className="text-left m-0 p-0 btn btn-block"
                    >
                    <h5 className="m-0 p-0 card-title">Error and warning</h5>
                    </button>
                </div>
                <div id="ProjectsCollapsibleDiv" className="collapse show">
                    <div className="card-body">           
                        {errorLoading && <p>Loading error and warning</p>}
                        <ul className="list-group">
                            {errors.map((e)=>{
                                if(e.type == 1 || e.type == 0){
                             
                                  return(<li style={{color:"red"}} className="list-group-item" key={guid.newGuid()}><b>{e.content} Type: Error</b></li>);
                                }
                                return(<li className="list-group-item" key={guid.newGuid()}>{e.content} Type: Warning</li>);                        
                            })}
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(AnalysisResult);