import Zones from "../../Portal/Accessor/Zone/Zones";
import ZoneDetailV001 from "../../Portal/Accessor/Zone/V001/ZoneDetailV001";
import MultipleZonesDetailV001 from "../../Portal/Accessor/Zone/V001/MultipleZonesDetailV001";
const AccessorZoneRouters = [
  {
    path: "ZoneAccessor/Zones/:designVariationVersionName/:developmentId/:projectId/:designVariationId",
    exact: true,
    requireAuth: true,
    element: <Zones />,
  },
  {
    path: "ZoneAccessor/Detail/V001/:projectId/:designVariationId/:zoneId",
    exact: true,
    requireAuth: true,
    element: <ZoneDetailV001 />,
  },
  {
    path: "ZoneAccessor/MultipleDetail/V001/:projectId/:designVariationId/:jsonParamHex",
    exact: true,
    requireAuth: true,
    element: <MultipleZonesDetailV001 />,
  },
];
export default AccessorZoneRouters;