import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
const PaymentDetail = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        stringCoding,
        layoutManager,
        useReducerSmart,
        pageProfile,
        AppLoading,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const { jsonParam} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        jsonParam: stringCoding.decodeObject(jsonParam)
    };

    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });

    const validateMethods = {
        // qualitySimulation:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Simulation number");},
        // qualityCertificate:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Certificate number");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);
    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        if(init===false) setInit(true);
        //loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Payment", appStoreDispatch);
    },[]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className=" pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Make Payment
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 col-3 card">
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <div className="form-row row">
                                <div className="col-12">
                                    <form autoComplete="off" id="payment-step-2-form" method="post" action={viewModel.jsonParam.nabEndPointUrl}>
                                        <input type="hidden" id="EPS_MERCHANT" name="EPS_MERCHANT" value={viewModel.jsonParam.merchantId}/>
                                        <input type="hidden" id="EPS_TXNTYPE" name="EPS_TXNTYPE" value={viewModel.jsonParam.txnType}/>
                                        <input type="hidden" id="EPS_REFERENCEID" name="EPS_REFERENCEID" value={viewModel.jsonParam.referenceId}/>
                                        <input type="hidden" id="EPS_AMOUNT" name="EPS_AMOUNT" value={viewModel.jsonParam.amount}/>
                                        <input type="hidden" id="EPS_TIMESTAMP" name="EPS_TIMESTAMP" value={viewModel.jsonParam.timestamp}/>
                                        <input type="hidden" id="EPS_FINGERPRINT" name="EPS_FINGERPRINT" value={viewModel.jsonParam.fingerPrint}/>
                                        <input type="hidden" id="EPS_RESULTURL" name="EPS_RESULTURL" value={viewModel.jsonParam.resultUrl}/>
                                        <input type="hidden" id="EPS_REDIRECT" name="EPS_REDIRECT" value="TRUE"/>
                                        <input type="hidden" id="EPS_RESULTPARAMS" name="EPS_RESULTPARAMS" value="TRUE"/>
                                        <input type="hidden" id="payamount" name="paymentamount" value={viewModel.jsonParam.amount}/>
                                        <input type="hidden" id="EPS_CALLBACKURL" name="EPS_CALLBACKURL" value={viewModel.jsonParam.callBackUrl}/>
                                        <input type="hidden" id="EPS_CALLBACKPARAMS" name="EPS_CALLBACKPARAMS" value="TRUE"/>
                                        <div className="form-row row">
                                            <div className="col-12">
                                                <div className="position-relative form-group">
                                                    <label>Payment amount (AUD): ${viewModel.jsonParam.amount}</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="position-relative form-group">
                                                    <label>Reference No: {viewModel.jsonParam.referenceId}</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="position-relative form-group">
                                                    <label htmlFor="EPS_CARDNUMBER">Card number <span>*</span></label>
                                                    <input type="text" id="EPS_CARDNUMBER" name="EPS_CARDNUMBER" className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="position-relative form-group">
                                                    <label htmlFor="EPS_EXPIRYMONTH">Expiry month <span>*</span></label>
                                                    <select name="EPS_EXPIRYMONTH" id="EPS_EXPIRYMONTH" className="form-control" required>
                                                        <option value="01">01</option>
                                                        <option value="02">02</option>
                                                        <option value="03">03</option>
                                                        <option value="04">04</option>
                                                        <option value="05">05</option>
                                                        <option value="06">06</option>
                                                        <option value="07">07</option>
                                                        <option value="08">08</option>
                                                        <option value="09">09</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="position-relative form-group">
                                                    <label htmlFor="EPS_EXPIRYYEAR">Expiry year <span>*</span></label>
                                                    <select name="EPS_EXPIRYYEAR" id="EPS_EXPIRYYEAR" className="form-control" required>
                                                        {viewModel.jsonParam.years.map((year) => (
                                                            <option key={year} value={year}>
                                                            {year}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="position-relative form-group">
                                                    <label htmlFor="EPS_CCV">CCV <span>*</span></label>
                                                    <input type="text" id="EPS_CCV" name="EPS_CCV" className="form-control" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row row">
                                            <div className="col-12">
                                                <div className="text-right pb-4">
                                                    <button className="mt-1 btn btn-primary" type="submit">Submit Payment</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default  connectAppStore(PaymentDetail);
