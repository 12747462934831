import { Fragment, useState } from "react";
import getData from "../Function/GetData";
import guid from "../Function/guid";
import toastPrint from "../Feature/ToastPrint";
import { useNavigate } from "react-router-dom";
import AppLoading from "./AppLoading";
import tryCatchFinally from "../Function/TryCatchFinally";

const BaseFormNoHeader = (props) => {
  const nav = useNavigate();
  const validate = props.validate;
  const init = props.init === undefined ? true : props.init;
  let [loading, setLoading] = useState({isLoading: false, text:""});
  if(props.loading !== undefined){
    loading = props.loading;
    setLoading = props.setLoading;
  }
 
  const handleSubmitButtonClick = async (e) => {
    e.preventDefault();
    await tryCatchFinally.asyncProcess(async ()=>{
      if(validate !== undefined){
        setLoading({isLoading:true,text:"Validating form"});
        validate.setValidateVisibility(true);
        const validateResult = validate.checkValidateResult(props.data);
        setLoading({isLoading:false,text:""});
        if (props.submitCallBack !== undefined){
          props.submitCallBack();
        }
        if(!validateResult) return;
      }
      //post data
      setLoading({isLoading:true,text:"Submitting form"});
      const submitResponse = await getData.processPost(
        true,
        props.submitButton.postUrl,
        props.data
      );
      toastPrint.printResponse(submitResponse);
      if (submitResponse.state === false) {
        setLoading({...loading, isLoading: false});
        return;
      }
      setLoading({...loading, isLoading: false});
      let redirectUrl = props.submitButton.redirectUrl;
      if(props.submitButton.redirectParams!==undefined){
        const params = props.submitButton.redirectParams(submitResponse);
        redirectUrl = redirectUrl + "/" + params;
      }
      nav(redirectUrl,{push:true});
    }, setLoading);
  };

  const handleSubmitButtonAndStayClick = async (e) => {
    e.preventDefault();
    await tryCatchFinally.asyncProcess(async ()=>{
      if(validate !== undefined){
        setLoading({isLoading:true,text:"Validating form"});
        validate.setValidateVisibility(true);
        const validateResult = validate.checkValidateResult(props.data);
        setLoading({isLoading:false,text:""});
        if (props.submitCallBack !== undefined){
          props.submitCallBack();
        }
        if(!validateResult) return;
      }
      //post data
      setLoading({isLoading:true,text:"Submitting form"});
      const submitResponse = await getData.processPost(
        true,
        props.submitButtonAndStay.postUrl,
        props.data
      );
      toastPrint.printResponse(submitResponse);
      if (submitResponse.state === false) {
        setLoading({...loading, isLoading: false});
        return;
      }
      setLoading({...loading, isLoading: false});
       let redirectUrl = props.submitButtonAndStay.redirectUrl;
      if(props.submitButtonAndStay.redirectParams!==undefined){
        const params = props.submitButtonAndStay.redirectParams(submitResponse);
        redirectUrl = redirectUrl + "/" + params;
      }
      if(props.submitButtonAndStay.redirectMethod!==undefined){
        redirectUrl = props.submitButtonAndStay.redirectMethod(submitResponse);
      }
      nav(redirectUrl,{push:true});
    }, setLoading);
  };

  const handleCancelButtonClick = async (e) => {
    e.preventDefault();
    nav(props.cancelButton.redirectUrl);
  };

  const disableSaveButton = ()=>{
    if(props.submitButtonDisabled === undefined) return false;
    return props.submitButtonDisabled;
  }

  const disableSaveButtonAndStay = ()=>{
    if(props.submitButtonAndStayDisabled === undefined) return false;
    return props.submitButtonAndStayDisabled;
  }

  return (
    <Fragment>
      {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
      <form autoComplete="off" id={props.id === undefined ? "from-" + guid.newGuid() : props.id} noValidate="noValidate">
        {init &&
          <>
              <div className="card-body">
          {props.cardTitle !== undefined && <h5 className="card-title">{props.cardTitle}</h5>}    
          { props.children}
        </div>
        <div className="card-footer">
          <div className="ml-auto">
            {props.submitButton !== undefined && (
              <button
                className="btn btn-primary btn-round"
                style={{marginRight: "10px"}}
                type="submit"
                id="SubmitButton"
                disabled = {disableSaveButton()}
                onClick={(e) => {
                  handleSubmitButtonClick(e);
                }}
              >
                {props.submitButton.text === undefined
                  ? "Submit"
                  : props.submitButton.text}
              </button>
            )}
            {props.submitButtonAndStay !== undefined && (
              <button
                className="btn btn-primary btn-round"
                type="submit"
                id="SubmitButtonAndStay"
                style={{marginRight: "10px"}}
                disabled = {disableSaveButtonAndStay()}
                onClick={(e) => {
                  handleSubmitButtonAndStayClick(e);
                }}
              >
                {props.submitButtonAndStay.text === undefined
                  ? "Submit and Stay"
                  : props.submitButtonAndStay.text}
              </button>
            )}
            {props.cancelButton !== undefined && (
              <a
                className="btn btn-primary btn-round"
                id="CancelButton"
                style={{marginRight: "10px"}}
                onClick={(e) => {
                  handleCancelButtonClick(e);
                }}
              >
                {props.cancelButton.text === undefined
                  ? "Cancel"
                  : props.cancelButton.text}
              </a>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
          </>
        
        }
    
      </form>
    </Fragment>
  );
};
export default BaseFormNoHeader;
