import { Fragment, useEffect } from "react";
import { connectAppStore } from "../Store/AppStore";
import layoutManager from "../Layout/LayoutManager";
import "./Page404.css";
const Page404 = (props)=>{
    useEffect(()=>{
      layoutManager.Layout_HideHeadter_Footer_HideSideBar(props);
    },[])
    return(<Fragment>
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1><span>4</span><span>0</span><span>4</span></h1>
        </div>
        <h2>we are sorry, but the page you requested was not found</h2>
      </div>
    </div>
    </Fragment>);
}
export default connectAppStore(Page404);