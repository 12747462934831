import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";
import GoogleAddress from "../Accessor/Default/GoogleAddress";
import PasswordChecklist from "react-password-checklist";

const UserDetail = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        guid,
        useReducerSmart,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const id = defaultParams.id;
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const [reloadAddress, setReloadAddress] = useState("");
    const defaultViewModel = {
        ...defaultParams,
        password: "",
        passwordConfirmation: "",
        passwordValidate: false
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const setAddress = (address)=>{dispatchViewModel({...viewModel, address: address}); }
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    //change model get url here if not using default model
    const getUrl = "api/User/Admin/Get";
    //add model post url here
    const postUrl = "api/User/Admin/Update";
    const validateMethods = {
        //add form property validate here
        roleId:(value)=>{return parseInt(value) >= 1 ? "" : "Select a user role";},
        userLevelId:(value)=>{return parseInt(value) >= 1 ? "" : "Select a user level";},
        email:(value)=>{return FormValidate.validateEmail(value);},
        firstName:(value)=>{return FormValidate.validateTextRequired(value, "First name");},
        lastName:(value)=>{return FormValidate.validateTextRequired(value, "Last name");}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), true);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, ()=>{
            setReloadAddress(guid.newGuid());
          }, setInit);
    },[]);

    const disableButton = ()=>{
        if(viewModel.password === undefined && viewModel.passwordConfirmation === undefined) return false;
        if(viewModel.password !== undefined && viewModel.passwordConfirmation !== undefined && viewModel.password.length === 0 && viewModel.passwordConfirmation.length === 0) return false;
        return !viewModel.passwordValidate;
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <BaseForm
                id="AdminUserForm"
                icon="pe-7s-info"
                loading={loading}
                setReload={setReloadForm}
                setLoading={setLoading}
                submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: "/Admin/Users" }}
                submitButtonAndStay = {{text:"Save", postUrl: postUrl, redirectUrl: "/Admin/Users/Detail/" + id}}
                cancelButton={{ redirectUrl: "/Admin/Users" }}
                submitButtonAndStayDisabled = {disableButton()}
                submitButtonDisabled = {disableButton()}
                data={viewModel}
                validate ={formValidate}
                title = "User detail"
                subTitle={"Create a user"}
            >
                <div className="form-row row">
                    <div className="col-3">
                        <label htmlFor="RoleId">User Role *</label>
                        <BindedSelect
                        className="form-control"
                        id="RoleId"
                        name="RoleId"
                        binding={[viewModel, "roleId", bindingViewModel]}
                        options={"roles"}
                        validate ={formValidate}
                        ></BindedSelect>
                    </div>
                    <div className="col-3">
                        <label htmlFor="UserLevelId">User Level *</label>
                        <BindedSelect
                        className="form-control"
                        id="UserLevelId"
                        name="UserLevelId"
                        binding={[viewModel, "userLevelId", bindingViewModel]}
                        options={"userLevels"}
                        validate ={formValidate}
                        ></BindedSelect>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="MagicPlanUserName">MagicPlan User Name</label>
                            <BindedInput id="MagicPlanUserName" name="MagicPlanUserName" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "magicPlanUserName", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="FirstName">Email *</label>
                            <BindedInput id="Email" name="Email" type="email" validate ={formValidate} className="form-control" binding={[viewModel, "email", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="FirstName">First Name *</label>
                            <BindedInput id="FirstName" name="FirstName" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "firstName", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="LastName">Last Name *</label>
                            <BindedInput id="LastName" name="LastName" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "lastName", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="AAO">AAO</label>
                            <BindedInput id="AAO" name="AAO" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "accreditingAssessorOrganization", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="MemberNumber">Member number</label>
                            <BindedInput id="MemberNumber" name="MemberNumber" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "memberNumber", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="Organization">Organization</label>
                            <BindedInput id="Organization" name="Organization" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "organization", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="Phone">Phone</label>
                            <BindedInput id="Phone" name="Phone" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "phone", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="ABN">ABN</label>
                            <BindedInput id="ABN" name="ABN" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "abn", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="Password">Password *</label>
                            <BindedInput id="Password" name="Password" autoComplete="new-password" type="password" className="form-control" binding={[viewModel, "password", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="PasswordConfirmation">Password confirmation *</label>
                            <BindedInput id="PasswordConfirmation" autoComplete="new-password" name="Password" type="password" className="form-control" binding={[viewModel, "passwordConfirmation", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-6">
                        <PasswordChecklist
                                rules={["minLength","specialChar","number","capital","match"]}
                                minLength={8}
                                value={viewModel.password}
                                valueAgain={viewModel.passwordConfirmation}
                                onChange={(isValid) => {
                                    if(isValid !== viewModel.passwordValidate){
                                        dispatchViewModel({...viewModel, passwordValidate: isValid});
                                    }
                                }}
                            />
                    </div>
                </div>
                <div className="form-row row">
                    {/* Address */}
                    <GoogleAddress 
                        id="AddressDewelling"
                        reload = {reloadAddress}
                        setReload = {setReloadAddress}
                        address = {viewModel.address}
                        setAddress = {setAddress}
                        googleMapsApiKey = {viewModel.googleMapsApiKey}
                        validate={formValidate}
                        debug = {debug}
                    ></GoogleAddress>
                </div>  
            </BaseForm>
        </Fragment>
    );
}
export default connectAppStore(UserDetail);