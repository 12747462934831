import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
const PurchaseCredits = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        tryCatchFinally,
        stringCoding,
        layoutManager,
        getData,
        useReducerSmart,
        BindedInput,
        getAppStore,
        useNavigate,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const nav = useNavigate();
    const [init, setInit] = useState(false);
    const {} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/Credit/Accessor/Get";
    const validateMethods = {
        qualitySimulation:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Simulation number");},
        qualityCertificate:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Certificate number");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Purchase Credits", appStoreDispatch, undefined, setInit);
    },[]);

    const calculateAmount = (unit, quanlity) => {
        if (unit === undefined || unit === "" || unit === null) unit = 0;
        if (quanlity === undefined || quanlity === "" || quanlity === null) quanlity = 0;
        unit = Number(unit);
        quanlity = Number(quanlity);
        return ((unit * quanlity)).toFixed(2);
    };

    const calculateTotalAmount = () => {
        var simulation = calculateAmount(viewModel.unitPriceSimulation,viewModel.qualitySimulation);
        var certificate = calculateAmount(viewModel.unitPriceCertificate,viewModel.qualityCertificate);
        simulation = Number(simulation);
        certificate = Number(certificate);
        return ((simulation + certificate)).toFixed(2);
    };

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/Credit/Accessor/GetPayment";
        setLoading({ isLoading: true, text: "Submitting" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            const urlParams = stringCoding.encodeObject(response.viewModel);
            nav("/UserAccessor/Credits/Payment/" + urlParams);
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Purchase Credits
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                            <div className="main-card mb-3 col-3 card">
                            <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                                <div className="card-body">
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <label htmlFor="UnitPriceSimulation">Price: ${viewModel.unitPriceSimulation} / Simulation, ${viewModel.unitPriceCertificate} / Certificate </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <label htmlFor="QualitySimulation">Simulation purchase </label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="QualitySimulation"
                                                    name="QualitySimulation"
                                                    type="number"
                                                    value="1000"
                                                    min="0"
                                                    step="1000"
                                                    validate = {formValidate}
                                                    binding={[viewModel, "qualitySimulation", bindingViewModel]}
                                                ></BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <label htmlFor="QualityCertificate">Certificate purchase </label>
                                                <BindedInput
                                                    className="form-control"
                                                    id="QualityCertificate"
                                                    name="QualityCertificate"
                                                    type="number"
                                                    value="10"
                                                    min="0"
                                                    step="10"
                                                    validate = {formValidate}
                                                    binding={[viewModel, "qualityCertificate", bindingViewModel]}
                                                ></BindedInput>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <label htmlFor="AmountSimulation">Amount simulation: ${calculateAmount(viewModel.unitPriceSimulation,viewModel.qualitySimulation)} (inc.GST)</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <label htmlFor="AmountCertificate">Amount certificate: ${calculateAmount(viewModel.unitPriceCertificate,viewModel.qualityCertificate)} (inc.GST)</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <label htmlFor="AmountLabel">Total amount: ${calculateTotalAmount()} (inc.GST)</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row row">
                                        <div className="col-12">
                                            <div className="position-relative form-group">
                                                <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Checkout</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
    
            </div>
        </Fragment>
    );
}
export default  connectAppStore(PurchaseCredits);
