import React from "react";
import { FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";
import PageDependencies from "../../../Feature/PageDependencies";
import "./WindowLibTree.css";
const SkylightRoofWindowLibTree = (props) => {
  const debug = props.debug === undefined ? false : props.debug;
  const { 
      tryCatchFinally,
      getData,
      guid,
      useState, 
      Fragment, 
      useEffect
  } = PageDependencies();

    const treeId = props.id === undefined ? guid.newGuid() : props.id;
    const setViewModel = props.setViewModel;
    const viewModel = props.viewModel;
    const defaultTree = {data:flattenTree({})};
    const [loading, setLoading] = useState({isLoading: false, text:""});
    const [treeData, setTreeData] =  useState(defaultTree);
    const [rawData, setRawData] = useState([]);
    const treeUrl = "api/Construction/Accessor/GetRoofWindowSkylightTree";
    const defaultPostModel = {type: props.typeName};
    const loadTree = async ()=>{
      await tryCatchFinally.syncProcess(async ()=>{
        setLoading({isLoading: true, text:"Loading windows"});
        const selectResponse = await getData.processPost(true, treeUrl, defaultPostModel); 
         const treeData = {data:flattenTree(selectResponse.data.treeRoot)};
         setTreeData(treeData);
         setRawData(selectResponse.data.data);
      }, setLoading);
    }
    useEffect(()=>{      
      loadTree();
    },[]);

    const FolderIcon = ({ isOpen }) =>
    isOpen ? (
        <FaRegFolderOpen color="e8a87c" className="icon" />
      ) : (
        <FaRegFolder color="e8a87c" className="icon" />
      );

    const FileIcon = ({ filename }) => {
        //const extension = filename.slice(filename.lastIndexOf(".") + 1);
        return null;
    };

    const onSelect = (e)=>{
      const windowName = e.element.name;
      const windowId = e.element.id;
      const childrenCount= e.element.children.length;
      if(windowName != "Loading" && childrenCount == 0){
        const window = rawData.filter((w)=>{return w.id == windowId})[0];
        const newViewModel = {...viewModel, systemCode: window.systemCode, uValue: window.uValue, shgc: window.shgc};
        setViewModel(newViewModel);
      }
    }

    return(<Fragment>
         {debug && <p>{JSON.stringify(defaultPostModel)}</p>}
         <div className="row dataTables_wrapper dt-bootstrap4">
          <div className="row" id={treeId}>
            {rawData.length == 0 && <h6>No roof windows or skylights found</h6>}
            {loading.isLoading && <h6>Loading {props.typeName.toLowerCase()}s</h6>}
            <div className="directory">
              <TreeView
                onBlur={({ treeState, dispatch }) => {
                  dispatch({
                    type: "DESELECT",
                    id: Array.from(treeState.selectedIds)[0],
                  });
                }}
                data={treeData.data}
                onSelect={(e)=>onSelect(e)}
                aria-label="directory tree"
                nodeRenderer={({
                  element,
                  isBranch,
                  isExpanded,
                  getNodeProps,
                  level,
                }) => (
                  <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                    {isBranch ? (
                      <FolderIcon isOpen={isExpanded} />
                    ) : (
                      <FileIcon filename={element.name} />
                    )}
                    {element.name}
                  </div>
                )}
              />
            </div>
          </div>
         </div>
    </Fragment>);
}
export default SkylightRoofWindowLibTree;