import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import ConstructionElementDataTable from "../../../Accessor/Construction/ConstructionElementDataTable";

const SkylightDeatil = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        useLocation,
        layoutManager,
        guid,
        useReducerSmart,
        BaseFormNoHeader,
        BindedInput,
        BindedCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const currentUrl = useLocation().pathname;
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const roofId = defaultParams.parentId;
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const { elementId } = defaultParams;
    const {designVariationVersionName, developmentId, projectId, designVariationId, zoneId, parentId, redirectUrlHex} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        selectedItemIds: [elementId]
    };

    const [extraPostModelForConstruction, setExtraPostModelForConstruction] = useState({
        developmentId: developmentId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName,
        constructionType: "Skylight"
    });
    const [tableReloadForConstruction, setTableReloadForConstruction] = useState("");
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    //change model get url here if not using default model
    const getUrl = "api/Element/Accessor/InitializeSkylight";
    //add model post url here
    const postUrl = "api/Element/Accessor/UpdateSkylight";
    const validateMethods = {
        name:(value)=>{return FormValidate.validateTextRequired(value, "Skylight name");},
        constructionId:(value)=>{ return value === emptyGuid ? "Must select a construction." : "";},
        outdoorShadingPercentTypeId:(value)=>{ return value == "-999" ? "Must select a type." : "";},
        shaftTypeId:(value)=>{ return value == "-999" ? "Must select a type." : "";},
        openingArea: (value) => { return FormValidate.validateNumberGreaterThanOrEqual0(value, "Opening area");},
        grossArea: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Gross area");},
        pitch: (value) => { return FormValidate.validateNumberBetween(value, "Pitch", 0, 359);},
        shaftLength: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Shaft length");},
        shaftReflectance: (value) => { return FormValidate.validateNumberGreaterThan0(value, "Shaft reflectance");},
        shaftInsulationRValue: (value) => { return FormValidate.validateNumberGreaterThanOrEqual0(value, "Shaft insulation R-value");}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Skylight", appStoreDispatch, undefined, setInit);
    }, []);

    useEffect(() => {
        if (init) {
            dispatchViewModel({ ...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId] },() => {
                setReloadForm(guid.newGuid());
            });
        }
    }, [elementId]);

    const generateStayUrl = (submitResponse) => {
        let redirectUrl = currentUrl;
        if (elementId === emptyGuid) {
            //ElementAccessor/Detail/Skylight/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex
            redirectUrl =  "/ElementAccessor/Detail/Skylight/" + designVariationVersionName + "/" + developmentId +"/"+projectId+"/"+designVariationId+"/"+zoneId+"/"+submitResponse.elementId +"/"+parentId+"/"+redirectUrlHex;
        }
        return redirectUrl;
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {debug && <p>{JSON.stringify(roofId)}</p>}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-safe icon-gradient bg-tempting-azure"></i>
                            </div>
                            <div>
                                Skylight
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-12">
                        <div className="main-card mb-3 card">
                            <BaseFormNoHeader
                                id="SkylightDetail"
                                submitButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl, text: "Save and close" }}
                                submitButtonAndStay={{ text: "Save", postUrl: postUrl, redirectMethod: (submitResponse) => { return generateStayUrl(submitResponse) } }}
                                cancelButton={{ postUrl: postUrl, redirectUrl: viewModel.redirectUrl }}
                                validate={formValidate}
                                setReload={setReloadForm}
                                loading={loading}
                                init={init}
                                setLoading={setLoading}
                                data={viewModel}
                                cardTitle="SKYLIGHT"
                            >
                                <div className="form-row row">
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="SkylightName">Name *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="SkylightName"
                                                name="SkylightName"
                                                type="text"
                                                validate={formValidate}
                                                binding={[viewModel, "name", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ZoneLitZoneId">
                                                Zone Lit*
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ZoneLitZoneId"
                                                name="ZoneLitZoneId"
                                                options="zoneLitZones"
                                                validate={formValidate}
                                                binding={[
                                                    viewModel,
                                                    "zoneLitZoneId",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="SkylightConstructionId">
                                                Construction *
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="SkylightConstructionId"
                                                name="SkylightConstructionId"
                                                options="constructions"
                                                validate={formValidate}
                                                binding={[viewModel, "constructionId", bindingViewModel]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="position-relative form-group">
                                            <label htmlFor="OutdoorShadingPercentTypeId">
                                                Outdoor Shading*
                                            </label>
                                            <BindedSelect
                                                className="form-control"
                                                id="OutdoorShadingPercentTypeId"
                                                name="OutdoorShadingPercentTypeId"
                                                options="outdoorShadingPercentTypes"
                                                validate={formValidate}
                                                binding={[
                                                    viewModel,
                                                    "outdoorShadingPercentTypeId",
                                                    bindingViewModel,
                                                ]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                        <label htmlFor="OpenablePercent">Openable (%) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="OpenablePercent"
                                                name="OpenablePercent"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "openablePercent", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label htmlFor="GrossArea">Gross Area (m<sup>2</sup>) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="GrossArea"
                                                name="GrossArea"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "grossArea", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label htmlFor="Pitch">Pitch (degree) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="Pitch"
                                                name="Pitch"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "pitch", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ShaftTypeId">Shaft type *</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ShaftTypeId"
                                                name="ShaftTypeId"
                                                options="shaftTypes"
                                                validate={formValidate}
                                                binding={[viewModel, "shaftTypeId", bindingViewModel]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ShaftLength">Shaft Length *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="ShaftLength"
                                                name="ShaftLength"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "shaftLength", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ShaftReflectance">Shaft Reflectance *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="ShaftReflectance"
                                                name="ShaftReflectance"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "shaftReflectance", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ShaftInsulationRValue">Shaft insulation (R-value) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="ShaftInsulationRValue"
                                                name="ShaftInsulationRValue"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "shaftInsulationRValue", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            id="HasDiffuser"
                                            name="HasDiffuser"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "hasDiffuser",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="HasDiffuser"
                                            className="custom-control-label"
                                        >
                                            Diffuser
                                        </label>
                                    </div>
                                </div>
                                <div className="form-row row">
                                    <div className="custom-checkbox custom-control">
                                        <BindedCheckBox
                                            id="IsVented"
                                            name="IsVented"
                                            className="custom-control-input"
                                            binding={[
                                                viewModel,
                                                "isVented",
                                                bindingViewModel,
                                            ]}
                                        ></BindedCheckBox>
                                        <label
                                            style={{ marginLeft: "5px" }}
                                            htmlFor="IsVented"
                                            className="custom-control-label"
                                        >
                                            Vented
                                        </label>
                                    </div>
                                </div>
                            </BaseFormNoHeader>
                        </div>
                    </div>
                    {init === true &&
                    <div className="col-xl-6 col-lg-12">
                    <div className="main-card mb-3 card">
                            <div className="card-header">
                                <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                                    <h5 className="m-0 p-0 card-title">SKYLIGHT Constructions</h5>
                                </button>
                            </div>
                            <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                                <div className="card-body">
                                    <div className="separator"></div>
                                    <ConstructionElementDataTable
                                        tableId={"SkylightConstructionElement-84078b3f-c84a-45f1-8060-774b099358f4"}
                                        tableReload={tableReloadForConstruction}
                                        setTableReload={setTableReloadForConstruction}
                                        extraPostModel={extraPostModelForConstruction}
                                        constructionType="Skylight"
                                        redirectUrl={currentUrl}
                                        debug={debug}
                                    >
                                    </ConstructionElementDataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(SkylightDeatil);