import { Fragment } from "react";
import PageDependencies from "../../../Feature/PageDependencies";

const HeatingCoolingSimulationDataTable = (props)=>{
    const { 
        CilentSideDataTable,
    } = PageDependencies();
    const debug = props.debug === undefined ? false : props.debug;
    const data = props.data;
    const setData = props.setData;
    const tableId = "Summary_3e7e4f5b-51ba-4962-b0e5-3f5c955d7242";
    const tableReload = props.tableReload;
    const columns =[
      { name: "occupancyType", text: "Occupancy Type", width: "16%" },
      { name: "cooling", text: "Cooling", width: "16%" },
      { name: "heatingElectricity", text: "Heating Electricity", width: "16%" },
      { name: "heatingLpg", text: "Heating LPG", width: "16%" },
      { name: "heatingGas", text: "Heating Gas", width: "16%" },
      { name: "heatingOthers", text: "Heating Others", width: "20%" },
    ];

      return(<Fragment>
            {debug && <p>{JSON.stringify(data)}</p>}
            <CilentSideDataTable
              disableSorting
              removePaging
              removeSearch
              debug ={debug}
              id={tableId}
              location="ModuleSummary"
              data={data}
              setData={setData}
              reload={tableReload}
              columns={columns}
              ></CilentSideDataTable>
      </Fragment>);
}
export default HeatingCoolingSimulationDataTable;