import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";

const ElementDataTableNew = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        useLocation,
        tryCatchFinally,
        stringCoding,
        layoutManager,
        getData,
        guid,
        toastPrint,
        ModalDatatableItem,
        useNavigate,
        pageProfile,
        AppLoading,
        dataExtension,
        ServerSideDataTable,
        usePrevious,
        cookiesManager   
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const tableId = "ElementNew_c91b5d97-453f-42b3-ab3c-2df5f314dd4e";

    const location = props.location === undefined ? "zone" : props.location;
    const nav = useNavigate();
    const emptyGuid = guid.emptyGuid();
    const redirectPathHex = stringCoding.encode(useLocation().pathname);
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
    });
    const [data, setData] = useState([]);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [tableReload, setTableReload] = useState("");
    let [selectedIds, setSelectedIds] = useState([]);
    const {designVariationVersionName, developmentId, projectId, designVariationId, redirectUrl, jsonParam} = defaultParams;
    const selectCookieId = "ElementNewCookie_c91b5d97-453f-42b3-ab3c-2df5f314dd4e_" + designVariationId;
    if (props.selectedIds !== undefined) selectedIds = props.selectedIds;
    if (props.setSelectedIds !== undefined) setSelectedIds = props.setSelectedIds;
    let zoneIds = props.zoneIds === undefined ? [] : props.zoneIds;
    if(zoneIds == 'undefined') zoneIds = [];
    const zoneId = zoneIds.length === 0 ? emptyGuid : zoneIds[0];
    const [extraPostModel, setExtraPostModel] = useState({
        designVariationVersionName: designVariationVersionName,
        projectId: projectId,
        designVariationId: designVariationId,
        zoneIds: props.zoneIds === undefined ? [] : props.zoneIds,
        elementTypes: props.elementTypes === undefined ? [] : props.elementTypes
    });
    const columns = [
        { name: "elementName", text: "Name", width: "15%" },
        { name: "elementType", text: "Type", width: "10%" },
        { name: "zoneName", text: "Zone", width: "10%" },
        { name: "adjacentZoneName", text: "Adjacent Zone", width: "10%", disableSorting:true },
        { name: "constructionName", text: "Construction", width: "15%" },
        { name: "netArea", text: "Net area (m<sup>2</sup>)", width: "10%" },
        { name: "grossArea", text: "Gross area (m<sup>2</sup>)", width: "10%" },
        { name: "openingArea", text: "Opening area (m<sup>2</sup>)", width: "10%" },
        { name: "azimuth", text: "Azimuth (<sup>o</sup>)", width: "10%" },
    ];

    const generateUrlParams = (elementType, elementId)=>{
      const gUrl =  '/ElementAccessor/Detail/' + elementType + '/' + designVariationVersionName + '/' + developmentId + '/'+ projectId + '/' + designVariationId + '/' + zoneId + '/' + elementId + '/' + redirectPathHex;
      return gUrl;
    }

    const checkSelectedTypes = () => {
        const rows = dataExtension.getSelectedRows(data);
        let type = "";
        for(let i=0; i<rows.length; i++){
          if(i==0){
            type = rows[i].elementType;
          }
          else{
            if(type != rows[i].elementType){
              return true;
            }
          }
        }
        return false;
      }

      const createMenuItems = {
        zone: {
          createRoof: {
            name: "Roof",
            icon: "",
            url: generateUrlParams("Roof", emptyGuid),
            isNavOption: true
          },
          createFloor: {
            name: "Floor",
            icon: "",
            url: generateUrlParams("Floor", emptyGuid),
            isNavOption: true
          },
          createCeiling: {
            name: "Ceiling",
            icon: "",
            url: generateUrlParams("Ceiling", emptyGuid),
            isNavOption: true
          },
          createExternalWall: {
            name: "External Wall",
            icon: "",
            url: generateUrlParams("ExternalWall", emptyGuid),
            isNavOption: true
          },
          createInternalWall: {
            name: "Internal Wall",
            icon: "",
            url: generateUrlParams("InternalWall", emptyGuid),
            isNavOption: true
          },
        },
        externallWall: {
          createDoor: {
            name: "Door",
            icon: "",
            url: generateUrlParams("Door", emptyGuid),
            isNavOption: true
          },
          createWindow: {
            name: "Window",
            icon: "",
            url: generateUrlParams("Window", emptyGuid),
            isNavOption: true
          },
        },
        roof: {
          createRoofWindow: {
            name: "Roof Window",
            icon: "",
            url: generateUrlParams("RoofWindow", emptyGuid),
            isNavOption: true
          },
          createSkylight: {
            name: "Skylight",
            icon: "",
            url: generateUrlParams("Skylight", emptyGuid),
            isNavOption: true
          },
        },
      };

      const handleDeleteComfirm = async () => {
        await tryCatchFinally.asyncProcess(async ()=>{
          const selectedItemDic = dataExtension.getSelectedRowsDic(data, "elementType");
          setLoading({isLoading:true,text:"Deleting elements"});
          const selectResponse = await getData.processPost(
          true,
          "api/Element/Accessor/DeleteElements",
              {selectedItemDic: selectedItemDic, designVariationVersionName: props.designVariationVersionName, projectId: props.projectId, designVariationId: props.designVariationId}
          ); 
        toastPrint.printResponse(selectResponse);
        setTableReload(guid.newGuid());
        }, setLoading);
      }
  
      const handleDuplicatedComfirm = async () => {
        await tryCatchFinally.asyncProcess(async ()=>{
          const selectedItemDic = dataExtension.getSelectedRowsDic(data, "elementType");
          setLoading({isLoading:true,text:"Duplicating elements"});
          const selectResponse = await getData.processPost(
          true,
          "api/Element/Accessor/CopyElements",
              {selectedItemDic: selectedItemDic, designVariationVersionName: props.designVariationVersionName, projectId: props.projectId, designVariationId: props.designVariationId, zoneId: props.zoneId}
          ); 
        toastPrint.printResponse(selectResponse);
        setTableReload(guid.newGuid());
        }, setLoading);
      }
  
      const contextMenu = {
          items: {
            new: {
              name: "New",
              icon: "add",
              defaultAction: false,
              items: createMenuItems[location]
            },
            separator: "---------",
            edit: {
                name: "Edit",
                icon: "edit",
                defaultAction: false,
                  disabled: function () {
                  return dataExtension.getSelectedRowDataIds(data).length === 0 || checkSelectedTypes();
                },
              },
              delete: {
                name: "Delete",
                icon: "delete",
                url: "",
                defaultAction: false,
                disabled: function () {
                  return dataExtension.getSelectedRowDataIds(data).length === 0;
                },
              },
              duplicate: {
                name: "Duplicate",
                icon: "copy",
                url: "",
                defaultAction: false,
                disabled: function () {
                  return dataExtension.getSelectedRowDataIds(data).length === 0;
                },
              }
          },
          callBack: (
            contextMenuCallBackKey,
            contextMenuCallBackElement,
            contextMenuCallBackTableRoadData,
            localtion
          ) => {   
            if (contextMenuCallBackKey === "edit") {
              const selectedElementIds = dataExtension.getSelectedRowDataIds(data);
              if(selectedElementIds.length == 1){
                 nav(generateUrlParams(dataExtension.getSelectedRows(data)[0].elementType.replace(" ","").replace("/",""), selectedElementIds[0]));
              }else{
                  var type = dataExtension.getSelectedRows(data)[0].elementType.replace(" ","").replace("/","");
                  const urlParams = props.designVariationVersionName + "/" + props.developmentId + "/" + props.projectId + "/" + props.designVariationId + "/" + props.zoneId + "/" + stringCoding.encodeObject(selectedElementIds) + "/" + redirectPathHex;
                  nav("/ElementAccessor/MultipleDetail/" + type + "/" + urlParams);
              }
           }
           if (contextMenuCallBackKey === "delete"){
              setModal({
                ...modal,
                isOpen: true,
                title: "Delete elements",
                message:
                  "Are sure to delete " +
                  dataExtension.getSelectedRowDataIds(data).length +
                  " selected elements?",
                comfirmButtonStyle: "danger",
                comfirmButtonText: "Confirm to delete",
                handleComfirm: handleDeleteComfirm,
              });
           }
           if (contextMenuCallBackKey === "duplicate") {
            setModal({
              ...modal,
              isOpen: true,
              title: "Duplicate elements",
              message:
                "Are sure to duplicate " +
                dataExtension.getSelectedRowDataIds(data).length +
                " selected elements?",
              comfirmButtonStyle: "primary",
              comfirmButtonText: "Confirm to duplicate",
              handleComfirm: handleDuplicatedComfirm,
            });
          }
          },
        };

    const actionOnDoubleClickRow = (model, newData) =>{
        nav(generateUrlParams(model.elementType.replace(" ","").replace("/",""), model.id.split("_")[0]));
    }

    useEffect(()=>{
        if(props.zoneIds === undefined || props.elementTypes === undefined) return;
        setExtraPostModel(oldModel=>{
            const newModel = {
                ...oldModel, 
                zoneIds: props.zoneIds,
                elementTypes: props.elementTypes
            }
            return newModel;
        });
    },[props.zoneIds, props.elementTypes]);

    const previousDataLength = usePrevious(data.length);
    useEffect(()=>{
      tryCatchFinally.syncProcess(()=>{
        if(previousDataLength !== undefined && previousDataLength == 0 && data.length > 0){
          const cookie = cookiesManager.getCookies(selectCookieId, {selectedIndexes: []});
          if(cookie.selectedIndexes.length > 0){
              const newData = [];
              const newSelectedIds = {selectedItemIds:[]};
              for(let i = 0; i < data.length; i++ ){
                  newData.push({...data[i]});
              }
              for(let i = 0; i < data.length; i++ ){
                  if(cookie.selectedIndexes.filter(index=>index==i).length > 0){
                      newData[i].isSelected = true;
                      //fix id 
                      const id = newData[i].id.split('_')[0];
                      newSelectedIds.selectedItemIds.push(id)
                  }
              }
              setData(newData);
              setSelectedIds(newSelectedIds);
          }
        }   
      });
    }, [data.length])

    useEffect(()=>{
      tryCatchFinally.syncProcess(()=>{
        if (data !== undefined && data.length > 0){
          const cookie = {selectedIndexes: []};
          for(let i = 0; i < data.length; i++ ){
             if(data[i].isSelected == true){
              cookie.selectedIndexes.push(i);
             }
          }
          cookiesManager.setCookies(selectCookieId, cookie);
        }
      });       
    }, [data]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(extraPostModel)}</p>}
            {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>}
            <ModalDatatableItem
          modal={modal}
          setModal={setModal}
        ></ModalDatatableItem>
            <ServerSideDataTable
                id={tableId}
                location="Element"
                data={data}
                setData={setData}
                reload={tableReload}
                columns={columns}
                actionOnDoubleClickRow = {actionOnDoubleClickRow}
                allowSelect
                allowSelectMultiple
                dataSource={{ isAuth: true, url: "api/Element/Accessor/IndexNew" }}
                contextMenu={contextMenu}
                extraPostModel={extraPostModel}
                setSelectedItemIds = {setSelectedIds}
        ></ServerSideDataTable>
        </Fragment>
    );
}
export default connectAppStore(ElementDataTableNew);