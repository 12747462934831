import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";
const ApproveRefund = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        stringCoding,
        tryCatchFinally,
        toastPrint,
        layoutManager,
        getData,
        useReducerSmart,
        BindedInput,
        getAppStore,
        useNavigate,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const nav = useNavigate();
    const [init, setInit] = useState(false);
    const { jsonParam } = defaultParams;
    const defaultViewModel = stringCoding.decodeObject(jsonParam);
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/DefaultModel/Accessor/Get";
    const validateMethods = {
        // qualitySimulation:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Simulation number");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Refund Data", appStoreDispatch, undefined, setInit);
    },[]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/PaymentAdmin/ApproveRefund";
        setLoading({ isLoading: true, text: "Submitting" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            nav("/Admin/PaymentRefunds");
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Approve Refund
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                          <div className="main-card mb-3 col-3 card">
                          <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                              <div className="card-body">
                                  <div className="form-row row">
                                      <div className="col-12">
                                          <div className="position-relative form-group">
                                              <label htmlFor="PaymentTransactionId">Payment Transaction Id: {viewModel.paymentTransactionId}</label>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="form-row row">
                                      <div className="col-12">
                                          <div className="position-relative form-group">
                                              <label htmlFor="CustomerInfo">Customer: {viewModel.customerInfo}</label>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="form-row row">
                                      <div className="col-12">
                                          <div className="position-relative form-group">
                                              <label htmlFor="CompletedTime">Payment Completed Time: {viewModel.completedTime}</label>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="form-row row">
                                      <div className="col-12">
                                          <div className="position-relative form-group">
                                              <p>Payment Detail: </p>
                                              <div className="form-row row">
                                                  <div className="col-6">
                                                      <div className="position-relative form-group">
                                                          <label htmlFor="UnitPriceSimulation">Unit Price Simulation: ${viewModel.purchaseCredits.unitPriceSimulation}</label>
                                                      </div>
                                                  </div>
                                                  <div className="col-6">
                                                      <div className="position-relative form-group">
                                                          <label htmlFor="QualitySimulation">Quality Simulation: {viewModel.purchaseCredits.qualitySimulation}</label>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="form-row row">
                                                  <div className="col-6">
                                                      <div className="position-relative form-group">
                                                          <label htmlFor="UnitPriceCertificate">Unit Price Certificate: ${viewModel.purchaseCredits.unitPriceCertificate}</label>
                                                      </div>
                                                  </div>
                                                  <div className="col-6">
                                                      <div className="position-relative form-group">
                                                          <label htmlFor="QualityCertificate">Quality Certificate: {viewModel.purchaseCredits.qualityCertificate}</label>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="form-row row">
                                      <div className="col-12">
                                          <div className="position-relative form-group">
                                              <label htmlFor="TransactionId">Transaction Id * </label>
                                              <BindedInput
                                                  className="form-control"
                                                  id="TransactionId *"
                                                  name="TransactionId *"
                                                  type="text"
                                                  binding={[viewModel, "transactionId *", bindingViewModel]}
                                              ></BindedInput>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="form-row row">
                                      <div className="col-12">
                                          <div className="position-relative form-group">
                                              <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Refund</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                
                }
      
            </div>
        </Fragment>
    );
}
export default connectAppStore(ApproveRefund);
