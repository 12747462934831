import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import ConstructionDataTable from "./ConstructionDataTable";
import ConstructionLibraryDataTable from "./ConstructionLibraryDataTable";
import { SharedConstructionsContext } from "./SharedConstructionsContext";
const Constructions = (props)=>{
    const { 
        layoutManager,
        useParams,
        Fragment, 
        useEffect, 
        useState,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        useLocation,
        useReducerSmart
    } = PageDependencies();
    const redirectUrl = useLocation().pathname;
    const projectId = useParams().projectId;
    const developmentId = useParams().developmentId;
    const designVariationId = useParams().designVariationId;
    const designVariationVersionName = useParams().designVariationVersionName;
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const [loading, setLoading] = useState({isLoading:false,text:""});
    const [copiedItem, setCopiedItem] = useState([]);
    const [extraPostModelForDevelopment, setExtraPostModelForDevelopment] = useState({
        developmentId: developmentId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName
    });
    const tableForDevelopmentId = "Development_08441500-aac7-406d-afe4-796afa1d68f5";
    const [tableReloadForDevelopment, setTableReloadForDevelopment] = useState("");
    const [viewModel, setViewModel] = useReducerSmart({
        developmentId: developmentId,
        projectId: projectId,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName
    });

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, "api/DefaultModel/Accessor/Get", viewModel, setViewModel, setLoading, "Loading data", appStoreDispatch);
    }, []);

    return (<Fragment>
        {layoutManager.Layout_GetDebugModel(props) && <p>{JSON.stringify(viewModel)}</p>}
        <div className="app-main__inner">
            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">
                        <div className="page-title-icon">
                        <i className="pe-7s-tools icon-gradient bg-tempting-azure">
                        </i>
                        </div>
                        <div>
                        Constructions
                        <div className="page-title-subheading">
                            {pageProfile.getPageSubTitle(appStoreState)}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <SharedConstructionsContext.Provider value={{copiedItem, setCopiedItem}}>
            <div id="ConstructionsAccordion">
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#ConstructionsCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Constructions in selected development</h5>
                        </button>
                    </div>
                    <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <ConstructionDataTable
                                tableId = {tableForDevelopmentId}
                                tableReload={tableReloadForDevelopment}
                                setTableReload={setTableReloadForDevelopment}
                                extraPostModel = {extraPostModelForDevelopment}
                                redirectUrl = {redirectUrl}
                                debug = {layoutManager.Layout_GetDebugModel(props)}
                            ></ConstructionDataTable>
                        </div>
                    </div>
                </div>
            </div>
            <div id="ConstructionsLibraryAccordion">
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#ConstructionsLibraryCollapsibleDiv" aria-expanded="true" aria-controls="ConstructionsLibraryCollapsibleDiv" className="text-left m-0 p-0 btn btn-block">
                        <h5 className="m-0 p-0 card-title">Constructions in library</h5>
                        </button>
                    </div>
                    <div data-parent="#ConstructionsLibraryAccordion" id="ConstructionsLibraryCollapsibleDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <ConstructionLibraryDataTable
                                tableId = "e3b7b314-d910-4f65-909c-137fefc1203d"
                                tableReload={tableReloadForDevelopment}
                                setTableReload={setTableReloadForDevelopment}
                                extraPostModel = {extraPostModelForDevelopment}
                                redirectUrl = {redirectUrl}
                                debug = {layoutManager.Layout_GetDebugModel(props)}
                            ></ConstructionLibraryDataTable>
                        </div>
                    </div>
                </div>
            </div>
            </SharedConstructionsContext.Provider>
        </div>
    </Fragment>);
}
export default connectAppStore(Constructions);