import ServerSideDataTable from "../../../Feature/DataTable/ServerSideDataTable";
import ModalDatatableItem from "../../../Feature/Modal/ModalDatatableItem";
import AppLoading from "../../../Feature/AppLoading";
import PageDependencies from "../../../Feature/PageDependencies";
import TooltipTemplate from "../../../Layout/AppNav/TooltipTemplate";
        

const DevelopmentDataTable = (props) => {
  const { 
    Fragment, 
    useState,
    guid,
    dataExtension,
    getData,
    tryCatchFinally,
    toastPrint,
  } = PageDependencies();
  const debug = props.debug === undefined ? false : props.debug;
  const emptyGuid = guid.emptyGuid();
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    message: "",
    comfirmButtonStyle: "",
    comfirmButtonText: "Comfirm",
    handleComfirm: () => {},
  });
  const [loading, setLoading] = useState({isLoading:false,text:""});
  const extraPostModelDevelopment = props.extraPostModelDevelopment;
  const setExtraPostModelDevelopment = props.setExtraPostModelDevelopment;
  const setTableReloadProject = props.setTableReloadProject;

  //development table
  const tableReload = props.tableReload;
  const setTableReload = props.setTableReload;
  const [data, setData] = useState([]);
  const tableId = "DevelopmentDataTable_3be58d90-e946-4514-9300-45dcdb39157a";
  const columns = [
    { name: "developmentName", text: "Name", width: "50%" },
    { name: "clientName", text: "Client", width: "50%" },
  ];
  const contextMenu = {
    items: {
      new: {
        name: "New",
        icon: "add",
        url: "Detail/" + emptyGuid,
        defaultAction: true,
      },
      separator: "---------",
      edit: {
        name: "Edit",
        icon: "edit",
        url: "Detail/",
        defaultAction: true,
        disabled: function () {
          return dataExtension.getSelectedRowDataIds(data).length !== 1;
        },
      },
      delete: {
        name: "Delete",
        icon: "delete",
        url: "",
        defaultAction: false,
        disabled: function () {
          return dataExtension.getSelectedRowDataIds(data).length === 0;
        },
      },
      duplicate: {
        name: "Duplicate",
        icon: "copy",
        url: "",
        defaultAction: false,
        disabled: function () {
          return dataExtension.getSelectedRowDataIds(data).length === 0;
        },
      },
    },
    callBack: (
      contextMenuCallBackKey,
      contextMenuCallBackElement,
      contextMenuCallBackTableRoadData,
      localtion
    ) => {
      if (contextMenuCallBackKey === "delete") {
        setModal({
          ...modal,
          isOpen: true,
          title: "Delete developments",
          message:
            "Are sure to delete " +
            dataExtension.getSelectedRowDataIds(data).length +
            " selected developments?",
          comfirmButtonStyle: "danger",
          comfirmButtonText: "Confirm to delete",
          handleComfirm: handleDeleteComfirm,
        });
      }
      if (contextMenuCallBackKey === "duplicate") {
        setModal({
          ...modal,
          isOpen: true,
          title: "Duplicate developments",
          message:
            "Are sure to duplicate " +
            dataExtension.getSelectedRowDataIds(data).length +
            " selected developments?",
          comfirmButtonStyle: "primary",
          comfirmButtonText: "Confirm to duplicate",
          handleComfirm: handleDuplicatedComfirm,
        });
      }
    },
  };

  const actionAfterReload = (rows) => {
    const selectedIds = dataExtension.getSelectedRowDataIds(rows);
    if(extraPostModelDevelopment.selectedItemIds !== undefined && (extraPostModelDevelopment.selectedItemIds.length > 0 || selectedIds.length > 0)){
      setExtraPostModelDevelopment({
        ...extraPostModelDevelopment,
        selectedItemIds: selectedIds,
      });
    }
  };

  const actionOnClickRow = (element, newData) => {
    const selectedIds = dataExtension.getSelectedRowDataIds(newData);
    setExtraPostModelDevelopment({
      ...extraPostModelDevelopment,
      selectedItemIds: selectedIds,
    });
  };

  const handleDeleteComfirm = async () => {
    setLoading({isLoading:true,text:"Deleting developments"});
    await tryCatchFinally.asyncProcess(async ()=>{
      const selectedItemIds = dataExtension.getSelectedRowDataIds(data);
      const deleteResponse = await getData.processPost(true, "api/development/Accessor/Delete", { selectedItemIds: selectedItemIds });
      setTableReload(guid.newGuid());
      toastPrint.printResponse(deleteResponse);
      setTableReloadProject(guid.newGuid());
      setLoading({isLoading:false,text:""});
    }, setLoading);
  };

  const handleDuplicatedComfirm = async () => {
    setLoading({isLoading:true,text:"Duplicating developments"});
    await tryCatchFinally.asyncProcess(async ()=>{
      const selectedItemIds = dataExtension.getSelectedRowDataIds(data);
      const deleteResponse = await getData.processPost(true, "api/development/Accessor/Copy", {selectedItemIds: selectedItemIds});
      setTableReload(guid.newGuid());
      toastPrint.printResponse(deleteResponse);
      setTableReloadProject(guid.newGuid());
      setLoading({isLoading:false,text:""});
    }, setLoading);
  };

  return (
    <Fragment>
      <ModalDatatableItem
        modal={modal}
        setModal={setModal}
      ></ModalDatatableItem>
      {debug && <p>Selected items: {JSON.stringify(extraPostModelDevelopment.selectedItemIds)}</p>}
      {debug && <button style={{marginBottom:"15px"}}  className="btn btn-primary btn-round" onClick={()=>{ setTableReload(guid.newGuid()); }}>Reload Development</button>}
      <div id="DevelopmentsAccordion">
        <div className="main-card mb-3 card">
          <div className="card-header">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#DevelopmentsCollapsibleDiv"
              aria-expanded="true"
              aria-controls="DevelopmentsCollapsibleDiv"
              className="text-left m-0 p-0 btn btn-block"
            >
              <h5 className="m-0 p-0 card-title">Developments<TooltipTemplate text="This table lists groups of project(s) associated with the same development, such as apartments." result={0} color="grey" /></h5>
            </button>
          </div>
          <div
            data-parent="#DevelopmentsAccordion"
            id="DevelopmentsCollapsibleDiv"
            className="hide collapse show"
          >
            <div className="card-body">
              {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>}           
              <ServerSideDataTable
                id={tableId}
                location="Development"
                data={data}
                setData={setData}
                reload={tableReload}
                columns={columns}
                actionOnClickRow={actionOnClickRow}
                actionAfterReload={actionAfterReload}
                actionOnDoubleClickRow
                allowSelect
                allowSelectMultiple
                dataSource={{ isAuth: true, url: "api/Development/Accessor" }}
                contextMenu={contextMenu}
                setSelectedItemIds = {props.setSelectedItemIds}
              ></ServerSideDataTable>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DevelopmentDataTable;
