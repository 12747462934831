
import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import { useLocation } from "react-router-dom";
const ElementDataTable = (props) =>{
    const debug = props.debug === undefined ? false : props.debug;
    const { 
        stringCoding,
        tryCatchFinally,
        getData,
        guid,
        toastPrint,
        useNavigate,
        dataExtension,
        Fragment, 
        useEffect, 
        useState,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const nav = useNavigate();
    const emptyGuid = guid.emptyGuid();
    const redirectPathHex = stringCoding.encode(useLocation().pathname);
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
    });
    const [loading, setLoading] = useState({isLoading:false,text:""});
    const [extraPostModel, setExtraPostModel] = useState({
        projectId: props.projectId,
        designVariationId: props.designVariationId,
        zoneId: props.zoneId,
        designVariationVersionName:props.designVariationVersionName,
        isRetrievalInZoneOnly: props.isRetrievalInZoneOnly
    });
    const location = props.location === undefined ? "zone" : props.location;
    const [data, setData] = useState([]);
    const tableId = props.id === undefined? "d3afff49-6ec8-4fd8-a68e-b04bd611601b": props.id;
    const [tableReload, setTableReload] = useState("");
    const [selectedIds, setSelectedIds] = useState([]);
    const columns = [
        { name: "elementName", text: "Name", width: "20%" },
        { name: "elementType", text: "Type", width: "20%" }
    ];

    if (props.showZone === true){
      columns.push({ name: "zoneName", text: "Zone", width: "10%" });
      columns.push({ name: "constructionName", text: "Construction", width: "20%" });
    }else{
      columns.push({ name: "constructionName", text: "Construction", width: "30%" });
    }

    columns.push({ name: "netArea", text: "Net area (m<sup>2</sup>)", width: "10%" });
    columns.push({ name: "grossArea", text: "Gross area (m<sup>2</sup>)", width: "10%" });
    columns.push({ name: "openingArea", text: "Opening area (m<sup>2</sup>)", width: "10%" });

    const generateUrlParams = (elementType, elementId)=>{
        return "/ElementAccessor/Detail/" + elementType + "/" + props.designVariationVersionName + "/" + props.developmentId + "/"+ props.projectId + "/" + props.designVariationId + "/" + props.zoneId + "/" + elementId + "/" + redirectPathHex;
    }

    const checkSelectedTypes = () => {
      const rows = dataExtension.getSelectedRows(data);
      let type = "";
      for(let i=0; i<rows.length; i++){
        if(i===0){
          type = rows[i].elementType;
        }
        else{
          if(type !== rows[i].elementType){
            return true;
          }
        }
      }
      return false;
    }

    const createMenuItems = {
      zone: {
        createRoof: {
          name: "Roof",
          icon: "",
          url: generateUrlParams("Roof", emptyGuid),
          isNavOption: true
        },
        createFloor: {
          name: "Floor",
          icon: "",
          url: generateUrlParams("Floor", emptyGuid),
          isNavOption: true
        },
        createCeiling: {
          name: "Ceiling",
          icon: "",
          url: generateUrlParams("Ceiling", emptyGuid),
          isNavOption: true
        },
        createExternalWall: {
          name: "External Wall",
          icon: "",
          url: generateUrlParams("ExternalWall", emptyGuid),
          isNavOption: true
        },
        createInternalWall: {
          name: "Internal Wall",
          icon: "",
          url: generateUrlParams("InternalWall", emptyGuid),
          isNavOption: true
        },
      },
      externallWall: {
        createDoor: {
          name: "Door",
          icon: "",
          url: generateUrlParams("Door", emptyGuid),
          isNavOption: true
        },
        createWindow: {
          name: "Window",
          icon: "",
          url: generateUrlParams("Window", emptyGuid),
          isNavOption: true
        },
      },
      roof: {
        createRoofWindow: {
          name: "Roof Window",
          icon: "",
          url: generateUrlParams("RoofWindow", emptyGuid),
          isNavOption: true
        },
        createSkylight: {
          name: "Skylight",
          icon: "",
          url: generateUrlParams("Skylight", emptyGuid),
          isNavOption: true
        },
      },
    };

    const contextMenu = {
        items: {
          new: {
            name: "New",
            icon: "add",
            defaultAction: false,
            items: createMenuItems[location]
          },
          separator: "---------",
          edit: {
              name: "Edit",
              icon: "edit",
              defaultAction: false,
                disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length === 0 || checkSelectedTypes();
              },
            },
            delete: {
              name: "Delete",
              icon: "delete",
              url: "",
              defaultAction: false,
              disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length === 0;
              },
            },
            duplicate: {
              name: "Duplicate",
              icon: "copy",
              url: "",
              defaultAction: false,
              disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length === 0;
              },
            }
        },
        callBack: (
          contextMenuCallBackKey,
          contextMenuCallBackElement,
          contextMenuCallBackTableRoadData,
          localtion
        ) => {   
          if (contextMenuCallBackKey === "edit") {
            const selectedElementIds = dataExtension.getSelectedRowDataIds(data);
            if(selectedElementIds.length == 1){
               nav(generateUrlParams(dataExtension.getSelectedRows(data)[0].elementType.replace(" ","").replace("/",""), selectedElementIds[0]));
            }else{
                var type = dataExtension.getSelectedRows(data)[0].elementType.replace(" ","").replace("/","");
                const urlParams = props.designVariationVersionName + "/" + props.developmentId + "/" + props.projectId + "/" + props.designVariationId + "/" + props.zoneId + "/" + stringCoding.encodeObject(selectedElementIds) + "/" + redirectPathHex;
                nav("/ElementAccessor/MultipleDetail/" + type + "/" + urlParams);
            }
         }
         if (contextMenuCallBackKey === "delete"){
            setModal({
              ...modal,
              isOpen: true,
              title: "Delete elements",
              message:
                "Are sure to delete " +
                dataExtension.getSelectedRowDataIds(data).length +
                " selected elements?",
              comfirmButtonStyle: "danger",
              comfirmButtonText: "Confirm to delete",
              handleComfirm: handleDeleteComfirm,
            });
         }
         if (contextMenuCallBackKey === "duplicate") {
          setModal({
            ...modal,
            isOpen: true,
            title: "Duplicate elements",
            message:
              "Are sure to duplicate " +
              dataExtension.getSelectedRowDataIds(data).length +
              " selected elements?",
            comfirmButtonStyle: "primary",
            comfirmButtonText: "Confirm to duplicate",
            handleComfirm: handleDuplicatedComfirm,
          });
        }
        },
      };

    const actionOnDoubleClickRow = (model, newData) =>{
      nav(generateUrlParams(model.elementType.replace(" ","").replace("/",""), model.id.split("_")[0]));
    }

    const handleDeleteComfirm = async () => {
      await tryCatchFinally.asyncProcess(async ()=>{
        const selectedItemDic = dataExtension.getSelectedRowsDic(data, "elementType");
        setLoading({isLoading:true,text:"Deleting elements"});
        const selectResponse = await getData.processPost(
        true,
        "api/Element/Accessor/DeleteElements",
            {selectedItemDic: selectedItemDic, designVariationVersionName: props.designVariationVersionName, projectId: props.projectId, designVariationId: props.designVariationId}
        ); 
      toastPrint.printResponse(selectResponse);
      setTableReload(guid.newGuid());
      }, setLoading);
    }

    const handleDuplicatedComfirm = async () => {
      await tryCatchFinally.asyncProcess(async ()=>{
        const selectedItemDic = dataExtension.getSelectedRowsDic(data, "elementType");
        setLoading({isLoading:true,text:"Duplicating elements"});
        const selectResponse = await getData.processPost(
        true,
        "api/Element/Accessor/CopyElements",
            {selectedItemDic: selectedItemDic, designVariationVersionName: props.designVariationVersionName, projectId: props.projectId, designVariationId: props.designVariationId, zoneId: props.zoneId}
        ); 
      toastPrint.printResponse(selectResponse);
      setTableReload(guid.newGuid());
      }, setLoading);
    }

    useEffect(()=>{
        setExtraPostModel({...extraPostModel, zoneId: props.zoneId});
    },[props.zoneId])
    
    return(
    <Fragment>
        {debug && <p>{JSON.stringify(extraPostModel)}</p>}
        {debug && <p>redirect Url: {stringCoding.decode(redirectPathHex)}</p>}
        <ModalDatatableItem
          modal={modal}
          setModal={setModal}
        ></ModalDatatableItem>
        <ServerSideDataTable
            id={tableId}
            location="Element"
            data={data}
            setData={setData}
            reload={tableReload}
            columns={columns}
            actionOnDoubleClickRow = {actionOnDoubleClickRow}
            allowSelect
            allowSelectMultiple
            dataSource={{ isAuth: true, url: "api/Element/Accessor" }}
            contextMenu={contextMenu}
            extraPostModel={extraPostModel}
            setSelectedItemIds = {setSelectedIds}
        ></ServerSideDataTable>
    </Fragment>);
}
export default connectAppStore(ElementDataTable);