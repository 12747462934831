//app hooks
import { Fragment, useEffect, useRef} from "react";
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import useStateOperation from "../Function/UseStateOperation";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getAppStore } from "../Store/AppStore";
//layout controller
import layoutManager from "../Layout/LayoutManager";
//common feaure
import guid from "../Function/guid";
import process from "../Function/Process";
import getData from "../Function/GetData";
import usePrevious from "../Function/UsePrevious";
import cookiesManager from "../Function/CookiesManager";
import GetSelectedItemText from "../Function/GetSelectedItemText";
import useReducerSmart from "../Function/useReducerSmart";
import useEffectWithPrevious from "../Function/UseEffectWithPrevious";
import useFromDefaultValue from "../Function/UseFromDefaultValue";
import toastPrint from "./ToastPrint";
//page load
import pageProfile from "../Portal/Accessor/PageProfile";
import BaseForm from "./BaseForm";
import BaseFormNoHeader from "./BaseFormNoHeader";
import BindedInput from "./BindedInput";
import BindedCheckBox from "./BindedCheckBox";
import BindedTriStateCheckBox from "./BindedTriStateCheckBox";
import BindedRadio from "./BindedRadio";
import BindedSelect from "./BindedSelect";
import BindedInputNumber from "./BindedInputNumber";
import BindedInputCalendar from "./BindedInputCalendar";
import BindedTextArea from "./BindedTextArea";
import FormValidate from "../Function/FormValidate";
import tryCatchFinally from "../Function/TryCatchFinally";
import stringCoding from "../Function/StringCoding";
import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";
import loaModelFromApi from "../Function/LoaModelFromApi";
//extra page load
import ModalDatatableItem from "./Modal/ModalDatatableItem";
import AppLoading from "./AppLoading";
//data manager
import dataExtension from "./DataTable/DataExtension";
import ServerSideDataTable from "./DataTable/ServerSideDataTable";
import CilentSideDataTable from "./DataTable/CilentSideDataTable";
import DataTableFancyTree from "./TreeTable/ServerSideFancyTree";
import DropDragDataTable from "./DataTable/DropDragDataTable";
import OnDragEndOperation from "./DataTable/OnDragEndOperation";
import { DragDropContext } from "react-beautiful-dnd";

//html
const HtmlToReact = require("html-to-react").Parser;

const PageDependencies = () => {
    return {
        Fragment: Fragment,
        useEffect: useEffect,
        useEffectWithPrevious: useEffectWithPrevious,
        useState: useStateWithCallbackLazy,
        useStateOperation: useStateOperation,
        useRef: useRef,
        htmlToReactParse: (new HtmlToReact()).parse,
        tryCatchFinally: tryCatchFinally,
        stringCoding:stringCoding,
        layoutManager: layoutManager,
        getData: getData,
        usePrevious: usePrevious,
        useFromDefaultValue: useFromDefaultValue,
        cookiesManager: cookiesManager,
        GetSelectedItemText: GetSelectedItemText,
        guid : guid,  
        process: process,
        objectPropertyValueGetSet: objectPropertyValueGetSet,
        useReducerSmart: useReducerSmart,
        toastPrint: toastPrint,
        useParams: useParams,
        useLocation: useLocation,
        BaseForm: BaseForm,
        BaseFormNoHeader: BaseFormNoHeader,
        BindedInput: BindedInput,
        BindedInputNumber: BindedInputNumber,
        BindedTriStateCheckBox: BindedTriStateCheckBox,
        BindedInputCalendar: BindedInputCalendar,
        BindedCheckBox: BindedCheckBox,
        BindedSelect: BindedSelect,
        BindedRadio: BindedRadio,
        BindedTextArea: BindedTextArea,
        getAppStore: getAppStore,
        useNavigate: useNavigate,
        pageProfile: pageProfile,
        AppLoading, AppLoading,
        loaModelFromApi, loaModelFromApi,
        FormValidate: FormValidate,
        dataExtension: dataExtension,
        ServerSideDataTable: ServerSideDataTable,
        CilentSideDataTable:CilentSideDataTable,
        DataTableFancyTree: DataTableFancyTree,
        DropDragDataTable: DropDragDataTable,
        OnDragEndOperation: OnDragEndOperation,
        ModalDatatableItem: ModalDatatableItem,
        DragDropContext: DragDropContext,
    };
}
export default PageDependencies;

// const { 
//     Fragment,
//     useEffect,
//     useState,
//     useParams,
//     useLocation,
//     usePrevious,
//     htmlToReactParse,
//     tryCatchFinally,
//     stringCoding,
//     layoutManager,
//     getData,
//     cookiesManager,
//     GetSelectedItemText,
//     guid,
//     objectPropertyValueGetSet,
//     useReducerSmart,
//     toastPrint,
//     BaseForm,
//     BaseFormNoHeader,
//     BindedInput,
//     BindedInputNumber,
//     BindedTriStateCheckBox,
//     BindedInputCalendar,
//     BindedCheckBox,
//     BindedSelect,
//     BindedTextArea,
//     getAppStore,
//     useNavigate,
//     pageProfile,
//     AppLoading,
//     loaModelFromApi,
//     FormValidate,
//     dataExtension,
//     ServerSideDataTable,
//     CilentSideDataTable,
//     DataTableFancyTree,
//     DropDragDataTable,
//     ModalDatatableItem,
//     DragDropContext
// } = PageDependencies();
// const emptyGuid = guid.emptyGuid();
// const currentUrl = useLocation().pathname;
// const defaultParams = pageProfile.getDefaultParams(useParams());
// const {designVariationVersionName, developmentId, projectId, designVariationId, redirectUrl, jsonParam} = defaultParams;
// const defaultViewModel = {
//     ...defaultParams,
//   };
