import { Fragment, useEffect, useRef, useState } from "react";
import guid from "../../../Function/guid";
import {
  LoadScript,
  Autocomplete,
  Marker,
  GoogleMap,
} from "@react-google-maps/api";
import AppLoading from "../../../Feature/AppLoading";
import Geocode from "react-geocode";
import useReducerSmart from "../../../Function/useReducerSmart";
import BindedInput from "../../../Feature/BindedInput";
import BindedSelect from "../../../Feature/BindedSelect";
import toastPrint from "../../../Feature/ToastPrint";
import $ from 'jquery'; 
import FormValidate from "../../../Function/FormValidate";

const GoogleAddress = (props) => {
  const debug = props.debug === undefined ? false : props.debug;
  const defaultAddress = {
    id:"",
    lotNumber: "",
    dpNumber: "",
    unitNumber: "",
    streetNumber: "",
    streetName: "",
    streetType: "",
    suburb: "",
    state: "",
    states: [],
    postcode: "",
    country: "",
    compoundPlusCode: "",
    latitude: "",
    longitude: "",
    council: ""
  };
  const id = props.id === undefined ? guid.newGuid() : props.id;
  const [isLoading, setIsLoading] = useState(false);
  const reload = props.reload;
  const setReload = props.setReload;
  const [clickTimer, setClickTimer] = useState(null);
  const searchText = useRef();
  const externalAddress = props.address;
  const updateAddressCallBack = props.updateAddressCallBack;
  const setExternalAddress = props.setAddress;
  const removeLotDpNumber = props.removeLotDpNumber === undefined ? false : true; 
  const [address, dispatchAddress, bindingAddress] = useReducerSmart(defaultAddress);
  const apiKey = props.googleMapsApiKey === undefined ? "" : props.googleMapsApiKey;
  const [libraries] = useState(["drawing", "places"]);
  const searchBox = useRef();
  const [defaultZoom, setDefaultZoom] = useState(8);
  const containerStyle = {
    width: "100%",
    height: "450px",
  };
  const defaultCenter = {
    lat: -37.8136,
    lng: 144.9631,
  };
  const [center, setCenter] = useState(defaultCenter);
  const validate = props.validate;
  const isClass2 = props.isClass2;

  const updateAddress = async (mapResponse, fromSearch = false, callback) =>{
    let geometry ={};
    if (mapResponse.status === "OK") {
        const googleAddress = mapResponse.results[0];
        searchText.current.value = googleAddress.formatted_address;
        let compoundPlusCode = mapResponse["plus_code"] == null ? "" : mapResponse["plus_code"]["compound_code"];
        const addressComponents = googleAddress.address_components;
        let newAddress = {...externalAddress, unitNumber: "",  streetNumber: "", streetName: "", suburb: "", state: "", council: " ", postcode: "", country: "", latitude: "", longitude: "", compoundPlusCode: ""};
        //update lat lng
        geometry = googleAddress.geometry.location;
        newAddress.latitude = parseFloat(Math.round(geometry.lat * 100000000) / 100000000).toString();
        newAddress.longitude = parseFloat(Math.round(geometry.lng * 100000000) / 100000000).toString();
        if(fromSearch){
            initGeocode();
            try {
                const plusCodeResponse = await Geocode.fromLatLng(newAddress.latitude, newAddress.longitude);
                compoundPlusCode = plusCodeResponse["plus_code"] == null ? "" : plusCodeResponse["plus_code"]["compound_code"];
            } catch {
                compoundPlusCode = "";
            }
        }
        newAddress.compoundPlusCode = compoundPlusCode;
        //update detail
        for (var i = 0; i < addressComponents.length; i++) {
          if ($.inArray("subpremise", addressComponents[i]["types"]) >= 0) {
              const unitNumber = addressComponents[i]["long_name"];
              newAddress["unitNumber"] = unitNumber;
          }

          if ($.inArray("street_number", addressComponents[i]["types"]) >= 0) {
              const streetNumber = addressComponents[i]["long_name"];
              newAddress["streetNumber"] = streetNumber;
          }

          if ($.inArray("route", addressComponents[i]["types"]) >= 0) {
              const streetName = addressComponents[i]["long_name"];
              newAddress["streetName"] = streetName;
          }

          if ($.inArray("locality", addressComponents[i]["types"]) >= 0) {
              const suburb = addressComponents[i]["long_name"];
              newAddress["suburb"] = suburb;
          }
          
          if ($.inArray("administrative_area_level_1", addressComponents[i]["types"]) >= 0) {
              const state = addressComponents[i]["short_name"];
              newAddress["state"] = state;
          }

          if ($.inArray("administrative_area_level_2", addressComponents[i]["types"]) >= 0) {
              const council = addressComponents[i]["long_name"];
              newAddress["council"] = council;
          }

          if ($.inArray("postal_code", addressComponents[i]["types"]) >= 0) {
              const postcode = addressComponents[i]["long_name"];
              if(postcode.length === 3){
                postcode = "0" + postcode;
              }
              newAddress["postcode"] = postcode;
          }else{
            switch(newAddress.state){
              case "NT":
                newAddress.postcode = "0800";
                break;
              case "ACT":
                newAddress.postcode = "2600";
                break;
              case "QLD":
                newAddress.postcode = "4000";
                break;
              case "VIC":
                newAddress.postcode = "3000";
                break;
              case "SA":
                newAddress.postcode = "5000";
                break;
              case "TAS":
                newAddress.postcode = "7000";
                break;
              case "WA":
                newAddress.postcode = "6000";
                break;
              case "NSW":
                newAddress.postcode = "2000";
                break;
              case "OT":
                newAddress.postcode = "6798";
                break;
            }
          }

          if ($.inArray("country", addressComponents[i]["types"]) >= 0) {
              const country = addressComponents[i]["long_name"];
              newAddress["country"] = country;
          }
          if(newAddress.country === undefined || newAddress.country == ""){
            newAddress.country = "Australia";
          }
        }
        dispatchAddress(newAddress, ()=>{
          if(updateAddressCallBack !== undefined){
            updateAddressCallBack(newAddress);
          }
        });
          
      } else {
          toastPrint.printErrorMessage("Error when laoding geocode information.");
      }
      callback(geometry);
  }

  const initGeocode =()=>{
    Geocode.setApiKey(apiKey);
    Geocode.setLanguage("en");
    Geocode.setRegion("au");
    Geocode.setLocationType("ROOFTOP");
  }

  const handleChange = async (geocodePromise, fromSearch) =>{
    setIsLoading(true);
    clearTimeout(clickTimer);
    setClickTimer(null);
    try{
        setClickTimer(
            setTimeout(() => {          
                geocodePromise().then(
                (response) => {
                      updateAddress(response, fromSearch, (geometry)=>{
                      setCenter(geometry);
                      setDefaultZoom(19);
                      setIsLoading(false);
                    } );                            
                },
                (error) => {
                    if(error.message.toLowerCase().includes("zero")){
                      toastPrint.printErrorMessage("Cannot found address in the pinned point.");
                    }
                    setIsLoading(false);
                    clearTimeout(clickTimer);
                    setClickTimer(null);
                });
            }, 800)
          );
    }catch(ex){
        toastPrint.printErrorMessage(ex.message);
        setIsLoading(false);
        clearTimeout(clickTimer);
        setClickTimer(null);
    }
  }

  const handleMapClick = (e) => {
    const newCenter = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    const geocodePromise = () =>{
        initGeocode();
        const geocode = Geocode.fromLatLng(newCenter.lat,newCenter.lng);
        return geocode;
    };
    handleChange(geocodePromise, false);
  };

  const onPlaceChanged = () => {
    const formattedAddress = searchText.current.value;
    const geocodePromise = () =>{
        initGeocode();
        const geocode = Geocode.fromAddress(formattedAddress);
        return geocode;
    };
    handleChange(geocodePromise, true);
  };

  useEffect(() => {
    if (reload.length > 0) {
      validate.setValidateMethods((previouseMethods)=>{
        const newMethods = {...previouseMethods};
        newMethods.streetName = (value)=>{ return FormValidate.validateTextRequired(value, "Street name"); };
        newMethods.suburb = (value)=>{ return FormValidate.validateTextRequired(value, "Suburb"); };
        newMethods.state = (value)=>{ return FormValidate.validateTextRequired(value, "State/Territory"); };
        newMethods.postcode = (value)=>{ return new RegExp("^\\d{4}$").test(value) ? "" : "Postcode must be a 4-digit."; };
        newMethods.country = (value)=>{ return FormValidate.validateTextRequired(value, "Country"); };
        newMethods.unitNumber = (value)=>{ return isClass2 !== undefined && isClass2 === true? (value.length > 0? "" : "Unit number must be completed.") : ""; };
        newMethods.streetNumber = (value)=>{ return value.length > 0? "" : "Street number must be completed."; };
        //newMethods.council = (value)=>{ return FormValidate.validateTextRequired(value, "Council"); };
        return newMethods; 
      });
        setReload("");
        dispatchAddress(externalAddress);
        const lat = Number(externalAddress.latitude);
        const lng = Number(externalAddress.longitude);
        if (lat != 0 || lng != 0) {
            setCenter({lat, lng:lng});
            setDefaultZoom(19);
        }
    }
    if (address.id.length > 0) {
        setExternalAddress(address);
    }
  }, [reload, address]);

  return (
    <Fragment>
      {isLoading && (
        <AppLoading active>
          <div>Loading map</div>
        </AppLoading>
      )}
      {debug && <p>{JSON.stringify(address)}</p>}
      <div className="main-card mb-3 card">
        <div className="card-header">
          <button className="text-left m-0 p-0 btn btn-block">
            <h5 className="m-0 p-0 card-title">Address</h5>
          </button>
        </div>
        <div className="card-body">
        {apiKey.length > 0 && <LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
            <div className="form-row row">
              <div className="col-12 pb-4" style={{minHeight:"450px", height:"450px", display:"block"}}>
                <div id={id}>
                  <GoogleMap
                    onClick={handleMapClick}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={defaultZoom}  
                    options={{
                      zoom: defaultZoom,
                      mapTypeId: "hybrid",
                      gestureHandling: "cooperative",
                      zoomControl: true,
                      scaleControl: true,
                      fullscreenControl: true,
                      disableDefaultUI: true,
                      mapTypeId: "satellite",
                      tilt: 0
                    }}
                  >
                    {/* Child components, such as markers, info windows, etc. */}
                    <Marker position={center} />
                    <></>
                  </GoogleMap>
                </div>
              </div>
            </div>
            <div className="form-row row">
              <div className="col-12">
                <div className="position-relative form-group">
                  <label htmlFor="AddressSearch">Address</label>
                  <Autocomplete
                    ref={searchBox}
                    restrictions={{ country: "AU" }}
                    onPlaceChanged={onPlaceChanged}
                  >
                    <input
                      ref={searchText}
                      id="AddressSearch"
                      name="search-address"
                      type="text"
                      className="form-control"
                      placeholder="Search address"
                    />
                  </Autocomplete>
                </div>
              </div>
            </div>
          </LoadScript>}
          <div className="form-row row">
                <BindedInput
                  id="Address_Id"
                  name="Address.Id"
                  binding={[
                    address,
                    "id",
                    bindingAddress,
                  ]}
                  type="hidden"
                ></BindedInput>
            <div className="col-6">
              <div className="position-relative form-group">
                <label htmlFor="Address_CompoundPlusCode">Plus code</label>
                <label
                  id="Address_CompoundPlusCode_Label"
                  className="form-control"
                  style={{ border: "none" }}
                >
                  {address.compoundPlusCode}
                </label>
                <BindedInput
                  id="Address_CompoundPlusCode"
                  name="Address.CompoundPlusCode"
                  binding={[
                    address,
                    "compoundPlusCode",
                    bindingAddress,
                  ]}
                  type="hidden"
                ></BindedInput>
              </div>
            </div>

            <div className="col-3">
              <div className="position-relative form-group">
                <label htmlFor="Address_Latitude">Latitude</label>
                <label
                  id="Address_Latitude_Label"
                  className="form-control"
                  style={{ border: "none" }}
                >
                  {address.latitude == "0"
                    ? ""
                    : address.latitude}
                </label>
                <BindedInput
                  id="Address_Latitude"
                  name="Address.Latitude"
                  type="hidden"
                  binding={[
                    address,
                    "latitude",
                    bindingAddress,
                  ]}
                ></BindedInput>
              </div>
            </div>
              
            <div className="col-3">
              <div className="position-relative form-group">
                <label htmlFor="Address_Longitude">Longitude</label>
                <label
                  id="Address_Longitude_Label"
                  className="form-control"
                  style={{ border: "none" }}
                >
                  {address.longitude == "0"
                    ? ""
                    : address.longitude}
                </label>
                <BindedInput
                  id="Address_Longitude"
                  name="Address.Longitude"
                  type="hidden"
                  binding={[
                    address,
                    "longitude",
                    bindingAddress,
                  ]}
                ></BindedInput>
              </div>
            </div>
          </div>
          {!removeLotDpNumber &&     <div className="form-row row">
            <div className="col-6">
              <div className="position-relative form-group">
                <label htmlFor="Address_LotNumber">Lot number</label>
                <BindedInput
                  className="form-control"
                  id="Address_LotNumber"
                  name="Address.LotNumber"
                  type="text"
                  binding={[
                    address,
                    "lotNumber",
                    bindingAddress,
                  ]}
                ></BindedInput>
              </div>
            </div>

            <div className="col-6">
              <div className="position-relative form-group">
                <label htmlFor="Address_DPNumber">DP number</label>
                <BindedInput
                  className="form-control"
                  id="Address_DPNumber"
                  name="Address.DPNumber"
                  type="text"
                  binding={[
                    address,
                    "dpNumber",
                    bindingAddress,
                  ]}
                ></BindedInput>
              </div>
            </div>
          </div>}
     
          <div className="form-row row">
            <div className="col-2">
              <div className="position-relative form-group">
                {isClass2 &&
                  <label htmlFor="Address_UnitNumber">Unit number *</label>
                }
                {!isClass2 &&
                  <label htmlFor="Address_UnitNumber">Unit number </label>
                }
                <BindedInput
                  className="form-control"
                  id="Address_UnitNumber"
                  name="Address.UnitNumber"
                  type="text"
                  binding={[
                    address,
                    "unitNumber",
                    bindingAddress,
                  ]}
                  validate={validate}
                ></BindedInput>
              </div>
            </div>

            <div className="col-2">
              <div className="position-relative form-group">
                <label htmlFor="Address_StreetNumber">Street number *</label>
                <BindedInput
                  className="form-control"
                  id="Address_StreetNumber"
                  name="Address.StreetNumber"
                  type="text"
                  binding={[
                    address,
                    "streetNumber",
                    bindingAddress,
                  ]}
                  validate={validate}
                ></BindedInput>
              </div>
            </div>

            <div className="col-8">
              <div className="position-relative form-group">
                <label htmlFor="Address_StreetName">Street *</label>
                <BindedInput
                  className="form-control"
                  id="Address_StreetName"
                  name="Address.StreetName"
                  type="text"
                  binding={[
                    address,
                    "streetName",
                    bindingAddress,
                  ]}
                  validate={validate}
                ></BindedInput>
              </div>
            </div>
          </div>

          <div className="form-row row">
            <div className="col-3">
              <div className="position-relative form-group">
                <label htmlFor="Address_Suburb">Suburb *</label>
                <BindedInput
                  className="form-control"
                  id="Address_Suburb"
                  name="Address.Suburb"
                  type="text"
                  binding={[
                    address,
                    "suburb",
                    bindingAddress,
                  ]}
                  validate={validate}
                ></BindedInput>
              </div>
            </div>

            <div className="col-3">
              <div className="position-relative form-group">
                <label htmlFor="Address_State">State/Territory *</label>
                <BindedSelect
                  className="form-control"
                  id="Address_State"
                  name="Address.State"
                  binding={[
                    address,
                    "state",
                    bindingAddress,
                  ]}
                  options={"states"}
                  validate={validate}
                ></BindedSelect>
              </div>
            </div>

            <div className="col-3">
              <div className="position-relative form-group">
                <label htmlFor="Address_Postcode">Postcode *</label>
                <BindedInput
                  className="form-control numberInputNoArrow"
                  id="Address_Postcode"
                  name="Address.Postcode"
                  type="number"                            
                  binding={[
                    address,
                    "postcode",
                    bindingAddress,
                  ]}
                  validate={validate}
                ></BindedInput>
              </div>
            </div>

            <div className="col-3">
              <div className="position-relative form-group">
                <label htmlFor="Address_Country">Country *</label>
                <BindedInput
                  className="form-control"
                  id="Address_Country"
                  name="Address.Country"
                  type="text"
                  binding={[
                    address,
                    "country",
                    bindingAddress,
                  ]}
                  validate={validate}
                ></BindedInput>
              </div>
            </div>
          </div>
          {!removeLotDpNumber && <div className="form-row row">
            <div className="col-12">
              <div className="position-relative form-group">
                <label htmlFor="Address_Council">Council </label>
                <BindedInput
                  className="form-control"
                  id="Address_Council"
                  name="Address.Council"
                  type="text"
                  binding={[
                    address,
                    "council",
                    bindingAddress,
                  ]}
                  // validate={validate}
                ></BindedInput>
              </div>
            </div>
          </div>}
          
        </div>
      </div>
    </Fragment>
  );
};
export default GoogleAddress;
