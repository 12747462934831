import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";
import UserDataTable from "./UserDataTable";
const Users = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        useReducerSmart,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const { } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
    };
    const [extraPostModel, setExtraPostModel] = useState({
        ...defaultParams,
      });
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/DefaultModel/Accessor/Get";
    const [reloadForm, setReloadForm] = useState("");
    const [data, setData] = useState([]);

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Users", appStoreDispatch, undefined, setInit);
    },[]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-user icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                All Users
                                <div className="page-title-subheading">
                                    All users can be accessed from this page.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="row">
                        <div className="col-3">
                            <a style={{marginLeft:"15px"}} className="btn btn-primary" href="/Admin/Users/RegisterUsersCSV" target="_blank">Register Users By CSV</a>
                            <a style={{marginLeft:"15px"}} className="btn btn-primary" href="api/User/Admin/DownloadRegisterUsersCsvTemplate" target="_blank">Download Register Users Csv Template</a>
                        </div>
                    </div>                   
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <UserDataTable
                                debug = {debug}
                                data = {data}
                                setData = {setData}
                                extraPostModel = {extraPostModel}
                                url="api/User/Admin/GetUsers"
                                tableReload = {reloadForm}
                                setTableReload = {setReloadForm}
                            ></UserDataTable>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(Users);
