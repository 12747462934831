import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import PasswordChecklist from "react-password-checklist";

const ChangePassword = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        useReducerSmart,
        BaseForm,
        BindedInput,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const defaultViewModel = {
        ...defaultParams,
        password: "",
        passwordConfirmation: "",
        passwordValidate: false
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    //change model get url here if not using default model
    const getUrl = "api/Profile/Accessor/ChangePassword";
    //add model post url here
    const postUrl = "api/Profile/Accessor/UpdatePassword";
    const validateMethods = {
    //add form property validate here
        oldPassword: (value) => {
            return FormValidate.validateTextRequired(value, "Old password was required");
        }
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        if(init===false) setInit(true);
        layoutManager.Layout_Headter_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch);
    },[]);

    const submitCallBack = function(){
        const newModel = {...viewModel, oldPassword: "", password: "", passwordConfirmation: ""};
        dispatchViewModel(newModel);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <BaseForm
                id="UserProfile"
                icon="pe-7s-key"
                loading={loading}
                setReload={setReloadForm}
                setLoading={setLoading}
                submitButtonAndStay = {{text:"Save", postUrl: postUrl, redirectUrl: ""}}
                submitButtonAndStayDisabled = {!viewModel.passwordValidate}
                data={viewModel}
                validate ={formValidate}
                submitCallBack = {submitCallBack}
                title = "Password"
                subTitle={"Change my password"}
            >
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="OldPassword">Old Password</label>
                            <BindedInput id="OldPassword" name="OldPassword" type="password" validate ={formValidate} className="form-control" binding={[viewModel, "oldPassword", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>                 
                </div>
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="Password">Password</label>
                            <BindedInput id="Password" name="Password" type="password" className="form-control" binding={[viewModel, "password", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>                 
                </div>
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="PasswordConfirmation">Password Confirmation</label>
                            <BindedInput id="PasswordConfirmation" name="PasswordConfirmation" type="password" className="form-control" binding={[viewModel, "passwordConfirmation", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>                 
                </div>
                <div className="form-row row">
                    <div className="col-6">
                        <PasswordChecklist
                            rules={["minLength","specialChar","number","capital","match"]}
                            minLength={8}
                            value={viewModel.password}
                            valueAgain={viewModel.passwordConfirmation}
                            onChange={(isValid) => {
                                if(isValid !== viewModel.passwordValidate){
                                    dispatchViewModel({...viewModel, passwordValidate: isValid});
                                }
                            }}
                        />
                    </div>
                </div>
            </BaseForm>
        </Fragment>
    );
}
export default connectAppStore(ChangePassword);