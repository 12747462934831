import toastPrint from "../Feature/ToastPrint";
import process from "../Function/Process";
class TryCatchFinally {
    async asyncProcess(action, setLoading, callback){
        let result = false;
        process.setProcessTimeout(async ()=>{
            try{
                await action();
                result = true;
            }catch(ex){
                toastPrint.printErrorMessage(ex.message);
            }finally{
                if(setLoading !== undefined){
                    setLoading({isLoading:false, text:""});
                }
                if(callback !== undefined){
                    callback();
                }
            }
        }, 200);
        return result;
    }

    syncProcess(action, setLoading, callback){
        let result = false;
        process.setProcessTimeout(()=>{
            try{
                action();
                result = true;
            }catch(ex){
                toastPrint.printErrorMessage(ex.message);
            }finally{
                if(setLoading !== undefined){
                    setLoading({isLoading:false, text:""});
                }
                if(callback !== undefined){
                    callback();
                }  
            }
        }, 200);
        return result;
    }
}
const tryCatchFinally = new TryCatchFinally();
export default tryCatchFinally;