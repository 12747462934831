import HeatingModuleV001 from "../../Portal/Accessor/Module/V001/ExistingHome/HeatingModule";
import CoolingModuleV001 from "../../Portal/Accessor/Module/V001/ExistingHome/CoolingModule";
import LightingModuleV001 from "../../Portal/Accessor/Module/V001/ExistingHome/LightingModule";
import PlugLoadCookingModuleV001 from "../../Portal/Accessor/Module/V001/ExistingHome/PlugLoadCookingModule";
import HotWaterModuleV001 from "../../Portal/Accessor/Module/V001/ExistingHome/HotWaterModule";
import PoolSpaModuleV001 from "../../Portal/Accessor/Module/V001/ExistingHome/PoolSpaModule";
import SolarPvModuleV001 from "../../Portal/Accessor/Module/V001/ExistingHome/SolarPvModule";
import ModuleSummary from "../../Portal/Accessor/Module/ModuleSummary";
const AccessorModuleExistingHomeRouters = [
    {
        path: "HeatingAccessor/ExistingHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <HeatingModuleV001/>,
    },
    {
        path: "CoolingAccessor/ExistingHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <CoolingModuleV001/>,
    },
    {
        path: "LightingAccessor/ExistingHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <LightingModuleV001/>,
    },
    {
        path: "PlugLoadAccessor/ExistingHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <PlugLoadCookingModuleV001/>,
    },
    {
        path: "HotWaterAccessor/ExistingHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <HotWaterModuleV001/>,
    },
    {
        path: "PoolSpaAccessor/ExistingHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <PoolSpaModuleV001/>,
    },
    {
        path: "SolarPvAccessor/ExistingHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <SolarPvModuleV001/>,
    },
    {
        path: "ModuleSummaryAccessor/ExistingHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <ModuleSummary/>,
    },
];
export default AccessorModuleExistingHomeRouters;