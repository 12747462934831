import { Fragment } from "react";
import ReactLoading from "react-loading";
import { Modal } from "reactstrap";
import "./AppLoading.css";
const AppLoading = (props) => {
  return (
    <Fragment>
      {props.active && <div
      style={{
        padding: 40,
        textAlign: "center",
        height: "100%",
        width: "100%",
        backgroundColor:"white",
        opacity: 0.5,
        position:"fixed",
        top:0,
        left:0,
        zIndex:1000    
      }}
    >
      <div
       style={{
        position:"fixed",
        textAlign: "center",
        top:"45%",
        left:"45%"}}
      >
      <ReactLoading
        type={"spokes"}
        color={"blue"}
        height={"50px"}
        width={"50px"}
        className="centered"
      />
      <Modal isOpen={props.active} contentClassName="modalContentStyle"></Modal>
      {{...props.children}}
      </div>
      
    </div>}
      {!props.active && <div></div>}
    </Fragment>
  );
};
export default AppLoading;
