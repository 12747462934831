import React from 'react';
import './Tooltip.css';
import { Tooltip } from 'primereact/tooltip';

const TooltipTemplate = ({ text, result, color }) => {
    if (result !== 0) {
        return null;
    }

    return (
        <>
            <Tooltip target=".tooltip-icon" />
            <div className={"tooltip-container-" + color}>
                <i id={"tooltip-icon-" + color} className="tooltip-icon metismenu-icon pe-7s-info" data-pr-tooltip={text} height="80px" />
            </div>
        </>
    );
};

export default TooltipTemplate;
