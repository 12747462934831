import { connectAppStore } from "../../../../../Store/AppStore";
import PageDependencies from "../../../../../Feature/PageDependencies";

const HotWaterModule = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        tryCatchFinally,
        layoutManager,
        getData,
        guid,
        useReducerSmart,
        toastPrint,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
        dataExtension,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const emptyGuid = guid.emptyGuid();
    const [init, setInit] = useState(false);
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/NewHome/HotWaterModule/Accessor/Get";
    const [reloadForm, setReloadForm] = useState("");
    const validateMethods = {
        systemTypeId:(value)=>{ return value === emptyGuid ? "Must select a hot water type." : "";},
        pvDiverterTankVolume:(value, newViewModel)=>{ 
            const types = newViewModel.systemTypes === undefined ? [] : newViewModel.systemTypes;
            const type = dataExtension.getSelectOptionByValue(types, newViewModel.systemTypeId);
            if(type !== undefined){
                if(type.text === "PVD (PV Diverter)"){
                    return FormValidate.validateNumberGreaterThan0(value, "Tank Volume");
                }
            }
            return "";
        }
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Hot Water", appStoreDispatch, undefined, setInit);
    },[reloadForm]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/NewHome/HotWaterModule/Accessor/UpdateHotWater";
        setLoading({ isLoading: true, text: "Updating Hot Water" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            setReloadForm(guid.newGuid());
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-coffee icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Hot Water
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                    <>
                    <div className="main-card mb-3 card">
                        <div className="card-header">
                            <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                                <h5 className="m-0 p-0 card-title">Annual Energy Consumption</h5>
                            </button>
                        </div>
                        <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                            <div className="card-body">
                                <div className="separator"></div>
                                <div className="form-row row">
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label>Electricity (kWh/yr): {parseFloat(viewModel.annualElectricityEnergy).toFixed(2)}</label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label>Gas (MJ/yr): {parseFloat(viewModel.annualGasEnergy).toFixed(2)}</label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label>Other (MJ/yr): {parseFloat(viewModel.annualOtherEnergy).toFixed(2)}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Hot Water Configuration</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-6">
                                    <div className="position-relative form-group">
                                        <label htmlFor="HotWaterSystemTypeId">Hot water system *</label>
                                        <BindedSelect
                                            className="form-control"
                                            id="HotWaterSystemTypeId"
                                            name="HotWaterSystemTypeId"
                                            options="systemTypes"
                                            validate={formValidate}
                                            binding={[viewModel, "systemTypeId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="NumberOfOccupants">Occupants Number </label>
                                        <BindedInput
                                            disabled
                                            className="form-control"
                                            id="NumberOfOccupants"
                                            name="NumberOfOccupants"
                                            type="number"
                                            binding={[viewModel, "numberOfOccupants", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="HotWaterClimateZoneName">Hot water zone </label>
                                        <BindedInput
                                            disabled
                                            className="form-control"
                                            id="HotWaterClimateZoneName"
                                            name="HotWaterClimateZoneName"
                                            type="text"
                                            binding={[viewModel, "hotWaterClimateZone", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="FloorArea">Floor Area (m<sup>2</sup>) </label>
                                        <BindedInput
                                            disabled
                                            className="form-control"
                                            id="FloorArea"
                                            name="FloorArea"
                                            type="number"
                                            binding={[viewModel, "floorArea", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                            </div>
                            
                            {viewModel.systemTypes !== undefined && dataExtension.getSelectOptionByValue(viewModel.systemTypes, viewModel.systemTypeId).index == 111 &&
                                <div className="form-row row">
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label htmlFor="PvDiverterTypeId">PV Diverter Type *</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="PvDiverterTypeId"
                                                name="PvDiverterTypeId"
                                                options="pvDiverterTypes"
                                                validate={formValidate}
                                                binding={[viewModel, "pvDiverterTypeId", bindingViewModel]}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="position-relative form-group">
                                            <label htmlFor="PvDiverterTankVolume">PV Diverter Tank Volume (m<sup>3</sup>) *</label>
                                            <BindedInput
                                                className="form-control"
                                                id="PvDiverterTankVolume"
                                                name="PvDiverterTankVolume"
                                                type="number"
                                                min="0"
                                                validate={formValidate}
                                                binding={[viewModel, "pvDiverterTankVolume", bindingViewModel]}
                                            ></BindedInput>
                                        </div>
                                    </div>
                                </div>
                            }
                        
                            <div className="form-row-separator"></div>
                            <div className="form-row row"></div>
                            <div className="form-row row">
                                <div className="col-12">
                                    <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </>
                }
           
            </div>
        </Fragment>
    );
}
export default connectAppStore(HotWaterModule);