import ReactPaginate from 'react-paginate';
const TablePaginate = (props)=>{
    return(<div className="col-sm-12 col-md-7">
    <div className="dataTables_paginate paging_full_numbers">
      <ReactPaginate
          className="pagination"
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={props.pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={3}
          onPageChange={async(data)=>props.handlePageClick(data)}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active page-active "
          hrefBuilder={(page, pageCount, selected) =>
            page >= 1 && page <= pageCount ? `/page/${page}` : '#'
          }
          hrefAllControls
          forcePage={props.currentPage}
          onClick={(clickEvent) => { }
        }
        />
    </div>
</div>);
}
export default TablePaginate;