
import { connectAppStore } from "../../../Store/AppStore";
import ProjectDataTable from "./ProjectDataTable";
import PageDependencies from "../../../Feature/PageDependencies";

const Projects = (props)=>{
    const { 
        layoutManager,
        useParams,
        Fragment, 
        useEffect, 
        useState,
        getAppStore,
        loaModelFromApi
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const developmentId = useParams().developmentId;
    const projectId = useParams().projectId;
    const [tableReloadProject, setTableReloadProject] = useState("");
    const [extraPostModelProject, setExtraPostModelProject] = useState({
      selectedItemIds: [],
    });
    const [extraPostModelDevelopment, setExtraPostModelDevelopment] = useState({
        selectedItemIds: [developmentId],
      });
    const [viewModel, setViewModel] = useState({
        projectId:projectId
    });
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, "api/DefaultModel/Accessor/Get", viewModel, setViewModel, setLoading, "Loading data", appStoreDispatch);
    }, []);
    
    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <div className="app-main__inner">
            <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className="page-title-icon">
                        <i className="pe-7s-map icon-gradient bg-tempting-azure">
                        </i>
                    </div>
                    <div>
                        Your projects in the selected development
                        <div className="page-title-subheading">
                        Select one or more developments to show the projects within your selection.
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p></p>
            </div>
            <div text-align="right">
                <a className="btn btn-primary" target="_blank" href="/">Download all analysis results</a>
            </div>
            <ProjectDataTable
                extraPostModelProject={extraPostModelProject}
                setExtraPostModelProject={setExtraPostModelProject}
                tableReload={tableReloadProject}
                setTableReload={setTableReloadProject}
                extraPostModelDevelopment={extraPostModelDevelopment}
                debug = {layoutManager.Layout_GetDebugModel(props)}
            >
            </ProjectDataTable>    
            </div>
        </div>
    </Fragment>);
}
export default connectAppStore(Projects);