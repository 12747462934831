import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import objectPropertyValueGetSet from "../../../../Function/ObjectPropertyValueGetSet";

const MultipleExternalWallsDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedTriStateCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    let zoneId = useParams().zoneId;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === 'undefined') zoneId = emptyGuid;
    const selectedWallIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedWallIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateExternalWalls";
    const getUrl = "api/Element/Accessor/GetExternalWalls";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading external walls", appStoreDispatch);
    },[]);

    const calculateAzimuth = (value) =>{
        if(value === undefined ) value = 0;
        if(value === null ) value = 0;
        if(value === "" ) value = 0;
        value = Number(value);
        const directions = ["North", "Northeast", "East", "Southeast", "South", "Southwest", "West", "Northwest"];
        const index = Math.round(value / 45) % 8;
        return directions[index];
    }

    const getBlockingFactors = (blockingFactor) => {
        const value = objectPropertyValueGetSet.getPropertyValue(viewModel, blockingFactor);
        return value;
    }

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="ExternalWallFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "External walls detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallName">Name *</label>
                        <BindedInput
                            className="form-control"
                            id="ExternalWallName"
                            name="ExternalWallName"
                            type="text"
                            binding={[viewModel, "name", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="ZoneId">Zone *</label>
                        <BindedSelect
                            className="form-control"
                            id="ZoneId"
                            name="ZoneId"
                            options="zones"
                            binding={[viewModel, "zoneId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallConstructionId">
                            Construction *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="ExternalWallConstructionId"
                            name="ExternalWallConstructionId"
                            options="constructions"
                            binding={[
                                viewModel,
                                "constructionId",
                                bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallLength">Length (mm) *</label>
                        <BindedInput
                            className="form-control"
                            id="ExternalWallLength"
                            name="ExternalWallLength"
                            type="number"
                            binding={[viewModel, "length", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallHeight">Height (mm) *</label>
                        <BindedInput
                            className="form-control"
                            id="ExternalWallHeight"
                            name="ExternalWallHeight"
                            type="number"
                            binding={[viewModel, "height", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallAzimuth">
                            Azimuth{" "}
                            <span>
                                (
                                {viewModel.azimuth === "" ||
                                    viewModel.azimuth === null ||
                                    viewModel.azimuth === undefined
                                    ? "0"
                                    : viewModel.azimuth}
                            </span>
                            <sup>o</sup>{" "}
                            <span>{calculateAzimuth(viewModel.azimuth)})</span>
                        </label>
                        <BindedInput
                            className="form-control"
                            min="0"
                            max="360"
                            id="ExternalWallAzimuth"
                            name="ExternalWallAzimuth"
                            type="number"
                            binding={[viewModel, "azimuth", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                {/* <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallGrossArea">
                            Area (m<sup>2</sup>) *
                        </label>
                        <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateArea(viewModel.length, viewModel.height)}</label>
                    </div>
                </div> */}
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallFixedShadingId">
                            Fixed shading
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="ExternalWallFixedShadingId"
                            name="ExternalWallFixedShadingId"
                            options="fixedShadings"
                            binding={[
                                viewModel,
                                "fixedShadingId",
                                bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>

            {viewModel.fixedShadingId !== "" && viewModel.fixedShadingId !== emptyGuid && <Fragment>
                <div className="form-row row">
                    <div className="col-12">
                        <h6>Eave</h6>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveLength">Length (mm)</label>
                            <BindedInput
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                name="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveLength", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveWidth">Width (mm)</label>
                            <BindedInput
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                name="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveWidth", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId">Projection</label>
                            <BindedSelect
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                name="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                options="horizontalShadingElement.eaveProjectionTypes"
                                binding={[viewModel, "horizontalShadingElement.eaveProjectionTypeId", bindingViewModel]}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset">Vertical offset (mm)</label>
                            <BindedInput
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveVerticalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset">Horizontal offset (mm)</label>
                            <BindedInput
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveHorizontalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-12">
                        <h6>Other fixed shading</h6>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength">Length (mm)</label>
                            <BindedInput
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingLength", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth">Width (mm)</label>
                            <BindedInput
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingWidth", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId">Projection</label>
                            <BindedSelect
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                options="horizontalShadingElement.eaveProjectionTypes"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingProjectionTypeId", bindingViewModel]}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset">Vertical offset (mm)</label>
                            <BindedInput
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingVerticalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset">Horizontal offset (mm)</label>
                            <BindedInput
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingHorizontalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-12">
                        <label>Blocking factor (opaque = 100%)</label>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage0")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage1")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage2")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage3")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage4">May</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage4")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage5")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage6")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage7")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage8")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage9")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage10")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage11")}</label>
                        </div>
                    </div>
                </div>
                <div className="form-row row"></div>
            </Fragment>}

            <div className="form-row row">
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallOpeningArea">
                            Opening area (m<sup>2</sup>) *
                        </label>
                        <BindedInput
                            className="form-control"
                            min="0"
                            max="360"
                            id="ExternalWallOpeningArea"
                            name="ExternalWallOpeningArea"
                            type="number"
                            binding={[viewModel, "openingArea", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallOpeningTypeId">
                            Opening type *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="ExternalWallOpeningTypeId"
                            name="ExternalWallOpeningTypeId"
                            options="openingTypes"
                            binding={[viewModel, "openingTypeId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        id="ExternalWallHasCompositeWall" 
                        name="ExternalWallHasCompositeWall"
                        className="custom-control-input"
                        binding={[viewModel, "hasCompositeWall", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="ExternalWallHasCompositeWall"
                        className="custom-control-label"
                    >
                        Composite wall
                    </label>
                </div>
                {viewModel.hasCompositeWall && (
                    <Fragment>
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="ExternalWallAboveWallId">
                                    Wall above
                                </label>
                                <BindedSelect
                                    className="form-control"
                                    id="ExternalWallAboveWallId"
                                    name="ExternalWallAboveWallId"
                                    options="aboveWalls"
                                    binding={[
                                        viewModel,
                                        "aboveWallId",
                                        bindingViewModel,
                                    ]}
                                ></BindedSelect>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="ExternalWallBelowWallId">
                                    Wall below
                                </label>
                                <BindedSelect
                                    className="form-control"
                                    id="ExternalWallBelowWallId"
                                    name="ExternalWallBelowWallId"
                                    options="belowWalls"
                                    binding={[
                                        viewModel,
                                        "belowWallId",
                                        bindingViewModel,
                                    ]}
                                ></BindedSelect>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        id="ExternalWallHasWingWalls" 
                        name="ExternalWallHasWingWalls"
                        className="custom-control-input"
                        binding={[viewModel, "hasWingWalls", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="ExternalWallHasWingWalls"
                        className="custom-control-label"
                    >
                        Wing walls
                    </label>
                </div>
                {viewModel.hasWingWalls && viewModel.simulationType === "Existing Home" &&
                    <>
                        <div className="col-2">
                            <div className="custom-checkbox custom-control">
                                <BindedTriStateCheckBox
                                    id="IgnoreLeft" 
                                    name="IgnoreLeft"
                                    className="custom-control-input"
                                    binding={[viewModel, "ignoreLeft", bindingViewModel]}
                                ></BindedTriStateCheckBox>
                                <label
                                    style={{ marginLeft: "5px" }}
                                    htmlFor="IgnoreLeft"
                                    className="custom-control-label"
                                >
                                    Ignore Left
                                </label>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="custom-checkbox custom-control">
                                <BindedTriStateCheckBox
                                    id="IgnoreRight" 
                                    name="IgnoreRight"
                                    className="custom-control-input"
                                    binding={[viewModel, "ignoreRight", bindingViewModel]}
                                ></BindedTriStateCheckBox>
                                <label
                                    style={{ marginLeft: "5px" }}
                                    htmlFor="IgnoreRight"
                                    className="custom-control-label"
                                >
                                    Ignore Right
                                </label>
                            </div>
                        </div>
                        <div className="col-12">
                            <p>Wing walls may be ignored if:                                                                         </p>
                            <p>-The depth of the wing wall is less than or equal to 2 metres                                         </p>
                            <p>-The wing wall is on the southern side of the wall/window                                             </p>
                            <p>-The top of the wing wall is less than 0.6metres or more below the top of the wall/window             </p>
                            <p>-The horizontal offset of the wing wall is greater than the depth of the wing wall                    </p>
                        </div>
                    </>                                                          
                }
            </div>
            {viewModel.hasWingWalls && <Fragment>
                <div className="form-row row">
                    <div id="ExternalWallWingWallsDiv" className="pl-4">
                        <div>
                            <h6>Left wing wall</h6>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallLeftWingWallProjection">
                                Projection (mm)
                            </label>
                            <BindedInput
                                className="form-control"
                                min="0"
                                id="ExternalWallLeftWingWallProjection"
                                name="ExternalWallLeftWingWallProjection"
                                type="number"
                                binding={[viewModel, "leftWingWallProjection", bindingViewModel]}
                            ></BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallLeftWingWallHorizontalOffset">
                                Horizontal offset (mm)
                            </label>
                            <BindedInput
                                className="form-control"
                                min="0"
                                id="ExternalWallLeftWingWallHorizontalOffset"
                                name="ExternalWallLeftWingWallHorizontalOffset"
                                type="number"
                                binding={[viewModel, "leftWingWallHorizontalOffset", bindingViewModel]}
                            ></BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallLeftWingWallVerticalOffset">
                                Vertical offset (mm)
                            </label>
                            <BindedInput
                                className="form-control"
                                min="0"
                                id="ExternalWallLeftWingWallVerticalOffset"
                                name="ExternalWallLeftWingWallVerticalOffset"
                                type="number"
                                binding={[viewModel, "leftWingWallVerticalOffset", bindingViewModel]}
                            ></BindedInput>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div id="ExternalWallWingWallsDiv" className="pl-4">
                        <div>
                            <h6 style={{ marginTop: "5px" }}>Right wing wall</h6>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallRightWingWallProjection">
                                Projection (mm)
                            </label>
                            <BindedInput
                                className="form-control"
                                min="0"
                                id="ExternalWallRightWingWallProjection"
                                name="ExternalWallRightWingWallProjection"
                                type="number"
                                binding={[viewModel, "rightWingWallProjection", bindingViewModel]}
                            ></BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallLeftWingWallHorizontalOffset">
                                Horizontal offset (mm)
                            </label>
                            <BindedInput
                                className="form-control"
                                min="0"
                                id="ExternalWallLeftWingWallHorizontalOffset"
                                name="ExternalWallRightWingWallHorizontalOffset"
                                type="number"
                                binding={[viewModel, "rightWingWallHorizontalOffset", bindingViewModel]}
                            ></BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallRightWingWallVerticalOffset">
                                Vertical offset (mm)
                            </label>
                            <BindedInput
                                className="form-control"
                                min="0"
                                id="ExternalWallRightWingWallVerticalOffset"
                                name="ExternalWallRightWingWallVerticalOffset"
                                type="number"
                                binding={[viewModel, "rightWingWallVerticalOffset", bindingViewModel]}
                            ></BindedInput>
                        </div>
                    </div>
                </div>
            </Fragment>}
            <div className="form-row row">
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="ExternalWallBaseHeight">
                            Base height (mm) *
                        </label>
                        <BindedInput
                            className="form-control"
                            min="0"
                            id="ExternalWallBaseHeight"
                            name="ExternalWallBaseHeight"
                            type="number"
                            binding={[viewModel, "baseHeight", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
            </div>          
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleExternalWallsDetail);