import { Fragment } from "react";
import PageDependencies from "../../../../../Feature/PageDependencies";

const ConditionerDataTable = (props)=>{
    const { 
        getData,
        guid,
        useState,
        toastPrint,
        tryCatchFinally,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const debug = props.debug === undefined ? false : props.debug;
    const conditionerType = props.conditionerType;
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
    });
    let [selectedIds, setSelectedIds] = useState({});
    if(props.selectedIds !== undefined) selectedIds = props.selectedIds;
    if(props.setSelectedIds !== undefined) setSelectedIds = props.setSelectedIds;
    const data = props.data;
    const setData = props.setData;
    const tableId = "Conditioner_"+ conditionerType +"_ff0c27bb-03c5-44e9-8867-ebeca30ea662";
    const tableReload = props.tableReload;
    const setTableReload = props.setTableReload;
    const extraPostModel = props.extraPostModel;
    const url = props.url;
    const columnsDic = {};
    columnsDic["Heating"] =[
      { name: "conditionerIndex", text: "Id", width: "5%" },
      { name: "name", text: "Name", width: "35%" },
      { name: "fuelType", text: "Fuel Type", width: "10%" },
      { name: "cop", text: "COP", width: "10%" },
      { name: "hspf", text: "HSPF", width: "10%" },
      { name: "starRating", text: "Star Rating", width: "10%" },
      { name: "capacity", text: "Capacity", width: "10%" },
      {
          name: "operation",
          text: "Operation",
          width: "10%",
          generate: operationGenerate
      },
    ];
    columnsDic["Cooling"] =[
      { name: "conditionerIndex", text: "Id", width: "5%" },
      { name: "name", text: "Name", width: "35%" },
      { name: "fuelType", text: "Fuel Type", width: "10%" },
      { name: "eer", text: "EER", width: "10%" },
      { name: "tcspf", text: "TCSPF", width: "10%" },
      { name: "starRating", text: "Star Rating", width: "10%" },
      { name: "capacity", text: "Capacity", width: "10%" },
      {
          name: "operation",
          text: "Operation",
          width: "10%",
          generate: operationGenerate
      },
    ];
   
    const deleteHandler = (id)=>{
      tryCatchFinally.syncProcess(()=>{
          setModal({
            ...modal,
            isOpen: true,
            title: "Delete " + conditionerType + " device",
            message:
              "Are sure to delete " + conditionerType + " device?",
            comfirmButtonStyle: "danger",
            comfirmButtonText: "Confirm to delete",
            handleComfirm: async ()=>{
              let deleteUrl = "";
              if(conditionerType === "Heating"){
                deleteUrl = "api/ExistingHome/HeatingModule/Accessor/DeleteHeatingConditioner";
              }
              else if (conditionerType === "Cooling"){
                deleteUrl = "api/ExistingHome/CoolingModule/Accessor/DeleteCoolingConditioner";
              }
              // const deleteUrl = "api/HeatingModule/Accessor/DeleteHeatingConditioner";
              const deleteResponse = await getData.processPost(
                true,
                deleteUrl,
                    {...extraPostModel, selectedItemIds:[id]}
                ); 
                toastPrint.printResponse(deleteResponse);
              setTableReload(guid.newGuid());
            },
          });
        });      
      }

      function operationGenerate(model, cloumn, index, style, onColumnValueChangeEvent){
        return(<button onClick={(e)=>deleteHandler(model.id)} id = {model.id} className="btn btn-danger">Delete</button>);
      }

      return(<Fragment>
            <ModalDatatableItem
              modal={modal}
              setModal={setModal}
            ></ModalDatatableItem>
            <ServerSideDataTable
              disableSorting
              removePaging
              removeSearch
              debug ={debug}
              id={tableId}
              location="Conditioner"
              data={data}
              allowSelect
              setSelectedItemIds = {setSelectedIds}
              setData={setData}
              reload={tableReload}
              columns={columnsDic[conditionerType]}
              dataSource={{ isAuth: true, url: url }}
              extraPostModel={extraPostModel}
              ></ServerSideDataTable>
      </Fragment>);
}
export default ConditionerDataTable;