import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";

const RoofWindowSkylightDataTable = (props) => {
    const {
        tryCatchFinally,
        stringCoding,
        layoutManager,
        getData,
        guid,
        toastPrint,
        useLocation,
        useNavigate,
        dataExtension,
        Fragment, 
        useState, 
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const emptyGuid = guid.emptyGuid()
    var tableId = props.id === undefined ? emptyGuid : props.id;
    const redirectPathHex = stringCoding.encode(useLocation().pathname);
    const roofId = props.roofId;
    const zoneId = props.zoneId;
    const designVariationVersionName = props.designVariationVersionName;
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultViewModel = {
        designVariationVersionName: designVariationVersionName,
        zoneId: zoneId,
        selectedItemIds: [roofId],
        elementInType: "Roof",
        parentId: roofId
    };
    const [extraPostModel, setExtraPostModel] = useState(defaultViewModel);
    const [data, setData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const getUrl = "api/Element/Accessor/GetRoofWindowSkylights";
    const [tableReload, setTableReload] = useState("");
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => { },
    });

    const nav = useNavigate();
    const columns = [
        { name: "zone", text: "Zone", width: "20%" },
        { name: "roof", text: "Roof", width: "10%" },
        { name: "name", text: "Name", width: "20%" },
        { name: "type", text: "Type", width: "20%" },
        { name: "construction", text: "Construction", width: "20%" },
        { name: "area", text: "Area (m<sup>2</sup>)", width: "10%" }
    ];

    const generateUrlParams = (type, elementId) => {
        return "/ElementAccessor/Detail/" + type + "/" + props.designVariationVersionName + "/" + props.developmentId + "/" + props.projectId + "/" + props.designVariationId + "/" + zoneId + "/" + elementId + "/" + roofId + "/" + redirectPathHex;
    }

    const handleDeleteComfirm = async () => {
        await tryCatchFinally.asyncProcess(async () => {
            const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
            setLoading({ isLoading: true, text: "Deleting elements" });
            const selectResponse = await getData.processPost(
                true,
                "api/Element/Accessor/DeleteElements",
                { selectedItemDic: selectedItemDic, designVariationVersionName: props.designVariationVersionName, projectId: props.projectId, designVariationId: props.designVariationId }
            );
            toastPrint.printResponse(selectResponse);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    const handleDuplicatedComfirm = async () => {
        await tryCatchFinally.asyncProcess(async () => {
            const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
            setLoading({ isLoading: true, text: "Duplicating elements" });
            const selectResponse = await getData.processPost(
                true,
                "api/Element/Accessor/CopyElements",
                { selectedItemDic: selectedItemDic, designVariationVersionName: props.designVariationVersionName, projectId: props.projectId, designVariationId: props.designVariationId, zoneId: props.zoneId, parentId: props.roofId, }
            );
            toastPrint.printResponse(selectResponse);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    const actionOnDoubleClickRow = (model, newData) => {
        let type = model.type.replace(/\s/g, '')
        nav(generateUrlParams(type, model.id));
    }

    const checkSelectedTypes = () => {
        const rows = dataExtension.getSelectedRows(data);
        let type = "";
        for(let i=0; i<rows.length; i++){
          if(i===0){
            type = rows[i].type;
          }
          else{
            if(type !== rows[i].type){
              return true;
            }
          }
        }
        return false;
    }

    const contextMenu = {
        items: {
            new: {
                name: "New",
                icon: "add",
                items: {
                    createRoofWindow: {
                        name: "Roof Window",
                        icon: "",
                        url: generateUrlParams("RoofWindow", emptyGuid),
                        isNavOption: true
                    },
                    createSkylight: {
                        name: "Skylight",
                        icon: "",
                        url: generateUrlParams("Skylight", emptyGuid),
                        isNavOption: true
                    },
                },
            },
            separator: "---------",
            edit: {
                name: "Edit",
                icon: "edit",
                defaultAction: false,
                disabled: function () {
                    return dataExtension.getSelectedRowDataIds(data).length === 0 || checkSelectedTypes();
                },
            },
            delete: {
                name: "Delete",
                icon: "delete",
                url: "",
                defaultAction: false,
                disabled: function () {
                    return dataExtension.getSelectedRowDataIds(data).length === 0;
                },
            },
            duplicate: {
                name: "Duplicate",
                icon: "copy",
                url: "",
                defaultAction: false,
                disabled: function () {
                    return dataExtension.getSelectedRowDataIds(data).length === 0;
                },
            }
        },
        callBack: (
            contextMenuCallBackKey,
            contextMenuCallBackElement,
            contextMenuCallBackTableRoadData,
            localtion
        ) => {
            if (contextMenuCallBackKey === "edit") {
                let type = dataExtension.getSelectedRows(data)[0].type.replace(" ","").replace("/","");
                const selectedElementIds = dataExtension.getSelectedRowDataIds(data);
                if (selectedElementIds.length == 1) {
                    nav(generateUrlParams(type, selectedElementIds[0]));
                } else {
                    const urlParams = props.designVariationVersionName + "/" + props.developmentId + "/" + props.projectId + "/" + props.designVariationId + "/" + props.zoneId + "/" + stringCoding.encodeObject(selectedElementIds) + "/" + redirectPathHex;
                    nav("/ElementAccessor/MultipleDetail/" + type + "/" + urlParams);
                }
                return;
            }
            if (contextMenuCallBackKey === "delete") {
                setModal({
                    ...modal,
                    isOpen: true,
                    title: "Delete elements",
                    message:
                        "Are sure to delete " +
                        dataExtension.getSelectedRowDataIds(data).length +
                        " selected elments?",
                    comfirmButtonStyle: "danger",
                    comfirmButtonText: "Confirm to delete",
                    handleComfirm: handleDeleteComfirm,
                });
            }
            if (contextMenuCallBackKey === "duplicate") {
                setModal({
                    ...modal,
                    isOpen: true,
                    title: "Duplicate elements",
                    message:
                        "Are sure to duplicate " +
                        dataExtension.getSelectedRowDataIds(data).length +
                        " selected elements?",
                    comfirmButtonStyle: "primary",
                    comfirmButtonText: "Confirm to duplicate",
                    handleComfirm: handleDuplicatedComfirm,
                });
            }
        },
    };

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
            {debug && <p>{JSON.stringify(extraPostModel)}</p>}
            {debug && <p>redirect Url: {stringCoding.decode(redirectPathHex)}</p>}
            <ModalDatatableItem
                modal={modal}
                setModal={setModal}
            ></ModalDatatableItem>
            <ServerSideDataTable
                id={tableId}
                location="RoofWindowsSkylights"
                data={data}
                setData={setData}
                reload={tableReload}
                columns={columns}
                actionOnDoubleClickRow={actionOnDoubleClickRow}
                allowSelect
                allowSelectMultiple
                dataSource={{ isAuth: true, url: getUrl }}
                contextMenu={contextMenu}
                extraPostModel={extraPostModel}
                setSelectedItemIds={setSelectedIds}
                removePaging={true}
                removeSearch={true}
                disableSorting
            ></ServerSideDataTable>
        </Fragment>
    );
}
export default connectAppStore(RoofWindowSkylightDataTable);