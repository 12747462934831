import DesignVariations from "../../Portal/Accessor/DesignVariation/DesignVariations";
import DesignVariationDetail from "../../Portal/Accessor/DesignVariation/DesignVariationDetail";
const AccessorDesignVariationRouters = [
    {
      path: "DesignVariationAccessor/DesignVariations/:projectId",
      exact: true,
      requireAuth: true,
      element: <DesignVariations/>,
    },
    {
      path: "DesignVariationAccessor/Detail/:projectId/:designVariationId",
      exact: true,
      requireAuth: true,
      element: <DesignVariationDetail/>,
    },
  ];
export default AccessorDesignVariationRouters;