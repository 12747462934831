import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./DataTableAe.css";
import { Droppable, Draggable } from "react-beautiful-dnd";
import "jquery-contextmenu/dist/jquery.contextMenu.min.css";
import "jquery-contextmenu/dist/jquery.contextMenu.min.js";
import PageDependencies from "../PageDependencies";
import { set } from "date-fns";
const HtmlToReactParser = require("html-to-react").Parser;

const DropDragDataTable = (props) => {
  const {
    guid,
    Fragment, 
    useEffect, 
    useState,
} = PageDependencies();
  const tableId = props.id === undefined ? guid.newGuid() : props.id;
  const htmlToReactParser = new HtmlToReactParser();
  const isDropDisabled = props.isDropDisabled === undefined ? false : true;
  const isDragDisabled = props.isDragDisabled === undefined ? false : true;
  const tableCell = { border: "1px solid #e9ecef" };
  const noRecordsMessage =
    props.noRecordsMessage === undefined
      ? "No records found."
      : props.noRecordsMessage;
  const [data, setData] = [setDataDefaultIsSelected(props.data), props.setData];
  const sortingColumns = props.columns;
  const [isLoading, setIsLoading] = useState(false);
  const debug = props.debug === undefined ? false : props.debug;
  function setDataDefaultIsSelected(oData) {
    if (oData.length === undefined) {
      return [];
    }
    if (oData.length === 0) {
      return [];
    }
    if (oData[0].isSelected === undefined) {
      let dataClone = [];
      for (let i = 0; i < oData.length; i++) {
        dataClone[i] = { ...oData[i], isSelected: false };
        if (dataClone[i].id === undefined) {
            dataClone[i].id = guid.newGuid();
        }
      }
      return dataClone;
    }
    return oData;
  }

  const onTdValueChange = (e, columnName, valueProcess) => {
    let value = e.target.value;
      if(valueProcess !== undefined){
        value = valueProcess(value);
      }
      const inputId = e.currentTarget.getAttribute("id");
      const id = inputId.replace(tableId,"").replace(columnName,"");
      const dataClone = [...data];
      for (let i = 0; i < dataClone.length; i++) {
        if (dataClone[i].id === id) {
          dataClone[i][columnName] = value;
          break;
        }
      }
      setData(dataClone, ()=>{
       setTimeout(()=>{
        const inputElement = document.getElementById(inputId);
        inputElement.focus();
       },100);
      });
  };

  const showCell = (model) => {
    var html = sortingColumns.map((c, i) => {
      const style = { ...c.style, tableCell };
      const elementHtml = c.generate ? c.generate(model, c, i, style, onTdValueChange) : model[c.name];
        return (<td style={style} key={i + "_" + guid.newGuid()}>
              {elementHtml}
            </td>);
    });
    return html;
  };

  const showRows = () => {
    const rows = data.map((m, i) => {
      return (
        <Draggable
          isDragDisabled={isDragDisabled}
          key={m.id}
          draggableId={m.id}
          index={i}
        >
          {(provided, snapshot) => {
            let classNameContent = m.isSelected
              ? "exclude table-active "
              : i % 2 === 1
              ? "odd "
              : "even ";
            if (snapshot.isDragging) {
              classNameContent = classNameContent + " " + "dragging";
            }
            return (
              <tr
                id={m.id}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                className={classNameContent}
              >
                {showCell(m)}
              </tr>
            );
          }}
        </Draggable>
      );
    });
    return rows;
  };

  const generateEmptyTd = () => {
    const count = sortingColumns.length - 1;
    const emptys = [];
    for (let i = 0; i < count; i++) {
      emptys[i] = i;
    }
    const trs = emptys.map((i, key) => {
      return <td key={key} style={tableCell}></td>;
    });
    return trs;
  };

  const generateTBody = (provided) => {
    const trs = (
      <Fragment>
        {isLoading && (
          <tr className="odd">
            <td style={tableCell}>Loading data...</td>
            {generateEmptyTd()}
          </tr>
        )}
        {!isLoading && data.length === 0 && (
                <tr id={guid.newGuid()} className="odd">
            <td style={tableCell}>{noRecordsMessage}</td>
            {generateEmptyTd()}
          </tr>
        )}
        {!isLoading && data.length > 0 && showRows()}
      </Fragment>
    );
    return (
      <tbody {...provided.droppableProps} ref={provided.innerRef}>
        {trs}
        {provided.placeholder}
      </tbody>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    setData(data);
    setIsLoading(false);
  }, []);

  return (
    <Fragment>
      {debug && <p>{JSON.stringify(data)}</p>}
      <div className="dataTables_wrapper dt-bootstrap4">
        <div className="row"  style={props.style} >
          <div className="col-sm-12">
            <table
              id={tableId}
              style={{
                width: "100%",
                "--bs-table-hover-bg": "#E0F3FF",
                "--bs-table-active-bg": "#0d6efd",
              }}
              className="table table-hover table-striped table-bordered dataTable"
              role="grid"
            >
              <thead>
                <tr>
                  {sortingColumns.map((column, index) => {
                    return (
                      <th
                        key={index}
                        style={{ ...tableCell, width: column.width }}
                      >
                        {htmlToReactParser.parse(column.text)}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tfoot>
                <tr>
                  {sortingColumns.map((column, index) => {
                    return (
                      <th
                        key={index}
                        style={{ ...tableCell, width: column.width }}
                      >
                        {htmlToReactParser.parse(column.text)}
                      </th>
                    );
                  })}
                </tr>
              </tfoot>
              <Droppable
                isDropDisabled={isDropDisabled}
                droppableId={tableId}
              >
                {(provided, snapshot) => {
                  return generateTBody(provided);
                }}
              </Droppable>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DropDragDataTable;
