import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const MultipleRoofWindowsDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedTriStateCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const zoneId = useParams().zoneId;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedElementIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedElementIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateMultipleRoofWindows";
    const getUrl = "api/Element/Accessor/GetMultipleRoofWindows";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading roof windows", appStoreDispatch);
    },[]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="FloorCeilingFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "Roof windows detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="RoofWindowName">Name *</label>
                        <BindedInput
                            className="form-control"
                            id="RoofWindowName"
                            name="RoofWindowName"
                            type="text"
                            binding={[viewModel, "name", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="RoofWindowConstructionId">
                            Construction *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="RoofWindowConstructionId"
                            name="RoofWindowConstructionId"
                            options="constructions"
                            binding={[
                                viewModel,
                                "constructionId",
                                bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="IndoorShadingTypeId">
                            Indoor Shading *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="IndoorShadingTypeId"
                            name="IndoorShadingTypeId"
                            options="indoorShadingTypes"
                            binding={[viewModel, "indoorShadingTypeId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="OutdoorShadingPercentTypeId">
                            Outdoor *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="OutdoorShadingPercentTypeId"
                            name="OutdoorShadingPercentTypeId"
                            options="outdoorShadingPercentTypes"
                            binding={[
                                viewModel,
                                "outdoorShadingPercentTypeId",
                                bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="OpenablePercent">Openable (%) *</label>
                        <BindedInput
                            className="form-control"
                            id="OpenablePercent"
                            name="OpenablePercent"
                            type="number"
                            min="0"
                            binding={[viewModel, "openablePercent", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="GrossArea">Area (m<sup>2</sup>) *</label>
                        <BindedInput
                            className="form-control"
                            id="GrossArea"
                            name="GrossArea"
                            type="number"
                            min="0"
                            binding={[viewModel, "grossArea", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                        <label htmlFor="Pitch">Pitch (degree) *</label>
                        <BindedInput
                            className="form-control"
                            id="Pitch"
                            name="Pitch"
                            type="number"
                            min="0"
                            binding={[viewModel, "pitch", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        disabled
                        id="HasInsectScreens"
                        name="HasInsectScreens"
                        className="custom-control-input"
                        binding={[viewModel, "hasInsectScreens", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                        style={{ marginLeft: "5px" }}
                        htmlFor="HasInsectScreens"
                        className="custom-control-label"
                    >
                        Insect screens
                    </label>
                </div>
            </div>
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleRoofWindowsDetail);