import React, { Fragment } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav
} from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styleLink = {
  color:"black"
}

class MegaMenu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      popoverOpen: false,
    };
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      popoverOpen: !this.state.popoverOpen,
    });
  }
  state = {};
  render() {
    return (
        <Fragment>
            {this.props.userInfo.userRole === 'Administrator' && <Fragment>
                <ul className="header-megamenu nav">
                    <li className="btn-group nav-item">
                        <a id="FinanceReportLink" className="nav-link" href="/Admin/FinanceReport" target="_blank">

                            <p style={{ marginTop: '15px' }}><i className="pe-7s-credit material-icons"></i>Finance Report</p>
                        </a>
                    </li>
                    <li className="btn-group nav-item">
                        <a id="PaymentLink" className="nav-link" href="/Admin/PaymentCredits">

                            <p style={{ marginTop: '15px' }}><i className="pe-7s-credit material-icons"></i>Payment</p>
                        </a>
                    </li>
                    <li className="btn-group nav-item">
                        <a id="RefundLink" className="nav-link" href="/Admin/PaymentRefunds">

                            <p style={{ marginTop: '15px' }}><i className="pe-7s-credit material-icons"></i>Refund</p>
                        </a>
                    </li>
                    <li className="btn-group nav-item">
                        <a id="CreditUnitLink" className="nav-link" href="/Admin/CreditUnits">

                            <p style={{ marginTop: '15px' }}><i className="pe-7s-credit material-icons"></i>Credit Units</p>
                        </a>
                    </li>
                    <li className="btn-group nav-item">
                        <a id="ProjectsLink" className="nav-link" href="/Admin/Developments">
                            {/* <i className="material-icons">build</i> */}
                            <p style={{ marginTop: '15px' }}> Developments </p>
                        </a>
                    </li>

                    <li className="btn-group nav-item">
                        <a id="ProjectsLink" className="nav-link" href="/Admin/Projects">
                            {/* <i className="material-icons">assignment</i> */}
                            <p style={{ marginTop: '15px' }}> Projects </p>
                        </a>
                    </li>

                    <li className="btn-group nav-item">
                        <a id="UsersLink" className="nav-link" href="/Admin/Users/">
                            {/* <i className="material-icons">person</i> */}
                            <p style={{ marginTop: '15px' }}><i className="pe-7s-user material-icons"></i> Users </p>
                        </a>
                    </li>
                </ul>
                <a className="btn btn-primary" href="/Admin/Users/Detail/00000000-0000-0000-0000-000000000000" target="_blank">Register User</a>
                <a style={{marginLeft:"15px"}} className="btn btn-primary" href="/Admin/SimulationByUserName" target="_blank">Simulation By Username</a>
            </Fragment>}
        <Nav className="header-megamenu">

          {this.props.userInfo.userRole !== 'Administrator' && <Fragment>
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  <u>L</u>ibraries
                  <FontAwesomeIcon className="ms-2 opacity-5" icon={faAngleDown} />
                </DropdownToggle>
                <DropdownMenu className="rm-pointers dropdown-menu">
                <DropdownItem>
                  <a style={styleLink} href="/LibraryAccessor/Constructions">
                    <u>C</u>onstructions
                  </a>
                </DropdownItem>
                <DropdownItem><u>S</u>hading schemes</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  <u>P</u>rojects
                  <FontAwesomeIcon className="ms-2 opacity-5" icon={faAngleDown} />
                </DropdownToggle>
                <DropdownMenu className="rm-pointers dropdown-menu">            
                  <DropdownItem>
                    <a style={styleLink} href="/ProjectAccessor/Detail/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000">
                      <u>N</u>ew project
                    </a>      
                  </DropdownItem>
                  <DropdownItem>
                      <a style={styleLink} href="/DevelopmentAccessor">
                      <u>S</u>aved projects
                    </a>
                  </DropdownItem>      
                </DropdownMenu>
              </UncontrolledDropdown>
            </Fragment>}
        </Nav>
      </Fragment>
    );
  }
}

export default MegaMenu;
