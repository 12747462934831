import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import "./PaymentResult.css"
const PaymentResult = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        useReducerSmart,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const {jsonParam} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        referenceId: jsonParam,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/Payment/Accessor/GetPaymentResult";

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Payment Result", appStoreDispatch, undefined, setInit);
    },[]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Payment Result
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                     <div className="main-card mb-3 col-6 card">
                     <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                         <div className="card-body">
                             <div className="standard-container__inner--reduced  bg--white gap--encircle--large">
                                 <div className="gap--top--default gap--bottom--default">
                                     <h1 className="h2 receipt__heading gap--bottom--small">{ viewModel.summaryCode == 1 ? "Thanks for your payment" : "Someting went wrong with your payment"}</h1>
                                     <p className="text--remove-margin">Transaction Id: {viewModel.transactionId}</p>
                                     <p className="text--remove-margin">Payment Status: {viewModel.resultText} </p>
                                 </div>
                                 <div className="bg--light">
                                     <div className="gap--encircle--large">
                                         {( viewModel.summaryCode == 1) && <Fragment>
                                         <h3>Transaction Details</h3>
                                         <div className="gap--encircle--large">
                                             <div className="grid grid--2 gap--top--small transaction-details">
                                                 <div className="grid__item">
                                                     <p>Reference Number</p>
                                                 </div>
                                                 <div className="grid__item">
                                                     <p>{viewModel.referenceId}</p>
                                                 </div>
                                                 <div className="grid__item">
                                                     <p>Amount</p>
                                                 </div>
                                                 <div className="grid__item">
                                                     <p>{viewModel.amount} AUD</p>
                                                 </div>
                                                 <div className="grid__item">
                                                     <p>Settlement Date</p>
                                                 </div>
                                                 <div className="grid__item">
                                                     <p>{viewModel.settleTime}</p>
                                                 </div>
                                             </div>
                                         </div></Fragment>}
                                         <div className="gap--top--default">
                                             If you have any questions or require assistance regarding your payment please contact us:<br/>
                                             <a style={{color:"#004B87"}} href="mailto:accurate.enterprise@csiro.au">accurate.enterprise@csiro.au</a>
                                         </div>
                                     </div>
                                 </div>
                                 <div className="gap--top--small">
                                     {( viewModel.summaryCode == 1) &&
                                     <a style={{marginRight:'15px'}} target="_blank" href={"api/Credit/Accessor/Download/" + jsonParam} type="button" className="btn btn--medium btn--transparent">Download receipt</a>}
                                     <a href="/UserAccessor/Credits" type="button" className="btn btn--medium btn--transparent">Back to Credits Page</a>
                                 </div>
                                 <div className="gap--top--x-small">
                                     <p>
                                         Before navigating away from this page we recommend that you print or save a copy in case an email receipt has not been delivered. Once you close your browser or navigate away from this page your receipt will be lost.
                                     </p>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
                }
           
            </div>
        </Fragment>
    );
}
export default  connectAppStore(PaymentResult);
