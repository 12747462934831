import { Fragment } from "react";
import PageDependencies from "../../Feature/PageDependencies";

const PaymentDataTable = (props)=>{
    const { 
        getData,
        tryCatchFinally,
        guid,
        useState,
        stringCoding,
        toastPrint,
        dataExtension,
        useNavigate,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const debug = props.debug === undefined ? false : props.debug;
    const data = props.data;
    const setData = props.setData;
    const tableId = props.tableId;
    const tableReload = props.tableReload;
    const setTableReload = props.setTableReload;
    const extraPostModel = props.extraPostModel;
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const nav = useNavigate();
    const url = props.url;
    const columns =[];
    if (props.showRefundColumns === true){
        columns.push({ name: "amount", text: "Amount", width: "9%" });
        columns.push({ name: "state", text: "State", width: "6%" });
        columns.push({ name: "issueTime", text: "Issue Time", width: "10%" });
        columns.push({ name: "completedTime", text: "Completed Time", width: "10%" });
        columns.push({ name: "customerInfo", text: "Customer Info", width: "15%" });
        columns.push({ name: "transactionType", text: "Transaction Type", width: "9%" });
        columns.push({ name: "transactionId", text: "Refund Transaction Id", width: "10%" });
        columns.push({ name: "operateAdmin", text: "Operate Admin", width: "7%" });
        columns.push({ name: "reason", text: "Reason", width: "9%" });
        columns.push({ name: "refundReferenceId", text: "Refund Reference Id", width: "15%" });
    }
    else{
        columns.push({ name: "amount", text: "Amount", width: "10%" });
        columns.push({ name: "state", text: "State", width: "10%" });
        columns.push({ name: "issueTime", text: "Issue Time", width: "10%" });
        columns.push({ name: "completedTime", text: "Completed Time", width: "10%" });
        columns.push({ name: "customerInfo", text: "Customer Info", width: "20%" });
        columns.push({ name: "transactionType", text: "Transaction Type", width: "20%" });
        columns.push({ name: "transactionId", text: "Payment Transaction Id", width: "20%" });
    }

    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
    });
  
    const contextMenu = {
        items: {
          cancel: {
            name: "Cancel",
            icon: "",
            defaultAction: false,
            disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length !== 1;
            },
          },
          approve: {
            name: "Approve",
            icon: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length !== 1;}
          },
        },callBack: (
          contextMenuCallBackKey,
          contextMenuCallBackElement,
          contextMenuCallBackTableRoadData,
          localtion
        ) => {
            if (contextMenuCallBackKey === "cancel"){
                cancelHandler();
            }
            if (contextMenuCallBackKey === "approve"){
                approveHandler();
            }
        },
    };

    const cancelHandler = async () => {
        setLoading({isLoading:true,text:"Canceling Refund"});
        await tryCatchFinally.syncProcess(async ()=>{
            const selectedItemId = dataExtension.getSelectedRowDataIds(data)[0];
            const response = await getData.processPost(
                true,
                "api/PaymentAdmin/Cancel",
                {selectedItemIds: [selectedItemId]}
                ); 
            toastPrint.printResponse(response);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    const approveHandler = async () => {
        setLoading({isLoading:true,text:"Approving Refund"});
        await tryCatchFinally.syncProcess(async ()=>{
            const selectedItemId = dataExtension.getSelectedRowDataIds(data)[0];
            const response = await getData.processPost(
                true,
                "api/PaymentAdmin/Approve",
                {selectedItemIds: [selectedItemId]}
                ); 
            const urlParams = stringCoding.encodeObject(response.viewModel);
            nav("/Admin/Credits/Refund/Approve/" + urlParams);
        }, setLoading);
    }

    return(<Fragment>
        {debug && <p>{JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
        <ModalDatatableItem
            modal={modal}
            setModal={setModal}
        ></ModalDatatableItem>
        {props.showRefundColumns && 
            <ServerSideDataTable
            allowSelect={props.showRefundColumns}
            debug ={debug}
            id={tableId}
            location="AdminPayments"
            data={data}
            setData={setData}
            reload={tableReload}
            columns={columns}
            dataSource={{ isAuth: true, url: url }}
            contextMenu={contextMenu}
            extraPostModel={extraPostModel}
        ></ServerSideDataTable>
        }
        {!props.showRefundColumns && 
            <ServerSideDataTable
            allowSelect={props.showRefundColumns}
            debug ={debug}
            id={tableId}
            location="AdminPayments"
            data={data}
            setData={setData}
            reload={tableReload}
            columns={columns}
            dataSource={{ isAuth: true, url: url }}
            extraPostModel={extraPostModel}
        ></ServerSideDataTable>
        }
        
    </Fragment>);
}
export default PaymentDataTable;