import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import ZonesInElementsPage from "./ZonesInElementsPage";
import ElementTypeDataTable from "./ElementTypeDataTable";
import ElementDataTableNew from "./ElementDataTableNew";
import DoorsInElementsDataTable from "./DoorsInElementsDataTable";
import WindowsInElementsDataTable from "./WindowsInElementsDataTable";
import OpaqueLouvresInElementsDataTable from "./OpaqueLouvresInElementsDataTable";
import ExternalScreensInElementsDataTable from "./ExternalScreensInElementsDataTable";
import InfiltrationPenetrationsInElementsDataTable from "./InfiltrationPenetrationsInElementsDataTable";
import RoofWindowSkylightInElementDataTable from "./RoofWindowSkylightInElementDataTable";

const ElementsNew = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        tryCatchFinally,
        useParams,
        layoutManager,
        cookiesManager,
        useReducerSmart,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [selectedZoneIds, setSelectedZoneIds] = useState([]);
    const [selectedTypeIds, setSelectedTypeIds] = useState([]);
    const [selectedElementIds, setSelectedElementIds] = useState([]);
    const {designVariationVersionName, developmentId, projectId, designVariationId, redirectUrl, jsonParam} = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const formId = "ElementsInfiltrationPenetrationsDetail";
    const defaultCookiesObject =cookiesManager.getCookies(formId, {elementTableType: "Doors"}).elementTableType;
    const [elementTableType, setElementTableType] = useState(defaultCookiesObject);
    //change model get url here if not using default model
    const getUrl = "api/DefaultModel/Accessor/Get";
    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch);
    },[]);

    const handleTagClick = (elementTableType) => {
        tryCatchFinally.syncProcess(()=>{
            setElementTableType(elementTableType);
            cookiesManager.setCookies(formId, { elementTableType: elementTableType });
        });
    }

    const generateTagClass = (elementType) => {
        let className = elementType === elementTableType ? "active" : "";
        className = "nav-link show " + className;
        return className;
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-plugin icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Elements
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-8 col-sm-12" >
                        <div className="card" style={{padding:"15px"}}>
                            {debug && <p>Selected zones: {JSON.stringify(selectedZoneIds.selectedItemIds)}</p>}
                            <ZonesInElementsPage
                                selectedIds = {selectedZoneIds}
                                setSelectedIds = {setSelectedZoneIds}
                            ></ZonesInElementsPage>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-4 col-sm-12">
                        <div className="card" style={{padding:"15px"}}>
                            {debug && <p>Selected Types: {JSON.stringify(selectedTypeIds.selectedItemIds)}</p>}
                            <ElementTypeDataTable
                                selectedIds = {selectedTypeIds}
                                setSelectedIds = {setSelectedTypeIds}
                                designVariationId = {designVariationId}
                            ></ElementTypeDataTable>
                        </div>
                      
                    </div>
                    <div className="col-lg-12 col-xl-12 col-sm-12" style={{marginTop:"15px"}}>                
                        <div className="card" style={{padding:"15px"}}>
                            {debug && <p>Selected Elements: {JSON.stringify(selectedElementIds.selectedItemIds)}</p>}
                            <ElementDataTableNew
                                zoneIds ={selectedZoneIds.selectedItemIds}
                                projectId = {projectId}
                                designVariationId = {designVariationId}
                                developmentId = {developmentId}
                                designVariationVersionName = {designVariationVersionName}
                                elementTypes ={selectedTypeIds.selectedItemIds}
                                selectedIds = {selectedElementIds}
                                setSelectedIds = {setSelectedElementIds}
                            ></ElementDataTableNew>
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-12 col-sm-12" style={{marginTop:"15px"}}>                
                        <div className="card" style={{padding:"15px"}}>
                            <div className="card-header">
                                <ul className="nav nav-justified">
                                    <li className="nav-item" onClick={() => handleTagClick("Doors")}><a className={generateTagClass("Doors")}>Doors</a></li>
                                    <li className="nav-item" onClick={() => handleTagClick("Windows")}><a className={generateTagClass("Windows")}>Windows</a></li>
                                    <li className="nav-item" onClick={() => handleTagClick("RoofWindowsSkylights")}><a className={generateTagClass("RoofWindowsSkylights")}>Roof Windows & Skylight</a></li>
                                    <li className="nav-item" onClick={() => handleTagClick("OpaqueLouvres")}><a className={generateTagClass("OpaqueLouvres")}>Opaque louvres</a></li>
                                    <li className="nav-item" onClick={() => handleTagClick("ExternalScreens")}><a className={generateTagClass("ExternalScreens")}>External screens</a></li>
                                    <li className="nav-item" onClick={() => handleTagClick("InfiltrationPenetrations")}><a className={generateTagClass("InfiltrationPenetrations")}>Infiltration penetrations</a></li>                          
                                </ul>
                            </div>
                            <div data-parent="#ConstructionsAccordion" id="ConstructionsCollapsibleDiv" className="collapse show">
                                <div className="card-body">
                                    <div className="separator"></div>
                                    {elementTableType === 'Doors' &&
                                        <DoorsInElementsDataTable
                                            id="DoorTable_ed2883cb-8793-417a-aa70-4fcafd4d309c"
                                            designVariationVersionName={designVariationVersionName}
                                            developmentId={developmentId}
                                            projectId={projectId}
                                            designVariationId={designVariationId}
                                            elementTypes ={selectedTypeIds.selectedItemIds}
                                            externalWallIds = {selectedElementIds.selectedItemIds}>
                                        </DoorsInElementsDataTable>
                                    }
                                    {elementTableType === 'OpaqueLouvres' &&
                                        <OpaqueLouvresInElementsDataTable
                                            id="OpaqueLouvreTable_ed2883cb-8793-417a-aa70-4fcafd4d309c"
                                            designVariationVersionName={designVariationVersionName}
                                            developmentId={developmentId}
                                            projectId={projectId}
                                            designVariationId={designVariationId}
                                            elementTypes ={selectedTypeIds.selectedItemIds}
                                            externalWallIds = {selectedElementIds.selectedItemIds}>
                                        </OpaqueLouvresInElementsDataTable>
                                    }
                                    {elementTableType === 'ExternalScreens' &&
                                        <ExternalScreensInElementsDataTable
                                            id="ExternalScreenTable_ed2883cb-8793-417a-aa70-4fcafd4d309c"
                                            designVariationVersionName={designVariationVersionName}
                                            developmentId={developmentId}
                                            projectId={projectId}
                                            designVariationId={designVariationId}
                                            elementTypes ={selectedTypeIds.selectedItemIds}
                                            externalWallIds = {selectedElementIds.selectedItemIds}>
                                        </ExternalScreensInElementsDataTable>
                                    }
                                    {elementTableType === 'InfiltrationPenetrations' &&
                                        <InfiltrationPenetrationsInElementsDataTable
                                            id="InfiltrationPenetrationTable_ed2883cb-8793-417a-aa70-4fcafd4d309c"
                                            designVariationVersionName={designVariationVersionName}
                                            developmentId={developmentId}
                                            projectId={projectId}
                                            designVariationId={designVariationId}
                                            externalWallIds = {selectedElementIds.selectedItemIds}
                                            elementTypes ={selectedTypeIds.selectedItemIds}>
                                        </InfiltrationPenetrationsInElementsDataTable>
                                    }
                                    {elementTableType === 'Windows' &&
                                        <WindowsInElementsDataTable
                                            id="WindowTable_ed2883cb-8793-417a-aa70-4fcafd4d309c"
                                            designVariationVersionName={designVariationVersionName}
                                            developmentId={developmentId}
                                            projectId={projectId}
                                            designVariationId={designVariationId}
                                            elementTypes ={selectedTypeIds.selectedItemIds}
                                            externalWallIds = {selectedElementIds.selectedItemIds}>
                                        </WindowsInElementsDataTable>
                                    }
                                     {elementTableType === 'RoofWindowsSkylights' &&  
                                        <RoofWindowSkylightInElementDataTable
                                            id="RoofWindowSkylightTable_ed2883cb-8793-417a-aa70-4fcafd4d309c"
                                            designVariationVersionName={designVariationVersionName}
                                            developmentId={developmentId}
                                            projectId={projectId}
                                            designVariationId={designVariationId}
                                            elementTypes ={selectedTypeIds.selectedItemIds}
                                            externalWallIds = {selectedElementIds.selectedItemIds}>
                                        </RoofWindowSkylightInElementDataTable>}
                                </div>
                            </div>
                        </div>      
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(ElementsNew);