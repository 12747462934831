class FormValidate{
    constructor(validateMethodsState, validateResultState, validateVisibilityState, setMessages, includeAddress = false){
        const [validateMethods, setValidateMethods] = validateMethodsState;
        const [validateResult, setValidateResult] = validateResultState;
        const [validateVisibility, setValidateVisibility] = validateVisibilityState;
        if(validateResult === undefined) setValidateResult({});
        if(validateVisibility === undefined) setValidateVisibility(false);
        if(validateMethods.streetName === undefined && includeAddress){
            setValidateMethods((previouseMethods)=>{
                const newMethods = {...previouseMethods};
                newMethods.streetName = (value)=>{ return FormValidate.validateTextRequired(value, "Street name"); };
                newMethods.suburb = (value)=>{ return FormValidate.validateTextRequired(value, "Suburb"); };
                newMethods.state = (value)=>{ return FormValidate.validateTextRequired(value, "State/Territory"); };
                newMethods.postcode = (value)=>{ return new RegExp("^\\d{4}$").test(value) ? "" : "Postcode must be a 4-digit."; };
                newMethods.country = (value)=>{ return FormValidate.validateTextRequired(value, "Country"); };
                newMethods.council = (value)=>{ return FormValidate.validateTextRequired(value, "Council"); };
                return newMethods; 
            });
        }
        this.validateMethods = validateMethods;
        this.setValidateMethods = setValidateMethods;
        this.validateResult = validateResult;
        this.setValidateResult = setValidateResult;
        this.validateVisibility = validateVisibility;
        this.setValidateVisibility = setValidateVisibility;
        this.setMessages = setMessages;
    }

    static validateTextRequired(text, propertyName, message, ignorLength) {
        if (message === undefined) {
          message = propertyName + " must be completed.";
        }
        if (text === undefined || text === null) return message;
        text = String(text);
        if (text.length === 0) return message;
        if (text.replace(" ", "").length === 0) return message;
        if(ignorLength === undefined){
            if (text.length > 80) return propertyName + " length must be less than 50.";
        }
        return "";
    }

    static validateNumberGreaterThan0(text, propertyName) {
        let value = 0;
        if(text !== undefined || text !== null || text !== '') value = Number(text);
        return value > 0 ? "" : propertyName + " value must greater than 0.";
    }

    static validateNumberGreaterThanOrEqual0(text, propertyName) {
        let value = 0;
        if (text !== undefined || text !== null || text !== '') value = Number(text);
        if(text === null) return propertyName + " value must greater than or equal 0.";
        return value >= 0 || text === undefined || text === '' ? "" : propertyName + " value must greater than or equal 0.";
    }

    static validateNumberBetween(text, propertyName, min, max ) {
        let value = 0;
        if (text !== undefined || text !== null || text !== '') value = Number(text);
        return value < min || value > max || text === undefined || text === null || text === ''  ? propertyName + " value must between " + min + " and " + max + "." : "";
    }

    static validateEmail(text){
        const match = String(text)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        return match ? "" : "Email address is invalid."
    }

    checkValidateResult(viewModel){
        //revalidate
        const keysMethods = Object.keys(this.validateMethods);
        let newResult = {};
        for (let i = 0; i < keysMethods.length; i++) {
            const key = keysMethods[i];
            const method = this.validateMethods[key];
            const value = viewModel[key];
            if (value !== undefined) {
                const val = method(value, viewModel);
                if (this.setMessages[0] !== undefined) {
                    const setMessage = this.setMessages[0][key];
                    if (setMessage !== undefined) {
                        setMessage(val);
                        newResult[key] = val.length === 0;
                    }            
                }  
            }
        }
        this.setValidateResult(newResult);
        const keys = Object.keys(newResult);
        let result = true;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const val = newResult[key];
            result = result && val;
        }
        if (viewModel.address !== undefined) {
            if(viewModel.address.streetName !== undefined){
                const addressResult = this.checkValidateResult(viewModel.address);
                result = result && addressResult;
            }     
        }
        this.setValidateVisibility(!result);
        return result;
    }
}

export default FormValidate;