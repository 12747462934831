
import PageDependencies from "../../../Feature/PageDependencies";

const PaymentDataTable = (props)=>{
    const { 
        Fragment,
        ServerSideDataTable,
    } = PageDependencies();
    const debug = props.debug === undefined ? false : props.debug;
    const data = props.data;
    const setData = props.setData;
    let tableId = "Payment_bca82fee-4eeb-415e-ac95-e65860904f15";
    if(props.tableId !== undefined){
      tableId = props.tableId;
    }
    const tableReload = props.tableReload;
    const extraPostModel = props.extraPostModel;
    const url = props.url;
    const columns =[
      { name: "completedTime", text: "Completed Time", width: "17%" },
      { name: "state", text: "State", width: "17%" },
      { name: "amount", text: "Amount", width: "17%" },
      { name: "type", text: "Type", width: "17%" }
    ];
    if (props.showInvoice === true){
      columns.push({ name: "transactionId", text: "Transaction Id", width: "17%" });
      columns.push({ name: "invoice", text: "Invoice", width: "15%", generate: downloadGenerate });
    }
    else{
      columns.push({ name: "transactionId", text: "Transaction Id", width: "32%" });
    }

    function downloadGenerate(model, cloumn, index, style, onColumnValueChangeEvent){     
      return(<a href={"api/Credit/Accessor/Download/" + model.id} target="_blank" className="btn btn-primary">Download</a>);
    }

    return(<Fragment>
      {debug && <p>{JSON.stringify(data)}</p>}
          <ServerSideDataTable
            debug ={debug}
            id={tableId}
            location="UserPayments"
            data={data}
            setData={setData}
            reload={tableReload}
            columns={columns}
            dataSource={{ isAuth: true, url: url }}
            extraPostModel={extraPostModel}
            ></ServerSideDataTable>
    </Fragment>);
}
export default PaymentDataTable;