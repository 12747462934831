export class DataExtension {
    getNumOfRows(data) {
        return data.length;
    }

    getSelectedRows(data){
        if  (data === undefined) return [];
        if  (data.length === 0) return [];
        return data.filter(r=>{return r.isSelected});
    }

    getSelectedRowsDic(data, property){
        if  (data === undefined) return {};
        if  (data.length === 0) return {};
        const dic = {};
        let selectedIds = this.getSelectedRowDataIds(data);
        selectedIds = this.getDistinctObjectsByProperty(selectedIds);
        for(let i = 0; i< selectedIds.length; i++){
            for(let j = 0; j< data.length; j++){
                if(data[j].id.includes(selectedIds[i])){
                    dic[data[j].id.split("_")[0]] = data[j][property];
                }
            }
        }
        return dic;
    }

    getSelectedRowDataIds(data){
        if  (data === undefined) return [];
        if  (data.length === 0) return [];
        return data.filter(r=>{return r.isSelected}).map(r=>r.id.split("_")[0]);
    }

    getById(data, id){
        if  (data === undefined) return undefined;
        if  (data.length === 0) return undefined;
        for(let i = 0; i < data.length; i++){
            if(data[i].id === id){
                return data[i];
            }
        }
        return undefined;
    }

    getIndexById(data, id){
        if  (data === undefined) return -1;
        if  (data.length === 0) return -1;
        for(let i = 0; i < data.length; i++){
            if(data[i].id == id){
                return i;
            }
        }
        return -1;
    }

    deleteById(data,id){
        if  (data === undefined) return [];
        if  (data.length === 0) return [];
        const dataClone = [];
        let index =0;
        for(let i = 0; i < data.length; i++){
            if(data[i].id !== id){
                dataClone[index] = {...data[i]};
                index++;
            }
        }
        return dataClone;
    }

    getDistinctObjectsByProperty(data, property) {
        if (property === undefined) return this.getDistinctObjects(data);
        const distinctObjects = [];
        const seen = {};
        for (const obj of data) {
            const key = obj[property];
            if (!seen[key]) {
                distinctObjects.push(obj);
                seen[key] = true;
            }
        }
        return distinctObjects;
    }

    getDistinctObjects(data) {
        const distinctStrings = [];
        const seen = {};
        for (const str of data) {
            if (!seen[str]) {
                distinctStrings.push(str);
                seen[str] = true;
            }
        }
    
        return distinctStrings;
    }

    getObjectByPropertyValue(data, property, value) {
        if(data === undefined || data === null) return null;
        for(let i =0; i< data.length; i++ ){
            const obj = data[i];
            if(obj[property].toString() === value.toString()){
                return obj;
            }
        }
        return undefined;
    }

    getSelectOptionByValue(options, value){
        if(options === undefined || options === null) return undefined;
        if(value === undefined || value === null) return undefined;
        if(value === 0) return undefined;
        for(let i = 0; i< options.length; i++ ){
            const opt = options[i];
            if(opt.value.toString()===value.toString()) 
            return opt;
        }
        return undefined;
    }

    getSelectOptionByText(options, text){
        if(options === undefined || options === null) return undefined;
        if(text === undefined || text === null) return undefined;
        if(text === 0) return undefined;
        for(let i = 0; i< options.length; i++ ){
            const opt = options[i];
            if(opt.text.toString() === text.toString()) 
            return opt;
        }
        return undefined;
    }

    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    distinct(array){
        return array.filter(this.onlyUnique);
    }

    distinctBy(array, propertyName){
        let flags = [], output = [], l = array.length, i;
        for( i = 0; i < l; i++) {
            if( flags[array[i][propertyName]]) continue;
            flags[array[i][propertyName]] = true;
            output.push(array[i]);
        }
        return [...output];
    }

    selectMany(array){
        return array.reduce(function(a, b){ return a.concat(b); }, []);
    }
}

const dataExtension = new DataExtension();
export default dataExtension;