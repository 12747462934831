
import DevelopmentAccessor from "../../Portal/Accessor/Development/DevelopmentAccessor";
import DevelopmentDetail from "../../Portal/Accessor/Development/DevelopmentDetail";
const AccessorDevelopmentRouters = [
    {
      path: "DevelopmentAccessor/Detail/:developmentId",
      exact: true,
      requireAuth: true,
      element: <DevelopmentDetail></DevelopmentDetail>,
    },
    {
      path: "DevelopmentAccessor",
      exact: true,
      requireAuth: true,
      element: <DevelopmentAccessor />,
    },
  ];
export default AccessorDevelopmentRouters;
