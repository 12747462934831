
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import { Button } from 'primereact/button';

const InfiltrationPenetrationDetail = (props) => {
    const {
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        useLocation,
        BaseForm,
        BindedInput,
        BindedCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        useEffectWithPrevious,
        Fragment, useEffect, useState
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const currentUrl = useLocation().pathname;
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const { elementId } = defaultParams;
    let {  redirectUrl,designVariationVersionName, developmentId, projectId, designVariationId, zoneId, parentId, redirectUrlHex } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
        elementType: props.elementType,
        selectedItemIds: [elementId]
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const [Inserting, setInserting] = useState(false);

    const getTitle = () => {
        if (props.elementType === "InfiltrationPenetrationInCeiling") {
            return "Infiltration and ceiling penetration";
        }else if (props.elementType === "InfiltrationPenetrationInExternalWall") {
            return "External Wall Infiltration Penetration";
        } else {
            return "Infiltration Penetration"
        }
    }
    //change model get url here if not using default model
    const getUrl = "api/Element/Accessor/GetInfiltrationPenetration";
    //add model post url here
    const postUrl = "api/Element/Accessor/UpdateInfiltrationPenetration";
    const validateMethods = {
        name: (value)=>{return FormValidate.validateTextRequired(value, "Infiltration penetration name");},
        aboveZoneId: (value)=>{ return value === emptyGuid ? "Must select a zone" : "";},
        typeId: (value)=>{ return value == null || value === "-999" ? "Must select a type." : "";},
        diameter: (value, newViewModel)=>{ return newViewModel.typeId == 5? "" : newViewModel.length==0 && newViewModel.width==0 ? FormValidate.validateNumberGreaterThan0(value, "Diameter") : "";},
        length: (value, newViewModel) => { return newViewModel.dwellingType === "New"? "" : newViewModel.typeId == 5? "" : newViewModel.diameter==0? FormValidate.validateNumberGreaterThan0(value, "Length") : "";},
        width: (value, newViewModel) => { return newViewModel.dwellingType === "New"? "" : newViewModel.typeId == 5? "" : newViewModel.diameter==0? FormValidate.validateNumberGreaterThan0(value, "Width") : "";},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    const insert = () => {
        setInserting(true);
        if (viewModel.typeId == 3) {
            dispatchViewModel({...viewModel, isSealed: false, diameter: 250, length: 0, width: 0, clearance: 50, isVentHeatLight: false});
        }else if (viewModel.typeId == 4) {
            dispatchViewModel({...viewModel, isSealed: false, diameter: 250, length: 0, width: 0, clearance: 0, isVentHeatLight: false});
        }else if (viewModel.typeId == 6 && !viewModel.isVentHeatLight) {
            dispatchViewModel({...viewModel, isSealed: false, diameter: 250, length: 0, width: 0, clearance: 0});
        }else if(viewModel.typeId == 6 && viewModel.isVentHeatLight){
            dispatchViewModel({...viewModel, isSealed: false, diameter: 250, length: 0, width: 0, clearance: 50});
        }
        setTimeout(() => {
            setInserting(false);
        }, 200);
    };

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, undefined, setInit);
    },[]);

    useEffect(()=>{  
        if(init){
          dispatchViewModel({...viewModel, elementId: elementId, id: elementId, selectedItemIds: [elementId]}, ()=>{
            setReloadForm(guid.newGuid());
          });
        } 
    },[elementId]);

    useEffect(()=>{
        if(viewModel.dwellingType !== "New" && viewModel.typeId == 6  && viewModel.isVentHeatLight){
          dispatchViewModel({...viewModel, isSealed: false, diameter: 250, length: 0, width: 0, clearance: 50});
        }
    },[viewModel.isVentHeatLight]);

    useEffectWithPrevious((oldState) => {
        if(oldState === undefined) return;
        const prevModeTypeId = oldState[0];
        if(prevModeTypeId !== undefined && viewModel.typeId !== undefined){
            if(viewModel.dwellingType === "New"){
                dispatchViewModel({...viewModel, isSealed: false, diameter: 0, length: 0, width: 0, clearance: 0, isVentHeatLight: false, downlightTypeId: 0});
            }else{
                if (viewModel.typeId == 2 || viewModel.typeId == 5) {
                    dispatchViewModel({...viewModel, isSealed: false, diameter: 0, length: 0, width: 0, clearance: 0, isVentHeatLight: false, downlightTypeId: 0});
                }else{
                    dispatchViewModel({...viewModel, isSealed: false, isVentHeatLight: false, downlightTypeId: 0});
                }
            }
        }
    },[viewModel.typeId]);

    useEffectWithPrevious((oldState) => {
        if(oldState === undefined) return;
        const prevModeTypeId = oldState[0];
        if(prevModeTypeId !== undefined && viewModel.downlightTypeId !== undefined){
            if(viewModel.dwellingType === "New" && viewModel.typeId == 2){
                if (viewModel.downlightTypeId == 0) {
                    dispatchViewModel({...viewModel, diameter: 0, length: 0, width: 0, clearance: 0, isVentHeatLight: false});
                }else if (viewModel.downlightTypeId == 1) {
                    dispatchViewModel({...viewModel, diameter: 0, length: 50, width: 50, clearance: 200, isVentHeatLight: false});
                }else if (viewModel.downlightTypeId == 2) {
                    dispatchViewModel({...viewModel, diameter: 0, length: 60, width: 60, clearance: 100, isVentHeatLight: false});
                }else if (viewModel.downlightTypeId == 3) {
                    dispatchViewModel({...viewModel, diameter: 0, length: 60, width: 60, clearance: 50, isVentHeatLight: false});
                }else if (viewModel.downlightTypeId == 3) {
                    dispatchViewModel({...viewModel, diameter: 0, length: 50, width: 50, clearance: 50, isVentHeatLight: false});
                }
              }
        }
    },[viewModel.downlightTypeId]);

    const generateStayUrl = (submitResponse)=>{
        let redirectUrl = currentUrl;
        if(elementId === emptyGuid){
            //ElementAccessor/Detail/InfiltrationPenetrationInExternalWall/:designVariationVersionName/:developmentId/:projectId/:designVariationId/:zoneId/:elementId/:parentId/:redirectUrlHex
            redirectUrl =  "/ElementAccessor/Detail/" + props.elementType + "/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/" + zoneId + "/" + submitResponse.elementId + "/" + parentId + "/" + redirectUrlHex;
        }
        return redirectUrl;
    }
    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <BaseForm
            id="DesignVariationFrom"
            icon="pe-7s-safe"
            loading={loading}
            init={init}
            setLoading={setLoading}
            submitButton={{ postUrl: postUrl, redirectUrl: redirectUrl, text: "Save and close"}}
            submitButtonAndStay={{ text:"Save", postUrl: postUrl, redirectMethod: (submitResponse)=>{return generateStayUrl(submitResponse)}}}
            cancelButton={{ redirectUrl: redirectUrl }}
            data={viewModel}
            validate ={formValidate}
            title = {getTitle()} 
            subTitle={pageProfile.getPageSubTitle(appStoreState)}
            >
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationName">Name *</label>
                            <BindedInput
                                className="form-control"
                                id="InfiltrationPenetrationName"
                                name="InfiltrationPenetrationName"
                                type="text"
                                validate={formValidate}
                                binding={[viewModel, "name", bindingViewModel]}
                            ></BindedInput>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationZoneAboveId">Zone above *</label>
                            <BindedSelect
                                className="form-control"
                                id="InfiltrationPenetrationZoneAboveId"
                                name="InfiltrationPenetrationZoneAboveId"
                                options="aboveZones"
                                validate={formValidate}
                                binding={[viewModel, "aboveZoneId", bindingViewModel]}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationTypeId">Type *</label>
                            <BindedSelect
                                className="form-control"
                                id="InfiltrationPenetrationTypeId"
                                name="InfiltrationPenetrationTypeId"
                                options="types"
                                validate={formValidate}
                                binding={[viewModel, "typeId", bindingViewModel]}
                            ></BindedSelect>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="InfiltrationPenetrationQuantity">Quantity *</label>
                            <BindedInput
                                className="form-control"
                                id="InfiltrationPenetrationQuantity"
                                name="InfiltrationPenetrationQuantity"
                                type="number"
                                min="0"
                                validate={formValidate}
                                binding={[viewModel, "quantity", bindingViewModel]}
                            ></BindedInput>
                        </div>
                    </div>
                </div>
                {viewModel.parentType !== "ExternalWall" &&
                    <div className="form-row row">
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="DownlightTypeId">Downlight Type *</label>
                            <BindedSelect
                                className="form-control"
                                id="DownlightTypeId"
                                name="DownlightTypeId"
                                options="downlightTypes"
                                validate={formValidate}
                                binding={[viewModel, "downlightTypeId", bindingViewModel]}
                                disabled={String(viewModel.typeId) === "2" ? false : true}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>}
                {viewModel.dwellingType === "New" &&
                <>
                    <div className="form-row row">
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="InfiltrationPenetrationDiameter">Diameter (mm) *</label>
                                <BindedSelect
                                    className="form-control"
                                    id="InfiltrationPenetrationDiameter"
                                    name="InfiltrationPenetrationDiameter"
                                    options={viewModel.typeId == 1? 
                                        [0, 900, 1200, 1400, 1800, 2100, 2400, 2700, 3000].map((m)=>{return  {text: m, value: m};}) :
                                        [0, 160, 180, 200, 225, 250, 300].map((m)=>{return  {text: m, value: m};})
                                    }
                                    validate={formValidate}
                                    binding={[viewModel, "diameter", bindingViewModel]}
                                    disabled={["2", "5"].includes(String(viewModel.typeId)) || (viewModel.typeId == 6 && (viewModel.length != 0 || viewModel.width != 0))}
                                ></BindedSelect>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="InfiltrationPenetrationLength">Length (mm) *</label>
                                <BindedInput
                                    className="form-control"
                                    id="InfiltrationPenetrationLength"
                                    name="InfiltrationPenetrationLength"
                                    type="number"
                                    min="0"
                                    validate={formValidate}
                                    binding={[viewModel, "length", bindingViewModel, 0]}
                                    disabled={(viewModel.typeId == 6 && viewModel.diameter != 0)}
                                ></BindedInput>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="InfiltrationPenetrationWidth">Width (mm) *</label>
                                <BindedInput
                                    className="form-control"
                                    id="InfiltrationPenetrationWidth"
                                    name="InfiltrationPenetrationWidth"
                                    type="number"
                                    min="0"
                                    validate={formValidate}
                                    binding={[viewModel, "width", bindingViewModel, 0]}
                                    disabled={(viewModel.typeId == 6 && viewModel.diameter != 0)}
                                ></BindedInput>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="InfiltrationPenetrationClearance">Clearance (mm) *</label>
                                <BindedInput
                                    className="form-control"
                                    id="InfiltrationPenetrationClearance"
                                    name="InfiltrationPenetrationClearance"
                                    type="number"
                                    min="0"
                                    validate={formValidate}
                                    binding={[viewModel, "clearance", bindingViewModel]}
                                    disabled={["1", "5", "7", "8"].includes(String(viewModel.typeId))}
                                ></BindedInput>
                            </div>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="custom-checkbox custom-control">
                            <BindedCheckBox
                                id="InfiltrationPenetrationIsVentHeatLight"
                                name="InfiltrationPenetrationIsVentHeatLight"
                                className="custom-control-input"
                                binding={[
                                    viewModel,
                                    "isVentHeatLight",
                                    bindingViewModel,
                                ]}
                                disabled={String(viewModel.typeId) === "6" ? false : true}
                            ></BindedCheckBox>
                            <label
                                style={{ marginLeft: "5px" }}
                                htmlFor="InfiltrationPenetrationIsVentHeatLight"
                                className="custom-control-label"
                                >
                                Exhaust fan with heat & light
                            </label>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="custom-checkbox custom-control">
                            <BindedCheckBox
                                id="InfiltrationPenetrationIsSealed"
                                name="InfiltrationPenetrationIsSealed"
                                className="custom-control-input"
                                binding={[
                                    viewModel,
                                    "isSealed",
                                    bindingViewModel,
                                ]}
                            ></BindedCheckBox>
                            <label
                            style={{ marginLeft: "5px" }}
                            htmlFor="InfiltrationPenetrationIsSealed"
                            className="custom-control-label"
                            >
                            Sealed
                            </label>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="custom-checkbox custom-control">
                            <BindedCheckBox
                                id="InfiltrationPenetrationIsInsulated"
                                name="InfiltrationPenetrationIsInsulated"
                                className="custom-control-input"
                                binding={[
                                    viewModel,
                                    "isInsulated",
                                    bindingViewModel,
                                ]}
                                disabled={String(viewModel.typeId) !== "2"}
                            ></BindedCheckBox>
                            <label
                            style={{ marginLeft: "5px" }}
                            htmlFor="InfiltrationPenetrationIsInsulated"
                            className="custom-control-label"
                            >
                            Insulated
                            </label>
                        </div>
                    </div>
                </>}
                {viewModel.dwellingType !== "New" &&
                <>
                    <div className="form-row row">
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="InfiltrationPenetrationDiameter">Diameter (mm) *</label>
                                <BindedSelect
                                    className="form-control"
                                    id="InfiltrationPenetrationDiameter"
                                    name="InfiltrationPenetrationDiameter"
                                    options={viewModel.typeId == 1? 
                                        [0, 250, 900, 1200, 1400, 1800, 2100, 2400, 2700, 3000].map((m)=>{return  {text: m, value: m};}) :
                                        [0, 160, 180, 200, 225, 250, 300].map((m)=>{return  {text: m, value: m};})
                                    }
                                    validate={formValidate}
                                    binding={[viewModel, "diameter", bindingViewModel]}
                                    disabled={!["1", "3", "4", "6", "7", "8"].includes(String(viewModel.typeId)) || (viewModel.length != 0 || viewModel.width != 0)}
                                ></BindedSelect>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="InfiltrationPenetrationLength">Length (mm) *</label>
                                <BindedInput
                                    className="form-control"
                                    id="InfiltrationPenetrationLength"
                                    name="InfiltrationPenetrationLength"
                                    type="number"
                                    min="0"
                                    validate={formValidate}
                                    binding={[viewModel, "length", bindingViewModel, 0]}
                                    disabled={(viewModel.typeId == 2 && viewModel.downlightTypeId == 0) || viewModel.diameter != 0}
                                ></BindedInput>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="InfiltrationPenetrationWidth">Width (mm) *</label>
                                <BindedInput
                                    className="form-control"
                                    id="InfiltrationPenetrationWidth"
                                    name="InfiltrationPenetrationWidth"
                                    type="number"
                                    min="0"
                                    validate={formValidate}
                                    binding={[viewModel, "width", bindingViewModel, 0]}
                                    disabled={(viewModel.typeId == 2 && viewModel.downlightTypeId == 0) || viewModel.diameter != 0}
                                ></BindedInput>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="position-relative form-group">
                                <label htmlFor="InfiltrationPenetrationClearance">Clearance (mm) *</label>
                                <BindedInput
                                    className="form-control"
                                    id="InfiltrationPenetrationClearance"
                                    name="InfiltrationPenetrationClearance"
                                    type="number"
                                    min="0"
                                    validate={formValidate}
                                    binding={[viewModel, "clearance", bindingViewModel]}
                                    disabled={["3", "4", "6"].includes(String(viewModel.typeId)) || (viewModel.typeId == 2 && viewModel.downlightTypeId !== 0) ? false : true}
                                ></BindedInput>
                            </div>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="custom-checkbox custom-control">
                            <BindedCheckBox
                                id="InfiltrationPenetrationIsVentHeatLight"
                                name="InfiltrationPenetrationIsVentHeatLight"
                                className="custom-control-input"
                                binding={[
                                    viewModel,
                                    "isVentHeatLight",
                                    bindingViewModel,
                                ]}
                                disabled={String(viewModel.typeId) === "6" ? false : true}
                            ></BindedCheckBox>
                            <label
                                style={{ marginLeft: "5px" }}
                                htmlFor="InfiltrationPenetrationIsVentHeatLight"
                                className="custom-control-label"
                                >
                                Exhaust fan with heat & light
                            </label>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="custom-checkbox custom-control">
                            <BindedCheckBox
                                id="InfiltrationPenetrationIsSealed"
                                name="InfiltrationPenetrationIsSealed"
                                className="custom-control-input"
                                binding={[
                                    viewModel,
                                    "isSealed",
                                    bindingViewModel,
                                ]}
                                disabled={(viewModel.typeId == 2 && viewModel.downlightTypeId == 0) || viewModel.typeId == 1 || viewModel.typeId == 5}
                            ></BindedCheckBox>
                            <label
                            style={{ marginLeft: "5px" }}
                            htmlFor="InfiltrationPenetrationIsSealed"
                            className="custom-control-label"
                            >
                            Sealed
                            </label>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="custom-checkbox custom-control">
                            <BindedCheckBox
                                id="InfiltrationPenetrationIsInsulated"
                                name="InfiltrationPenetrationIsInsulated"
                                className="custom-control-input"
                                binding={[
                                    viewModel,
                                    "isInsulated",
                                    bindingViewModel,
                                ]}
                                disabled={String(viewModel.typeId) !== "2" || (String(viewModel.typeId) === "2" && String(viewModel.downlightTypeId) === "0")}
                            ></BindedCheckBox>
                            <label
                            style={{ marginLeft: "5px" }}
                            htmlFor="InfiltrationPenetrationIsInsulated"
                            className="custom-control-label"
                            >
                            Insulated
                            </label>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="position-relative form-group">
                            <Button label="Insert Default Values" icon="pi pi-plus-circle" loading={Inserting} onClick={insert} outlined tooltip="Insert default value for Chimney/Ceiling vent/Ceiling exhaust fan" tooltipOptions={{ showOnDisabled: true }} disabled={viewModel.typeId == null || ["-999", "1", "2", "5", "7", "8"].includes(String(viewModel.typeId))} />
                        </div>
                    </div>
                </>}
            </BaseForm>
        </Fragment>
    );
}
export default connectAppStore(InfiltrationPenetrationDetail);