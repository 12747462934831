import { useEffect } from "react";
import cookiesManager from "./CookiesManager";

const checkPropertiesAreNotUndefined = (viewModel, porperties)=>{
    let notUnfefinded = true;
    for(let i = 0; i < porperties.length; i++){
        notUnfefinded = viewModel[porperties[i]] !== undefined && notUnfefinded;
    }
    return notUnfefinded;
}

const checkPropertiesValuesAreEqual = (viewModel, defaultValues, porperties)=>{
    let equal = true;
    for(let i = 0; i < porperties.length; i++){
        equal = viewModel[porperties[i]] == defaultValues[porperties[i]] && equal;
    }
    return equal;
}

const setNewCookies = (cookiesId, model, porperties)=>{
    const newCookies = {};
    for(let i = 0; i < porperties.length; i++){
        newCookies[porperties[i]] = model[porperties[i]];
    }
    cookiesManager.setCookies(cookiesId, newCookies);
}

const dispatchNewViewModel = (model, defaultvalues, porperties, dispatchViewModel)=>{
    const newViewModel = {...model};
    for(let i = 0; i < porperties.length; i++){
        newViewModel[porperties[i]] = defaultvalues[porperties[i]];
    }
    dispatchViewModel(newViewModel);
}

const generateDependencies = (model, porperties)=>{
    const d = []
    for(let i = 0; i < porperties.length; i++){
        d[i] = model[porperties[i]];
    }
    return d;
}

function useFromDefaultValue(cookiesId, model, dispatchViewModel, bindingPorperties, defaultvalues, applyConditionCookies, applyConditionDispatch){
    //const d = generateDependencies(model, bindingPorperties);
    const defaultValues = {};
    for(let i = 0; i < bindingPorperties.length; i++){
        defaultValues[bindingPorperties[i]] = defaultvalues[i];
    }
    const formDefaultValues = cookiesManager.getCookies(cookiesId, defaultValues);
    useEffect(()=>{
        const isNotUnfefinded = checkPropertiesAreNotUndefined(model, bindingPorperties);
        if(isNotUnfefinded){      
            const isEqual = checkPropertiesValuesAreEqual(model, formDefaultValues, bindingPorperties) === true;
            if(isEqual) return;
            if(applyConditionCookies(model) === true){
                setNewCookies(cookiesId, model, bindingPorperties);
            }
            if(applyConditionDispatch(model) === true){
                dispatchNewViewModel(model, formDefaultValues, bindingPorperties, dispatchViewModel);
            }
        }
    }, [model]);
}
export default useFromDefaultValue;