import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import GoogleAddress from "../../Accessor/Default/GoogleAddress";

const Profile = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        guid,
        useReducerSmart,
        toastPrint,
        BaseForm,
        BindedInput,
        getAppStore,
        tryCatchFinally,
        pageProfile,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const [reloadForm, setReloadForm] = useState("");
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const setAddress = (address)=>{dispatchViewModel({...viewModel, address: address}); }
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [reloadAddress, setReloadAddress] = useState("");
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    //change model get url here if not using default model
    const getUrl = "api/Profile/Accessor/Get";
    //add model post url here
    const postUrl = "api/Profile/Accessor/Update";
    const validateMethods = {
    //add form property validate here
        email:(value)=>{return FormValidate.validateEmail(value);},
        firstName:(value)=>{return FormValidate.validateTextRequired(value, "First name");},
        lastName:(value)=>{return FormValidate.validateTextRequired(value, "Last name");}
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, (newViewModel)=>{
            setReloadAddress(guid.newGuid());
        }, setInit);
    },[reloadForm]);

    useEffect(()=>{
        tryCatchFinally.syncProcess(()=>{
            if(viewModel.imgPath !== undefined && viewModel.imgPath !== ""){
                const input = document.getElementById("formFile");
                const fReader = new FileReader();
                fReader.readAsDataURL(input.files[0]);
                fReader.onloadend = function(event){
                    const image = event.target.result;
                    const size = input.files[0].size;
                    if(size > 50000 * 10){
                        toastPrint.printErrorMessage("Selected image is too big. Max 500kB allowed.");
                    }else{
                        dispatchViewModel({...viewModel, avatarBase64: image});
                    } 
                }
            }   
        });
    },[viewModel.imgPath]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            <BaseForm
                id="UserProfile"
                icon="pe-7s-user"
                loading={loading}
                init={init}
                setReload={setReloadForm}
                setLoading={setLoading}
                submitButtonAndStay = {{text:"Save", postUrl: postUrl, redirectUrl: ""}}
                data={viewModel}
                validate ={formValidate}
                title = "Profile"
                subTitle={""}
            >
                <div className="form-row row">             
                    <div className="col-4">
                        <img width="200px" height="200px" src={viewModel.avatarBase64}></img>             
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Upload image</label>
                            <BindedInput  type="file" id="formFile" accept="image/*" validate ={formValidate} className="form-control" binding={[viewModel, "imgPath", bindingViewModel]}></BindedInput>    
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="FirstName">First Name *</label>
                            <BindedInput id="FirstName" name="FirstName" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "firstName", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="LastName">First Name *</label>
                            <BindedInput id="LastName" name="LastName" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "lastName", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="FirstName">Email *</label>
                            <BindedInput id="Email" name="Email" type="email" validate ={formValidate} className="form-control" binding={[viewModel, "email", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="AAO">AAO</label>
                            <BindedInput id="AAO" name="AAO" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "accreditingAssessorOrganization", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="Organization">Organisation</label>
                            <BindedInput id="Organization" name="Organization" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "organization", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="Phone">Phone</label>
                            <BindedInput id="Phone" name="Phone" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "phone", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="position-relative form-group">
                            <label htmlFor="ABN">ABN</label>
                            <BindedInput id="ABN" name="ABN" type="text" validate ={formValidate} className="form-control" binding={[viewModel, "abn", bindingViewModel]}></BindedInput>                      
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    {/* Address */}
                    <GoogleAddress 
                        id="AddressDewelling"
                        reload = {reloadAddress}
                        setReload = {setReloadAddress}
                        address = {viewModel.address}
                        setAddress = {setAddress}
                        googleMapsApiKey = {viewModel.googleMapsApiKey}
                        validate={formValidate}
                        debug = {debug}
                    ></GoogleAddress>
                </div>  
            </BaseForm>
        </Fragment>
    );
}
export default connectAppStore(Profile);