import React, { Fragment, Component } from "react";
import authService from "../../../api-authorization/AuthorizeService";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  UncontrolledButtonDropdown,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import { ApplicationPaths } from "../../../api-authorization/ApiAuthorizationConstants";
import city3 from "../../../../assets/utils/images/dropdown-header/city3.jpg";

const styleLink = {
  color: "black",
};
class UserBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      userName: "",
      logoutPath: "",
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);
    const newState = {...this.state, isAuthenticated: isAuthenticated, userName: user && user.name};
    this.setState(newState);
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    const logoutState = { local: true };
    if (!isAuthenticated) {
      return this.authenticatedView("", "", "", logoutState);
    } else {
      const logoutPath = `${ApplicationPaths.LogOut}`;
      return this.authenticatedView(userName, logoutPath, logoutState);
    }
  }

  authenticatedView(userName, logoutPath, logoutState) {
    return (
      <Fragment>
        <div className="header-btn-lg pe-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0">
                    <img
                      width={42}
                      height={42}
                      className="rounded-circle"
                      src={this.props.avatar}
                      alt=""
                    />
                    <FontAwesomeIcon
                      className="ms-2 opacity-8"
                      icon={faAngleDown}
                    />
                  </DropdownToggle>
                  <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-info">
                        <div
                          className="menu-header-image opacity-2"
                          style={{
                            backgroundImage: "url(" + city3 + ")",
                          }}
                        />
                        <div className="menu-header-content text-start">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left me-3">
                                <img
                                  width={42}
                                  height={42}
                                  className="rounded-circle"
                                  src={this.props.avatar}
                                  alt=""
                                />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">{userName}</div>
                              </div>
                              <div className="widget-content-right me-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    <a  style={styleLink} href="/UserAccessor/Profile/" target="_blank">
                      <DropdownItem  className="nav-item-header">
                        Profile
                      </DropdownItem>
                    </a>
                    <a  style={styleLink} href="/UserAccessor/ChangePassword/" target="_blank">
                      <DropdownItem  className="nav-item-header">
                      Change Password
                      </DropdownItem>
                    </a>                                               
                      {this.props.userInfo.userRole !== 'Administrator' && <Fragment>
                        <a  style={styleLink} href="/UserAccessor/Credits/" target="_blank">
                          <DropdownItem  className="nav-item-header">
                            My Credits
                          </DropdownItem>
                        </a>     
                        <a  style={styleLink} href="/UserAccessor/Payments/" target="_blank">
                          <DropdownItem  className="nav-item-header">
                            My Payments
                          </DropdownItem>
                        </a>    
                        <a  style={styleLink} href="/UserAccessor/Refunds/" target="_blank">
                          <DropdownItem  className="nav-item-header">
                            My Refunds
                          </DropdownItem>
                        </a>    
                        </Fragment>}  
                    </div>
                    <Nav vertical>
                      <NavItem className="nav-item-divider" />
                      <NavItem className="nav-item-btn text-center">
                        <Button
                          replace
                          tag={Link}
                          to={logoutPath}
                          state={logoutState}
                          className="btn-pill btn-shadow btn-shine"
                          color="focus"
                        >
                          Logout
                        </Button>
                      </NavItem>
                    </Nav>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left  ms-3 header-user-info">
                <div className="widget-heading">{userName}</div>
                <div className="widget-subheading">{this.props.userInfo.userRole}</div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default UserBox;
