import $ from "jquery";
import "jquery-contextmenu/dist/jquery.contextMenu.min.css";
import "jquery-contextmenu/dist/jquery.contextMenu.min.js";
import dataExtension from "../../DataTable/DataExtension";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ContextMenu = (props) => {
    const nav = useNavigate();
    const contextMenu = props.contextMenu;
    const data = props.data;
    const location = props.location;
    const build = props.build;
    const loadData = props.loadData;
    const defaultItemNames = ["edit", "delete", "copy", "cut"];
    const contextMenuSelector = "#" + props.dataTableId + " tbody tr";

    const defaultContextMenuCallBack = (elementDom, key, options, location) => {
        const elementId = elementDom.attr("id");
        const element = data.filter((e) => {
            return e.id === elementId;
        })[0];

        //handle new
        if (key === "new") {
            const newOperation = contextMenu.items["new"];
            if (newOperation !== undefined) {
                if (newOperation.defaultAction) {
                    const url = newOperation.url;
                    nav(url);
                    return;
                }
            }
        }
        
        if (key === "edit") {
            const editOperation = contextMenu.items["edit"];
            if (editOperation !== undefined) {
                if (editOperation.defaultAction) {
                    const url = editOperation.url + elementId;
                    nav(url);
                    return;
                }
            }
        }

        if(key.includes("create") && key.length > 6){
            const opt = contextMenu.items["new"].items[key];
            if(opt.url !== undefined && opt.isNavOption){
                nav(opt.url);
                return;
            }
        }

        if(key.includes("import") && key.length > 6){
            const opt = contextMenu.items["import"].items[key];
            if (opt !== undefined){
                if (contextMenu.callBack !== undefined) {
                    contextMenu.callBack(key, element, loadData, location);
                    return;
                }
            }
        }

        // if(key.includes("analysis") && key.length > 7){
        //     const opt = contextMenu.items["analysis"].items[key];
        //     if (opt !== undefined){
        //         if (contextMenu.callBack !== undefined) {
        //             contextMenu.callBack(key, element, loadData, location);
        //             return;
        //         }
        //     }
        // }

        {
            const opt = contextMenu.items[key];
            if(opt.url !== undefined && opt.isNavOption && opt.defaultAction){
                nav(opt.url);
                return;
            }
        }
        
        if (contextMenu.callBack !== undefined) {
            contextMenu.callBack(key, element, loadData, location);
        }
    };

    const setMenuDisbale = () => {
        defaultItemNames.forEach((defaultItemName) => {
            if (contextMenu.items[defaultItemName] !== undefined) {
                if (contextMenu.items[defaultItemName].disabled === undefined) {
                    contextMenu.items[defaultItemName].disabled = function () {
                        return dataExtension.getNumOfRows(data) === 0;
                    };
                }
            }
        });
    };

    const drawContextMenu = () => {
        $(contextMenuSelector).contextMenu('destroy');
        $.contextMenu({
            build: function ($trigger, e) {
                build($trigger, e, contextMenuSelector);
            },
            selector: contextMenuSelector,
            callback: function (key, options) {
                defaultContextMenuCallBack($(this), key, options, location);
            },
            items: contextMenu.items,
            autoHide: true,
            trigger: "right",
        });
    };

    useEffect(() => {
        if (contextMenu !== undefined) {
            setMenuDisbale();
            drawContextMenu();
        }
    }, [data]);

    return (<Fragment></Fragment>);
};
export default ContextMenu;
