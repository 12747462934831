import React from "react";
import {connectAppStore} from "../../Store/AppStore";
import { NavLink } from "react-router-dom";
import PageDependencies from "../../Feature/PageDependencies";
import TooltipTemplate from "./TooltipTemplate";

const Nav = (props) =>{
  const { 
      Fragment,
      getAppStore,
      pageProfile,
  } = PageDependencies();

  const [appStoreState] = getAppStore(props);
  const defaultValue = appStoreState.defaultProjectReducer;
  let {
    projectId,
    developmentId,
    designVariationId,
    designVariationVersionName,
  } = defaultValue;

  return (
    <Fragment>
      <h5 className="app-sidebar__heading">{pageProfile.getSoftwareVersion(appStoreState)}</h5>
      <h5 className="app-sidebar__heading">DEVELOPMENT</h5>
      <div className="metismenu vertical-nav-menu">
          <ul className="metismenu-container">
            <li className="metismenu-item"><NavLink className="metismenu-link" to={"ProjectAccessor/Projects/"+ developmentId + "/" + projectId}><i className="metismenu-icon lnr-apartment sidebar-lnr-icon"></i>Projects</NavLink></li>
          </ul>
      </div>
      <h5 className="app-sidebar__heading">PROJECT PROFILE</h5>
      <div className="metismenu vertical-nav-menu">
          <ul className="metismenu-container">
            <li className="metismenu-item"><NavLink className="metismenu-link" to={"ProjectAccessor/Detail/" + developmentId + "/" + projectId}><i className="metismenu-icon pe-7s-info"></i>About</NavLink></li>
            <li className="metismenu-item"><NavLink className="metismenu-link" to={"ProjectAccessor/Dwelling/" + projectId}><i className="metismenu-icon pe-7s-map-marker"></i>Dwelling<TooltipTemplate text="Dwelling details are required to determine the applicable climate zone for thermal simulations" result={parseInt(pageProfile.getClimateZoneIndex(appStoreState))} color="red" /></NavLink></li>
            <li className="metismenu-item"><NavLink className="metismenu-link" to={"ProjectAccessor/Client/" + projectId}><i className="metismenu-icon pe-7s-user"></i>Client</NavLink></li>
            <li className="metismenu-item"><NavLink className="metismenu-link" to={"ProjectAccessor/Plan/" + projectId}><i className="metismenu-icon pe-7s-portfolio"></i>Plan</NavLink></li>   
          </ul>
      </div>
      {pageProfile.getUserRole(appStoreState) === "Assessor" && parseInt(pageProfile.getClimateZoneIndex(appStoreState)) > 0 &&
        <>
          <h5 className="app-sidebar__heading">Thermal</h5>
          <div className="metismenu vertical-nav-menu">
              <ul className="metismenu-container">
                <li className="metismenu-item"><NavLink className="metismenu-link" to={"DesignVariationAccessor/DesignVariations/" + projectId}><i className="metismenu-icon pe-7s-science"></i>Design Variations</NavLink></li>
                <li className="metismenu-item"><NavLink className="metismenu-link" to={"ZoneAccessor/Zones/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId}><i className="metismenu-icon pe-7s-safe"></i>Zones</NavLink></li>
                <li className="metismenu-item"><NavLink className="metismenu-link" to={"ConstructionAccessor/Constructions/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId}><i className="metismenu-icon pe-7s-tools"></i>Constructions</NavLink></li>
                <li className="metismenu-item"><NavLink className="metismenu-link" to={"ShadingAccessor/ShadingSchemes/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId}><i className="metismenu-icon pe-7s-umbrella"></i>Shading schemes</NavLink></li>
                <li className="metismenu-item"><NavLink className="metismenu-link" to={"ElementAccessor/ElementsNew/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId}><i className="metismenu-icon pe-7s-plugin"></i>Elements</NavLink></li>
                <li className="metismenu-item"><NavLink className="metismenu-link" to={"VentilationAccessor/Detail/" + projectId + "/" + designVariationId}><i className="metismenu-icon pe-7s-refresh-cloud"></i>Ventilation</NavLink></li>   
              </ul>
          </div>
          {pageProfile.getModuleTypes(appStoreState).filter(e => e.simulationType === 'New Home').length > 0 &&
            <>
          <h5 className="app-sidebar__heading">Whole of Home (New Home)</h5>
            <div className="metismenu vertical-nav-menu">
                <ul className="metismenu-container"> 
                  {pageProfile.hasModuleType(appStoreState, 'Lighting', 'New Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"LightingAccessor/NewHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-light"></i>Lighting</NavLink></li>}                              
                  {pageProfile.hasModuleType(appStoreState, 'Hot Water', 'New Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"HotWaterAccessor/NewHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-coffee"></i>Hot water</NavLink></li>}   
                  {pageProfile.hasModuleType(appStoreState, 'Heating', 'New Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"HeatingAccessor/NewHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-angle-up-circle"></i>Heating</NavLink></li>}
                  {pageProfile.hasModuleType(appStoreState, 'Cooling', 'New Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"CoolingAccessor/NewHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-angle-down-circle"></i>Cooling</NavLink></li>} 
                  {pageProfile.hasModuleType(appStoreState, 'Plug Load', 'New Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"PlugLoadAccessor/NewHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-plug"></i>Plug Load</NavLink></li>}    
                  {pageProfile.hasModuleType(appStoreState, 'Solar PV', 'New Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"SolarPvAccessor/NewHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-sun"></i>Solar PV</NavLink></li>}    
                  {pageProfile.hasModuleType(appStoreState, 'Pool Spa', 'New Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"PoolSpaAccessor/NewHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-drop"></i>Pool SPA</NavLink></li>}   
                  <li className="metismenu-item"><NavLink className="metismenu-link" to={"ModuleSummaryAccessor/NewHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-note2"></i>Summary</NavLink></li>     
                </ul>
            </div>
            </>
          }    
           {pageProfile.getModuleTypes(appStoreState).filter(e => e.simulationType === 'Existing Home').length > 0 &&
            <>
          <h5 className="app-sidebar__heading">Whole of Home (Existing Home)</h5>
            <div className="metismenu vertical-nav-menu">
                <ul className="metismenu-container"> 
                  {pageProfile.hasModuleType(appStoreState, 'Lighting', 'Existing Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"LightingAccessor/ExistingHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-light"></i>Lighting</NavLink></li>}                              
                  {pageProfile.hasModuleType(appStoreState, 'Hot Water', 'Existing Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"HotWaterAccessor/ExistingHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-coffee"></i>Hot water</NavLink></li>}   
                  {pageProfile.hasModuleType(appStoreState, 'Heating', 'Existing Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"HeatingAccessor/ExistingHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-angle-up-circle"></i>Heating</NavLink></li>}
                  {pageProfile.hasModuleType(appStoreState, 'Cooling', 'Existing Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"CoolingAccessor/ExistingHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-angle-down-circle"></i>Cooling</NavLink></li>} 
                  {pageProfile.hasModuleType(appStoreState, 'Plug Load', 'Existing Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"PlugLoadAccessor/ExistingHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-plug"></i>Plug Load</NavLink></li>}    
                  {pageProfile.hasModuleType(appStoreState, 'Solar PV', 'Existing Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"SolarPvAccessor/ExistingHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-sun"></i>Solar PV</NavLink></li>}    
                  {pageProfile.hasModuleType(appStoreState, 'Pool Spa', 'Existing Home') && <li className="metismenu-item"><NavLink className="metismenu-link" to={"PoolSpaAccessor/ExistingHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-drop"></i>Pool SPA</NavLink></li>}   
                  <li className="metismenu-item"><NavLink className="metismenu-link" to={"ModuleSummaryAccessor/ExistingHome/Detail/" + designVariationVersionName + "/" + designVariationId + "/" + projectId}><i className="metismenu-icon pe-7s-note2"></i>Summary</NavLink></li>     
                </ul>
            </div>
            </>
          }        
        </>
      }
      {parseInt(pageProfile.getClimateZoneIndex(appStoreState)) > 0 && 
      <>
        <h5 className="app-sidebar__heading">CALCULATE RATING</h5>
        <div className="metismenu vertical-nav-menu">
            <ul className="metismenu-container">
              <li className="metismenu-item"><NavLink className="metismenu-link" to={"AnalysisResultAccessor/Analysises/" + designVariationVersionName + "/" + projectId}><i className="metismenu-icon pe-7s-graph3"></i>Analysis</NavLink></li>   
            </ul>
        </div>
      </>
      }
    </Fragment>
  );
}

export default connectAppStore(Nav);
