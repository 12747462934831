import React from "react";
import RowsPerPage from "../DataTable/SharedComponent/RowsPerPage";
import SearchBar from "../DataTable/SharedComponent/SearchBar";
import TablePaginate from "../DataTable/SharedComponent/TablePaginate";
import ContextMenu from "../DataTable/SharedComponent/ContextMenu";
import "bootstrap/dist/css/bootstrap.min.css";
import "./DataTableAe.css";
import guid from "../../Function/guid";
import PageDependencies from "../PageDependencies";
import $ from 'jquery';
const HtmlToReactParser = require("html-to-react").Parser;

const ServerSideDataTable = (props) => {
  const { 
    Fragment, 
    useEffect, 
    useState,
    useNavigate,
    dataExtension,
    cookiesManager,
    useRef,
    getData,
    toastPrint,
  } = PageDependencies();
  const nav = useNavigate();
  const tableRef = useRef();
  let [dataResponse, setDataResponse] = useState({});
  if(props.dataResponse !== undefined){
    dataResponse = props.dataResponse;
  }
  if(props.setDataResponse !== undefined){
    setDataResponse = props.setDataResponse;
  }
  const [doubleClickTimer, setDoubleClickTimer] = useState([]);
  const [shiftClickIndxes, setShiftClickIndxes] = useState([]);
  const dataTableId = props.id === undefined ? guid.newGuid() : props.id;
  const location = props.location;
  const contextMenu = props.contextMenu;
  const timeOut = props.timeOut === undefined ? 800 : props.timeOut;
  const htmlToReactParser = new HtmlToReactParser();
  const tableCell = { border: "1px solid #e9ecef" };
  let columnMinumSize = props.columnMinumSize === undefined ? 5 : props.columnMinumSize;
  const removePaging = props.removePaging === undefined ? false : true;
  const removeSearch = props.removeSearch === undefined ? false : true;
  const disableSorting = props.disableSorting === undefined ? false : true;
  const allowSelect = props.allowSelect === undefined ? false : true;
  const allowSelectMultiple = props.allowSelectMultiple === undefined ? false : true;
  const dataSource = props.dataSource;
  const noRecordsMessage =
    props.noRecordsMessage === undefined
      ? "No records found."
      : props.noRecordsMessage;
  const stateForData = useState(
    props.data === undefined ? [] : setDataDefaultIsSelected(props.data)
  );
  const [data, setData] =
    props.setData === undefined
      ? [stateForData[0], stateForData[1]]
      : [props.data, props.setData];
  const setSelectedItemIds = props.setSelectedItemIds;
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(data.length);
  const perPageOpts =
    props.perPageOpts === undefined
      ? [
          { value: 10, label: "10" },
          { value: 20, label: "20" },
          { value: 50, label: "50" },
          { value: 999999999, label: "All" },
        ]
      : props.perPageOpts;
  //load table setting from cookies
  const tableCookiesId = "data-table-" + dataTableId;
  const defautTableColumnSize = cookiesManager.getCookies(tableCookiesId + "_TableColumnSize", setColumnDefaultSize(props.columns));
  const defautTableSetting = cookiesManager.getCookies(tableCookiesId, {
    search: "",
    rowPerPage: removePaging ? -1 : 10,
    currentPage: 0,
    sortingColumns: setColumnDefaultSorting(props.columns)
  });
  let columSizes = [];
  for(let i = 0; i < props.columns.length; i++){
    const columnData = props.columns[i];
    if(columnData.generate !== undefined){
      defautTableSetting.sortingColumns[i].generate = columnData.generate;
    }
    if(defautTableColumnSize.length > 0){
      try {
        columSizes.push(defautTableColumnSize[i]);
      } catch (error) {
        columSizes.push(10);
      }
    }else{
      columSizes = setColumnDefaultSize(props.columns);
    }
  }
  const [columnWidths, setColumnWidths] = useState(columSizes);
  const [search, setSearch] = useState(defautTableSetting.search);
  const [rowPerPage, setRowPerPage] = useState(defautTableSetting.rowPerPage);
  const [sortingColumns, setSortingColumns] = useState(
    defautTableSetting.sortingColumns
  );
  const [currentPage, setCurrentPage] = useState(
    defautTableSetting.currentPage
  );
  const [pageCount, setPageCount] = useState(1);

  function setColumnDefaultSize(columnObjects) {
    const sizes = [];
    for (let i = 0; i < columnObjects.length; i++) {
      const width = Math.round(columnObjects[i].width.replace("%",""));
      sizes.push(width);
    }
    return sizes;
  }

  function setColumnDefaultSorting(columnObjects) {
    const columnsClone = [];
    for (let i = 0; i < columnObjects.length; i++) {
      columnsClone[i] = { ...columnObjects[i] };
      if (i === 0) {
        columnsClone[i].sorting = "sorting_asc";
      } else {
        columnsClone[i].sorting = "sorting";
      }
      columnsClone[i].id = guid.newGuid();
    }
    return columnsClone;
  }

  function setDataDefaultIsSelected(oData) {
    if (oData.length === undefined) {
      return [];
    }
    if (oData.length === 0) {
      return [];
    }
    if (oData[0].isSelected === undefined) {
      let dataClone = [];
      for (let i = 0; i < oData.length; i++) {
        dataClone[i] = { ...oData[i], isSelected: false };
        if (dataClone[i].id === undefined) {
          dataClone[i].id = guid.newGuid();
        }
      }
      return dataClone;
    }
    return oData;
  }

  const handleMouseDown = (index) => (e) => {
    var timer = setTimeout(()=>{
      const totalWidth = tableRef.current.clientWidth;
      const startX = e.pageX;
      const mouseMoveHandler = (ex) => {
        if (tableRef.current) {
          const newColumnWidths = [...columnWidths];
          let deltaX =  Math.round((ex.pageX - startX) / totalWidth * 100);
          const widthCurrent = newColumnWidths[index];
          const widthNext = newColumnWidths[index + 1];
          if(deltaX > widthNext - columnMinumSize){
            deltaX = widthNext - columnMinumSize;
          }
          if(0 - deltaX > widthCurrent - columnMinumSize){
            deltaX = -(widthNext - columnMinumSize);
          }
          newColumnWidths[index] = newColumnWidths[index] + deltaX;
          newColumnWidths[index + 1] = newColumnWidths[index + 1] - deltaX;
          setColumnWidths(newColumnWidths);
          cookiesManager.setCookies(tableCookiesId + "_TableColumnSize", newColumnWidths);
        }
    };
  
      const mouseUpHandler = () => {
          document.removeEventListener('mousemove', mouseMoveHandler);
          document.removeEventListener('mouseup', mouseUpHandler);
      };
  
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }, 300);
    return timer;
  };

  const handleSortingClick = async (e) => {
    if(e.target !== undefined && e.target.className === "resizer") return;
    const sortingId = e.currentTarget.getAttribute("id");
    const sortingValue = e.currentTarget.getAttribute("class");
    setSortingColumns((prevState) => {
      const sortingColumnsClone = [...prevState];
      for (let i = 0; i < prevState.length; i++) {
          if (e.ctrlKey) {
              if (prevState[i].id === sortingId) {
                  const sortingNew = sortingValue.includes("asc")
                      ? "sorting_desc"
                      : "sorting_asc";
                  sortingColumnsClone[i] = { ...prevState[i], sorting: sortingNew };
              }
          } else {
              if (prevState[i].id === sortingId) {
                  const sortingNew = sortingValue.includes("asc")
                      ? "sorting_desc"
                      : "sorting_asc";
                  sortingColumnsClone[i] = { ...prevState[i], sorting: sortingNew };
              } else {
                  sortingColumnsClone[i] = { ...prevState[i], sorting: "sorting" };
              }
          }      
      }
      return sortingColumnsClone;
    });
  };

  const handleOptionChange = async (e) => {
    const optionValue = e.currentTarget.value;
    setRowPerPage(optionValue);
  };

  const handleSearchChange = async (e) => {
    const optionValue = e.currentTarget.value;
    setSearch(optionValue);
    setCurrentPage(0);
  };

  const handlePageClick = async (data) => {
    let selected = data.selected;
    setCurrentPage(selected);
  };

  const onTdValueChange = (e, columnName, valueProcess) => {
    let value = e.target.value;
    if (valueProcess !== undefined) {
      value = valueProcess(value);
    }
    const inputId = e.currentTarget.getAttribute("id");
    const id = inputId.replace(dataTableId, "").replace(columnName, "");
    const dataClone = [...data];
    for (let i = 0; i < dataClone.length; i++) {
      if (dataClone[i].id === id) {
        dataClone[i][columnName] = value;
        break;
      }
    }
    updateSelectedItemIds(dataClone);
    setData(dataClone, () => {
      const inputElement = document.getElementById(inputId);
      inputElement.focus();
    });
  };

  const showCell = (model) => {
    var html = sortingColumns.map((c, i) => {
      const style = { ...c.style, tableCell };
      const elementHtml = c.generate
        ? c.generate(model, c, i, style, onTdValueChange)
        : model[c.name];
      return (
        <td style={style} key={i + "_" + guid.newGuid()}>
          {elementHtml}
        </td>
      );
    });
    return html;
  };

  const showRows = () => {
    const rows = data.map((m, i) => {
      return (
        <tr
          id={m.id}
          onClick = {async (e) => handleClickRow(e)}
          key={i + "_" + guid.newGuid()}
          className={
            m.isSelected
              ? "exclude table-active"
              : i % 2 === 1
              ? "odd "
              : "even "
          }
        >
          {showCell(m)}
        </tr>
      );
    });

    return rows;
  };

  const handleClickRow = async (e) => {
    if (e === null) return;
    if (e === undefined) return;
    const elementId = e.currentTarget.getAttribute("id");
    clearTimeout(doubleClickTimer);
    setDoubleClickTimer(null);
    //single click
    if (e.detail === 1) {
      setDoubleClickTimer(
        setTimeout(async () => {
          if (allowSelect && !e.shiftKey) {
            setShiftClickIndxes([]);
            setData((prevState) => {
              const newData = [];
              const model = prevState.filter((m) => {
                return m.id === elementId;
              })[0];
              const isSelected = !model.isSelected;
              for (let i = 0; i < prevState.length; i++) {
                if (prevState[i].id === elementId) {
                  newData[i] = { ...prevState[i], isSelected: isSelected };
                } else {
                  if (allowSelectMultiple && e.ctrlKey) {
                    newData[i] = { ...prevState[i] };                 
                  } else{
                    newData[i] = { ...prevState[i], isSelected: false };
                  }
                }
              }
              if (props.actionOnSelectedRow !== undefined) {
                if (isSelected) {
                  props.actionOnSelectedRow(model, newData);
                }
              }
              if (props.actionOnClickRow !== undefined) {
                props.actionOnClickRow(model, newData);
              }
              updateSelectedItemIds(newData);
              return newData;
            });
          }
          if(allowSelect && e.shiftKey){
            const newShiftClickIndxes = [...shiftClickIndxes];
            if(newShiftClickIndxes.length === 0){
              for (let i = 0; i < data.length; i++) {
                if (data[i].isSelected === true){
                  newShiftClickIndxes.push(i);
                  break;
                }
              }
            }
            for (let i = 0; i < data.length; i++) {
              if (data[i].id === elementId){
                newShiftClickIndxes.push(i);
                break;
              }
            }
            newShiftClickIndxes.sort((a, b) => {
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            });
            setShiftClickIndxes(newShiftClickIndxes);
          }
        }, 300)
      );
      //double click
    } else if (e.detail === 2) {
      clearTimeout(doubleClickTimer);
      setDoubleClickTimer(null);
      if (allowSelect && props.actionOnDoubleClickRow != undefined) {
        setData((prevState) => {
          const newData = [];
          const model = prevState.filter((m) => {
            return m.id === elementId;
          })[0];
          const isSelected = true;
          for (let i = 0; i < prevState.length; i++) {
            if (prevState[i].id === elementId) {
              newData[i] = { ...prevState[i], isSelected: isSelected };
            } else {
              if (allowSelectMultiple && e.ctrlKey) {
                newData[i] = { ...prevState[i] };                 
              } else{
                newData[i] = { ...prevState[i], isSelected: false };
              }
            }
          }
          if (props.actionOnSelectedRow !== undefined) {
            if (isSelected) {
              props.actionOnSelectedRow(model, newData);
            }
          }
          try {
            props.actionOnDoubleClickRow(model, newData);
          }catch{
            if (contextMenu !== undefined) {
              const edititem = contextMenu.items.edit;
              if (edititem !== undefined) {
                if(edititem.defaultAction){
                  const editUrl = edititem.url + elementId;
                  nav(editUrl);
                }
              }
            }
          }
          updateSelectedItemIds(newData);
          return newData;
        });
      }
    }
  };

  const generateEmptyTd = () => {
    const count = sortingColumns.length - 1;
    const emptys = [];
    for (let i = 0; i < count; i++) {
      emptys[i] = i;
    }
    const trs = emptys.map((i, key) => {
      return <td key={key} style={tableCell}></td>;
    });
    return trs;
  };

  async function loadData() {
    setIsLoading(true);
    const postModel = generatePostModel();
    const expensesResponse = await getData.processPost(
      dataSource.isAuth,
      dataSource.url,
      postModel
    );
    if(props.debug === true){
      console.log(expensesResponse);
    }
    if (!expensesResponse.state) {
      if(!expensesResponse.message.includes("DOCTYPE")){
        toastPrint.printErrorMessage(
          "Data table error: " + expensesResponse.message
        );
      }
    } else {
      const tableData = expensesResponse.data;
      setDataResponse(expensesResponse);
      const rows = setDataDefaultIsSelected(tableData.data);
      if(props.actionAfterReload !== undefined){
        props.actionAfterReload(rows);
      }
      const totalPage = Math.ceil(tableData.recordsFiltered / rowPerPage);
      setData(rows, ()=>{
        setTotalCount(tableData.recordsFiltered);
        setPageCount(totalPage);
        setIsLoading(false);
      });
    }
  }

  const generatePostModel = () => {
    const postModel = {
      start: currentPage * rowPerPage,
      length: rowPerPage,
      search: { value: search, regex: "" },
      order: [],
      columns: [],
    };

    if (props.extraPostModel !== undefined) {
      const properties = Object.keys(props.extraPostModel);
      for (let i = 0; i < properties.length; i++) {
        postModel[properties[i]] = props.extraPostModel[properties[i]];
      }
    }

    let intdex = 0;
    for (let i = 0; i < sortingColumns.length; i++) {
      const column = sortingColumns[i];
      if (column.sorting.includes("asc") || column.sorting.includes("desc")) {
        postModel.order[intdex] = {
          column: i,
          dir: column.sorting.replace("sorting_", ""),
        };
        intdex++;
      }
      postModel.columns[i] = {
        data: sortingColumns[i].name,
      };
    }
    return postModel;
  };

  const generateTBody = () => {
    const trs = (
      <Fragment>
        {isLoading && (
          <tr className="odd">
            <td style={tableCell}>Loading data...</td>
            {generateEmptyTd()}
          </tr>
        )}
        {!isLoading && data.length === 0 && (
          <tr id={guid.newGuid()} className="odd">
            <td style={tableCell}>{noRecordsMessage}</td>
            {generateEmptyTd()}
          </tr>
        )}
        {!isLoading && data.length > 0 && showRows()}
      </Fragment>
    );
    return <tbody>{trs}</tbody>;
  };

  const contextMenuBuild = ($triggerElement, e, contextMenuSelector) => {
    if (allowSelect) {
      const newData = [];
      const elementId = e.currentTarget.id;
      const model = data.filter((m) => {
        return m.id === elementId;
      })[0];
      let index = -1;
      if (model !== undefined) {
        for(let i = 0; i < data.length; i++){
          if(data[i].id == model.id){
            index = i;
          }
        }
        if (!model.isSelected) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].id === elementId) {
              newData[i] = { ...data[i], isSelected: true };
            } else {
              if (!allowSelectMultiple) {
                newData[i] = { ...data[i], isSelected: false };
              } else {
                if(e.ctrlKey){
                  newData[i] = { ...data[i] };
                }else{
                  newData[i] = { ...data[i], isSelected: false };
                }
              }
            }
          }
          updateSelectedItemIds(newData);
          setData(newData, ()=>{
            const selectedRowNum = dataExtension.getSelectedRowDataIds(data).length;
            if(selectedRowNum === 0){
              $(contextMenuSelector).contextMenu('hide');
              $(contextMenuSelector).eq(index).trigger(
                $.Event('contextmenu', {pageX: e.pageX, pageY: e.pageY})
              );
            }
          });
          if (props.actionOnClickRow !== undefined) {
            props.actionOnClickRow(model);
          }
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      //set cookies
      cookiesManager.setCookies(tableCookiesId, {
        rowPerPage: rowPerPage,
        search: search,
        sortingColumns: sortingColumns,
        currentPage: currentPage
      });
      loadData();
    }, timeOut);
    return () => {
      clearTimeout(timer);
    };
  }, [
    rowPerPage,
    search,
    sortingColumns,
    currentPage,
    props.extraPostModel,
    props.reload,
  ]);

  useEffect(()=>{
    if(shiftClickIndxes.length > 0){
      const startIndex = shiftClickIndxes[0];
      const lastIndex = shiftClickIndxes[shiftClickIndxes.length - 1];
      setData((oldData)=>{
        const newData = [...oldData];
        for(let i = 0; i < newData.length; i++){
          if(i >= startIndex && i <= lastIndex){
            newData[i].isSelected = true;
          }
        }
        updateSelectedItemIds(newData);
        return newData;
      });
    }
  }, [shiftClickIndxes]);

  useEffect(()=>{
    if(setSelectedItemIds !== undefined){
      if(data.length > 0){
        const selectedIds = dataExtension.getSelectedRowDataIds(data);
        setSelectedItemIds({ selectedItemIds:selectedIds });
      }else{
        setSelectedItemIds({
          selectedItemIds:[]
        });
      }
    }
  }, [data]);

  function updateSelectedItemIds(newData){
    if(setSelectedItemIds !== undefined){
      if(newData.length > 0){
        const selectedIds = dataExtension.getSelectedRowDataIds(newData);
        setSelectedItemIds({ selectedItemIds:selectedIds });
      }else{
        setSelectedItemIds({
          selectedItemIds:[]
        });
      }
    }
  }

  useEffect(()=>{
    if(setSelectedItemIds !== undefined){
      if(data.length > 0){
        const selectedIds = dataExtension.getSelectedRowDataIds(data);
        setSelectedItemIds({ selectedItemIds:selectedIds });
      }else{
        setSelectedItemIds({
          selectedItemIds:[]
        });
      }
    }
  }, [data]);

  const handleSelectAllClick = ()=>{
    setData((oldData)=>{
      const newData = [...oldData];
      for(let i = 0; i < newData.length; i++){
        newData[i].isSelected = true;
      }
      updateSelectedItemIds(newData);
      return newData;
    });
  }

  const handleUnselectAllClick = ()=>{
    setData((oldData)=>{
      const newData = [...oldData];
      for(let i = 0; i < newData.length; i++){
        newData[i].isSelected = false;
      }
      updateSelectedItemIds(newData);
      return newData;
    });
  }

  return (
    <Fragment>
      {props.debug === true && 
        <p>{JSON.stringify(data)}</p>
      }
      <ContextMenu
        location={location}
        contextMenu={contextMenu}
        data={data}
        build={contextMenuBuild}
        loadData={loadData}
        dataTableId={dataTableId}
      ></ContextMenu>
      <div className="dataTables_wrapper dt-bootstrap4">
        <div className="row">
          <div className="col-sm-12 col-md-6" >
            {!removePaging && (
              <RowsPerPage
                rowPerPage={rowPerPage}
                perPageOpts={perPageOpts}
                handleOptionChange={handleOptionChange}
                selectAllButtonId = {dataTableId + "_SelectAll"}
                unselectAllButtonId = {dataTableId + "_UnselectAll"}
                handleSelectAllClick = {handleSelectAllClick}
                handleUnselectAllClick = {handleUnselectAllClick}
              />
            )}
            {removePaging &&
                <>
                  <a style={{display:"inline-block", marginRight:"15px", marginBottom:"10px"}} id = {dataTableId + "_SelectAll"} onClick={handleSelectAllClick} className="btn btn-primary btn-round">Select All</a>
                  <a style={{display:"inline-block", marginRight:"15px", marginBottom:"10px"}}  id = {dataTableId + "_UnselectAll"} onClick={handleUnselectAllClick} className="btn btn-primary btn-round">Unselect All</a>
                </>
            }
          </div>
          <div className="col-sm-12 col-md-6">
            {!removeSearch && (
              <SearchBar
                search={search}
                handleSearchChange={handleSearchChange}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <table
              ref = {tableRef}
              id={dataTableId}
              style={{
                width: "100%",
                "--bs-table-hover-bg": "#E0F3FF",
                "--bs-table-active-bg": "#0d6efd",
              }}
              className="table table-hover table-striped table-bordered dataTable"
              role="grid"
            >
              <thead>
                <tr>
                  {sortingColumns.map((column, index) => {
                    if (disableSorting) {
                      return (
                        <th
                          key={index}
                          style={{ ...tableCell, width: columnWidths[index] + "%" }}
                          //style={{ ...tableCell, width: column.width }}
                        >
                                <div  style={{marginRight:"10px"}}>
                            {htmlToReactParser.parse(column.text)}
                          </div>                   
                          {index !== sortingColumns.length -1 &&
                            <div
                                className="resizer"
                                onMouseDown={handleMouseDown(index)}                            
                            />
                          }                                       
                        </th>
                      );
                    }
                    if (column.disableSorting !== undefined){
                      return (
                        <th
                          key={index}
                          id={column.id}
                          style={{ ...tableCell, width: columnWidths[index] + "%"}}
                          //style={{ ...tableCell, width: column.width }}
                        >
                               <div  style={{marginRight:"10px"}}>
                            {htmlToReactParser.parse(column.text)}
                          </div>                        
                          {index !== sortingColumns.length -1 &&
                            <div
                                className="resizer"
                                onMouseDown={handleMouseDown(index)}                            
                            />
                          }     
                        </th>
                      );
                    }
                    return (
                      <th
                        key={index}
                        id={column.id}
                        onClick={async (e) => handleSortingClick(e)}
                        className={column.sorting}
                        style={{ ...tableCell, width: columnWidths[index] + "%"}}
                        //style={{ ...tableCell, width: column.width }}
                      >
                         <div  style={{marginRight:"10px"}}>
                            {htmlToReactParser.parse(column.text)}
                          </div>                       
                          {index !== sortingColumns.length -1 &&
                            <div
                                className="resizer"
                                onMouseDown={handleMouseDown(index)}                            
                            />
                          }      
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tfoot>
                <tr>
                  {sortingColumns.map((column, index) => {
                    return (
                      <th
                        key={index}
                        style={{ ...tableCell, width: columnWidths[index] + "%"}}
                        //style={{ ...tableCell, width: column.width }}
                      >
                       <div  style={{marginRight:"10px"}}>
                            {htmlToReactParser.parse(column.text)}
                          </div>                     
                          {index !== sortingColumns.length -1 &&
                            <div
                                className="resizer"
                                onMouseDown={handleMouseDown(index)}                            
                            />
                          }    
                      </th>
                    );
                  })}
                </tr>
              </tfoot>
              {generateTBody()}
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-5">
            {!removePaging && (
              <div className="dataTables_info" role="status" aria-live="polite">
                Showing {data.length > 0 ? currentPage * rowPerPage + 1 == 1000000000 ? 1 : currentPage * rowPerPage + 1 : 0} to{" "}
                {currentPage * rowPerPage + data.length >= 1000000000 ? currentPage * rowPerPage + data.length - 1000000000 + 1: currentPage * rowPerPage + data.length} of {totalCount}{" "}
                entries,{" "}
                <span className="select-info">
                  <span className="select-item">
                    {
                      data.filter((m) => {
                        return m.isSelected;
                      }).length
                    }{" "}
                    row selected
                  </span>
                  <span className="select-item"></span>
                  <span className="select-item"></span>
                </span>
              </div>
            )}
          </div>
          {!removePaging && (
            <TablePaginate
              pageCount={pageCount}
              currentPage={currentPage}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ServerSideDataTable;
