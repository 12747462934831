import React, { Fragment } from "react";
import { Slider } from "react-burgers";
import cx from "classnames";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { getAppStore, connectAppStore } from "../../Store/AppStore";
import { appThemeSliceActions } from "../../Store/reducers/index";
import { SET_ENABLE_MOBILE_MENU, SET_ENABLE_MOBILE_MENU_SMALL } from "../../Store/reducers/ThemeOptions";

class AppMobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
    };
  }

    toggleMobileSidebar = () => {
        const [appStoreState, appStoreDispatch] = getAppStore(this.props);
        const enableMobileMenu = appStoreState.appThemeReducer.enableMobileMenu;
        const action = {
            type: SET_ENABLE_MOBILE_MENU,
            enableMobileMenu: !enableMobileMenu
        };
        appStoreDispatch(appThemeSliceActions.setAppTheme(action));
    };

    toggleMobileSmall = () => {
        const [appStoreState, appStoreDispatch] = getAppStore(this.props);
        const enableMobileMenuSmall = appStoreState.appThemeReducer.enableMobileMenuSmall;
        const action = {
            type: SET_ENABLE_MOBILE_MENU_SMALL,
            enableMobileMenuSmall: !enableMobileMenuSmall
        };
        appStoreDispatch(appThemeSliceActions.setAppTheme(action));
    };

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  };

  render() {
    return (
      <Fragment>
        <div className="app-header__mobile-menu">
          <div onClick={this.toggleMobileSidebar}>
            <Slider width={26} lineHeight={2} lineSpacing={5} color="#6c757d" 
              active={this.state.active} onClick={() => this.setState({ active: !this.state.active })}/>
          </div>
        </div>
        <div className="app-header__menu">
          <span onClick={this.toggleMobileSmall}>
            <Button size="sm" className={cx("btn-icon btn-icon-only", {
                active: this.state.activeSecondaryMenuMobile,
              })}
              color="primary"
              onClick={() =>
                this.setState({
                  activeSecondaryMenuMobile: !this.state
                    .activeSecondaryMenuMobile,
                })
              }>
              <div className="btn-icon-wrapper">
                <FontAwesomeIcon icon={faEllipsisV} />
              </div>
            </Button>
          </span>
        </div>
      </Fragment>
    );
  }
}
export default connectAppStore(AppMobileMenu);
