class Process {
    setProcessTimeout(action, delay) {
        const timer = setTimeout(()=>{
            action();
        }, delay);
        return () => {
            clearTimeout(timer);
          };
    }
}
const process = new Process();
export default process;