import { Fragment, useEffect } from "react";
import { connectAppStore } from "./components/Store/AppStore";
import PageDependencies from "./components/Feature/PageDependencies";
import "./AppDebugMenu.css";
const AppDebugMenu = (props)=>{
    const { 
        layoutManager,
        useReducerSmart,
        BindedCheckBox,
    } = PageDependencies();
    const debug = false;
    const defaultViewModel = {
        debug: debug,
        hidden: !debug
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);

    useEffect(()=>{
        layoutManager.Layout_SetDebugModel(props, viewModel.debug);
    },[viewModel]);

    return(<Fragment>
        {!defaultViewModel.hidden && 
            <div className="floating-menu">
                <h3>Debug Menu</h3>
                <div className="form-check">
                    <BindedCheckBox className="form-check-input" id="DebugModel" name="DebugModel" type="checkbox" binding={[viewModel, "debug", bindingViewModel]}></BindedCheckBox>
                    <label className="form-check-label" htmlFor="DebugModel">
                        Debug model
                    </label>
                </div>
            </div>
        }
    </Fragment>);
}
export default connectAppStore(AppDebugMenu);