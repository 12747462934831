import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
const MultipleZonesDetailV001 = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        BindedTriStateCheckBox,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const selectedZoneIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        selectedItemIds:selectedZoneIds,
      };
    const debug = layoutManager.Layout_GetDebugModel(props);
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [init, setInit] = useState(false);
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Zone/Accessor/UpdateMultiple";
    const getUrl = "api/Zone/Accessor/Get";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading zones", appStoreDispatch, undefined, setInit);
    },[]);

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="ZoneFrom"
           icon="pe-7s-safe"
           loading={loading}
           init= {init}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: "/ZoneAccessor/Zones/" + viewModel.designVariationVersionName + "/" + viewModel.developmentId + "/" + viewModel.projectId + "/" + viewModel.designVariationId }}
           cancelButton={{ redirectUrl: "/ZoneAccessor/Zones/" + viewModel.designVariationVersionName + "/" + viewModel.developmentId + "/" + viewModel.projectId + "/" + viewModel.designVariationId }}
           data={viewModel}
           title = "Zones detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-12">
                    <div className="form-row row">
                        <label htmlFor="ZoneName">Name *</label>
                        <BindedInput className="form-control" id="ZoneName" name="ZoneName" binding={[viewModel, "zoneName", bindingViewModel]}></BindedInput>
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-8">
                    <div className="form-row row">
                        <div className="col-6">
                            <label htmlFor="ZoneTypeId">Type *</label>
                            <BindedSelect
                            className="form-control"
                            id="ZoneTypeId"
                            name="ZoneTypeId"
                            binding={[viewModel, "zoneTypeId", bindingViewModel]}
                            options={"zoneTypes"}
                        ></BindedSelect>
                        </div>
                    </div>
                    <div className="form-row row">
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="ZoneFloorHeight">Floor height (mm) *</label>
                                <BindedInput id="ZoneFloorHeight" name="ZoneFloorHeight" type="number" className="form-control" binding={[viewModel, "zoneFloorHeight", bindingViewModel]}></BindedInput>                      
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="position-relative form-group">
                                <label htmlFor="ZoneCeilingHeight">Maximum ceiling height above floor (mm) *</label>
                                <BindedInput id="ZoneCeilingHeight" name="ZoneCeilingHeight" type="number" className="form-control" binding={[viewModel, "zoneCeilingHeight", bindingViewModel]}></BindedInput>   
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-1">
                    <div className="separator"></div>
                </div>
                <div className="col-3">
               
                
                    <div className="position-relative row form-group">
                    <div className="col-12">
                        <div className="custom-checkbox custom-control">
                            <BindedTriStateCheckBox
                                id="ZoneIsHeated" 
                                name="ZoneIsHeated"
                                className="custom-control-input"
                                disabled
                                binding={[viewModel, "zoneIsHeated", bindingViewModel]}
                            ></BindedTriStateCheckBox>
                            <label style={{marginLeft:"5px"}} htmlFor="ZoneIsHeated" className="custom-control-label">Heated</label>                          
                        </div>
                    </div>
                    </div>
                    <div className="position-relative row form-group">
                    <div className="col-12">
                        <div className="custom-checkbox custom-control">
                            <BindedTriStateCheckBox
                                id="ZoneIsCooled" 
                                name="ZoneIsCooled"
                                className="custom-control-input"
                                disabled
                                binding={[viewModel, "zoneIsCooled", bindingViewModel]}
                            ></BindedTriStateCheckBox>
                            <label style={{marginLeft:"5px"}} htmlFor="ZoneIsCooled" className="custom-control-label">Cooled</label>                   
                        </div>
                    </div>
                    </div>
                    <div className="position-relative row form-group">
                        <div className="col-12">
                            <div className="custom-checkbox custom-control">
                                <BindedTriStateCheckBox
                                    id="ZoneIsReflective" 
                                    name="ZoneIsReflective"
                                    className="custom-control-input"
                                    disabled
                                    binding={[viewModel, "zoneIsReflective", bindingViewModel]}
                                ></BindedTriStateCheckBox>
                                <label style={{marginLeft:"5px"}} htmlFor="ZoneIsReflective" className="custom-control-label">Reflective</label>                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
           {(viewModel.zoneTypeId === "11" || viewModel.zoneTypeId === 11) &&  <div className="form-row">
                <div className="col-6">
                    <div className="form-row row">
                        <div className="position-relative form-group">
                            <label htmlFor="SubfloorZoneInfiltration_OpennessType">Openness</label>
                            <BindedSelect
                                className="form-control"
                                id="SubfloorZoneInfiltration_OpennessType"
                                name="SubfloorZoneInfiltration_OpennessType"
                                binding={[viewModel, "subFloorOpennessTypeId", bindingViewModel]}
                                options={"suFloorOpennesses"}
                                style={{marginBottom:"15px"}}
                            ></BindedSelect>
                        </div>
                        <div className="custom-checkbox custom-control">
                            <BindedTriStateCheckBox
                                id="SubfloorZoneInfiltration_HasWallCavity" 
                                name="SubfloorZoneInfiltration.HasWallCavity"
                                className="custom-control-input"
                                binding={[viewModel, "hasWallCavity", bindingViewModel]}
                            ></BindedTriStateCheckBox>
                            <label style={{marginLeft:"5px"}} htmlFor="SubfloorZoneInfiltration_HasWallCavity" className="custom-control-label">Is there a wall cavity allowing unobstructed air flow between the sub-floor and roof-space or outdoors?</label>
                        </div>
                        <div className="position-relative form-group">
                            <label htmlFor="SubfloorZoneInfiltration_VentilationOpeningsTotalArea">Area of sub-floor ventilation openings</label>
                            <BindedSelect
                                className="form-control"
                                id="SubfloorZoneInfiltration_VentilationOpeningsTotalArea"
                                name="SubfloorZoneInfiltration.VentilationOpeningsTotalArea"
                                binding={[viewModel, "ventilationOpeningsTotalArea", bindingViewModel]}
                                options={"ventilationOpeningsTotalAreas"}
                                style={{marginBottom:"15px"}}
                            ></BindedSelect>
                     </div>
                    </div>
                </div>
            </div>}
             {(viewModel.zoneTypeId === "10" || viewModel.zoneTypeId === 10) &&  <div className="form-row">
                <div className="col-6">
                    <div className="form-row row">
                        <div className="position-relative form-group">
                            <label htmlFor="RoofspaceZoneInfiltration_SarkingType">Sarking</label>
                            <BindedSelect
                                className="form-control"
                                id="RoofspaceZoneInfiltration_SarkingType"
                                name="RoofspaceZoneInfiltration.SarkingType"
                                binding={[viewModel, "roofSpaceSarkingTypeId", bindingViewModel]}
                                options={"roofSpaceSarkings"}
                                style={{marginBottom:"15px"}}
                            ></BindedSelect>
                        </div>
                        <div className="position-relative form-group">
                            <label htmlFor="RoofspaceZoneInfiltration_SurfaceType">Roof Surface</label>
                            <BindedSelect
                                className="form-control"
                                id="RoofspaceZoneInfiltration_SurfaceType"
                                name="RoofspaceZoneInfiltration.SurfaceType"
                                binding={[viewModel, "roofSpaceSurfaceTypeId", bindingViewModel]}
                                options={"roofSpaceSurfaceTypes"}
                                style={{marginBottom:"15px"}}
                            ></BindedSelect>
                        </div>
                        <div className="position-relative form-group">
                            <label htmlFor="RoofspaceZoneInfiltration_OpennessType">Openness</label>
                            <BindedSelect
                                className="form-control"
                                id="RoofspaceZoneInfiltration_OpennessType"
                                name="RoofspaceZoneInfiltration.OpennessType"
                                binding={[viewModel, "roofSpaceOpennessTypeId", bindingViewModel]}
                                options={"roofSpaceOpennesses"}
                                style={{marginBottom:"15px"}}
                            ></BindedSelect>
                     </div>
                    </div>
                </div>
            </div>}
            {viewModel.simulationType === "Existing Home" &&
                <>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Existing Infiltration</h5>
                        </button>
                    </div>
                    <div className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            
                    <div className="row">
                        <div className="col-6">
                            <div className="main-card mb-3 card">
                                <div className="card-header">
                                    <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">Infiltration Fan & Wall Vent</h5>
                                    </button>
                                </div>
                                <div className="collapse show">
                                    <div className="card-body">
                                        <div className="separator"></div>
                                        <div className="form-row row">
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="FanUnsealed">Exhaust Fan Unsealed (to outside)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "fanUnsealed", bindingViewModel]}
                                                    id="FanUnsealed"
                                                    name="FanUnsealed"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="FanSealed">Exhaust Fan Sealed (to outside)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "fanSealed", bindingViewModel]}
                                                    id="FanSealed"
                                                    name="FanSealed"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="Vent">Vent (to attic)</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "vent", bindingViewModel]}
                                                    id="Vent"
                                                    name="Vent"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="col-6">
                            <div className="main-card mb-3 card">
                                <div className="card-header">
                                    <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">Ceiling</h5>
                                    </button>
                                </div>
                                <div className="collapse show">
                                    <div className="card-body">
                                        <div className="separator"></div>
                                        <div className="form-row row">
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="Rose">Rose</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "rose", bindingViewModel]}
                                                    id="Rose"
                                                    name="Rose"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="HatchUnsealed">Hatch Unsealed</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "hatchUnsealed", bindingViewModel]}
                                                    id="HatchUnsealed"
                                                    name="HatchUnsealed"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-4">
                                                <div className="position-relative form-group">
                                                <label htmlFor="HatchWeatherStripped">Hatch Weather-stripped</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "hatchWeatherStripped", bindingViewModel]}
                                                    id="HatchWeatherStripped"
                                                    name="HatchWeatherStripped"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="main-card mb-3 card">
                                <div className="card-header">
                                    <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">Internal Cavity Sliding Door</h5>
                                    </button>
                                </div>
                                <div className="collapse show">
                                    <div className="card-body">
                                        <div className="separator"></div>
                                        <div className="form-row row">
                                            <div style={{marginTop:"15px"}} className="col-6">
                                                <div className="position-relative form-group">
                                                <label htmlFor="NonWeatherStrippedGaps">Number of Doors with Gaps Unsealed</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "nonWeatherStrippedGaps", bindingViewModel]}
                                                    id="NonWeatherStrippedGaps"
                                                    name="NonWeatherStrippedGaps"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-6">
                                                <div className="position-relative form-group">
                                                <label htmlFor="WeatherStrippedGaps">Number of Doors with Gaps Sealed</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "weatherStrippedGaps", bindingViewModel]}
                                                    id="WeatherStrippedGaps"
                                                    name="WeatherStrippedGaps"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>                            
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="col-6">
                            <div className="main-card mb-3 card">
                                <div className="card-header">
                                    <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                        <h5 className="m-0 p-0 card-title">Downlight</h5>
                                    </button>
                                </div>
                                <div className="collapse show">
                                    <div className="card-body">
                                        <div className="separator"></div>
                                        <div className="form-row row">
                                            <div style={{marginTop:"15px"}} className="col-6">
                                                <div className="position-relative form-group">
                                                <label htmlFor="DownlightGimballed">Gimballed</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "downlightGimballed", bindingViewModel]}
                                                    id="DownlightGimballed"
                                                    name="DownlightGimballed"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>
                                            <div style={{marginTop:"15px"}} className="col-6">
                                                <div className="position-relative form-group">
                                                <label htmlFor="DownlightThinRingOpening">Thin Ring Opening</label>
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "downlightThinRingOpening", bindingViewModel]}
                                                    id="DownlightThinRingOpening"
                                                    name="DownlightThinRingOpening"
                                                    type="number"
                                                    min = "0"
                                                    step = "1"
                                                ></BindedInput>
                                                </div>
                                            </div>                            
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="main-card mb-3 card">
                        <div className="card-header">
                            <button type="button" data-toggle="collapse" className="text-left m-0 p-0 btn btn-block">
                                <h5 className="m-0 p-0 card-title">Gaps in elements</h5>
                            </button>
                        </div>
                        <div className="collapse show">
                            <div className="card-body">
                                <div className="separator"></div>
                                <div className="form-row row">
                                    <div style={{marginTop:"15px"}} className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExistingInfiltrationFloorBoardsTypeId">Floorboard</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ExistingInfiltrationFloorBoardsTypeId"
                                                name="ExistingInfiltrationFloorBoardsTypeId"
                                                binding={[viewModel, "existingInfiltrationFloorBoardsTypeId", bindingViewModel]}
                                                options={"existingInfiltrationFloorBoardsTypes"}
                                                style={{marginBottom:"15px"}}
                                            ></BindedSelect>
                                        </div>
                                    </div>
                                    <div style={{marginTop:"15px"}} className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExistingInfiltrationSkirtingTypeId">Skirting</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ExistingInfiltrationSkirtingTypeId"
                                                name="ExistingInfiltrationSkirtingTypeId"
                                                binding={[viewModel, "existingInfiltrationSkirtingTypeId", bindingViewModel]}
                                                options={"existingInfiltrationSkirtingTypes"}
                                                style={{marginBottom:"15px"}}
                                            ></BindedSelect>
                                        </div>
                                    </div>   
                                    <div style={{marginTop:"15px"}} className="col-3">
                                        <div className="position-relative form-group">
                                            <label htmlFor="ExistingInfiltrationOtherSignificantGapsTypeId">Other Significant Gaps</label>
                                            <BindedSelect
                                                className="form-control"
                                                id="ExistingInfiltrationOtherSignificantGapsTypeId"
                                                name="ExistingInfiltrationOtherSignificantGapsTypeId"
                                                binding={[viewModel, "existingInfiltrationOtherSignificantGapsTypeId", bindingViewModel]}
                                                options={"existingInfiltrationOtherSignificantGapsTypes"}
                                                style={{marginBottom:"15px"}}
                                            ></BindedSelect>
                                        </div>
                                    </div> 
                                    <div style={{marginTop:"15px"}} className="col-3">
                                        <div className="position-relative form-group">
                                        <label htmlFor="OutletNumberInZone">outlet Number in zone</label>
                                        <BindedInput
                                            className="form-control"
                                            binding={[viewModel, "outletNumberInZone", bindingViewModel]}
                                            id="OutletNumberInZone"
                                            name="OutletNumberInZone"
                                            type="number"
                                            min = "0"
                                            step = "1"
                                        ></BindedInput>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                        </div>
                    </div> 
                        </div>
                    </div>
                </div>
                </>
            }
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleZonesDetailV001);