import { createSlice } from "@reduxjs/toolkit";
const guidEmpty = "00000000-0000-0000-0000-000000000000";
const defaultState = {
  developmentId: guidEmpty,
  projectId: guidEmpty,
  projectName: "",
  designVariationId: guidEmpty,
  designVariationName: "",
  userId: guidEmpty,
  userRole: "",
  modules:[]
};
function reducer(state, action) {
  const payload = action.payload;
  switch (payload.type) {
    case "developmentId":
      return {
        ...state,
        developmentId: payload.developmentId,
      };
    case "projectId":
      return {
        ...state,
        projectId: payload.projectId,
      };
    case "projectName":
      return {
        ...state,
        projectName: payload.projectName,
      };
    case "designVariationId":
      return {
        ...state,
        designVariationId: payload.designVariationId,
      };
    case "designVariationName":
      return {
        ...state,
        designVariationName: payload.designVariationName,
      };
    case "designVariationVersionId":
      return {
        ...state,
        designVariationVersionId: payload.designVariationVersionId,
      };
    case "designVariationVersionName":
      return {
        ...state,
        designVariationVersionName: payload.designVariationVersionName,
      };
      case "designVariationId":
        return {
          ...state,
          designVariationVersionId: payload.designVariationVersionId,
        };
      case "designVariationName":
        return {
          ...state,
          designVariationVersionName: payload.designVariationVersionName,
        };
    case "userId":
      return {
        ...state,
        userId: payload.userId,
      };
    case "userRole":
      return {
        ...state,
        userRole: payload.userRole,
      };
    case "modules":
      return {
        ...state,
        modules: payload.modules,
      };
    case "dwellingType":
      return {
        ...state,
        dwellingType: payload.dwellingType,
      };
      case "climateZoneIndex":
        return {
          ...state,
          climateZoneIndex: payload.climateZoneIndex,
        };
      case "softwareVersion":
        return {
          ...state,
          softwareVersion: payload.softwareVersion,
        };
      case "extraData":
        return {
          ...state,
          extraData: payload.extraData,
        };
    }
}
const defaultProjectSlice = createSlice({
  name: "defaultProject",
  initialState: defaultState,
  reducers: {
    setDefaultProject(state, action) {
      return reducer(state, action);
    },
  },
});
export default defaultProjectSlice;
