import React, { Fragment, useEffect, useState } from 'react';
import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import guid from '../Function/guid';

const BindedTriStateCheckBox = (props)=>{
    const newProps = {};
    const binding = props.binding;
    const bindingObject = binding[0];
    const bindingProperty = binding[1];
    const bindingMethod = binding[2];
    const bindingCallBack = binding[3];
    const bindingValue = objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty) === undefined ? false : objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty);
    const [message, setMessage] = useState("");
    const [valueCheckBox, setValueCheckBox] = useState(null);
    const validate = props.validate;

    let label = props.label;
    let inputId = props.inputId;
    if(inputId === undefined){
        inputId = guid.newGuid();
    }
    if(label === undefined){
        label = "";
    }

    for (const prop in props) {
        if (!(prop==='binding') && !(prop==='componentRef') && !(prop==='validate') && !(prop==='inputId')) {
            newProps[prop] = props[prop];
        }
    }

    useEffect(() => {
        if (validate !== undefined) {
            if (validate.setMessages !== undefined) {
                validate.setMessages[1](oldSetMessage => {
                    let newMessages = {};
                    if (oldSetMessage !== undefined) {
                        newMessages = { ...oldSetMessage };
                    }
                    newMessages[bindingProperty] = setMessage;
                    return newMessages;
                });
            }           
        }    
    }, []);

    const getValidateVisibility = ()=>{
        if(validate === undefined) return false;
        if(validate.validateVisibility === undefined) return false;
        return validate.validateVisibility;
    }

    useEffect(()=>{ 
        if(bindingValue === "" || bindingValue === null || bindingValue === true || bindingValue === false){
            if(valueCheckBox === null || valueCheckBox === true || valueCheckBox === false){
             
                if(bindingValue != valueCheckBox){
                    const bindingObjectNew = { };
                    bindingObjectNew[bindingProperty] = valueCheckBox;           
                    bindingMethod(bindingObjectNew, undefined, valueCheckBox, false);
                    if (validate !== undefined) {
                        const validateObject = { ...bindingObject };
                        validateObject[bindingProperty] = valueCheckBox;
                        validate.checkValidateResult(validateObject);
                    }
                    if(bindingCallBack !== undefined){
                        bindingCallBack(valueCheckBox);
                    }
                } 
            }
        }
    }, [valueCheckBox]);

    useEffect(()=>{  
        if(bindingValue === "" || bindingValue === null || bindingValue === true || bindingValue === false){
            if(valueCheckBox === null || valueCheckBox === true || valueCheckBox === false){
                if(bindingValue != valueCheckBox){
                    if(bindingValue === ""){
                        setValueCheckBox(null);
                    }else{
                        setValueCheckBox(bindingValue);
                    }
                }
            }
        }     
    },[bindingValue]);

    return <Fragment>
        <TriStateCheckbox 
            {...newProps} 
            id = {inputId}
            value = {valueCheckBox}
            onChange={(e) => {
                if (e.value === undefined) return null;
                if(e.value === null || e.value === true || e.value === false){                
                    setValueCheckBox(e.value);
                }
            }}
            />
        {<span id={bindingProperty + "-Validate"} className="field-validation-valid" style={{color:"red"}}>{getValidateVisibility() && message}</span>}
    </Fragment>;
};
export default BindedTriStateCheckBox;