import HeatingModuleV001 from "../../Portal/Accessor/Module/V001/NewHome/HeatingModule";
import CoolingModuleV001 from "../../Portal/Accessor/Module/V001/NewHome/CoolingModule";
import LightingModuleV001 from "../../Portal/Accessor/Module/V001/NewHome/LightingModule";
import PlugLoadCookingModuleV001 from "../../Portal/Accessor/Module/V001/NewHome/PlugLoadCookingModule";
import HotWaterModuleV001 from "../../Portal/Accessor/Module/V001/NewHome/HotWaterModule";
import PoolSpaModuleV001 from "../../Portal/Accessor/Module/V001/NewHome/PoolSpaModule";
import SolarPvModuleV001 from "../../Portal/Accessor/Module/V001/NewHome/SolarPvModule";
import ModuleSummary from "../../Portal/Accessor/Module/ModuleSummary";
const AccessorModuleNewHomeRouters = [
    {
        path: "HeatingAccessor/NewHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <HeatingModuleV001/>,
    },
    {
        path: "CoolingAccessor/NewHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <CoolingModuleV001/>,
    },
    {
        path: "LightingAccessor/NewHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <LightingModuleV001/>,
    },
    {
        path: "PlugLoadAccessor/NewHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <PlugLoadCookingModuleV001/>,
    },
    {
        path: "HotWaterAccessor/NewHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <HotWaterModuleV001/>,
    },
    {
        path: "PoolSpaAccessor/NewHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <PoolSpaModuleV001/>,
    },
    {
        path: "SolarPvAccessor/NewHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <SolarPvModuleV001/>,
    },
    {
        path: "ModuleSummaryAccessor/NewHome/Detail/:designVariationVersionName/:designVariationId/:projectId",
        exact: true,
        requireAuth: true,
        element: <ModuleSummary/>,
    },
];
export default AccessorModuleNewHomeRouters;