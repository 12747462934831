import React, { Fragment, useEffect, useState } from 'react';
import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";

const BindedInput = (props) => {
    const newProps = {};
    const binding = props.binding;
    let type = props.type;
    if(type === undefined){
        type = "";
    }else{
        type = type.toLowerCase();
    }
    const bindingObject = binding[0];
    const bindingProperty = binding[1];
    const bindingMethod = binding[2];
    let bindingDefaultValue = binding[3];
    const bindingValue = objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty);
    const [message, setMessage] = useState("");
    const validate = props.validate;

    for (const prop in props) {
        if (!(prop==='binding') && !(prop==='componentRef') && !(prop==='validate')) {
            newProps[prop] = props[prop];
        }
    }

    useEffect(() => {
        if (validate !== undefined) {
            if (validate.setMessages !== undefined) {
                validate.setMessages[1](oldSetMessage => {
                    let newMessages = {};
                    if (oldSetMessage !== undefined) {
                        newMessages = { ...oldSetMessage };
                    }
                    newMessages[bindingProperty] = setMessage;
                    return newMessages;
                });
            }           
        }    
    }, []);

    const getValidateVisibility = ()=>{
        if(validate === undefined) return false;
        if(validate.validateVisibility === undefined) return false;
        return validate.validateVisibility;
    }

    return (
        <Fragment>
             <input {...newProps} ref={props.componentRef} value={bindingValue} onChange={(e) => {
                    let value = e.target.value   
                    const bindingObjectNew = { };
                    bindingObjectNew[bindingProperty] = value;         
                    bindingMethod(bindingObjectNew, e, undefined, undefined, bindingDefaultValue);
                    if (validate !== undefined) {
                        const validateObject = { ...bindingObject };
                        validateObject[bindingProperty] = value;
                        validate.checkValidateResult(validateObject);
                    }
                }}/>
            {<span id={bindingProperty + "-Validate"} className="field-validation-valid" style={{color:"red"}}>{getValidateVisibility() && message}</span>}
        </Fragment>
    );
};
export default BindedInput;