import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";
import ProjectDataTable from "./ProjectDataTable";

const Projects = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        useReducerSmart,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const defaultViewModel = {
        ...defaultParams,
    };
    const [extraPostModel, setExtraPostModel] = useState({
        ...defaultParams,
      });
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/DefaultModel/Accessor/Get";
    const [reloadForm, setReloadForm] = useState("");
    const [data, setData] = useState([]);
    
    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        //loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Projects", appStoreDispatch);
    },[]);

    return (
        <Fragment>
            {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>}
            {debug && <p>{JSON.stringify(viewModel)}</p>}       
            {debug && <p>{JSON.stringify(loading)}</p>}       
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-tools icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Projects
                                <div className="page-title-subheading">
                                    All projects can be accessed from this page.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <ProjectDataTable
                                debug = {debug}
                                data = {data}
                                setData = {setData}
                                extraPostModel = {extraPostModel}
                                url = "api/Project/Admin"
                                tableReload = {reloadForm}
                                setTableReload = {setReloadForm}
                            ></ProjectDataTable>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(Projects);
