import React, { Fragment, useState } from "react";
import { Slider } from "react-burgers";
import AppMobileMenu from "../AppMobileMenu";
import { getAppStore, connectAppStore } from "../../Store/AppStore";
import { appThemeSliceActions } from "../../Store/reducers/index";
import { SET_ENABLE_CLOSED_SIDEBAR } from "../../Store/reducers/ThemeOptions";

const HeaderLogo = (props)=>{
  const[state, setState] = useState({
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  });
  
  const [appStoreState, appStoreDispatch] = getAppStore(props);
  const showSideBarOptButton = appStoreState.appThemeReducer.showSideBarOptButton;
  const enableClosedSidebar = appStoreState.appThemeReducer.enableClosedSidebar;

  function toggleEnableClosedSidebar(){
    setState({ active: !enableClosedSidebar });
    const action = {
      type: SET_ENABLE_CLOSED_SIDEBAR,
      enableClosedSidebar: !enableClosedSidebar,
    };
    appStoreDispatch(appThemeSliceActions.setAppTheme(action));
  };
  return (
    <Fragment>
      <div className="app-header__logo">
        <a href="/">
          <div className="logo-src" />
        </a>
        <div className="header__pane ms-auto">
        <div className={showSideBarOptButton ? "": "hideAppSideBarOptButton"} onClick={toggleEnableClosedSidebar}>
              <Slider
                width={26}
                lineHeight={2}
                lineSpacing={5}
                color="#6c757d"
                active={state.active}
              />
            </div>
        </div>
      </div>
      <AppMobileMenu />
    </Fragment>
  );
}

export default connectAppStore(HeaderLogo);
