import { connectAppStore } from "../../../../../Store/AppStore";
import PageDependencies from "../../../../../Feature/PageDependencies";

const PoolSpaModule = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        tryCatchFinally,
        layoutManager,
        getData,
        guid,
        useReducerSmart,
        toastPrint,
        BindedInput,
        BindedSelect,
        BindedRadio,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
        htmlToReactParse,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const { } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/ExistingHome/PoolSpaModule/Accessor/Get";
    const [reloadForm, setReloadForm] = useState("");
    const validateMethods = {
        pumpTypeId:(value)=>{ return value == -999 ? "Must select a pump type." : "";},
        volume: (value, newViewModel) => {
            if(newViewModel.inputModel === "Volume"){
                return FormValidate.validateNumberGreaterThanOrEqual0(value, "Volume");
            } 
            return "";
        },
        area: (value, newViewModel) => {
            if(newViewModel.inputModel === "Area"){
                return FormValidate.validateNumberGreaterThanOrEqual0(value, "Area");
            }
            return "";
        },
        // volume:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Volume");},
        // area:(value)=>{ return FormValidate.validateNumberGreaterThan0(value, "Area");},
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Pool & Spa", appStoreDispatch, undefined, setInit);
    },[reloadForm]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/ExistingHome/PoolSpaModule/Accessor/UpdatePoolSpa";
        setLoading({ isLoading: true, text: "Updating Pool & Spa" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            setReloadForm(guid.newGuid());
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className=" pe-7s-drop icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Pool & Spa
                                <div className="page-title-subheading">
                                    {pageProfile.getPageSubTitle(appStoreState)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&             
                    <>
   <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#AnnualEnergyConsumptionDiv" aria-expanded="true" aria-controls="AnnualEnergyConsumptionDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Annual Energy Consumption</h5>
                        </button>
                    </div>
                    <div data-parent="#AnnualEnergyConsumptionAccordion" id="AnnualEnergyConsumptionDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label>Pump Electricity (kWh/yr): {parseFloat(viewModel.annualPumpElectricityEnergy).toFixed(2)}</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label>Pump Star Rating: {parseFloat(viewModel.pumpStarRating).toFixed(1)}</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label>Pump Operating Power (kWh/yr): {parseFloat(viewModel.pumpOperatingPower).toFixed(2)}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div className="card-header">
                        <button type="button" data-toggle="collapse" data-target="#HeatingConditionersListDiv" aria-expanded="true" aria-controls="HeatingConditionersListDiv" className="text-left m-0 p-0 btn btn-block">
                            <h5 className="m-0 p-0 card-title">Pool & Spa Configuration</h5>
                        </button>
                    </div>
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                        <div className="card-body">
                            <div className="separator"></div>
                            <div className="form-row row">
                                <div className="col-12">
                                    <div className="position-relative form-group pr-3">
                                        <div className="form-row row">
                                            <div className="col-lg-2 col-2 form-check" style={{marginTop:"10px"}}>
                                                <BindedRadio
                                                    id="VolumeInput"
                                                    buttonValue = "Volume"
                                                    buttonLabel = "Volume Input (L)"
                                                    binding={[viewModel, "inputModel", bindingViewModel]}
                                                ></BindedRadio>
                                            </div>
                                            {viewModel.inputModel === "Volume" &&
                                            <div className="col-lg-2 col-2">
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "volume", bindingViewModel]}
                                                    id="VolumeInput"
                                                    name="VolumeInput"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="position-relative form-group pr-3">
                                        <div className="form-row row">
                                            <div className="col-lg-2 col-2 form-check" style={{marginTop:"10px"}}>
                                                <BindedRadio
                                                    id="AreaInput"
                                                    buttonValue = "Area"
                                                    buttonLabel = {htmlToReactParse("Area Input m<sup>2</sup>")}
                                                    binding={[viewModel, "inputModel", bindingViewModel]}
                                                ></BindedRadio>              
                                            </div>
                                            {viewModel.inputModel === "Area" &&
                                            <div className="col-lg-2 col-2">
                                                <BindedInput
                                                    className="form-control"
                                                    binding={[viewModel, "area", bindingViewModel]}
                                                    id="AreaInput"
                                                    name="AreaInput"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    validate = {formValidate}
                                                ></BindedInput>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row row">
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="PoolSpaPumpType">Pump type *</label>
                                        <BindedSelect
                                            className="form-control"
                                            id="PoolSpaPumpType"
                                            name="PoolSpaPumpType"
                                            options="pumpTypes"
                                            validate={formValidate}
                                            binding={[viewModel, "pumpTypeId", bindingViewModel]}
                                        ></BindedSelect>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="position-relative form-group">
                                        <label htmlFor="AverageDepth">Average Depth </label>
                                        <BindedInput
                                            disabled
                                            className="form-control"
                                            id="AverageDepth"
                                            name="AverageDepth"
                                            type="number"
                                            binding={[viewModel, "averageDepth", bindingViewModel]}
                                        ></BindedInput>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row-separator"></div>
                            <div className="form-row row"></div>
                            <div className="form-row row">
                                <div className="col-12">
                                    <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </>
                }
             
            </div>
        </Fragment>
    );
}
export default connectAppStore(PoolSpaModule);