import tryCatchFinally from "./TryCatchFinally";
class UseStateOperation {
   async asyncInvoke(setUseStateLazy, action, setLoading) {
      setUseStateLazy(async (oldState)=>{    
         await tryCatchFinally.asyncProcess(async ()=>{
            await action(oldState);
         }, setLoading); 
         return oldState;
      });
   }

   syncInvoke(setUseStateLazy, action, setLoading) {
      setUseStateLazy((oldState)=>{   
         tryCatchFinally.syncProcess(()=>{
            action(oldState);
         }, setLoading);  
         return oldState;
      });
   }
}
const useStateOperation = new UseStateOperation();
export default useStateOperation;