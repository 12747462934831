import React, { Fragment } from "react";
import { getAppStore, connectAppStore } from "../../Store/AppStore";
import pageProfile from "../../Portal/Accessor/PageProfile";
import getData from "../../Function/GetData";

const AppFooter = (props)=>{
  const [appStoreState, appStoreDispatch] = getAppStore(props);
  const getUrl = "api/DefaultModel/Accessor/GetSoftwareVersion";
  const getSoftwareVersion = async () =>{
    const response = await getData.processPost(true, getUrl, {});
    if(response.state === true){
      pageProfile.setSoftwareVersion(appStoreDispatch, response.softwareVersion);
    }
  }

  const theme = appStoreState.appThemeReducer;
  let { showFooter } = theme;
  let softwareVersion = pageProfile.getSoftwareVersion(appStoreState);
  if(softwareVersion !== undefined && softwareVersion !== null && softwareVersion.length > 0){
    softwareVersion = "Software Version " + softwareVersion.replace("AccuRate Enterprise ","")
  }else{
    getSoftwareVersion();
  }

  return (
    <Fragment>
      <div className={showFooter ? "" : "hideAppFooter"}>
        <div className="app-footer">
          <div className="app-footer__inner">
            <div className="app-footer-left">
              <div className="footer-dots">
                <a href="/Software as a Service Agreement (for Direct Users of AccuRate Enterprise) Version 1.0 150724.pdf" target="_blank">
                  Terms of Service
                </a>
                <div className="dots-separator"></div>
                <a href="/AccuRate Website Terms Version 1.0 150724.pdf" target="_blank">
                  Website Terms
                </a>
                <div className="dots-separator"></div>
                <a href="/Privacy Collection Notice Version 1.0 150724.pdf" target="_blank">
                  Privacy Collection Notice
                </a>
                <div className="dots-separator"></div>
                <a href="https://www.csiro.au/en/about/Policies/Privacy" target="_blank">
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className="app-footer-right">
              © 2023-{new Date().getFullYear()} CSIRO, the AccuRate Enterprise team, {softwareVersion}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
// class AppFooter extends React.Component {
  
//   async getSoftwareVersion (){
//     const [appStoreState, appStoreDispatch] = getAppStore(this.props);
//     const getUrl = "api/DefaultModel/Accessor/GetSoftwareVersion";
//     const response = await getData.processPost(true, getUrl, {});
//     if(response.state === true){
//       pageProfile.setSoftwareVersion(appStoreDispatch, response.softwareVersion);
//     }
//   }
//   render() {
//     const [appStoreState, appStoreDispatch] = getAppStore(this.props);
//     const theme = appStoreState.appThemeReducer;
//     let { showFooter } = theme;
//     let softwareVersion = pageProfile.getSoftwareVersion(appStoreState);
//     if(softwareVersion !== undefined && softwareVersion !== null){
//       softwareVersion = "Software Version " + softwareVersion.replace("AccuRate Enterprise ","")
//     }else{
//       this.getSoftwareVersion();
//     }
//     return (
//       <Fragment>
//         <div className={showFooter ? "" : "hideAppFooter"}>
//           <div className="app-footer">
//             <div className="app-footer__inner">
//               <div className="app-footer-left">
//                 <div className="footer-dots">
//                   <a href="/Software as a Service Agreement (for Direct Users of AccuRate Enterprise) Version 1.0 150724.pdf" target="_blank">
//                     Terms of Service
//                   </a>
//                   <div className="dots-separator"></div>
//                   <a href="/AccuRate Website Terms Version 1.0 150724.pdf" target="_blank">
//                     Website Terms
//                   </a>
//                   <div className="dots-separator"></div>
//                   <a href="/Privacy Collection Notice Version 1.0 150724.pdf" target="_blank">
//                     Privacy Collection Notice
//                   </a>
//                   <div className="dots-separator"></div>
//                   <a href="https://www.csiro.au/en/about/Policies/Privacy" target="_blank">
//                     Privacy Policy
//                   </a>
//                 </div>
//               </div>
//               <div className="app-footer-right">
//                 © 2023-{new Date().getFullYear()} CSIRO, the AccuRate Enterprise team, {softwareVersion}
//               </div>
//             </div>
//           </div>
//         </div>
//       </Fragment>
//     );
//   }
// }

export default connectAppStore(AppFooter);
