class CookiesManager {

    getCookies(tableCookiesId, defaultCookiesObject) {
        const storageDicItemId = '3be58d90-e946-4514-9300-45dcdb39157a_storage';
        let storageDic = {};
        const storageDicJson = sessionStorage.getItem(storageDicItemId);
        if(storageDicJson === null){
            storageDic[tableCookiesId] = defaultCookiesObject;
            sessionStorage.setItem(storageDicItemId, JSON.stringify(storageDic));
            return defaultCookiesObject;
        }else{
            storageDic = JSON.parse(storageDicJson);
            const result = storageDic[tableCookiesId];
            if(result !== undefined) return result;
            storageDic[tableCookiesId] = defaultCookiesObject;
            sessionStorage.setItem(storageDicItemId, JSON.stringify(storageDic));
            return defaultCookiesObject;
        }
    }

    setCookies(tableCookiesId, cookiesObject) {
        const storageDicItemId = '3be58d90-e946-4514-9300-45dcdb39157a_storage';
        let storageDic = {};
        const storageDicJson = sessionStorage.getItem(storageDicItemId);
        if(storageDicJson === null){
            storageDic[tableCookiesId] = cookiesObject;
            sessionStorage.setItem(storageDicItemId, JSON.stringify(storageDic));
        }else{
            storageDic = JSON.parse(storageDicJson);
            storageDic[tableCookiesId] = cookiesObject;
            sessionStorage.setItem(storageDicItemId, JSON.stringify(storageDic));
        }
    }

    getAllCookies(){
        const storageDicItemId = '3be58d90-e946-4514-9300-45dcdb39157a_storage';
        let storageDic = {};
        const storageDicJson = sessionStorage.getItem(storageDicItemId);
        if(storageDicJson === null){
            sessionStorage.setItem(storageDicItemId, JSON.stringify(storageDic));
            return storageDic;
        }else{
            storageDic = JSON.parse(storageDicJson);
            return storageDic;
        }
    }
}
const cookiesManager = new CookiesManager();
export default cookiesManager;