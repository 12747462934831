import { useEffect } from "react";
import usePrevious from "./UsePrevious";

function useEffectWithPrevious(action, dependencies){
    const previous = usePrevious(dependencies);
    useEffect(()=>{
        action(previous);
    }, dependencies);
}

export default useEffectWithPrevious;