import React, { Fragment, useState } from "react";
import { connectAppStore, getAppStore } from "./Store/AppStore";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import HeaderAccessor from "./Layout/Header/index";
import Footer from "./Layout/Footer/index";
import SideBar from "./Layout/SideBar/index";
import "./Layout/Layout.css";

const Layout =(props)=>{
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const theme = appStoreState.appThemeReducer;
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
      showSideBar,
      showHeader,
    } = theme;

    return (
      <Fragment>
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              id="appLayoutDiv"
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader && showHeader },
                { "fixed-sidebar": (enableFixedSidebar || width < 1250) && showSideBar },
                { "fixed-footer": enableFixedFooter && showSideBar  },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                { "closed-sidebar-mobile": closedSmallerSidebar || width < 1250, },
                { "sidebar-mobile-open": enableMobileMenu && showSideBar },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}
            >
              <HeaderAccessor />         
              <div className="app-main">
                <SideBar/>       
                <div className="app-main__outer">
                  <div className="app-main__inner">{props.children}</div>
                  <Footer></Footer>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      />
      </Fragment>
    );

}

export default connectAppStore(Layout);
