
import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";

const InfiltrationPenetrationDataTable = (props) => {
    const {
        tryCatchFinally,
        stringCoding,
        layoutManager,
        getData,
        guid,
        toastPrint,
        useLocation,
        Fragment, 
        useState,
        useNavigate,
        dataExtension,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const emptyGuid = guid.emptyGuid()
    var tableId = props.id === undefined ? emptyGuid : props.id;
    const redirectPathHex = stringCoding.encode(useLocation().pathname);
    //const externalWallId = props.externalWallId;
    const elementId = props.elementId;
    const elementType = props.elementType;
    const designVariationVersionName = props.designVariationVersionName;
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultViewModel = {
        designVariationVersionName: designVariationVersionName,
        selectedItemIds: [elementId],
        elementInType: elementType
    };
    const [extraPostModel, setExtraPostModel] = useState(defaultViewModel);
    const [data, setData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    //change model get url here if not using default model
    const getUrl = "api/Element/Accessor/GetInfiltrationPenetrations";
    const [tableReload, setTableReload] = useState("");
    const [modal, setModal] = useState({
        isOpen: false,
        title: "",
        message: "",
        comfirmButtonStyle: "",
        comfirmButtonText: "Comfirm",
        handleComfirm: () => {},
    });

    const nav = useNavigate();
    const columns = [
        { name: "name", text: "Name", width: "70%" },
        { name: "quantity", text: "Quantity", width: "30%" }
    ];

    const generateUrlParams = (elementId) => {
        let urlElementType;
        let elementInType = defaultViewModel.elementInType;
        switch (elementInType) {
            case "ExternalWall":
                urlElementType = "InfiltrationPenetrationInExternalWall";
                break;
            case "Roof":
                urlElementType = "InfiltrationPenetrationInRoof";
                break;
            case "Ceiling":
                urlElementType = "InfiltrationPenetrationInCeiling";
                break;
            default:
                urlElementType = "InfiltrationPenetrationInExternalWall";
        }
        return "/ElementAccessor/Detail/" + urlElementType + "/" + props.designVariationVersionName + "/" + props.developmentId + "/" + props.projectId + "/" + props.designVariationId + "/" + props.zoneId + "/" + elementId + "/" + props.elementId + "/" + redirectPathHex;
    }

    const handleDeleteComfirm = async () => {
        await tryCatchFinally.asyncProcess(async ()=>{
          const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
          setLoading({isLoading:true,text:"Deleting elements"});
          const selectResponse = await getData.processPost(
          true,
          "api/Element/Accessor/DeleteElements",
              {selectedItemDic: selectedItemDic, designVariationVersionName: props.designVariationVersionName, projectId: props.projectId, designVariationId: props.designVariationId}
          ); 
        toastPrint.printResponse(selectResponse);
        setTableReload(guid.newGuid());
        }, setLoading);
      }

      const actionOnDoubleClickRow = (model, newData) =>{
        nav(generateUrlParams(model.id));
      }

    const handleDuplicatedComfirm = async () => {
      await tryCatchFinally.asyncProcess(async ()=>{
        const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
        setLoading({isLoading:true,text:"Duplicating elements"});
        const selectResponse = await getData.processPost(
        true,
        "api/Element/Accessor/CopyElements",
            {selectedItemDic: selectedItemDic, designVariationVersionName: props.designVariationVersionName, projectId: props.projectId, designVariationId: props.designVariationId, parentId: props.elementId, parentType: elementType}
        ); 
      toastPrint.printResponse(selectResponse);
      setTableReload(guid.newGuid());
      }, setLoading);
    }

    const contextMenu = {
        items: {
          new: {
            name: "New",
            icon: "add",
            defaultAction: true,
            url: generateUrlParams(emptyGuid),
            isNavOption: true
          },
          separator: "---------",
          edit: {
              name: "Edit",
              icon: "edit",
              defaultAction: false,
                disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length === 0;
              },
            },
            delete: {
              name: "Delete",
              icon: "delete",
              url: "",
              defaultAction: false,
              disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length === 0;
              },
            },
            duplicate: {
              name: "Duplicate",
              icon: "copy",
              url: "",
              defaultAction: false,
              disabled: function () {
                return dataExtension.getSelectedRowDataIds(data).length === 0;
              },
            }
        },
        callBack: (
          contextMenuCallBackKey,
          contextMenuCallBackElement,
          contextMenuCallBackTableRoadData,
          localtion
        ) => {   
          if (contextMenuCallBackKey === "edit") {
            const selectedElementIds = dataExtension.getSelectedRowDataIds(data);
            if(selectedElementIds.length == 1){
               nav(generateUrlParams(selectedElementIds[0]));
            }else{
              const urlParams = elementType + "/" + props.designVariationVersionName + "/" + props.developmentId + "/" + props.projectId + "/" + props.designVariationId + "/" + props.zoneId + "/" + stringCoding.encodeObject(selectedElementIds) + "/" + redirectPathHex;
              nav("/ElementAccessor/MultipleDetail/InfiltrationPenetration/" + urlParams);
            }
            return;
         }
          if (contextMenuCallBackKey === "delete"){
            setModal({
              ...modal,
              isOpen: true,
              title: "Delete elements",
              message:
                "Are sure to delete " +
                dataExtension.getSelectedRowDataIds(data).length +
                " selected elments?",
              comfirmButtonStyle: "danger",
              comfirmButtonText: "Confirm to delete",
              handleComfirm: handleDeleteComfirm,
            });
          }
          if (contextMenuCallBackKey === "duplicate") {
            setModal({
              ...modal,
              isOpen: true,
              title: "Duplicate elements",
              message:
                "Are sure to duplicate " +
                dataExtension.getSelectedRowDataIds(data).length +
                " selected elements?",
              comfirmButtonStyle: "primary",
              comfirmButtonText: "Confirm to duplicate",
              handleComfirm: handleDuplicatedComfirm,
            });
          }
        },
      };

    return (
        <Fragment>
            {debug && <p>InfiltrationPenetrationDataTable</p>}
            {debug && <p>{JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
            {debug && <p>{JSON.stringify(extraPostModel)}</p>}
            {debug && <p>redirect Url: {stringCoding.decode(redirectPathHex)}</p>}
            {debug && <p>{JSON.stringify(elementType)}</p>}
            <ModalDatatableItem
            modal={modal}
            setModal={setModal}
            ></ModalDatatableItem>
            {elementType === 'ExternalWall' &&(
                <ServerSideDataTable
                    title="Users"
                    id={tableId}
                    location="ExternalWallInfiltrationPenetrationElement"
                    data={data}
                    setData={setData}
                    reload={tableReload}
                    columns={columns}
                    actionOnDoubleClickRow = {actionOnDoubleClickRow}
                    allowSelect
                    allowSelectMultiple
                    dataSource={{ isAuth: true, url: getUrl }}
                    contextMenu={contextMenu}
                    extraPostModel={extraPostModel}
                    setSelectedItemIds = {setSelectedIds}
                    removePaging ={true}
                    removeSearch ={true}
                    disableSorting
              ></ServerSideDataTable>
            )}
            {elementType === 'Roof' && (
                <ServerSideDataTable
                    id={tableId}
                    location="RoofInfiltrationPenetrationElement"
                    data={data}
                    setData={setData}
                    reload={tableReload}
                    columns={columns}
                    actionOnDoubleClickRow = {actionOnDoubleClickRow}
                    allowSelect
                    allowSelectMultiple
                    dataSource={{ isAuth: true, url: getUrl }}
                    contextMenu={contextMenu}
                    extraPostModel={extraPostModel}
                    setSelectedItemIds = {setSelectedIds}
                    removePaging ={true}
                    removeSearch ={true}
                    disableSorting
              ></ServerSideDataTable>
            )}
            {elementType === 'Ceiling' && (
                <ServerSideDataTable
                    id={tableId}
                    location="CeilingInfiltrationPenetrationElement"
                    data={data}
                    setData={setData}
                    reload={tableReload}
                    columns={columns}
                    actionOnDoubleClickRow={actionOnDoubleClickRow}
                    allowSelect
                    allowSelectMultiple
                    dataSource={{ isAuth: true, url: getUrl }}
                    contextMenu={contextMenu}
                    extraPostModel={extraPostModel}
                    setSelectedItemIds={setSelectedIds}
                    removePaging={true}
                    removeSearch={true}
                    disableSorting
                ></ServerSideDataTable>
            )}
        </Fragment>
    );
}
export default connectAppStore(InfiltrationPenetrationDataTable);