import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";

const MultipleRoofsDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    let zoneId = useParams().zoneId;
    if (zoneId === 'undefined') zoneId = emptyGuid;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedElementIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedElementIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateRoofs";
    const getUrl = "api/Element/Accessor/GetRoofs";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading roofs", appStoreDispatch);
    },[]);

    const calculateAzimuth = (value) =>{
        if(value === undefined ) value = 0;
        if(value === null ) value = 0;
        if(value === "" ) value = 0;
        value = Number(value);
        const directions = ["North", "Northeast", "East", "Southeast", "South", "Southwest", "West", "Northwest"];
        const index = Math.round(value / 45) % 8;
        return directions[index];
      }

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="FloorCeilingFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "Roofs detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="Name">Name *</label>
                        <BindedInput
                            className="form-control"
                            id="Name"
                            name="Name"
                            type="text"
                            binding={[viewModel, "name", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="ZoneId">Zone *</label>
                        <BindedSelect
                            className="form-control"
                            id="ZoneId"
                            name="ZoneId"
                            options="zones"
                            binding={[viewModel, "zoneId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
                </div>
                <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                        <label htmlFor="RoofConstructionId">
                            Construction *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="RoofConstructionId"
                            name="RoofConstructionId"
                            options="constructions"
                            binding={[
                            viewModel,
                            "constructionId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
                </div>
                <div className="form-row row">
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="RoofGrossArea">
                            Area (m<sup>2</sup>) *
                        </label>
                        <BindedInput
                            className="form-control"
                            min="0"
                            id="RoofGrossArea"
                            name="RoofGrossArea"
                            type="number"
                            binding={[viewModel, "grossArea", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="RoofAzimuth">
                            Azimuth{" "} *
                            <span>
                            (
                            {viewModel.azimuth === "" ||
                            viewModel.azimuth === null ||
                            viewModel.azimuth === undefined
                                ? "0"
                                : viewModel.azimuth}
                            </span>
                            <sup>o</sup>{" "}
                            <span>{calculateAzimuth(viewModel.azimuth)})</span>
                        </label>
                        <BindedInput
                            className="form-control"
                            min="0"
                            max="360"
                            id="RoofAzimuth"
                            name="RoofAzimuth"
                            type="number"
                            binding={[viewModel, "azimuth", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="RoofPitch">
                            Pitch (degrees) *
                        </label>
                        <BindedInput
                            className="form-control"
                            min="0"
                            max="360"
                            id="RoofPitch"
                            name="RoofPitch"
                            type="number"
                            binding={[viewModel, "pitch", bindingViewModel]}
                        ></BindedInput>
                    </div>
                </div>
                <div className="col-3">
                    <div className="position-relative form-group">
                        <label htmlFor="RoofExposureTypeId">
                            Exposure *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="RoofExposureTypeId"
                            name="RoofExposureTypeId"
                            options="exposureTypes"
                            binding={[viewModel, "exposureTypeId", bindingViewModel]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleRoofsDetail);