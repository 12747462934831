import { Fragment } from "react";
import PageDependencies from "../../../Feature/PageDependencies";
const ConstructionElementDataTable = (props)=>{
    const debug = props.debug === undefined ? false : props.debug;
    const { 
        tryCatchFinally,
        stringCoding,
        getData,
        guid,
        toastPrint,
        useNavigate,
        useState,
        dataExtension,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const emptyGuid = guid.emptyGuid();
    const nav = useNavigate();
    const [modal, setModal] = useState({
      isOpen: false,
      title: "",
      message: "",
      comfirmButtonStyle: "",
      comfirmButtonText: "Comfirm",
      handleComfirm: () => {},
    });
    const [loading, setLoading] = useState({isLoading:false,text:""});
    const [data, setData] = useState([]);
    const tableId = props.tableId;
    const constructionType = props.constructionType;
    const redirectUrl = props.redirectUrl === undefined ? "0" : props.redirectUrl;
    const tableReload = props.tableReload;
    const setTableReload = props.setTableReload;
    const extraPostModel = props.extraPostModel;
    const setSelectedIds = props.setSelectedIds;
    const designVariationVersionName = extraPostModel.designVariationVersionName;
    const developmentId = extraPostModel.developmentId;
    const projectId = extraPostModel.projectId;
    const designVariationId = extraPostModel.designVariationId;
    const columns = [
        { name: "name", text: "Name", width: "75%" },
        { name: "area", text: "Area", width: "25%" }
      ];

    const generateBaseUrl = (type, isMultiple = false)=>{
        const multiple =  isMultiple ? "Multiple" : "";
        const url = "/ConstructionAccessor/Detail" + multiple + "/" + type + "/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/" + emptyGuid + "/" + stringCoding.encode(redirectUrl);
        return url;
    }

    const contextMenu = {
      items: {
        new: {
          name: "New",
          icon: "add",
          url: generateBaseUrl(constructionType, false),
          defaultAction: true
        },
        separator: "---------",
        edit: {
            name: "Edit",
            icon: "edit",
            defaultAction: false,
              disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length !== 1;
            },
          },
          delete: {
            name: "Delete",
            icon: "delete",
            url: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          },
          duplicate: {
            name: "Duplicate",
            icon: "copy",
            url: "",
            defaultAction: false,
            disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length === 0;
            },
          },
      },
      callBack: (
        contextMenuCallBackKey,
        contextMenuCallBackElement,
        contextMenuCallBackTableRoadData,
        localtion
      ) => {
        if (contextMenuCallBackKey === "edit") {
          const selectedZoneIds = dataExtension.getSelectedRowDataIds(data);
          if(selectedZoneIds.length == 1){
             nav("/ConstructionAccessor/Detail/" + constructionType + "/" + designVariationVersionName + "/" + developmentId + "/"+ projectId + "/" + designVariationId + "/" + selectedZoneIds[0] + "/" + stringCoding.encode(redirectUrl));
          }else{
             nav("/ConstructionAccessor/MultipleDetail/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/" + stringCoding.encodeObject(selectedZoneIds));
          }
        }
          if (contextMenuCallBackKey === "delete") {
              setModal({
                  ...modal,
                  isOpen: true,
                  title: "Delete constructions",
                  message:
                      "Are sure to delete " +
                      dataExtension.getSelectedRowDataIds(data).length +
                      " selected constructions?",
                  comfirmButtonStyle: "danger",
                  comfirmButtonText: "Confirm to delete",
                  handleComfirm: handleDeleteComfirm,
              });
          }
          if (contextMenuCallBackKey === "duplicate") {
              setModal({
                  ...modal,
                  isOpen: true,
                  title: "Duplicate constructions",
                  message:
                      "Are sure to duplicate " +
                      dataExtension.getSelectedRowDataIds(data).length +
                      " selected constructions?",
                  comfirmButtonStyle: "primary",
                  comfirmButtonText: "Confirm to duplicate",
                  handleComfirm: handleDuplicatedComfirm,
              });
          }
      },
    };

    const actionOnDoubleClickRow = (model, newData) =>{
      nav("/ConstructionAccessor/Detail/" + model.type.replace(" ","").replace("/","") + "/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/" + model.id + "/" + stringCoding.encode(redirectUrl));
    }

    const handleDeleteComfirm = async () => {
        await tryCatchFinally.asyncProcess(async () => {
            const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
            setLoading({ isLoading: true, text: "Deleting constructions" });
            const selectResponse = await getData.processPost(
                true,
                "api/Construction/Accessor/DeleteConstructions",
                { selectedItemDic: selectedItemDic, designVariationVersionName: designVariationVersionName, projectId: projectId, developmentId: developmentId }
            );
            toastPrint.printResponse(selectResponse);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    const handleDuplicatedComfirm = async () => {
        await tryCatchFinally.asyncProcess(async () => {
            const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
            setLoading({ isLoading: true, text: "Duplicating constructions" });
            const selectResponse = await getData.processPost(
                true,
                "api/Construction/Accessor/CopyConstructions",
                { selectedItemDic: selectedItemDic, designVariationVersionName: designVariationVersionName, projectId: projectId, developmentId: developmentId }
            );
            toastPrint.printResponse(selectResponse);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    return(<Fragment>
        {debug && <p>{JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
            <ModalDatatableItem
                modal={modal}
                setModal={setModal}
            ></ModalDatatableItem>
           <ServerSideDataTable
                id={tableId}
                location="Constructions"
                data={data}
                setData={setData}
                reload={tableReload}
                columns={columns}
                actionOnDoubleClickRow = {actionOnDoubleClickRow}
                allowSelect
                allowSelectMultiple
                dataSource={{ isAuth: true, url: "api/Construction/Accessor" }}
                contextMenu={contextMenu}
                extraPostModel={extraPostModel}
                setSelectedItemIds = {setSelectedIds}
            >
            </ServerSideDataTable>
    </Fragment>);
}
export default ConstructionElementDataTable;