import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import SkylightRoofWindowLibTree from "../SkylightRoofWindowLibTree";

const SkylightRoofWindowConstructionDetail = (props) => {
    const { 
        Fragment, 
        useEffect, 
        useState,
        stringCoding,
        layoutManager,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        FormValidate,
        dataExtension
    } = PageDependencies();
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const constructionId = useParams().constructionId;
    const designVariationVersionName = useParams().designVariationVersionName;
    const defaultViewModel = {
        id:constructionId,
        projectId: projectId,
        typeName: props.typeName,
        designVariationId: designVariationId,
        designVariationVersionName: designVariationVersionName,
        developmentId: developmentId,
        constructionId: constructionId,
        selectedItemIds:[constructionId],
        category:"Glazed",
        constructionTypeName: "Loading",
        isLibrary: props.isLibrary === true
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Construction/Accessor/Update";
    const getUrl = "api/Construction/Accessor/Get";
    const [init, setInit] = useState(false);

    const validateMethods = {
        name:(value)=>{ return FormValidate.validateTextRequired(value, "Construction name"); },
        frameAbsorptance:(value)=>{ return FormValidate.validateNumberBetween(value, "Frame solar absorptance", 0 , 100); },
        systemCode:(value)=>{ return value === undefined || value === "" || value === null ? "Select a " + props.typeName.toLowerCase() + " from the tree" : ""; },
        frameColourTypeId:(value)=>{ 
          const newValue = value == "" ? -999 : Number(value);
          return newValue == -999 ? "Selecte a frame color" : "";
         },
        frameTypeId:(value)=>{ 
            const newValue = value == "" ? -999 : Number(value);
            return newValue == -999 ? "Selecte a frame type" : "";
        }
      };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(),false);

    useEffect(()=>{
        try {
          if(!init) return;
          const selectedColor = dataExtension.getSelectOptionByValue(viewModel.frameColourTypes, viewModel.frameColourTypeId);
          let absorptance = 0;
          if(selectedColor.text === "Dark"){
            absorptance = 85;
          }
          if(selectedColor.text === "Medium"){
            absorptance = 50;
          }
          if(selectedColor.text === "Light"){
            absorptance = 30;
          }
          if(absorptance > 0){
            dispatchViewModel({...viewModel, frameAbsorptance: absorptance});
          }
        }catch{}
      }, [viewModel.frameColourTypeId]);

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading construction", appStoreDispatch, (newViewModel)=>{
          dispatchViewModel({...newViewModel, category: "Glazed", typeName: props.typeName});
        }, setInit);
    },[]);

    return(
    <Fragment>
         {layoutManager.Layout_GetDebugModel(props) &&  <p>{JSON.stringify(viewModel)}</p>}
         <BaseForm
            id="DesignVariationFrom"
            icon="pe-7s-safe"
            loading={loading}
            init={init}
            setLoading={setLoading}
            submitButton={{ postUrl: postUrl + viewModel.category, redirectUrl: redirectUrl}}
            cancelButton={{ redirectUrl: redirectUrl }}
            data={viewModel}
            validate ={formValidate}
            title = {viewModel.constructionTypeName + " Construction"} 
            subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                  <div className="col-12">
                      <div className="position-relative form-group">
                      <label htmlFor="Name">Name *</label>
                          <BindedInput validate={formValidate} className="form-control valid" id="Name" name="Name" type="text" binding={[viewModel, "name", bindingViewModel]}></BindedInput>
                      </div>
                  </div>
              </div>
              <div className="form-row row">
                  <div className="col-12">
                      <div className="position-relative form-group">
                          <label htmlFor="SystemCode">System Code *</label>           
                          <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} validate={formValidate} disabled id="SystemCode" name="SystemCode" binding={[viewModel, "systemCode", bindingViewModel]}></BindedInput>                
                      </div>
                  </div>
              </div>
              <div className="form-row row">
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="GlazedConstructionFrameColourTypeId">Frame colour *</label>           
                          <BindedSelect className="form-control" id="GlazedConstructionFrameColourTypeId" name="GlazedConstructionFrameColourTypeId" validate={formValidate} binding={[viewModel, "frameColourTypeId", bindingViewModel]} options="frameColourTypes"></BindedSelect>                
                      </div>
                  </div>
                  <div className="col-6">
                      <div className="position-relative form-group">
                          <label htmlFor="GlazedConstructionFrameAbsorptance">Frame absorptance (%) *</label>           
                          <BindedInput className="form-control valid" id="GlazedConstructionFrameAbsorptance" name="GlazedConstructionFrameAbsorptance" validate={formValidate} type="number" binding={[viewModel, "frameAbsorptance", bindingViewModel]}></BindedInput>       
                      </div>
                  </div>
              </div>   
              {viewModel.typeName === "Skylight" && 
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                            <label htmlFor="GlazedConstructionFrameTypeId">Frame type *</label>           
                            <BindedSelect className="form-control" id="GlazedConstructionFrameTypeId" name="GlazedConstructionFrameTypeId" validate={formValidate} binding={[viewModel, "frameTypeId", bindingViewModel]} options="frameTypes"></BindedSelect>          
                        </div>
                    </div>
                </div> 
              }

              {viewModel.typeName === "RoofWindow" && 
                <div className="form-row row">
                    <div className="col-6">
                        <div className="position-relative form-group">
                        <label htmlFor="UValue">U-value *</label>           
                            <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} validate={formValidate} disabled id="UValue" name="UValue" binding={[viewModel, "uValue", bindingViewModel]}></BindedInput>  
                        </div>
                    </div>
                    <div className="col-6">
                    <div className="position-relative form-group">
                        <label htmlFor="SHGC">SHGC *</label>           
                            <BindedInput className="form-control valid" style={{border:"none", backgroundColor:"white"}} validate={formValidate} disabled id="SHGC" name="SHGC" binding={[viewModel, "shgc", bindingViewModel]}></BindedInput>  
                        </div>
                    </div>
                </div>  
              }
    
        </BaseForm>
        {init === true && 
            <div className="app-main__inner">
            <div className="row">
                <div className="col-12">
                    <div id="ConstructionLayerAccordion">
                        <div className="main-card mb-3 card">
                            <div className="card-header">
                                <button type="button" className="text-left m-0 p-0 btn btn-block">
                                    <h5 className="m-0 p-0 card-title">{viewModel.constructionTypeName === undefined ? "" : viewModel.constructionTypeName + "s"}</h5>
                                </button>
                            </div>
                            <div className="card-body">
                                <SkylightRoofWindowLibTree
                                    id="SkylightRoofWindowTree-d3f7783d-4824-4d08-abdf-243b5ceaa1f5"
                                    typeName = {props.typeName}
                                    setViewModel = {dispatchViewModel}
                                    viewModel = {viewModel}
                                >
                                </SkylightRoofWindowLibTree>
                            </div>
                        </div>
                    </div>
                </div>
            </div>       
        </div>  
        }

    </Fragment>);
}
export default connectAppStore(SkylightRoofWindowConstructionDetail);