
import PageDependencies from "../../../Feature/PageDependencies";
const ShadingSchemeDataTable = (props)=>{
  const debug = props.debug === undefined ? false : props.debug;
    const { 
        tryCatchFinally,
        stringCoding,
        getData,
        guid,
        Fragment, 
        useState,
        toastPrint,
        useNavigate,
        dataExtension,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const emptyGuid = guid.emptyGuid();
    const nav = useNavigate();
    const [modal, setModal] = useState({
      isOpen: false,
      title: "",
      message: "",
      comfirmButtonStyle: "",
      comfirmButtonText: "Comfirm",
      handleComfirm: () => {},
    });
    const [loading, setLoading] = useState({isLoading:false,text:""});
    const [data, setData] = useState([]);
    const tableId = "55c767f3-f6ea-4061-9eae-b2714b15d9fb";
    const tableReload = props.tableReload;
    const redirectUrl = props.redirectUrl;
    const setTableReload = props.setTableReload;
    const extraPostModel = props.extraPostModel;
    const setSelectedIds = props.setSelectedIds;
    const designVariationVersionName = extraPostModel.designVariationVersionName;
    const developmentId = extraPostModel.developmentId;
    const projectId = extraPostModel.projectId;
    const designVariationId = extraPostModel.designVariationId;
    const columns = [
        { name: "type", text: "Type", width: "25%" },
        { name: "name", text: "Name", width: "75%" }
      ];

    const generateBaseUrl = (type, isMultiple = false)=>{
        const multiple =  isMultiple ? "Multiple" : "";
        return "/ShadingAccessor/Detail" + multiple + "/" + type + "/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/"  + emptyGuid + "/" + stringCoding.encode(redirectUrl);
    }

    const contextMenu = {
      items: {
        new: {
          name: "New",
          icon: "add",
          items: {
            createHorizontal: {
                name: "Horizontal Shading",
                icon: "",
                url: generateBaseUrl("Horizontal", false),
                isNavOption: true
              },
            createVertical: {
              name: "Vertical Shading",
              icon: "",
              url: generateBaseUrl("Vertical", false),
              isNavOption: true
            }
          },
        },
        separator: "---------",
        edit: {
            name: "Edit",
            icon: "edit",
            defaultAction: false,
              disabled: function () {
              return dataExtension.getSelectedRowDataIds(data).length !== 1;
            },
        },
        delete: {
            name: "Delete",
            icon: "delete",
            url: "",
            defaultAction: false,
            disabled: function () {
            return dataExtension.getSelectedRowDataIds(data).length === 0;
        },
        },
        duplicate: {
            name: "Duplicate",
            icon: "copy",
            url: "",
            defaultAction: false,
            disabled: function () {
            return dataExtension.getSelectedRowDataIds(data).length === 0;
        },
        }
      },
      callBack: (
        contextMenuCallBackKey,
        contextMenuCallBackElement,
        contextMenuCallBackTableRoadData,
        localtion
      ) => {
        if (contextMenuCallBackKey === "edit") {
          const selectedZoneIds = dataExtension.getSelectedRowDataIds(data);
          if(selectedZoneIds.length == 1){
              nav("/ShadingAccessor/Detail/" + dataExtension.getSelectedRows(data)[0].type.replace(" ", "").replace("/", "") + "/" + designVariationVersionName + "/" + developmentId + "/"+ projectId + "/" + designVariationId + "/" + selectedZoneIds[0] + "/" + stringCoding.encode(redirectUrl));
          }else{
             nav("/ShadingAccessor/MultipleDetail/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/" + stringCoding.encodeObject(selectedZoneIds));
          }
       }
       if (contextMenuCallBackKey === "delete"){
          setModal({
            ...modal,
            isOpen: true,
            title: "Delete shadings",
            message:
              "Are sure to delete " +
              dataExtension.getSelectedRowDataIds(data).length +
              " selected shadings?",
            comfirmButtonStyle: "danger",
            comfirmButtonText: "Confirm to delete",
            handleComfirm: handleDeleteComfirm,
          });
        }
        if (contextMenuCallBackKey === "duplicate") {
            setModal({
                ...modal,
                isOpen: true,
                title: "Duplicate shadings",
                message:
                    "Are sure to duplicate " +
                    dataExtension.getSelectedRowDataIds(data).length +
                    " selected shadings?",
                comfirmButtonStyle: "primary",
                comfirmButtonText: "Confirm to duplicate",
                handleComfirm: handleDuplicatedComfirm,
            });
        }
      },
    };

    const actionOnDoubleClickRow = (model, newData) =>{
      nav("/ShadingAccessor/Detail/" + model.type.replace(" ","").replace("/","") + "/" + designVariationVersionName + "/" + developmentId + "/" + projectId + "/" + designVariationId + "/" + model.id + "/" + stringCoding.encode(redirectUrl)) ;
    }

    const handleDeleteComfirm = async () => {
      await tryCatchFinally.asyncProcess(async ()=>{
        const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
        setLoading({isLoading:true,text:"Deleting shadings"});
        const selectResponse = await getData.processPost(
        true,
        "api/Shading/Accessor/DeleteShadings",
            {selectedItemDic: selectedItemDic, designVariationVersionName: designVariationVersionName, projectId: projectId, designVariationId: designVariationId}
        ); 
      toastPrint.printResponse(selectResponse);
      setTableReload(guid.newGuid());
      }, setLoading);
    }

    const handleDuplicatedComfirm = async () => {
        await tryCatchFinally.asyncProcess(async () => {
            const selectedItemDic = dataExtension.getSelectedRowsDic(data, "type");
            setLoading({ isLoading: true, text: "Duplicating shadings" });
            const selectResponse = await getData.processPost(
                true,
                "api/Shading/Accessor/CopyShadings",
                { selectedItemDic: selectedItemDic, designVariationVersionName: designVariationVersionName, projectId: projectId, designVariationId: designVariationId, developmentId: developmentId }
            );
            toastPrint.printResponse(selectResponse);
            setTableReload(guid.newGuid());
        }, setLoading);
    }

    return(<Fragment>
            {debug && <p>Selected items: {JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
            <ModalDatatableItem
              modal={modal}
              setModal={setModal}
            ></ModalDatatableItem>
           <ServerSideDataTable
                id={tableId}
                location="ShadingSchemes"
                data={data}
                setData={setData}
                reload={tableReload}
                columns={columns}
                actionOnDoubleClickRow = {actionOnDoubleClickRow}
                allowSelect
                allowSelectMultiple
                dataSource={{ isAuth: true, url: "api/Shading/Accessor" }}
                contextMenu={contextMenu}
                extraPostModel={extraPostModel}
                setSelectedItemIds = {setSelectedIds}
            >
            </ServerSideDataTable>
    </Fragment>);
}
export default ShadingSchemeDataTable;