import About from "../../Portal/Accessor/Project/About";
import Client from "../../Portal/Accessor/Project/Client";
import Plan from "../../Portal/Accessor/Project/Plan";
import Dwelling from "../../Portal/Accessor/Project/Dwelling";
import Projects from "../../Portal/Accessor/Project/Projects";
import Ventilation from "../../Portal/Accessor/Project/Ventilation";
import AnalysisResult from "../../Portal/Accessor/Project/AnalysisResult";
import AnalysisResultDetail from "../../Portal/Accessor/Project/AnalysisResultDetail";

const AccessorProjectRouters = [
  {
    path: "ProjectAccessor/Projects/:developmentId/:projectId",
    exact: true,
    requireAuth: true,
    element: <Projects />,
  },
  {
    path: "ProjectAccessor/Plan/:projectId",
    exact: true,
    requireAuth: true,
    element: <Plan />,
  },
  {
    path: "ProjectAccessor/Client/:projectId",
    exact: true,
    requireAuth: true,
    element: <Client />,
  },
  {
    path: "ProjectAccessor/Dwelling/:projectId",
    exact: true,
    requireAuth: true,
    element: <Dwelling />,
  },
  {
    path: "ProjectAccessor/Detail/:developmentId/:projectId",
    exact: true,
    requireAuth: true,
    element: <About />,
  },
  {
    path: "VentilationAccessor/Detail/:projectId/:designVariationId",
    exact: true,
    requireAuth: true,
    element: <Ventilation />,
  },
  {
    path: "AnalysisResultAccessor/Analysises/:designVariationVersionName/:projectId",
    exact: true,
    requireAuth: true,
    element: <AnalysisResult />,
  },
  {
    path: "AnalysisResultAccessor/Detail/:designVariationVersionName/:projectId/:designVariationId",
    exact: true,
    requireAuth: true,
    element: <AnalysisResultDetail />,
  },
];
export default AccessorProjectRouters;
