
import React, { Fragment, useEffect, useRef, useState } from 'react';
import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";

const BindedRadio = (props)=>{
    const newProps = {};
    const binding = props.binding;
    const buttonValue = props.buttonValue;
    const buttonLabel = props.buttonLabel;
    const id = props.id;
    const bindingObject = binding[0];
    const bindingProperty = binding[1];
    const bindingMethod = binding[2];
    const bindingValue = objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty);
    const [message, setMessage] = useState("");
    const validate = props.validate;
    const checkbox = useRef();

    for (const prop in props) {
        if (!(prop==='binding') && !(prop==='componentRef') && !(prop==='validate')&& !(prop==='buttonValue')&& !(prop==='buttonLabel')) {
            newProps[prop] = props[prop];
        }
    }

    useEffect(() => {
        if (validate !== undefined) {
            if (validate.setMessages !== undefined) {
                validate.setMessages[1](oldSetMessage => {
                    let newMessages = {};
                    if (oldSetMessage !== undefined) {
                        newMessages = { ...oldSetMessage };
                    }
                    newMessages[bindingProperty] = setMessage;
                    return newMessages;
                });
            }           
        }    
    }, []);

    useEffect(() => {
        checkbox.current.checked = bindingValue === buttonValue;
    }, [bindingValue]);

    const getValidateVisibility = ()=>{
        if(validate === undefined) return false;
        if(validate.validateVisibility === undefined) return false;
        return validate.validateVisibility;
    }

    return <Fragment>
    <input 
        {...newProps} 
        ref={checkbox} 
        type='radio'
        id = {id}
        name = {bindingProperty}
        className='form-check-input'
        onChange={() => { }}
        onClick={(e)=>{  
            const value = buttonValue; 
            const bindingObjectNew = { };
            bindingObjectNew[bindingProperty] = value;           
            bindingMethod(bindingObjectNew, e, value);
            if (validate !== undefined) {
                const validateObject = { ...bindingObject };
                validateObject[bindingProperty] = value;
                validate.checkValidateResult(validateObject);
            }
        }}
        />
         <label className="form-check-label" htmlFor={id}> {buttonLabel} </label>
        {<span id={bindingProperty + "-Validate"} className="field-validation-valid" style={{color:"red"}}>{getValidateVisibility() && message}</span>}
</Fragment>;
}
export default BindedRadio;