import React from "react";
import { FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";
import PageDependencies from "../../../Feature/PageDependencies";
import "./WindowLibTree.css";

const WindowLibTree = (props) => {
  const debug = props.debug === undefined ? false : props.debug;
  const { 
      tryCatchFinally,
      getData,
      guid,
      useReducerSmart,
      BindedInput,
      BindedSelect,
      toastPrint,
      AppLoading,
      useState, 
      Fragment, 
      useEffect,
      cookiesManager
  } = PageDependencies();

    const treeId = props.id === undefined ? guid.newGuid() : props.id;
    const setViewModel = props.setViewModel;
    const viewModel = props.viewModel;
    const defaultTree = {data:flattenTree({})};
    let [loading, setLoading] = useState({isLoading: false, text:""});
    if(props.loading !== undefined){
      loading = props.loading;
    }
    if(props.setLoading !== undefined){
      setLoading = props.setLoading;
    }
    const [treeData, setTreeData] =  useState(defaultTree);
    const [rawData, setRawData] = useState([]);
    const [clickedSpan, setClickedSpan] = useState("");
    const defaultPostModel = cookiesManager.getCookies(treeId, {
      manufacturer: "",
      manufacturerCode: "",
      manufacturerCodes: [],
      minUValue: "",
      maxUValue: "",
      minShgc: "",
      maxShgc: ""
    });
    const [postModel, dispatchPostModel, bindingPostModel] = useReducerSmart(defaultPostModel);
    const windowCategoriesUrl = "api/Construction/Accessor/GetWindowCategoriesTree";
    const windowsTreeUrl = "api/Construction/Accessor/GetWindowsTree";

    const loadCategories = async ()=>{
      setLoading({isLoading: true, text:"Loading windows"});
      await tryCatchFinally.syncProcess(async ()=>{
        const getPostModel = {...postModel};
        if(getPostModel.minUValue === "")getPostModel.minUValue = 0;
        if(getPostModel.maxUValue === "")getPostModel.maxUValue = 0;
        if(getPostModel.minShgc === "")getPostModel.minShgc = 0;
        if(getPostModel.maxShgc === "")getPostModel.maxShgc = 0;
        const selectResponse = await getData.processPost(true, windowCategoriesUrl, {searchModel: getPostModel}); 
         const treeData = {data:flattenTree(selectResponse.data.treeRoot)};
         setTreeData(treeData);
         setRawData(selectResponse.data.data);
      }, setLoading);
    }

    useEffect(()=>{  
      if(postModel.manufacturerCodes !== viewModel.manufacturerCodes){
        const newPostModel = {...postModel, manufacturerCodes: viewModel.manufacturerCodes};
        if(newPostModel.manufacturerCode === undefined || newPostModel.manufacturerCode.length === 0){
          newPostModel.manufacturerCode = viewModel.defaultManufacturerCode;
        }
        dispatchPostModel(newPostModel);
        cookiesManager.setCookies(treeId, newPostModel);
        return;
      }
      const timer = setTimeout(() => {
        if(postModel.manufacturerCodes !== undefined){
          if(postModel.manufacturerCodes.length > 0){
            loadCategories();
            cookiesManager.setCookies(treeId, postModel);
          }
        } 
      }, 800);
      return () => {
        clearTimeout(timer);
      };
    },[postModel, viewModel.manufacturerCodes]);

    useEffect(()=>{      
      setLoading({isLoading: false, text:""});
    },[clickedSpan]);

    const FolderIcon = ({ isOpen }) =>
    isOpen ? (
        <FaRegFolderOpen color="e8a87c" className="icon" />
      ) : (
        <FaRegFolder color="e8a87c" className="icon" />
      );

    const FileIcon = ({ filename }) => {
        //const extension = filename.slice(filename.lastIndexOf(".") + 1);
        return null;
    };

    const onExpand = async (e)=>{
        setLoading({isLoading: true, text:"Loading windows"});
        await setTimeout(async ()=>{
          await tryCatchFinally.syncProcess(async ()=>{
            const isExpanded = e.isExpanded;
            const manufacturer = e.element.name;
            const manufacturerId = e.element.id;
            const childId = e.element.children[0];
            if(isExpanded){
              const childName = treeData.data.filter((w)=>  {return w.id === childId})[0].name;
              if(childName === "Loading"){
                //get windows
                const getPostModel = { ...postModel };
                getPostModel.manufacturer = manufacturer;
                if(getPostModel.manufacturer === undefined && postModel.manufacturer !== undefined && postModel.manufacturer.length > 0){
                  getPostModel.manufacturer = postModel.manufacturer;
                }
                if(getPostModel.minUValue === "")getPostModel.minUValue = 0;
                if(getPostModel.maxUValue === "")getPostModel.maxUValue = 0;
                if(getPostModel.minShgc === "")getPostModel.minShgc = 0;
                if(getPostModel.maxShgc === "")getPostModel.maxShgc = 0;
                const selectResponse = await getData.processPost(true, windowsTreeUrl, {searchModel: getPostModel}); 
                const getWindowsTree = selectResponse.data.treeRoot;
                getWindowsTree.id = manufacturerId;
                getWindowsTree.name = manufacturer;
                const treeDataGet = flattenTree(getWindowsTree).filter((w)=>  {return w.id !== manufacturerId});
                //remove loading child
                const newData = treeData.data.filter((w)=>  {return w.id !== childId});
                let finalData = [];
                for(let i = 0; i < newData.length; i ++){
                  if(newData[i].id === manufacturerId){
                    newData[i].children = getWindowsTree.children.map((w)=>{return w.id});
                    finalData.push(newData[i]);
                    finalData = [...finalData, ...treeDataGet];
                  }else{
                    finalData.push(newData[i]);
                  }
                }
                setTreeData({...treeData, data: finalData});
                const newRawData = [...rawData, ...selectResponse.data.data];
                setRawData(newRawData);
              }
            }
            setClickedSpan(guid.newGuid());
         });
        }, 500);
    }

    const onSelect = (e)=>{
      try{
        const windowName = e.element.name;
        const windowId = e.element.id;
        const isSelected = e.isSelected;
        const childrenCount= e.element.children.length;
        if(windowName !== "Loading" && childrenCount === 0 && isSelected){
          const window = rawData.filter((w)=>{return w.id === windowId})[0]; 
          const newViewModel = {...viewModel, systemCode: window.systemCode, uValue: window.uValue, shgc: window.shgc, windowName: window.name};
          setViewModel(newViewModel);
        }
      }
      catch
      {
        toastPrint.printErrorMessage("error occurred when selecting window.");
        loadCategories();
      }
    }

    return(<Fragment>
         {debug && <p>{JSON.stringify(postModel)}</p>}
         {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
         <div className="row dataTables_wrapper dt-bootstrap4">
         <div className="col-10">
         <div className="dataTables_filter">
            <label htmlFor="search">
                Search:
                <BindedInput style={{marginRight:"10px"}} className="form-control form-control-sm" id="search" name="search" type="search" binding={[postModel, "manufacturer", bindingPostModel]}></BindedInput>
            </label>
            <label htmlFor="manufacturerCode">
                <BindedSelect style={{marginRight:"10px"}} className="form-control form-control-sm" id="manufacturerCode" name="manufacturerCode" type="text" binding={[postModel, "manufacturerCode", bindingPostModel]} options="manufacturerCodes"></BindedSelect>
            </label>
         </div>
         <div className="dataTables_filter">
          <label htmlFor="minUValue">
                Min UValue:
                <BindedInput style={{marginRight:"10px"}} step="0.1" className="form-control form-control-sm" id="minUValue" name="minUValue" type="number" binding={[postModel, "minUValue", bindingPostModel]}></BindedInput>
              </label>
              <label htmlFor="maxUValue">
                Max UValue:
                <BindedInput style={{marginRight:"10px"}} step="0.1" className="form-control form-control-sm" id="maxUValue" name="maxUValue" type="number" binding={[postModel, "maxUValue", bindingPostModel]}></BindedInput>
              </label>
         </div>
          <div className="dataTables_filter">
              <label htmlFor="minShgc">
                Min SHGC:
                <BindedInput style={{marginRight:"10px"}} step="0.1" className="form-control form-control-sm" id="minShgc" name="minShgc" type="number" binding={[postModel, "minShgc", bindingPostModel]}></BindedInput>
              </label>
              <label htmlFor="maxShgc">
                Max SHGV:
                <BindedInput style={{marginRight:"10px"}} step="0.1" className="form-control form-control-sm" id="maxShgc" name="maxShgc" type="number" binding={[postModel, "maxShgc", bindingPostModel]}></BindedInput>
              </label>
        </div>
         </div>
          <div className="row" id={treeId}>
            {rawData.length === 0 && !loading.isLoading && <h6>No windows found</h6>}
            {loading.isLoading && <h6>Loading windows manufacturers</h6>}
            <div className="directory">
              <TreeView
                onBlur={({ treeState, dispatch }) => {
                  dispatch({
                    type: "DESELECT",
                    id: Array.from(treeState.selectedIds)[0],
                  });
                }}
                onExpand = {(e)=>onExpand(e)}
                data={treeData.data}
                onSelect={(e)=>onSelect(e)}
                aria-label="directory tree"
                nodeRenderer={({
                  element,
                  isBranch,
                  isExpanded,
                  getNodeProps,
                  level,
                }) => (
                  <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                    {isBranch ? (
                      <FolderIcon isOpen={isExpanded} />
                    ) : (
                      <FileIcon filename={element.name} />
                    )}
                    {element.name}
                  </div>
                )}
              />
            </div>
          </div>
         </div>
  
    </Fragment>);
}
export default WindowLibTree;