import defaultProjectSlice from "./DefaultProject";
import appThemeSlice from "./ThemeOptions";

const appSlices = {
    reducer: {
      appThemeReducer: appThemeSlice.reducer,
      defaultProjectReducer: defaultProjectSlice.reducer
    },
  };

export default appSlices;
export const appThemeSliceActions = appThemeSlice.actions;
export const defaultProjectSliceActions = defaultProjectSlice.actions;