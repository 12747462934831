import React, { useState, Fragment, useEffect } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";
import "./ServerSideFancyTree.css";
import guid from "../../Function/guid";
import getData from "../../Function/GetData";
import toastPrint from "../../Feature/ToastPrint";
import SearchBar from "../DataTable/SharedComponent/SearchBar";
import cookiesManager from "../../Function/CookiesManager";
import $ from 'jquery';

const ServerSideFancyTree = (props) => {
  const treeId = props.id === undefined ? guid.newGuid() : props.id;
  const folderSelector = '#' + treeId+' .tree-node__branch';
  const isDropDisabled = props.isDropDisabled === undefined ? false : true;
  const isDragDisabled = props.isDragDisabled === undefined ? false : true;
  const sortingColumns = props.columns === undefined ? [{ name: "0" }] : props.columns;
  const dataSource = props.dataSource;
  const stateForData = useState(props.data === undefined ? [] : props.data);
  const [data, setData] = props.setData === undefined
      ? [stateForData[0], stateForData[1]]
      : [props.data, props.setData];
  const [dataView, setDataView] = useState({data:flattenTree({}),folderIds:[], num :0});
  const defautTableSetting = cookiesManager.getCookies(treeId, {
    search: "",
    expand: false
  });
  const [search, setSearch] = useState(defautTableSetting.search);
  const [isLoading, setIsLoading] = useState(false);
  const timeOut = props.timeOut === undefined ? 800 : props.timeOut;

  async function loadData() {
    const postModel = {
      start: 0,
      length: -1,
      search: { value: search, regex: "" },
      order: [
        {
          column: 0,
          dir: "asc",
        },
      ],
      columns: [
        {
          data: "name",
        },
      ],
      simulationType: props.simulationType
    };
    const expensesResponse = await getData.processPost(
      dataSource.isAuth,
      dataSource.url,
      postModel
    );
    if (!expensesResponse.state) {
      toastPrint.printErrorMessage(
        "Tree view eror: " + expensesResponse.message
      );
    }
    const responseData = expensesResponse.data;
    const rawData = responseData.data;
    const treeRawData = responseData.treeRoot;
    setData(rawData);
    const treeData = {data:flattenTree(treeRawData), num:rawData.length};
    setDataView(treeData);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
      //set cookies
      cookiesManager.setCookies(treeId, {
        search: search
      });
      loadData();
      setIsLoading(false);
    }, timeOut);
    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const handleSearchChange = async (e) => {
    const optionValue = e.currentTarget.value;
    setSearch(optionValue);
  };

  const FolderIcon = ({ isOpen }) =>
    isOpen ? (
      <FaRegFolderOpen color="e8a87c" className="icon" />
    ) : (
      <FaRegFolder color="e8a87c" className="icon" />
    );

  const FileIcon = ({ filename }) => {
    //const extension = filename.slice(filename.lastIndexOf(".") + 1);
    return null;
  };

  const showCell = (element, isBranch, isExpanded, getNodeProps, level) => {
    var html = sortingColumns.map((c, i) => {
      if (i === 0) {
        return (
          <td
                key={i + "_" + guid.newGuid()}
            {...getNodeProps()}
            style={{ paddingLeft: 20 * (level - 1) }}
          >
            {isBranch ? (
              <FolderIcon isOpen={isExpanded} />
            ) : (
              <FileIcon filename={element.name} />
            )}
            {element.name}
          </td>
        );
      }
        return <td key={i + "_" + guid.newGuid()}> </td>;
    });
    return html;
  };

  const generateItem = (element, isBranch, isExpanded, getNodeProps, level) => {
    return (
      <Fragment>
        <table>
          <tbody>
            {isDragDisabled && (
              <tr>
                {showCell(element, isBranch, isExpanded, getNodeProps, level)}
              </tr>
            )}
            {!isDragDisabled && (
              <Draggable
                isDragDisabled={isBranch}
                draggableId={element.id}
                key={element.id}
                index={0}
              >
                {(provided, snapshot) => {
                  return (
                    <tr
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      {showCell(
                        element,
                        isBranch,
                        isExpanded,
                        getNodeProps,
                        level
                      )}
                    </tr>
                  );
                }}
              </Draggable>
            )}
          </tbody>
        </table>
      </Fragment>
    );
  };

  const opterateFolders = (opt)=>{
    const folders = $(folderSelector);
      for(let i=0;i<folders.length;i++){
        const folderClass = folders[i].getAttribute("class");
        if(opt==='expand' && !folderClass.includes('tree-node--expanded'))  
        {
          folders[i].click();
        }
        if(opt==='collapse' && folderClass.includes('tree-node--expanded'))  
        {
          folders[i].click();
        }
      }
  }

  return (
    <Fragment>
      <div className="row dataTables_wrapper dt-bootstrap4">
        <div className="col-6">
        <SearchBar
          search={search}
          isLeft={true}
          handleSearchChange={handleSearchChange}
        ></SearchBar>
        </div>
        <div className="col-6">
          <button onClick={()=>opterateFolders('expand')} style={{ marginRight:25}} className="btn btn-primary">Expand</button>
          <button onClick={()=>opterateFolders('collapse')} className="btn btn-primary">Collapse</button>
      </div>
      </div>
      <div className="row" id={treeId}>
        <div className="directory">
          <Droppable isDropDisabled={isDropDisabled} droppableId={treeId}>
            {(providedDrop, snapshot) => {
              return (
                <Fragment>
                  {isLoading && <div>Loading...</div>}
                  {dataView.num ===0 && <div>No items in the tree.</div>}
                  {!isLoading && (
                    <div>
                      <TreeView
                        {...providedDrop.droppableProps}
                        ref={providedDrop.innerRef}
                        data={dataView.data}
                        aria-label="directory tree"
                        onBlur={({ treeState, dispatch }) => {
                          dispatch({
                            type: "DESELECT",
                            id: Array.from(treeState.selectedIds)[0],
                          });
                        }}
                        nodeRenderer={({
                          element,
                          isBranch,
                          isExpanded,
                          getNodeProps,
                          level,
                        }) => {
                          return generateItem(
                            element,
                            isBranch,
                            isExpanded,
                            getNodeProps,
                            level
                          );
                        }}
                      />
                      <table>
                        <tbody>{providedDrop.placeholder}</tbody>
                      </table>
                    </div>
                  )}
                </Fragment>
              );
            }}
          </Droppable>
        </div>
      </div>
    </Fragment>
  );
};

export default ServerSideFancyTree;
