import { connectAppStore } from "../../../Store/AppStore";
import DevelopmentDataTable from "./DevelopmentDataTable";
import ProjectDataTable from "../Project/ProjectDataTable";
import PageDependencies from "../../../Feature/PageDependencies";

const DevelopmentAccessor = (props) => {
  const { 
    layoutManager,
    Fragment, 
    useEffect, 
    useState
  } = PageDependencies();
  const [tableReload, setTableReload] = useState("");
  const [extraPostModelDevelopment, setExtraPostModelDevelopment] = useState({
    selectedItemIds: [],
  });
  const [tableReloadProject, setTableReloadProject] = useState("");
  const [extraPostModelProject, setExtraPostModelProject] = useState({
    selectedItemIds: [],
  });
  const debug = layoutManager.Layout_GetDebugModel(props);
  useEffect(() => {
    layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
  }, []);

  return (
    <Fragment>
      <div className="app-main__inner">
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i className="pe-7s-map icon-gradient bg-tempting-azure"></i>
              </div>
              <div>
                Your developments and projects
                <div className="page-title-subheading">
                  Select one or more developments to show the projects within
                  your selection.
                </div>
              </div>
            </div>
          </div>
        </div>
        <DevelopmentDataTable
          extraPostModelDevelopment={extraPostModelDevelopment}
          setExtraPostModelDevelopment={setExtraPostModelDevelopment}
          tableReload={tableReload}
          setTableReload={setTableReload}
          setTableReloadProject={setTableReloadProject}
          debug = {debug}
        ></DevelopmentDataTable>
        <ProjectDataTable
          extraPostModelProject={extraPostModelProject}
          setExtraPostModelProject={setExtraPostModelProject}
          tableReload={tableReloadProject}
          setTableReload={setTableReloadProject}
          extraPostModelDevelopment={extraPostModelDevelopment}
          debug = {debug}
        ></ProjectDataTable>
      </div>
    </Fragment>
  );
};
export default connectAppStore(DevelopmentAccessor);
